import React from 'react';
import { Container, Jumbotron, Row, Col } from 'reactstrap';
import { Route } from 'react-router-dom';
import Footer from 'components/common/PageFooter';
import SignInButton from 'components/users/SignInButton';
import logo from 'assets/images/panasonic.png';
import logo_next_insights from 'assets/images/next-insights.svg';

class PublicHomePage extends React.Component {
    constructor(props) {
        super(props);
        console.debug('CONSTRUCTOR PROPS: ', props);

        this.state = {
            pageTitle: '',
            pageSubTitle: 'Get Critical Insight Into Your Operations.'
        };
    }

    async componentDidMount() {
        if (typeof process.env.REACT_APP_TITLE != 'undefined') {
            this.setState({ pageTitle: process.env.REACT_APP_TITLE });
        }
    }

    render() {
        let { pageSubTitle } = this.state;

        return (
            <div>
                <Container>
                    <Jumbotron className="bg-panasonic">
                        <Row>
                            <Col className="text-center py-3">
                                <img src={logo} className="logo" alt="PanaAir" onClick={this.onLogoClick} />
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-center py-3">
                                <img src={logo_next_insights} alt={'Product Analytics'} />
                            </Col>
                        </Row>

                        <br />
                        <p className="text-center">{pageSubTitle}</p>

                        <Row>
                            <Col sm={{ size: 4, offset: 4 }} className="py-3">
                                <SignInButton className="btn btn-light" {...this.props} />
                            </Col>
                        </Row>
                    </Jumbotron>
                </Container>

                <Route path="/" component={Footer} />
            </div>
        );
    }
}

export default PublicHomePage;
