function getPagesDropdownListHelper(reportSubType) {
    let dropdownList;

    switch (reportSubType) {
        case 'Seatback':
            dropdownList = [
                {
                    label: 'Total Page Views (Top 10)',
                    unit: 'count',
                    value: 'pages.most_popular.total_views',
                    text: 'Seatback Total Page Views',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Page Views'
                },
                {
                    label: 'Total Viewers (Top 10)',
                    unit: 'count',
                    value: 'pages.most_popular.total_viewers',
                    text: 'Seatback Total Viewers',
                    free_tier: 0,
                    section: 'top_10_total_viewers',
                    small_count_label: 'Total Viewers'
                },
                {
                    label: 'Total Browsing Time (Top 10)',
                    unit: 'hr',
                    value: 'pages.most_popular.total_browsing_time',
                    text: 'Seatback Total Page Browsing Time',
                    small_count_label: 'Total Browsing Time',
                    section: 'top_10_total_time'
                },
                {
                    label: 'Total Page Views (Bottom 10)',
                    unit: 'count',
                    value: 'pages.least_popular.total_views',
                    text: 'Seatback Total Page Views',
                    free_tier: 1,
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Page Views'
                },
                {
                    label: 'Total Viewers (Bottom 10)',
                    unit: 'count',
                    value: 'pages.least_popular.total_viewers',
                    text: 'Seatback Total Viewers',
                    free_tier: 0,
                    section: 'bottom_10_total_viewers',
                    small_count_label: 'Total Viewers'
                },
                {
                    label: 'Total Browsing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'pages.least_popular.total_browsing_time',
                    text: ' Seatback Total Page Browsing Time',
                    free_tier: 0,
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Browsing Time'
                }
            ];
            break;
        case 'Excelseatback':
            dropdownList = [
                {
                    label: 'Total Page Views',
                    unit: 'count',
                    value: 'pages.all_records.total_views',
                    text: 'Seatback Total Page Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Viewers',
                    unit: 'count',
                    value: 'pages.all_records.total_viewers',
                    text: 'Seatback Total Viewers'
                },
                {
                    label: 'Total Browsing Time',
                    unit: 'hr',
                    value: 'pages.all_records.total_browsing_time',
                    text: 'Seatback Total Page Browsing Time'
                }
            ];
            break;
        case 'Portal':
            dropdownList = [
                {
                    label: 'Total Page Views (Top 10)',
                    unit: 'count',
                    value: 'pages.most_popular.total_views',
                    text: 'Portal Browsing Total Page Views',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Page Views'
                },
                {
                    label: 'Total Viewers (Top 10)',
                    unit: 'count',
                    value: 'pages.most_popular.total_viewers',
                    text: 'Portal Browsing Total Viewers',
                    free_tier: 0,
                    section: 'top_10_total_viewers',
                    small_count_label: 'Total Viewers'
                },
                {
                    label: 'Total Browsing Time (Top 10)',
                    unit: 'hr',
                    value: 'pages.most_popular.total_browsing_time',
                    text: 'Portal Total Page Browsing Time',
                    free_tier: 0,
                    section: 'top_10_total_time',
                    small_count_label: 'Total Browsing Time'
                },
                {
                    label: 'Total Page Views (Bottom 10)',
                    unit: 'count',
                    value: 'pages.least_popular.total_views',
                    text: 'Portal Browsing Total Page Views',
                    free_tier: 1,
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Page Views'
                },
                {
                    label: 'Total Viewers (Bottom 10)',
                    unit: 'count',
                    value: 'pages.least_popular.total_viewers',
                    text: 'Portal Browsing Total Viewers',
                    free_tier: 0,
                    section: 'bottom_10_total_viewers',
                    small_count_label: 'Total Viewers'
                },
                {
                    label: 'Total Browsing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'pages.least_popular.total_browsing_time',
                    text: 'Portal Total Page Browsing Time',
                    free_tier: 0,
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Browsing Time'
                }
            ];
            break;
        case 'Excelportal':
            dropdownList = [
                {
                    label: 'Total Page Views',
                    unit: 'count',
                    value: 'pages.all_records.total_views',
                    text: 'Portal Browsing Total Page Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Viewers',
                    unit: 'count',
                    value: 'pages.all_records.total_time',
                    text: 'Portal Browsing Total Viewers'
                },
                {
                    label: 'Total Browsing Time',
                    unit: 'hr',
                    value: 'pages.all_records.total_browsing_timee',
                    text: 'Portal Total Page Browsing Time'
                }
            ];
            break;
        case 'Companion App':
            dropdownList = [
                {
                    label: 'Total Page Views (Top 10)',
                    unit: 'count',
                    value: 'pages.most_popular.total_views',
                    text: 'Companion App Browsing Total Page Views',
                    default: 1,
                    free_tier: 1,
                    small_count_label: 'Total Page Views'
                },
                {
                    label: 'Total Viewers (Top 10)',
                    unit: 'count',
                    value: 'pages.most_popular.total_viewers',
                    text: 'Companion App Browsing Total Viewers',
                    free_tier: 0,
                    small_count_label: 'Total Viewers'
                },
                {
                    label: 'Total Page Views (Bottom 10)',
                    unit: 'count',
                    value: 'pages.least_popular.total_views',
                    text: 'Companion App Browsing Total Page Views',
                    free_tier: 1,
                    small_count_label: 'Total Page Views'
                },
                {
                    label: 'Total Viewers (Bottom 10)',
                    unit: 'count',
                    value: 'pages.least_popular.total_viewers',
                    text: 'Companion App Browsing Total Viewers',
                    free_tier: 0,
                    small_count_label: 'Total Viewers'
                }
            ];
            break;
        default:
    }

    return dropdownList;
}

export default getPagesDropdownListHelper;
