function getVerticalChartRequestedSectionsHelper(requestedSection) {
    let sections;

    switch (requestedSection) {
        case 'Companion App':
            sections = [
                {
                    key: 'total_usage_time',
                    title: 'Total Time Spent',
                    y_axis_label: 'Time',
                    label: 'Total Time Spent',
                    unit: 'time',
                    basic: '1'
                },
                {
                    key: 'total_users',
                    title: 'Total Users',
                    y_axis_label: 'Users',
                    label: 'Total Users',
                    unit: 'count',
                    basic: '0'
                },
                {
                    key: 'total_media_launches',
                    title: 'Total Media Launches',
                    y_axis_label: 'Launches',
                    label: 'Total Media Launches',
                    unit: 'count',
                    basic: '1'
                }
            ];
            break;
        default:
            sections = [
                {
                    key: 'total_usage_time',
                    title: 'Total Time Spent',
                    y_axis_label: 'Time',
                    label: 'Total Time Spent',
                    unit: 'time',
                    basic: '1'
                },
                {
                    key: 'total_users',
                    title: 'Total Viewers',
                    y_axis_label: 'Viewers',
                    label: 'Total Viewers',
                    unit: 'count',
                    basic: '0'
                },
                {
                    key: 'total_unique_views',
                    title: 'Total Distinct Views',
                    y_axis_label: 'Views',
                    label: 'Total Distinct Views',
                    unit: 'count',
                    basic: '1'
                }
            ];
            break;
    }

    return sections;
}

export default getVerticalChartRequestedSectionsHelper;
