function getDonutDropdownListHelper(requestedSection) {
    let dropdownList;

    switch (requestedSection) {
        case 'passenger_engagement':
            dropdownList = [
                {
                    label: 'Total Hits',
                    unit: 'count',
                    value: 'passenger_engagement.total_views',
                    text: 'Total Hits',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Usage Time',
                    unit: 'hr',
                    value: 'passenger_engagement.total_time',
                    text: 'Usage Time',
                    free_tier: 0
                }
            ];
            break;
        case 'ped_vs_seatback':
            dropdownList = [
                {
                    label: 'Total Views',
                    unit: 'count',
                    value: 'ped_vs_seatback.total_views',
                    text: 'Total Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'ped_vs_seatback.total_time',
                    text: 'Viewing Time',
                    free_tier: 0
                }
            ];
            break;
        case 'audio_passenger_engagement':
            dropdownList = [
                {
                    label: 'Total Hits',
                    unit: 'count',
                    value: 'passenger_engagement.total_views',
                    text: 'Total Hits',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Usage Time',
                    unit: 'hr',
                    value: 'passenger_engagement.total_time',
                    text: 'Usage Time',
                    free_tier: 0
                }
            ];
            break;
        case 'audio_ped_vs_seatback':
            dropdownList = [
                {
                    label: 'Total Listens',
                    unit: 'count',
                    value: 'ped_vs_seatback.total_views',
                    text: 'Total Listens',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Listening Time',
                    unit: 'hr',
                    value: 'ped_vs_seatback.total_time',
                    text: 'Listening Time',
                    free_tier: 0
                }
            ];
            break;
        case 'games_passenger_engagement':
            dropdownList = [
                {
                    label: 'Total Hits',
                    unit: 'count',
                    value: 'passenger_engagement.total_views',
                    text: 'Total Hits',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Usage Time',
                    unit: 'hr',
                    value: 'passenger_engagement.total_time',
                    text: 'Usage Time',
                    free_tier: 0
                }
            ];
            break;
        case 'games_ped_vs_seatback':
            dropdownList = [
                {
                    label: 'Total Plays',
                    unit: 'count',
                    value: 'ped_vs_seatback.total_views',
                    text: 'Total Plays',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Playing Time',
                    unit: 'hr',
                    value: 'ped_vs_seatback.total_time',
                    text: 'Playing Time',
                    free_tier: 0
                }
            ];
            break;
        case 'ereader_passenger_engagement':
            dropdownList = [
                {
                    label: 'Total Hits',
                    unit: 'count',
                    value: 'passenger_engagement.total_views',
                    text: 'Total Hits',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Usage Time',
                    unit: 'hr',
                    value: 'passenger_engagement.total_time',
                    text: 'Total Usage Time',
                    free_tier: 0
                }
                //{ label: "Avg. Reading Time", unit: "hr", value: "passenger_engagement.avg_reading_time", text: "Avg. Reading Time", "free_tier" : 0 },
            ];
            break;
        case 'ereader_ped_vs_seatback':
        case 'ereader_passenger_touchpoints':
            dropdownList = [
                {
                    label: 'Total Title Views',
                    unit: 'count',
                    value: 'ped_vs_seatback.total_title_views',
                    text: 'Total Title Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Readers',
                    unit: 'count',
                    value: 'ped_vs_seatback.total_readers',
                    text: 'Total Readers',
                    default: 1,
                    free_tier: 0
                },
                {
                    label: 'Total Reading Time',
                    unit: 'hr',
                    value: 'ped_vs_seatback.total_reading_time',
                    text: 'Total Reading Time',
                    free_tier: 0
                }
            ];
            break;
        case 'portal_device_os':
            dropdownList = [
                {
                    label: 'Device Count',
                    unit: 'pr',
                    value: 'portal_device_os.total_counts',
                    text: 'Total Count',
                    default: 1,
                    free_tier: 1
                }
            ];
            break;
        case 'portal_device_browser':
            dropdownList = [
                {
                    label: 'Device Count',
                    unit: 'pr',
                    value: 'portal_device_browser.total_counts',
                    text: 'Total Count',
                    default: 1,
                    free_tier: 1
                }
            ];
            break;
        case 'portal_device_type':
            dropdownList = [
                {
                    label: 'Device Count',
                    unit: 'pr',
                    value: 'portal_device_type.total_counts',
                    text: 'Total Count',
                    default: 1,
                    free_tier: 1
                }
            ];
            break;
        case 'media_launches_by_content_type':
            dropdownList = [
                {
                    label: 'Media launches by Content Type',
                    unit: 'pr',
                    value: 'passenger_engagement.total_counts',
                    text: 'Media launches by Content Type',
                    default: 1,
                    free_tier: 1
                }
            ];
            break;
        case 'Excelpassenger_engagement':
            dropdownList = [
                {
                    label: 'Total Hits',
                    unit: 'pr',
                    value: 'passenger_engagement.total_views',
                    text: 'Total Hits',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Usage Time',
                    unit: 'hr',
                    value: 'passenger_engagement.total_time',
                    text: 'Usage Time',
                    free_tier: 0
                }
            ];
            break;
        case 'Excelpassenger_touchpoints':
            dropdownList = [
                {
                    label: 'Total Views',
                    unit: 'pr',
                    value: 'ped_vs_seatback.total_views',
                    text: 'Total Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'ped_vs_seatback.total_time',
                    text: 'Viewing Time',
                    free_tier: 0
                }
            ];
            break;
        case 'Excel_audio_passenger_engagement':
            dropdownList = [
                {
                    label: 'Total Hits',
                    unit: 'pr',
                    value: 'passenger_engagement.total_views',
                    text: 'Total Hits',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Usage Time',
                    unit: 'hr',
                    value: 'passenger_engagement.total_time',
                    text: 'Usage Time',
                    free_tier: 0
                }
            ];
            break;
        case 'Excel_audio_passenger_touchpoints':
            dropdownList = [
                {
                    label: 'Total Listens',
                    unit: 'pr',
                    value: 'ped_vs_seatback.total_views',
                    text: 'Total Listens',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Listening Time',
                    unit: 'hr',
                    value: 'ped_vs_seatback.total_time',
                    text: 'Listening Time',
                    free_tier: 0
                }
            ];
            break;
        case 'Excel_games_passenger_engagement':
            dropdownList = [
                {
                    label: 'Total Hits',
                    unit: 'pr',
                    value: 'passenger_engagement.total_views',
                    text: 'Total Hits',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Usage Time',
                    unit: 'hr',
                    value: 'passenger_engagement.total_time',
                    text: 'Usage Time',
                    free_tier: 0
                }
            ];
            break;
        case 'Excel_games_passenger_touchpoints':
            dropdownList = [
                {
                    label: 'Total Plays',
                    unit: 'pr',
                    value: 'ped_vs_seatback.total_views',
                    text: 'Total Plays',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Playing Time',
                    unit: 'hr',
                    value: 'ped_vs_seatback.total_time',
                    text: 'Playing Time',
                    free_tier: 0
                }
            ];
            break;
        case 'Excelportal_device_os':
            dropdownList = [
                {
                    label: 'Device Count by OS',
                    unit: 'pr',
                    value: 'portal_device_os.portal_device_os',
                    text: 'Device Count',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Device Count by OS version',
                    unit: 'pr',
                    value: 'portal_device_os.portal_device_os_version',
                    text: 'Device Count',
                    default: 1,
                    free_tier: 1
                }
            ];
            break;
        case 'Excelportal_device_browser':
            dropdownList = [
                {
                    label: 'Device Count',
                    unit: 'pr',
                    value: 'portal_device_browser.total_counts',
                    text: 'Device Count',
                    default: 1,
                    free_tier: 1
                }
            ];
            break;
        case 'Excelportal_device_type':
            dropdownList = [
                {
                    label: 'Device Count by Device Model',
                    unit: 'pr',
                    value: 'portal_device_type.total_counts',
                    text: 'Device Count',
                    default: 1,
                    free_tier: 1
                }
            ];
            break;
        case 'Excelmedia_launches_by_content_type':
            dropdownList = [
                {
                    label: 'Media launches by Content Type',
                    unit: 'pr',
                    value: 'passenger_engagement.total_counts',
                    text: 'Media launches by Content Type',
                    default: 1,
                    free_tier: 1
                }
            ];
            break;
        default:
            //  throw new Error("Please check the label you are passing")
            break;
    }

    return dropdownList;
}

export default getDonutDropdownListHelper;
