export const USER_HAS_ACCESS_NO = 0;
export const USER_HAS_ACCESS_YES = 1;
export const USER_HAS_ACCESS_CHECKING = 2;
export const USER_HAS_NO_SUBSCRIPTION = 3;

export const SUBSCRIPTION_STATUS_ACTIVE = 'Active';
export const SUBSCRIPTION_STATUS_EXPIRED = 'Expired';
export const SUBSCRIPTION_STATUS_NOT_STARTED = 'Not Started';
export const SUBSCRIPTION_STATUS_NO_SUBSCRIPTION = 'No Subscription';

export const NO_SUBSCRIPTION_MESSAGE = 'Sorry, no subscription found.';

export const THROTTLE_FETCH_TIME = 1500;

export const TABLE_PAGINATION_ROWS_PER_PAGE = 10;

export const TENANT_ICAO_PANASKY = 'pac';
export const TENANT_ICAO_PANASONIC_AVIONICS = 'p4ll';

export const DISCLAIMER_PANASKY_HEADER = 'Please note: ';
export const DISCLAIMER_PANASKY = 'The PanaSky airline dataset is fictional and used for demonstration purposes only.';
export const DISCLAIMER_PANASONIC_AVIONICS_HEADER = 'Please note: ';
export const DISCLAIMER_PANASONIC_AVIONICS =
    'Panasonic Avionics Corp is an PAC internal only tenant and has confidential airline related information meant for PAC internal evaluation only.';
export const DISCLAIMER_STAGE_2_ACTIVE_HEADER = 'Evaluation stage: ';
export const DISCLAIMER_STAGE_2_ACTIVE =
    "These reports may not reflect the most recent data and is intended for evaluating the tool's capabilities using airlines real data.";
export const DISCLAIMER_STAGE_2_EXPIRED = 'Expired Subscription';
export const DISCLAIMER_STAGE_2_FUTURE = 'Future Subscription';
export const DISCLAIMER_STAGE_1_ACTIVE_HEADER = 'Demo Stage: ';
export const DISCLAIMER_STAGE_1_ACTIVE =
    "The dataset used in this demo is fictional and is meant for demonstrating the tool's capabilities only.";
export const DISCLAIMER_ALL_TENANTS_VIEW =
    'This is a PAC internal portal and not for external view. Some reports may consist sensitive data related to PAC.';
export const DISCLAIMER_ALL_TENANTS_VIEW_POPUP =
    'This is a PAC internal only portal and has confidential airline related information meant for PAC internal evaluation only.';
export const SUBSCRIPTION_STAGE_0 = 0;
export const SUBSCRIPTION_STAGE_1 = 1;
export const SUBSCRIPTION_STAGE_2 = 2;
export const SUBSCRIPTION_STAGE_3 = 3;

export const SUBSCRIPTION_STAGES = [1, 2, 3];

export const GROUP_QUALITY_MANAGEMENT = 'quality_management';
export const GROUP_PAC_RACK_TESTER = 'racktesters_865cec22-e8d5-41d4-9fe2-ba86587aa5c7';
export const GROUP_INSIGHTS_PLM = 'insights_plm_865cec22-e8d5-41d4-9fe2-ba86587aa5c7';

export const ADMIN_GROUPS = [
    'app_admin_865cec22-e8d5-41d4-9fe2-ba86587aa5c7',
    'developer_865cec22-e8d5-41d4-9fe2-ba86587aa5c7',
    'plm_865cec22-e8d5-41d4-9fe2-ba86587aa5c7',
    'prodmarketing_865cec22-e8d5-41d4-9fe2-ba86587aa5c7'
];
