import React from 'react';
import UtilHelper from 'util/UtilHelper.js';

const utilHelper = new UtilHelper();

class WidgetTabsDynamic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0
        };
    }

    async componentDidMount() {
        await this.tabClick(0, false);
    }

    componentDidUpdate() {}

    async tabClick(i, captureInteraction = true) {
        this.setState({ active: i }, () => this.props.widgetTabFunction(i, captureInteraction));
    }

    tabClick2 = (i) => {
        this.setState({ active: i }, () => this.props.widgetTabFunction(i));
    };

    render() {
        let tabsData = this.props.tabs;
        let tabs = [];

        let insightsTab = this.props.page === 'Overview' ? 'insights' : this.props.page;

        for (let i = 0; i < tabsData.length; i++) {
            let active = this.state.active === i;

            let tabValue = tabsData[i].value;
            if (tabsData[i].unit && tabValue) {
                if (tabsData[i].unit.toLowerCase() === 'time') {
                    tabValue = ['total_usage_time', 'total_reading_time'].includes(tabsData[i].key)
                        ? utilHelper.secondsToHmsForDynamicTabs(tabValue)
                        : utilHelper.secondsToHoursMinutes(tabValue);
                } else {
                    tabValue = utilHelper.formatToUnitsForPillBox(tabValue) + ` ${tabsData[i].y_axis_label}`;
                }
            } else {
                tabValue = 'No data';
            }

            if (this.props.showWorkbenchLink && this.props.comparisonRangeText) {
                let changeArrow =
                    tabsData[i].comparison_value > 0 ? (
                        <span style={{ fontSize: '12px' }}>{'\u25B2  '}</span>
                    ) : tabsData[i].comparison_value < 0 ? (
                        <span style={{ fontSize: '12px' }}>{'\u25BC  '}</span>
                    ) : tabsData[i].comparison_value == 0 ? (
                        <i className="fas fa-sort" style={{ fontSize: 15 }}></i>
                    ) : (
                        ''
                    );
                let changeColor =
                    tabsData[i].comparison_value > 0
                        ? '#225522'
                        : tabsData[i].comparison_value < 0
                        ? '#FF0000'
                        : '#9E9E9E';

                let comparisonValue = tabsData[i].comparison_value;
                if (comparisonValue === null) {
                    comparisonValue = 'N/A';
                } else if (Math.abs(comparisonValue) > 999) {
                    comparisonValue = '999+%';
                } else if (comparisonValue > 100) {
                    comparisonValue = `${Math.abs(Math.round(comparisonValue))}%`;
                } else if (comparisonValue || comparisonValue === 0) {
                    comparisonValue = `${Math.abs(comparisonValue)}%`;
                }

                tabs.push(
                    <div
                        className={active ? 'tab active ' + insightsTab : 'tab ' + insightsTab}
                        onClick={this.tabClick.bind(this, i)}
                        key={Math.random()}
                        id={Math.random()}
                    >
                        <div
                            className="tab-title"
                            data-tooltip={tabsData[i].title}
                            style={{ fontWeight: active ? 'bold' : 'normal' }}
                        >
                            {tabsData[i].title}
                        </div>
                        <div className="tab-data" style={{ fontWeight: active ? 'bold' : '500' }}>
                            {tabValue}
                        </div>
                        <div className="tab-change" style={{ color: changeColor }}>
                            {changeArrow}
                            {comparisonValue}
                        </div>
                    </div>
                );
            } else {
                tabs.push(
                    <div
                        className={active ? 'tab free active ' + insightsTab : 'tab free ' + insightsTab}
                        onClick={this.tabClick.bind(this, i)}
                        key={Math.random()}
                        id={Math.random()}
                    >
                        <div
                            className="tab-title"
                            data-tooltip={tabsData[i].title}
                            style={{ fontWeight: active ? 'bold' : 'normal' }}
                        >
                            {tabsData[i].title}
                        </div>
                        <div className="tab-data" style={{ fontWeight: active ? 'bold' : '500' }}>
                            {tabValue}
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className="tabs" key={Math.random()}>
                {tabs}
            </div>
        );
    }
}

export default WidgetTabsDynamic;
