import React from 'react';
import { Row } from 'reactstrap';
import CarouselContainer from 'components/containers/CarouselContainer';
import DonutContainer from 'components/containers/DonutContainer';
import TrendContainer from 'components/containers/TrendContainer';
import HorizontalBarChartContainer from 'components/containers/HorizontalBarChartContainer';
import PlaneContainer from 'components/containers/PlaneContainer';
import TableContainer from 'components/containers/TableContainer';

let page = 'ereader';
let displayPageName = 'EReader';

class EReaderOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let dashboard = (
            <div>
                <CarouselContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    contentType="Electronic Reader"
                    title="eReader Rankings"
                    workbenchTitle="titles"
                    page={page}
                    pageName={displayPageName}
                    forceReloadAll={this.props.forceReloadAll}
                    tabFunction={this.props.tabFunction}
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    subscription={this.props.subscription}
                    app={this.props.app}
                    subscriptionStage={this.props.subscriptionStage}
                />

                <Row>
                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        renderType="row"
                        page={page}
                        pageName={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        tabFunction={this.props.tabFunction}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        allowedApps={this.props.allowedApps}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>

                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    contentType="Electronic Reader"
                    forceReloadTrend={this.props.forceReloadTrend}
                    forceReloadAll={this.props.forceReloadAll}
                    title="Trends"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="ereader_overview_trend_1"
                    id="ereader_overview_trend_1"
                    tabFunction={this.props.tabFunction}
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    subscription={this.props.subscription}
                    app={this.props.app}
                    subscriptionStage={this.props.subscriptionStage}
                />

                <PlaneContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    contentType="Electronic Reader"
                    title="Cabin Class"
                    workbenchTitle="cabin_class_viewing"
                    page={page}
                    pageName={displayPageName}
                    forceReloadAll={this.props.forceReloadAll}
                    tabFunction={this.props.tabFunction}
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    subscription={this.props.subscription}
                    app={this.props.app}
                    subscriptionStage={this.props.subscriptionStage}
                />

                <Row>
                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        contentType="Electronic Reader"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Routes"
                        workbenchTitle="routes"
                        page={page}
                        pageName={displayPageName}
                        reportType="routes"
                        color="#F7A77F"
                        routes={true}
                        key="ereader_overview_routes_1"
                        id="ereader_overview_routes_1"
                        tabFunction={this.props.tabFunction}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        contentType="Electronic Reader"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Languages"
                        workbenchTitle="languages"
                        page={page}
                        pageName={displayPageName}
                        reportType="languages"
                        color="#7FCECD"
                        key="ereader_overview_languages_1"
                        id="ereader_overview_languages_1"
                        tabFunction={this.props.tabFunction}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>

                <Row>
                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        contentType="Electronic Reader"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Airlines"
                        workbenchTitle="airlines"
                        page={page}
                        pageName={displayPageName}
                        reportType="airlines"
                        color="#7FCECD"
                        key="ereader_overview_airlines_1"
                        id="ereader_overview_airlines_1"
                        tabFunction={this.props.tabFunction}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>
                <Row>
                    <TableContainer
                        pageName={displayPageName}
                        data={this.props.data}
                        dataset={this.props.dataset}
                        loading={this.props.loading}
                        appliedFilters={this.props.appliedFilters}
                        title="Electronic Reader"
                        app={this.props.app}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                    />
                </Row>
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default EReaderOverview;
