export const QuicksightData = {
    mapping : [{
        path: "/app/premium-dashboard/one-media",
        dashboard_id: "91344813-f0ab-4202-923e-cd72fe467cf7",
        component_key: "quick-sight-workbench-1"
    },
        {
            path: "/app/premium-dashboard/app-usage",
            dashboard_id: "d044b1f0-911a-4499-8c20-460d124c4545",
            component_key: "quick-sight-workbench-2"
        },
        {
            path: "/app/premium-dashboard/screen-transition",
            dashboard_id: "d27c7516-c1bf-4491-b8a6-7939f58abac5",
            component_key: "quick-sight-workbench-3"
        },
        {
            path: "/",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-100"
        },
        {
            path: "/app",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-100"
        },
        {
            path: "/app/entertainment/movies",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-101"
        },
        {
            path: "/app/entertainment/tv-series",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-102"
        },
        {
            path: "/app/entertainment/audio",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-103"
        },
        {
            path: "/app/entertainment/games",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-104"
        },
        {
            path: "/app/entertainment/live-tv",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-105"
        },
        {
            path: "/app/web-portal/seatback",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-106"
        },
        {
            path: "/app/web-portal/portal",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-107"
        },
        {
            path: "/app/web-portal/arc",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-108"
        },
    ],
    mapping_demo : [{
        path: "/app/premium-dashboard/one-media",
        dashboard_id: "aa1005aa-6b50-433b-b5b8-e4e51d8acf1d"
    },
        {
            path: "/app/premium-dashboard/app-usage",
            dashboard_id: "233fe741-a4ed-46e5-accf-49ec9d96739a"
        },
        {
            path: "/app/premium-dashboard/screen-transition",
            dashboard_id: "b08957df-3045-4d78-99e3-c5650edebcd5"
        },
        {
            path: "/",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-100"
        },
        {
            path: "/app",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-100"
        },
        {
            path: "/app/entertainment/movies",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-101"
        },
        {
            path: "/app/entertainment/tv-series",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-102"
        },
        {
            path: "/app/entertainment/audio",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-103"
        },
        {
            path: "/app/entertainment/games",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-104"
        },
        {
            path: "/app/entertainment/live-tv",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-105"
        },
        {
            path: "/app/web-portal/seatback",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-106"
        },
        {
            path: "/app/web-portal/portal",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-107"
        },
        {
            path: "/app/web-portal/arc",
            dashboard_id: "e1c5c4d6-3cb3-430c-9bdb-9a8f1b7a0ebd",
            component_key: "quick-sight-workbench-108"
        },
    ],
    pac_tenantId : "903a42bc-985e-40d1-9c72-32b8c2e33397"
};