import React from 'react';
import { Row } from 'reactstrap';
import _, { debounce } from 'lodash';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import ErrorBoundary from 'util/ErrorBoundary.js';
import AppDataHelper from 'data/app-data-helper.js';
import AppDataRequestedSectionsHelper from 'data/app-data-requested-sections-helper.js';
import * as AppConstants from 'constants/app/constants';
import TrendWidget from 'components/updatedWidgets/TrendWidget';

const appDataHelper = new AppDataHelper();
const appDataRequestedSectionsHelper = new AppDataRequestedSectionsHelper();

class TrendContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: '',
            loading: true,
            error: false,
            requestedSections: []
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) ||
            prevProps.path !== this.props.path ||
            this.props.forceReloadTrend ||
            this.props.forceReloadAll
        ) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        this.setState({ loading: true });

        let selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));
        let reportSubType = '';
        if (this.props.contentType) {
            selectedFiltersDeepClone.content_types = [this.props.contentType];
            reportSubType = this.props.contentType;
        } else if (this.props.usageSource) {
            if (this.props.usageSource !== 'Insights') {
                selectedFiltersDeepClone.usage_source = this.props.usageSource;
            }
            reportSubType = this.props.usageSource;
        }

        let requestedSections = appDataRequestedSectionsHelper.getTrendChartRequestedSections(reportSubType);
        if (!this.props.showWorkbenchLink) {
            requestedSections = requestedSections.filter((requestedSection) => requestedSection.free_tier === 1);
        }
        if (
            this.props.usageSource &&
            this.props.usageSource === 'Insights' &&
            this.props.allowedApps &&
            this.props.allowedApps.length > 0
        ) {
            requestedSections = requestedSections.filter((requestedSection) =>
                this.props.allowedApps.includes(requestedSection.title)
            );
        }
        selectedFiltersDeepClone.requested_sections = requestedSections.map(function (section) {
            return section['key'];
        });
        if (this.props.usageSource === 'Companion App') {
            delete selectedFiltersDeepClone.usage_source;
            selectedFiltersDeepClone.launched_by = ['Companion App'];
        }

        if (this.props.subscription) {
            selectedFiltersDeepClone['tier'] = this.props.subscription;
        }

        if (this.props.app) {
            selectedFiltersDeepClone['app'] = this.props.app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        this.throttleFetch(selectedFiltersDeepClone);
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchNextInsightsMetricsAxios(
                selectedFiltersDeepClone,
                appDataHelper.getAPIPath('trend'),
                this.cancelRequestSource.token
            )
            .then((response) => {
                if (!response && !response.requested_sections) {
                    this.setState({ error: true, dataset: [], loading: false });
                } else {
                    this.setState({ error: false, dataset: response.requested_sections, loading: false });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, dataset: [], loading: true });
                } else {
                    this.setState({ error: true, dataset: [], loading: false });
                }
            });
    }

    renderChart() {
        let { dataset, loading } = this.state;

        let reportSubType = '';
        if (this.props.contentType) {
            reportSubType = this.props.contentType;
        } else if (this.props.usageSource) {
            reportSubType = this.props.usageSource;
        }

        let requestedSections = appDataRequestedSectionsHelper.getTrendChartRequestedSections(reportSubType);
        if (!this.props.showWorkbenchLink) {
            requestedSections = requestedSections.filter((requestedSection) => requestedSection.free_tier === 1);
        }
        if (
            this.props.usageSource &&
            this.props.usageSource === 'Insights' &&
            this.props.allowedApps &&
            this.props.allowedApps.length > 0
        ) {
            requestedSections = requestedSections.filter((requestedSection) =>
                this.props.allowedApps.includes(requestedSection.title)
            );
        }

        let tabData = [];
        if (dataset) {
            if (requestedSections && requestedSections.length > 0) {
                for (let i = 0; i < requestedSections.length; i++) {
                    let tab = JSON.parse(JSON.stringify(requestedSections[i]));
                    tab.value = null;
                    tab.comparison_value = null;
                    if (dataset[requestedSections[i].key]) {
                        let trendData = dataset[requestedSections[i].key];
                        tab.value = trendData.hasOwnProperty('value') ? trendData.value : null;
                        tab.comparison_value = trendData.hasOwnProperty('comparison_value')
                            ? trendData.comparison_value
                            : null;

                        tabData.push(tab);
                    }
                }
            }
        }

        return (
            <Row className={this.props.className ? this.props.className : ''}>
                <div className="align-component card">
                    <ErrorBoundary>
                        <TrendWidget
                            key={'line_chart_' + reportSubType}
                            data={dataset ? dataset : []}
                            tabData={tabData}
                            title={this.props.title}
                            page={this.props.page}
                            pageName={this.props.pageName}
                            reportSubType={reportSubType}
                            loading={loading}
                            showTabs={true}
                            showViewMore={false}
                            showFooter={false}
                            tabFunction={this.props.tabFunction}
                            workbench_type={this.props.workbenchTitle}
                            showWorkbenchLink={this.props.showWorkbenchLink}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            appliedFilters={this.props.appliedFilters.api}
                            subscription={this.props.subscription}
                            app={this.props.app}
                            subscriptionStage={this.props.subscriptionStage}
                        />
                    </ErrorBoundary>
                </div>
            </Row>
        );
    }

    render() {
        return <React.Fragment>{this.renderChart()}</React.Fragment>;
    }
}

export default TrendContainer;
