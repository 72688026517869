import { Auth, API } from 'aws-amplify';
import axios from 'axios';
import axiosRetry from 'axios-retry';

class BaseService {
    constructor(apiName, collectionPath) {
        this.apiName = apiName;
        this.collectionPath = collectionPath;
    }

    async getAuthorizationHeader() {
        const currentSession = await Auth.currentSession();
        return currentSession.idToken.jwtToken;
    }

    async fetchRecords(query, callback) {
        let path = this.collectionPath;

        let headers = {
            Authorization: await this.getAuthorizationHeader()
        };

        let queryStringParameters = { start: 0, limit: 3 };
        // overwrite
        queryStringParameters = query;

        let options = { headers: headers, queryStringParameters: queryStringParameters };

        this.makeGetApiCall(this.apiName, path, options).then(callback);
    }

    async fetch(recordId, callback) {
        let path = this.collectionPath + '/' + recordId;

        let headers = {
            Authorization: await this.getAuthorizationHeader()
        };
        let queryStringParameters = {};
        let options = { headers: headers, queryStringParameters: queryStringParameters };

        this.makeGetApiCall(this.apiName, path, options).then(callback);
    }

    async post(apiName, path, options) {
        return new Promise((resolve, reject) => {
            this.makePostApiCall(apiName, path, options).then((response) => {
                //console.log('SERVICE - response = ' + JSON.stringify(response));
                if (response && response.response) {
                    return resolve(response.response);
                } else {
                    return reject(response);
                }
            });
        });
    }

    async postAxios(path, data, options, cancelRequestToken) {
        if (options.headers) {
            options.headers.Authorization = await this.getAuthorizationHeader();
        }

        options.cancelToken = cancelRequestToken;

        if (window.sessionStorage.getItem('all_tenants_view') === 'true') {
            let adminAPINameMap = {
                '/v2/metrics/popularity_metrics_data': 'popularity_metrics_data',
                '/v2/metrics/media_usage_data': 'media_usage_data',
                '/v2/metrics/trend_usage_data': 'trend_usage_data',
                '/v2/metrics/cabin_class_data': 'cabin_class_data',
                '/v2/metrics/engagement_data': 'engagement_data',
                '/v2/metrics/flight_metrics_data': 'flight_metrics_data',
                '/v2/metrics/screen_transition_data': 'screen_transition_data',
                '/v2/metrics/media_usage_by_flight_phases': 'media_usage_by_flight_phases',
                '/v2/metrics/seat_usage_data': 'seat_usage_data',
                '/v2/metrics/flight_list_data': 'flight_list_data',
                '/v2/metrics/workbench_metrics_data': 'workbench_metrics_data'
            };

            data['airlines'] = [window.sessionStorage.getItem('pac_selected_tenant_icao_code').toUpperCase()];

            let stationTitle = window.sessionStorage.getItem('channel_title');
            if (stationTitle && stationTitle !== 'ALL') {
                data['channel_title'] = stationTitle;
            }

            let region = window.sessionStorage.getItem('region');
            if (region && region !== 'ALL') {
                data['region'] = region;
            }

            delete data['tier'];
            options.headers['admin_api_name'] = adminAPINameMap[path];
            path = process.env.REACT_APP_INSIGHTS_API_URL + '/v2/admin/all_airlines_data';
        } else {
            path = process.env.REACT_APP_INSIGHTS_API_URL + path;
        }

        return new Promise((resolve, reject) => {
            this.makePostApiCallAxios(path, data, options).then((response) => {
                if (response && response.data && response.data.response) {
                    return resolve(response.data.response);
                } else {
                    return reject(response);
                }
            });
        });
    }

    async postAdminAxios(path, data, options, cancelRequestToken) {
        if (options.headers) {
            options.headers.Authorization = await this.getAuthorizationHeader();
        }

        options.cancelToken = cancelRequestToken;
        path = process.env.REACT_APP_INSIGHTS_API_URL + path;

        return new Promise((resolve, reject) => {
            this.makePostApiCallAxios(path, data, options).then((response) => {
                //console.log('SERVICE - response = ' + JSON.stringify(response));
                if (response && response.data) {
                    return resolve(response.data);
                } else {
                    //if (response && response.error && response.error === 'Operation canceled by the user.') {
                    //    return reject(response);
                    //}
                    return reject(response);
                }
            });
        });
    }

    async add(payload, callback) {
        let path = '/feedback/';

        let headers = {
            'Content-Type': 'application/json',
            Authorization: await this.getAuthorizationHeader()
        };

        let options = {
            body: payload,
            headers: headers
        };
        return new Promise((resolve, reject) => {
            this.makePostApiCall(this.apiName, path, options).then((response) => {
                if (response.status) {
                    return resolve(response);
                } else {
                    return reject(response.status, response.json());
                }
            });
        });
    }

    async update(recordId, payload, callback) {
        let path = this.collectionPath + '/' + recordId;

        let headers = {
            'Content-Type': 'application/json',
            Authorization: await this.getAuthorizationHeader()
        };

        let options = {
            body: payload,
            headers: headers
        };

        this.makePutApiCall(this.apiName, path, options).then(callback);
    }

    async deleteRecord(recordId, callback) {
        let path = this.collectionPath + '/' + recordId;

        let headers = {
            Authorization: await this.getAuthorizationHeader()
        };

        let queryStringParameters = {};
        let options = { headers: headers, queryStringParameters: queryStringParameters };

        this.makeDeleteApiCall(this.apiName, path, options).then(callback);
    }

    async makeGetApiCall(apiName, path, options) {
        console.debug(`making GET call to ${apiName} api with path: ${path}`);

        try {
            return await API.get(apiName, path, options);
        } catch (e) {
            console.error(e);
        }
    }

    async makeGetApiCallAxios(path, options) {
        console.debug(`making GET call(axios) with path: ${path}`);

        try {
            return await axios.get(path, options);
        } catch (e) {
            console.error(e);
        }
    }

    async makePostApiCall(apiName, path, options) {
        console.debug(`making POST call to ${apiName} api with path: ${path}`);

        try {
            return await API.post(apiName, path, options);
        } catch (e) {
            console.error(e);
        }
    }

    async makePostApiCallAxios(path, data, options) {
        console.debug(`making POST call(axios) with path: ${path}`);

        axiosRetry(axios, {
            retries: 2,
            shouldResetTimeout: true,
            retryCondition: (_error) => {
                return true;
            },
            retryDelay: (retryCount) => {
                if (retryCount === 1) {
                    return retryCount * 3000;
                } else {
                    return retryCount * 9000;
                }
            }
        });

        try {
            return await axios.post(path, data, options);
        } catch (e) {
            console.error(e);
            if (e && e.message === 'Operation canceled by the user.') {
                return { error: 'Operation canceled by the user.' };
            }
        }
    }

    async makePutApiCall(apiName, path, options) {
        console.debug(`making PUT call to ${apiName} api with path: ${path}`);

        try {
            return await API.put(apiName, path, options);
        } catch (e) {
            console.error(e);
        }
    }

    async makeDeleteApiCall(apiName, path, options) {
        console.debug(`making DELETE call to ${apiName} api with path: ${path}`);

        try {
            return await API.del(apiName, path, options);
        } catch (e) {
            console.error(e);
        }
    }
}

export default BaseService;
