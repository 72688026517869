import { QuicksightData } from 'data/quicksight-data.js';

export default class QuicksightDataHelper {
    getDropdownValues = function (path) {
        let dropdownList;

        switch (path) {
            case '/':
            case '/app/':
            case '/app':
                dropdownList = [
                    {
                        label: 'Passenger Engagement',
                        value: 'passenger_engagement',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_MyInsights_Metrics_Dashboard_Stage',
                            dev: 'Next_Insights_CAE_MyInsights_Metrics_Dashboard_Dev',
                            test: 'Next_Insights_CAE_MyInsights_Metrics_Dashboard_Test',
                            stage: 'Next_Insights_CAE_MyInsights_Metrics_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_MyInsights_Metrics_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-100',
                        default: 1,
                        content_type: '',
                        iframe_height: '1360px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'Flight Data Metrics',
                        value: 'flight_data_metrics',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Flight_Metric_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Flight_Metric_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Flight_Metric_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Flight_Metric_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Flight_Metric_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-101',
                        default: 0,
                        content_type: '',
                        include_content_types: ['Screen Transition'],
                        iframe_height: '620px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'Passenger Touchpoints',
                        value: 'passenger_touch_points',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Touch_Point_Metrics_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Touch_Point_Metrics_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Touch_Point_Metrics_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Touch_Point_Metrics_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Touch_Point_Metrics_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-102',
                        default: 0,
                        content_type: '',
                        include_content_types: ['Screen Transition'],
                        iframe_height: '620px',
                        iframe_width: '1032px'
                    }
                    //{ label: "Passenger Touchpoints", value: "passenger_touch_points", dashboard_id: {cloud9: "adfa6e29-370e-4b79-ac8d-a0c0e349e01f", dev: "adfa6e29-370e-4b79-ac8d-a0c0e349e01f", test: "adfa6e29-370e-4b79-ac8d-a0c0e349e01f", stage: "5c172767-89f0-4bcc-9794-eaae222edb63", prd: "34306e82-e1a5-43b7-8b5a-5efc2c8e6410"}, component_key: "quick-sight-workbench-102", default: 0, "content_type": "", include_content_types: ["Screen Transition"], "iframe_height": "620px" , "iframe_width": "1032px"},
                    //{ label: "Passenger Touchpoints", value: "passenger_touch_points", dashboard_id: {cloud9: "Next_Insights_CAE_Touch_Point_Dashboard_Dev", dev: "Next_Insights_CAE_Touch_Point_Dashboard_Dev", test: "Next_Insights_CAE_Touch_Point_Dashboard_Test", stage: "Next_Insights_CAE_Touch_Point_Dashboard_Stage", prd: "Next_Insights_CAE_Touch_Point_Dashboard_Prd"}, component_key: "quick-sight-workbench-102", default: 0, "content_type": "", include_content_types: ["Screen Transition"], "iframe_height": "620px" , "iframe_width": "1032px"},
                ];
                break;
            case '/app/entertainment/movies':
                dropdownList = [
                    {
                        label: 'Passenger Engagement',
                        value: 'passenger_engagement',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Content_Metrics_Movies_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Content_Metrics_Movies_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Content_Metrics_Movies_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Content_Metrics_Movies_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Content_Metrics_Movies_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-200',
                        default: 1,
                        content_type: 'Movies',
                        iframe_height: '1080px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/entertainment/tv-series':
                dropdownList = [
                    {
                        label: 'Passenger Engagement',
                        value: 'passenger_engagement',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Content_Metrics_TV_Series_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Content_Metrics_TV_Series_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Content_Metrics_TV_Series_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Content_Metrics_TV_Series_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Content_Metrics_TV_Series_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-300',
                        default: 1,
                        content_type: 'TV Series',
                        iframe_height: '1080px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/entertainment/audio':
                dropdownList = [
                    {
                        label: 'Passenger Engagement',
                        value: 'passenger_engagement',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Content_Metrics_Audio_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Content_Metrics_Audio_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Content_Metrics_Audio_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Content_Metrics_Audio_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Content_Metrics_Audio_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-400',
                        default: 1,
                        content_type: 'Audio',
                        iframe_height: '1080px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/entertainment/games':
                dropdownList = [
                    {
                        label: 'Passenger Engagement',
                        value: 'passenger_engagement',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Content_Metrics_Games_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Content_Metrics_Games_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Content_Metrics_Games_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Content_Metrics_Games_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Content_Metrics_Games_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-500',
                        default: 1,
                        content_type: 'Games',
                        iframe_height: '1080px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/entertainment/live-tv':
                dropdownList = [
                    {
                        label: 'Passenger Engagement',
                        value: 'passenger_engagement',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Content_Metrics_Live_TV_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Content_Metrics_Live_TV_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Content_Metrics_Live_TV_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Content_Metrics_Live_TV_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Content_Metrics_Live_TV_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-600',
                        default: 1,
                        content_type: 'Live TV',
                        iframe_height: '915px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/entertainment/ereader':
                dropdownList = [
                    //{ label: "Usage Report", value: "usage_report", dashboard_id: {cloud9: "8c3b3749-c9f5-4bd7-8f84-2320ef144d37", dev: "8c3b3749-c9f5-4bd7-8f84-2320ef144d37", test: "8c3b3749-c9f5-4bd7-8f84-2320ef144d37", stage: "8c3b3749-c9f5-4bd7-8f84-2320ef144d37", prd: "8c3b3749-c9f5-4bd7-8f84-2320ef144d37"}, component_key: "quick-sight-workbench-1100", default: 1, "content_type": "Electronic Reader", "iframe_height": "700px" , "iframe_width": "1032px" },
                    {
                        label: 'Usage Report',
                        value: 'usage_report',
                        dashboard_id: {
                            cloud9: 'cb3ca554-0286-4b09-8463-06439bf4d7ce',
                            dev: 'cb3ca554-0286-4b09-8463-06439bf4d7ce',
                            test: 'cb3ca554-0286-4b09-8463-06439bf4d7ce',
                            stage: 'cb3ca554-0286-4b09-8463-06439bf4d7ce',
                            prd: 'cb3ca554-0286-4b09-8463-06439bf4d7ce'
                        },
                        component_key: 'quick-sight-workbench-1100',
                        default: 1,
                        content_type: '',
                        iframe_height: '700px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/web-portal/seatback':
                dropdownList = [
                    {
                        label: 'Page Views',
                        value: 'page_views',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Seatback_Screen_Transition_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Seatback_Screen_Transition_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Seatback_Screen_Transition_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Seatback_Screen_Transition_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Seatback_Screen_Transition_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-701',
                        default: 1,
                        content_type: '',
                        include_content_types: ['Screen Transition'],
                        iframe_height: '660px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/web-portal/portal':
                dropdownList = [
                    {
                        label: 'Page Views',
                        value: 'page_views',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Portal_Screen_Transition_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Portal_Screen_Transition_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Portal_Screen_Transition_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Portal_Screen_Transition_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Portal_Screen_Transition_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-801',
                        default: 1,
                        content_type: '',
                        include_content_types: ['Screen Transition'],
                        iframe_height: '660px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/web-portal/portal-navigation':
                dropdownList = [
                    {
                        label: 'Page Views',
                        value: 'page_views',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Portal_Screen_Transition_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Portal_Screen_Transition_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Portal_Screen_Transition_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Portal_Screen_Transition_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Portal_Screen_Transition_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-851',
                        default: 1,
                        content_type: '',
                        include_content_types: ['Screen Transition'],
                        iframe_height: '660px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/web-portal/companion-app':
                dropdownList = [
                    {
                        label: 'Companion App Usage',
                        value: 'companion_app_usage',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Companion_App_Seat_Usage_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Companion_App_Seat_Usage_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Companion_App_Seat_Usage_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Companion_App_Seat_Usage_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Companion_App_Seat_Usage_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-900',
                        default: 1,
                        content_type: '',
                        include_content_types: ['Screen Transition'],
                        iframe_height: '660px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/web-portal/bluetooth':
                dropdownList = [
                    {
                        label: 'Bluetooth Usage',
                        value: 'bluetooth_usage',
                        dashboard_id: {
                            cloud9: 'Next_Insights_CAE_Companion_App_Seat_Usage_Dashboard_Dev',
                            dev: 'Next_Insights_CAE_Companion_App_Seat_Usage_Dashboard_Dev',
                            test: 'Next_Insights_CAE_Companion_App_Seat_Usage_Dashboard_Test',
                            stage: 'Next_Insights_CAE_Companion_App_Seat_Usage_Dashboard_Stage',
                            prd: 'Next_Insights_CAE_Companion_App_Seat_Usage_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-900',
                        default: 1,
                        content_type: '',
                        include_content_types: ['Screen Transition'],
                        iframe_height: '660px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/connectivity':
                dropdownList = [
                    {
                        label: 'SLA Aircraft Bandwidth',
                        value: 'sla_aircraft_bandwidth',
                        dashboard_id: {
                            cloud9: 'f3753472-8046-4876-9100-40bf9f050985',
                            dev: 'f3753472-8046-4876-9100-40bf9f050985',
                            test: 'f3753472-8046-4876-9100-40bf9f050985',
                            stage: 'f3753472-8046-4876-9100-40bf9f050985',
                            prd: 'f3753472-8046-4876-9100-40bf9f050985'
                        },
                        component_key: 'quick-sight-workbench-1000',
                        default: 1,
                        content_type: '',
                        iframe_height: '600px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'SLA Satellite Link Packet Loss',
                        value: 'sla_satellite_link_packet_loss',
                        dashboard_id: {
                            cloud9: '6614bde2-9f42-4235-9030-36042dac7ae0',
                            dev: '6614bde2-9f42-4235-9030-36042dac7ae0',
                            test: '6614bde2-9f42-4235-9030-36042dac7ae0',
                            stage: '6614bde2-9f42-4235-9030-36042dac7ae0',
                            prd: '6614bde2-9f42-4235-9030-36042dac7ae0'
                        },
                        component_key: 'quick-sight-workbench-1001',
                        default: 0,
                        content_type: '',
                        iframe_height: '650px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'SLA Connectivity Availability',
                        value: 'sla_connectivity_availability',
                        dashboard_id: {
                            cloud9: '1a0a5039-67ff-4b61-89c4-4ade277b0bec',
                            dev: '1a0a5039-67ff-4b61-89c4-4ade277b0bec',
                            test: '1a0a5039-67ff-4b61-89c4-4ade277b0bec',
                            stage: '1a0a5039-67ff-4b61-89c4-4ade277b0bec',
                            prd: '1a0a5039-67ff-4b61-89c4-4ade277b0bec'
                        },
                        component_key: 'quick-sight-workbench-1002',
                        default: 0,
                        content_type: '',
                        iframe_height: '650px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'SLA Latency',
                        value: 'sla_latency',
                        dashboard_id: {
                            cloud9: 'a5726953-a93d-44f7-b8fc-94aefb98323d',
                            dev: 'a5726953-a93d-44f7-b8fc-94aefb98323d',
                            test: 'a5726953-a93d-44f7-b8fc-94aefb98323d',
                            stage: 'a5726953-a93d-44f7-b8fc-94aefb98323d',
                            prd: 'a5726953-a93d-44f7-b8fc-94aefb98323d'
                        },
                        component_key: 'quick-sight-workbench-1003',
                        default: 0,
                        content_type: '',
                        iframe_height: '650px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'SLA Beam Switch',
                        value: 'sla_beam_switch',
                        dashboard_id: {
                            cloud9: '41e8c6cd-ecf8-47c5-8138-a0d264f617cd',
                            dev: '41e8c6cd-ecf8-47c5-8138-a0d264f617cd',
                            test: '41e8c6cd-ecf8-47c5-8138-a0d264f617cd',
                            stage: '41e8c6cd-ecf8-47c5-8138-a0d264f617cd',
                            prd: '41e8c6cd-ecf8-47c5-8138-a0d264f617cd'
                        },
                        component_key: 'quick-sight-workbench-1004',
                        default: 0,
                        content_type: '',
                        iframe_height: '950px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'SLA User Bandwidth',
                        value: 'sla_user_bandwidth',
                        dashboard_id: {
                            cloud9: 'c66dead0-6c46-4bb9-ba9c-cda0fe3f857c',
                            dev: 'c66dead0-6c46-4bb9-ba9c-cda0fe3f857c',
                            test: 'c66dead0-6c46-4bb9-ba9c-cda0fe3f857c',
                            stage: 'c66dead0-6c46-4bb9-ba9c-cda0fe3f857c',
                            prd: 'c66dead0-6c46-4bb9-ba9c-cda0fe3f857c'
                        },
                        component_key: 'quick-sight-workbench-1005',
                        default: 0,
                        content_type: '',
                        iframe_height: '650px',
                        iframe_width: '1032px'
                    },
                    //{ label: "Data Consumption per User per Flight", value: "data_consumption_per_user_per_flight", dashboard_id: {cloud9: "a656aa74-ca43-454d-afdf-037be5d7f381", dev: "a656aa74-ca43-454d-afdf-037be5d7f381", test: "a656aa74-ca43-454d-afdf-037be5d7f381", stage: "a656aa74-ca43-454d-afdf-037be5d7f381", prd: "a656aa74-ca43-454d-afdf-037be5d7f381"}, component_key: "quick-sight-workbench-1006", default: 0, "content_type": "", "iframe_height": "850px" , "iframe_width": "1032px" },
                    {
                        label: 'Data Consumption',
                        value: 'data_consumption',
                        dashboard_id: {
                            cloud9: 'd0d08540-ee57-4497-846d-e22b8b14f31d',
                            dev: 'd0d08540-ee57-4497-846d-e22b8b14f31d',
                            test: 'd0d08540-ee57-4497-846d-e22b8b14f31d',
                            stage: 'd0d08540-ee57-4497-846d-e22b8b14f31d',
                            prd: 'd0d08540-ee57-4497-846d-e22b8b14f31d'
                        },
                        component_key: 'quick-sight-workbench-1007',
                        default: 0,
                        content_type: '',
                        iframe_height: '850px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/admin/data-loading-dashboard':
                dropdownList = [
                    {
                        label: 'Aggregator Dashboard',
                        value: 'aggregator_dashboard',
                        dashboard_id: {
                            cloud9: '96b5d652-abe1-48ae-aa82-f54e6efa08c0',
                            dev: '96b5d652-abe1-48ae-aa82-f54e6efa08c0',
                            test: '96b5d652-abe1-48ae-aa82-f54e6efa08c0',
                            stage: '96b5d652-abe1-48ae-aa82-f54e6efa08c0',
                            prd: '96b5d652-abe1-48ae-aa82-f54e6efa08c0'
                        },
                        component_key: 'quick-sight-workbench-2000',
                        default: 1,
                        content_type: '',
                        iframe_height: '990px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'Cost and Time Estimator',
                        value: 'cost_and_time_estimator',
                        dashboard_id: {
                            cloud9: 'c32764b5-9a24-4b6f-ab90-e55e0304e2e5',
                            dev: 'c32764b5-9a24-4b6f-ab90-e55e0304e2e5',
                            test: 'c32764b5-9a24-4b6f-ab90-e55e0304e2e5',
                            stage: 'c32764b5-9a24-4b6f-ab90-e55e0304e2e5',
                            prd: 'c32764b5-9a24-4b6f-ab90-e55e0304e2e5'
                        },
                        component_key: 'quick-sight-workbench-2001',
                        default: 0,
                        content_type: '',
                        iframe_height: '870px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'Paxus Data Quality Dashboard',
                        value: 'paxus_data_quality_dashboard',
                        dashboard_id: {
                            cloud9: 'f40f7e76-67a7-4d46-8bc2-0cc66d517560',
                            dev: 'f40f7e76-67a7-4d46-8bc2-0cc66d517560',
                            test: 'f40f7e76-67a7-4d46-8bc2-0cc66d517560',
                            stage: 'f40f7e76-67a7-4d46-8bc2-0cc66d517560',
                            prd: 'c68c6384-e711-4a95-9af6-b3fb9d9f7d6b'
                        },
                        component_key: 'quick-sight-workbench-2002',
                        default: 0,
                        content_type: '',
                        iframe_height: '870px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            case '/app/analytics/analytics-dashboard':
                dropdownList = [
                    {
                        label: 'NEXT Insights',
                        value: 'product_analytics_dashboard',
                        dashboard_id: {
                            cloud9: 'NI_Analytics_v2_Dashboard_Dev',
                            dev: 'NI_Analytics_v2_Dashboard_Dev',
                            test: 'NI_Analytics_v2_Dashboard_Test',
                            stage: 'NI_Analytics_v2_Dashboard_Stage',
                            prd: 'NI_Analytics_v2_Dashboard_Prd'
                        },
                        component_key: 'quick-sight-workbench-3000',
                        default: 1,
                        content_type: '',
                        iframe_height: '820px',
                        iframe_width: '1400px'
                    }
                ];
                break;
            case '/app/advertising':
                dropdownList = [
                    {
                        label: 'Advertising',
                        value: 'advertising_dashboard',
                        dashboard_id: {
                            cloud9: '3f0803b7-2afe-41ee-9505-c8dc50671340',
                            dev: '3f0803b7-2afe-41ee-9505-c8dc50671340',
                            test: '3f0803b7-2afe-41ee-9505-c8dc50671340',
                            stage: '3f0803b7-2afe-41ee-9505-c8dc50671340',
                            prd: '3f0803b7-2afe-41ee-9505-c8dc50671340'
                        },
                        component_key: 'quick-sight-workbench-3000',
                        default: 1,
                        content_type: '',
                        iframe_height: '820px',
                        iframe_width: '1400px'
                    }
                ];
                break;
            case '/app/offloads/paxus-offloads':
                dropdownList = [
                    {
                        label: 'PAXUS Offloads',
                        value: 'offloads_dashboard',
                        dashboard_id: {
                            cloud9: 'e480211f-4ca0-4974-b1af-42454f75eeba',
                            dev: 'e480211f-4ca0-4974-b1af-42454f75eeba',
                            test: 'e480211f-4ca0-4974-b1af-42454f75eeba',
                            stage: 'e480211f-4ca0-4974-b1af-42454f75eeba',
                            prd: 'e480211f-4ca0-4974-b1af-42454f75eeba'
                        },
                        component_key: 'quick-sight-workbench-3000',
                        default: 1,
                        content_type: '',
                        iframe_height: '820px',
                        iframe_width: '1400px'
                    }
                ];
                break;
            case '/app/rack-test-data/rack-offload-dashboard':
                dropdownList = [
                    {
                        label: 'Media Usage',
                        value: 'rack_offload_dashboard',
                        dashboard_id: {
                            cloud9: '03977d38-aba6-494a-bf65-5f590e4828e4',
                            dev: '03977d38-aba6-494a-bf65-5f590e4828e4',
                            test: '03977d38-aba6-494a-bf65-5f590e4828e4',
                            stage: '03977d38-aba6-494a-bf65-5f590e4828e4',
                            prd: '03977d38-aba6-494a-bf65-5f590e4828e4'
                        },
                        component_key: 'quick-sight-workbench-4000',
                        default: 1,
                        content_type: '',
                        iframe_height: '720px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'Bluetooth Usage',
                        value: 'rack_offload_dashboard_test',
                        dashboard_id: {
                            cloud9: '8147dcc9-600c-453a-bfcf-586643eaec13',
                            dev: '8147dcc9-600c-453a-bfcf-586643eaec13',
                            test: '8147dcc9-600c-453a-bfcf-586643eaec13',
                            stage: '8147dcc9-600c-453a-bfcf-586643eaec13',
                            prd: '8147dcc9-600c-453a-bfcf-586643eaec13'
                        },
                        component_key: 'quick-sight-workbench-4001',
                        default: 0,
                        content_type: '',
                        iframe_height: '720px',
                        iframe_width: '1032px'
                    },
                    {
                        label: 'Electronic Reader Usage',
                        value: 'rack_offload_dashboard_ereader',
                        dashboard_id: {
                            cloud9: '5a6d8ccd-a718-43ae-ae3d-02fc82858ad0',
                            dev: '5a6d8ccd-a718-43ae-ae3d-02fc82858ad0',
                            test: '5a6d8ccd-a718-43ae-ae3d-02fc82858ad0',
                            stage: '5a6d8ccd-a718-43ae-ae3d-02fc82858ad0',
                            prd: '5a6d8ccd-a718-43ae-ae3d-02fc82858ad0'
                        },
                        component_key: 'quick-sight-workbench-4001',
                        default: 0,
                        content_type: '',
                        iframe_height: '720px',
                        iframe_width: '1032px'
                    }
                ];
                break;
            default:
                //  throw new Error("Please check the label you are passing")
                break;
        }

        return dropdownList;
    };

    getWorkbenchComponentKey = function (path) {
        let dashboardMapping = QuicksightData['mapping'];

        var componentKey = 'quick-sight-workbench-999';
        for (var i = 0; i < dashboardMapping.length; i++) {
            if (path === dashboardMapping[i]['path']) {
                componentKey = dashboardMapping[i]['component_key'];
            }
        }

        return componentKey;
    };
}
