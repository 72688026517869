function getTailsDropdownListHelper(reportSubType) {
    let dropdownList;

    switch (reportSubType) {
        case 'Movies':
        case 'TV Series':
        case 'Live TV':
        case 'Seatback':
        case 'Overview':
        case 'Portal':
            dropdownList = [
                {
                    label: 'Total Views',
                    unit: 'count',
                    value: 'tails.total_views',
                    text: 'Views',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'tails.total_time',
                    text: 'Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Companion App':
            dropdownList = [
                {
                    label: 'Paired Devices',
                    unit: 'count',
                    value: 'tails.paired_devices',
                    text: 'Devices',
                    default: 1,
                    section: 'top_10_paired_devices',
                    small_count_label: 'Paired Devices'
                },
                {
                    label: 'Total Launches',
                    unit: 'count',
                    value: 'tails.total_launches',
                    text: 'Launches',
                    section: 'top_10_total_launches',
                    small_count_label: 'Total Launches'
                }
            ];
            break;
        case 'Audio':
            dropdownList = [
                {
                    label: 'Total Listens',
                    unit: 'count',
                    value: 'tails.total_listens',
                    text: 'Listens',
                    default: 1,
                    section: 'top_10_total_listens',
                    small_count_label: 'Total Listens'
                },
                {
                    label: 'Total Listening Time',
                    unit: 'hr',
                    value: 'tails.total_time',
                    text: 'Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Listening Time'
                }
            ];
            break;
        case 'Games':
            dropdownList = [
                {
                    label: 'Total Plays',
                    unit: 'count',
                    value: 'tails.total_plays',
                    text: 'Plays',
                    default: 1,
                    section: 'top_10_total_plays',
                    small_count_label: 'Total Plays'
                },
                {
                    label: 'Total Playing Time',
                    unit: 'hr',
                    value: 'tails.total_time',
                    text: 'Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Playing Time'
                }
            ];
            break;
        case 'EReader':
            dropdownList = [
                {
                    label: 'Total Title Views',
                    unit: 'count',
                    value: 'tails.total_views',
                    text: 'Views',
                    default: 1,
                    section: 'top_10_total_title_views',
                    small_count_label: 'Total Title Views'
                },
                {
                    label: 'Total Reading Time',
                    unit: 'hr',
                    value: 'tails.total_time',
                    text: 'Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Reading Time'
                }
            ];
            break;
        case 'Bluetooth':
            dropdownList = [
                {
                    label: 'Paired Devices',
                    unit: 'count',
                    value: 'tails.paired_devices',
                    text: 'Devices',
                    default: 1,
                    section: 'top_10_total_paired_devices',
                    small_count_label: 'Paired Devices'
                },
                {
                    label: 'Total Connections',
                    unit: 'count',
                    value: 'tails.total_connections',
                    text: 'Connections',
                    section: 'top_10_total_connections',
                    small_count_label: 'Total Connections'
                }
            ];
            break;
        default:
    }

    return dropdownList;
}

export default getTailsDropdownListHelper;
