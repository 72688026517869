import React from 'react';

import QuicksightWorkbenchComponent from 'components/workbench/QuicksightWorkbenchComponent';

class OffloadsDashboardOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.quicksightWorkbenchRef = React.createRef();
    }

    async componentDidMount() {}

    async componentDidUpdate(prevProps, prevState) {}

    render() {
        let dashboard = (
            <div>
                <QuicksightWorkbenchComponent
                    ref={this.quicksightWorkbenchRef}
                    key="quick-sight-workbench-offloads-dashboard"
                    tenantId={this.props.tenantId}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    path={this.props.path}
                    forceLoad={true}
                    iFrameHeight="850px"
                    iFrameWidth="100%"
                    dropdownClassPrefix="select-long"
                    tabFunction={this.props.tabFunction}
                    {...this.props}
                />
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default OffloadsDashboardOverview;
