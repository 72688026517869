import React from 'react';
import _, { debounce } from 'lodash';
import { Card } from 'reactstrap';
import ErrorBoundary from 'util/ErrorBoundary.js';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import * as AppConstants from 'constants/app/constants';
import AppDataHelper from 'data/app-data-helper.js';
//import TableWidget from 'components/updatedWidgets/TableWidget.js';
import TableWithDropdownsWidget from 'components/updatedWidgets/TableWithDropdownsWidget.js';
import ScatterPlotDropdownWidget from 'components/updatedWidgets/ScatterPlotDropdownWidget.js';

const appDataHelper = new AppDataHelper();

class TableContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            tails: [],
            tailsCopy: [],
            showTable: true
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.appliedFilters, prevProps.appliedFilters)) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        let requested_sections = ['tails_list'];
        this.setState({ loading: true });

        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));

        selectedFiltersDeepClone.requested_sections = requested_sections;

        if (this.props.title === 'Seatback') {
            selectedFiltersDeepClone.usage_source = 'Seatback';
        } else if (this.props.title === 'Portal') {
            selectedFiltersDeepClone.usage_source = 'Portal';
        } else if (this.props.title === 'Portal Navigation') {
            selectedFiltersDeepClone.usage_source = 'Portal';
        } else if (this.props.title === 'Companion App') {
            delete selectedFiltersDeepClone.usage_source;
            selectedFiltersDeepClone.launched_by = ['Companion App'];
        } else if (this.props.title === 'Bluetooth') {
            selectedFiltersDeepClone.usage_source = 'Bluetooth';
        }

        let contentType = appDataHelper.getContentType(this.props.title);
        if (this.props.title.includes('Electronic')) {
            contentType = ['Electronic Reader'];
        }

        if (contentType) {
            selectedFiltersDeepClone.content_types = contentType;
        }

        if (this.props.subscription) {
            selectedFiltersDeepClone['tier'] = this.props.subscription;
        }

        if (this.props.app) {
            selectedFiltersDeepClone['app'] = this.props.app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        this.throttleFetch(selectedFiltersDeepClone);
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchFlightMetricsAxios(selectedFiltersDeepClone, this.cancelRequestSource.token)
            .then((response) => {
                if (!response && !response.requested_sections) {
                    this.setState({ error: true, tails: [], loading: false });
                } else {
                    let tailsCopy = JSON.parse(JSON.stringify(response.requested_sections));
                    this.setState({ tails: response.requested_sections, tailsCopy: tailsCopy, loading: false });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, loading: true });
                } else {
                    this.setState({ error: true, loading: false });
                }
            });
    }

    toggle() {
        this.setState({ showTable: !this.state.showTable });
    }

    render() {
        let showTable = this.state.showTable;

        let tableView = showTable ? (
            <TableWithDropdownsWidget
                type="table-with-scatter"
                pageName={this.props.pageName}
                loading={this.state.loading}
                title={'Tails'}
                tailsList={this.state.tailsCopy}
                tenantIcaoCode={this.props.tenantIcaoCode}
                switchView={this.toggle}
            />
        ) : (
            <ScatterPlotDropdownWidget
                data={this.state.tails}
                pageName={this.props.pageName}
                loading={this.state.loading}
                title={'Tails'}
                type="scatter"
                switchView={this.toggle}
            />
        );

        return (
            <Card className="bar-chart-gap">
                <ErrorBoundary>{tableView}</ErrorBoundary>
            </Card>
        );
    }
}

export default TableContainer;
