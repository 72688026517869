import React from 'react';
import { ToolMenu, ToolMenuItem, ToolMenuChild, IconHome24, IconReport24 } from 'pac-ux-components';
import menus from 'data/menus.js';
import SubscriptionService from 'services/SubscriptionService';
import * as labels from 'constants/labels';
import { I18n } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import DashboardHelper from 'components/common/dashboards/DashboardHelper';
import * as AppConstants from '../../constants/app/constants';
import FiltersService from 'services/FiltersService';

const dashboardHelper = new DashboardHelper();

class PageToolsSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentMenuItem: null,
            currentMenuItemChild: null,
            menuItemActive: false,
            isClosed: false,
            pacTenantIcaCode: null,
            tenantId: null,
            tenantName: '',
            tenantIcaoCode: null,
            allSubscriptionTiers: [],
            subscription: '',
            allowedApps: [],
            allowedAppsOriginal: [],
            showPopup: false,
            modal: props.modal,
            activeMenuItems: {},
            activeMenuItemsOriginal: {},
            tierDropdown: false,
            selectedTier: 'Standard',
            userInAdminGroup: false,
            userInPacRackTesterGroup: false,
            userInInsightsPLMGroup: false
        };

        this.handleClosed = this.handleClosed.bind(this);
        this.handleSidebar = this.handleSidebar.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.handleOnToggleExpand = this.handleOnToggleExpand.bind(this);
        this.toggleTierDropdown = this.toggleTierDropdown.bind(this);
        this.toggleSelectedTier = this.toggleSelectedTier.bind(this);
    }

    inAllTenantView = true; //this.props.view === 'allTenants';

    componentDidMount() {
        this.handleSidebar();
        window.addEventListener(
            'resize',
            function () {
                this.handleSidebar();
            }.bind(this)
        );
        this.fetchSesssion();
    }

    componentDidUpdate() {
        if (this.state.menuItemActive && this.props.location.pathname !== this.state.menuItemActive) {
            this.setState({ menuItemActive: this.props.location.pathname });
        }
    }

    renderMenuItem(menuItem, menuItemIndex) {
        let { currentMenuItem, currentMenuItemChild, allowedApps, menuItemActive, tenantIcaoCode } = this.state;
        let { history } = this.props;
        let { userInAdminGroup, userInPacRackTesterGroup, userInInsightsPLMGroup } = this.state;

        let iconComponent = menuItem.title === 'Home' ? <IconHome24 /> : <IconReport24 />;

        if (menuItem.icon) {
            iconComponent = menuItem.icon;
        }

        let self = this;
        if (menuItem.children.length > 0) {
            let displayMenu = false;
            for (let i = 0; i < menuItem.children.length; i++) {
                let childItem = menuItem.children[i];
                if (
                    !childItem.verifyApp ||
                    (childItem.verifyApp &&
                        childItem.isAdmin &&
                        userInAdminGroup &&
                        tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                    (childItem.verifyApp &&
                        childItem.isPacRackTester &&
                        userInPacRackTesterGroup &&
                        tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                    (childItem.verifyApp &&
                        childItem.isInsightsPLM &&
                        userInInsightsPLMGroup &&
                        tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                    (childItem.verifyApp && allowedApps.includes(childItem.title)) ||
                    (childItem.verifyApp &&
                        childItem.isInsightsPLM &&
                        userInInsightsPLMGroup &&
                        self.inAllTenantView &&
                        childItem.isAllTenantView)
                ) {
                    displayMenu = true;
                    break;
                }
            }

            if (displayMenu) {
                return (
                    <ToolMenuItem
                        key={menuItemIndex}
                        as="div"
                        href={menuItem.path}
                        title={I18n.get(menuItem.title)}
                        icon={iconComponent}
                        onClick={(event) => {
                            self.setState({ currentMenuItem: menuItem });
                            return true;
                        }}
                        isActive={menuItemActive && menuItemActive === menuItem.path}
                        isExpanded={menuItem.isExpanded}
                        onToggleExpand={(event) => {
                            event.preventDefault();
                            this.handleOnToggleExpand(menuItem);
                        }}
                    >
                        {menuItem.children.map(function (item, index) {
                            let isActiveChild = false;
                            if (currentMenuItem !== 'undefined') {
                                if (currentMenuItem !== null) {
                                    if (currentMenuItem.children.length > 0) {
                                        if (currentMenuItemChild !== 'undefined') {
                                            if (currentMenuItemChild !== null) {
                                                isActiveChild = currentMenuItemChild.path === item.path;
                                            }
                                        }
                                    }
                                }
                            }

                            let displayItem = false;
                            if (
                                !item.verifyApp ||
                                (item.verifyApp &&
                                    item.isAdmin &&
                                    userInAdminGroup &&
                                    tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                                (item.verifyApp &&
                                    item.isPacRackTester &&
                                    userInPacRackTesterGroup &&
                                    tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                                (item.verifyApp &&
                                    item.isInsightsPLM &&
                                    userInInsightsPLMGroup &&
                                    tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                                (item.verifyApp && allowedApps.includes(item.title)) ||
                                (item.verifyApp &&
                                    item.isInsightsPLM &&
                                    userInInsightsPLMGroup &&
                                    self.inAllTenantView &&
                                    item.isAllTenantView)
                            ) {
                                displayItem = true;
                            }

                            if (displayItem) {
                                return (
                                    <ToolMenuChild
                                        key={index}
                                        as="div"
                                        href={item.path}
                                        title={I18n.get(item.title)}
                                        onClick={(event) => {
                                            if (self.state.menuItemActive === item.path) {
                                                return false;
                                            }

                                            self.setState({
                                                currentMenuItemChild: item,
                                                menuItemActive: item.path
                                            });
                                            history.push(item.path);

                                            return false;
                                        }}
                                        isActive={menuItemActive && menuItemActive === item.path}
                                    />
                                );
                            }
                        })}
                    </ToolMenuItem>
                );
            }
        } else {
            let isActive = false;
            if (currentMenuItem !== 'undefined') {
                if (currentMenuItem !== null) {
                    isActive = currentMenuItem.path === menuItem.path;
                }
            }

            if (!menuItem.verifyApp || (menuItem.verifyApp && allowedApps.includes(menuItem.title))) {
                return (
                    <ToolMenuItem
                        key={menuItemIndex}
                        as="div"
                        href={menuItem.path}
                        title={I18n.get(menuItem.title)}
                        icon={iconComponent}
                        className="cursor-pointer"
                        onClick={(event) => {
                            if (self.state.menuItemActive === menuItem.path) {
                                return false;
                            }

                            self.setState({ currentMenuItem: menuItem, menuItemActive: menuItem.path });
                            history.push(menuItem.path);

                            return false;
                        }}
                        isActive={menuItemActive && menuItemActive === menuItem.path}
                    />
                );
            }
        }
    }

    handleOnToggleExpand(menuItem) {
        let { activeMenuItems } = this.state;

        activeMenuItems.map(function (item, index) {
            if (item.path === menuItem.path) {
                item.isExpanded = !item.isExpanded;
            }
        });

        this.setState({
            activeMenuItems: activeMenuItems
        });
    }

    handleClosed() {
        let { isClosed } = this.state;

        this.setState({
            isClosed: !isClosed
        });

        return !isClosed;
    }

    handleSidebar() {
        if (window.innerWidth < 1400) {
            this.setState({
                isClosed: true
            });
        } else {
            this.setState({
                isClosed: false
            });
        }
    }

    togglePopup() {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            showPopup: !this.state.showPopup
        }));
    }

    updateDisclaimer() {
        let { tenantIcaoCode, allSubscriptionTiers, selectedTier } = this.state;
        let popupHeader = '';
        let popupMessage = '';

        if (document.getElementById('app-disclaimer')) {
            document.getElementById('app-disclaimer').style.display = 'none';
        }

        if (document.getElementById('app-disclaimer')) {
            document.getElementById('app-disclaimer').style.display = 'initial';
            document.getElementById('app-disclaimer').className = 'app-disclaimer default';
        }

        document.getElementById('subscription_stage_msg').innerText =
            AppConstants.DISCLAIMER_PANASONIC_AVIONICS_HEADER + AppConstants.DISCLAIMER_ALL_TENANTS_VIEW;
        popupMessage = AppConstants.DISCLAIMER_ALL_TENANTS_VIEW_POPUP;
        popupHeader = AppConstants.DISCLAIMER_PANASONIC_AVIONICS_HEADER;
        document.getElementById('subscription_stage_msg').style['color'] = 'white';
        document.getElementById('app-disclaimer').style['background-color'] = 'red';

        if (document.getElementById('app-disclaimer')) {
            if (document.getElementById('app-disclaimer').style.display === 'none') {
                if (document.getElementsByClassName('top-header')[0]) {
                    document.getElementsByClassName('top-header')[0].style.paddingTop = '15px';
                    document.getElementsByClassName('top-header')[0].style.height = '72px';
                }
                if (document.getElementById('content-container')) {
                    document.getElementById('content-container').style.transition = 'all .25s ease-in-out';
                    document.getElementById('content-container').style.marginTop = '25px';
                }
                let sideBar = document.getElementsByClassName('side-bar')[0];
                if (sideBar) {
                    sideBar.style.transition = 'all .25s ease-in-out';
                    sideBar.style.marginTop = '0';
                }
            } else {
                if (document.getElementsByClassName('top-header')[0]) {
                    document.getElementsByClassName('top-header')[0].style.paddingTop = '55px';
                    document.getElementsByClassName('top-header')[0].style.height = '112px';
                }
                if (document.getElementById('content-container')) {
                    document.getElementById('content-container').style.transition = 'all .25s ease-in-out';
                    document.getElementById('content-container').style.marginTop = '0px';
                }
                let sideBar = document.getElementsByClassName('side-bar')[0];
                if (sideBar) {
                    sideBar.style.transition = 'all .25s ease-in-out';
                    sideBar.style.marginTop = '40px';
                }
            }
        }

        //show popup
        if (popupMessage !== '' && popupHeader !== '') {
            this.props.showDisclaimerPopupAction(popupHeader, popupMessage);
        }
    }

    toggleTierDropdown() {
        if (
            (this.state.subscription === 'Standard' &&
                this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY) ||
            this.state.allSubscriptionTiers.length > 1
        ) {
            this.setState((prevState) => ({
                tierDropdown: !prevState.tierDropdown
            }));
        }
    }

    toggleSelectedTier(selectedTier) {
        try {
            // Subscription tier selection change
            if (
                (this.state.subscription === 'Standard' &&
                    this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY) ||
                this.state.allSubscriptionTiers.length > 1
            ) {
                let allowedApps = [];
                let subscriptionStatus = AppConstants.SUBSCRIPTION_STATUS_NO_SUBSCRIPTION;

                let menuItemActive;

                if (selectedTier === 'Basic') {
                    let activeMenuItems = menus['sidebar'].filter(
                        (menuItem) => menuItem.title !== 'Overview' && menuItem.isActive === true
                    );

                    for (let i = 0; i < this.state.allSubscriptionTiers.length; i++) {
                        if (this.state.allSubscriptionTiers[i]['subscription-level'] === 'Basic') {
                            allowedApps = JSON.parse(JSON.stringify(this.state.allSubscriptionTiers[i]['apps']));
                            subscriptionStatus = dashboardHelper.getSubscriptionStatus(
                                this.state.allSubscriptionTiers[i]['license-start-date'],
                                this.state.allSubscriptionTiers[i]['license-end-date']
                            );
                        }
                    }

                    const index = allowedApps.indexOf('Overview');
                    if (index > -1) {
                        allowedApps.splice(index, 1);
                    }

                    menuItemActive = ['/app', '/app/'].includes(this.state.menuItemActive)
                        ? dashboardHelper.getPagePath(allowedApps)
                        : this.state.menuItemActive;

                    if (
                        subscriptionStatus !== AppConstants.SUBSCRIPTION_STATUS_ACTIVE &&
                        this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASKY
                    ) {
                        activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true); // && menuItem.isExpanded === true);
                        menuItemActive = '/app/help';
                        allowedApps = [];
                    }

                    this.setState(
                        {
                            selectedTier: selectedTier,
                            allowedApps: allowedApps,
                            activeMenuItems: activeMenuItems,
                            menuItemActive: menuItemActive
                        },
                        () => {
                            this.updateDisclaimer();
                            this.reRenderMenuItem();
                        }
                    );
                } else {
                    let activeMenuItems = this.state.activeMenuItemsOriginal;

                    for (let i = 0; i < this.state.allSubscriptionTiers.length; i++) {
                        if (this.state.allSubscriptionTiers[i]['subscription-level'] === 'Standard') {
                            allowedApps = JSON.parse(JSON.stringify(this.state.allSubscriptionTiers[i]['apps']));
                            subscriptionStatus = dashboardHelper.getSubscriptionStatus(
                                this.state.allSubscriptionTiers[i]['license-start-date'],
                                this.state.allSubscriptionTiers[i]['license-end-date']
                            );
                        }
                    }

                    if (allowedApps.length === 0 && subscriptionStatus === AppConstants.SUBSCRIPTION_STATUS_ACTIVE) {
                        allowedApps = JSON.parse(JSON.stringify(this.state.allowedAppsOriginal));
                    }

                    menuItemActive = ['/app', '/app/'].includes(this.state.menuItemActive)
                        ? dashboardHelper.getPagePath(allowedApps)
                        : this.state.menuItemActive;
                    if (subscriptionStatus !== AppConstants.SUBSCRIPTION_STATUS_ACTIVE || allowedApps.length === 0) {
                        activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true); // && menuItem.isExpanded === true);
                        menuItemActive = '/app/help'; // TODO: keep an eye
                        allowedApps = [];
                    }

                    this.setState(
                        {
                            selectedTier: selectedTier,
                            allowedApps: allowedApps,
                            activeMenuItems: activeMenuItems,
                            menuItemActive: menuItemActive // TODO: keep an eye
                        },
                        () => {
                            this.updateDisclaimer();
                            this.reRenderMenuItem();
                        }
                    );
                }

                let landingPagePath = dashboardHelper.getPagePath(this.state.allowedApps, this.state.menuItemActive);
                this.props.onUpdatedTier(selectedTier, landingPagePath);
                window.sessionStorage.setItem('pac_subscription', selectedTier);
                window.sessionStorage.removeItem('pac_next_insights_selected_filters');
                FiltersService.resetFilterValues();
            }
        } catch (e) {
            console.error(e);
        }
    }

    reRenderMenuItem() {
        if (this.state.activeMenuItems) {
            this.state.activeMenuItems.map((menuItem, menuItemIndex) => {
                return this.renderMenuItem(menuItem, menuItemIndex);
            });
        }
    }

    renderTierDropdown() {
        let tierDropdownOptions = [];

        for (let i = 0; i < this.state.allSubscriptionTiers.length; i++) {
            if (this.state.allSubscriptionTiers[i]['subscription-level'] === 'Standard') {
                tierDropdownOptions.push(
                    <div
                        className="tier-info-standard"
                        onClick={() => this.toggleSelectedTier('Standard')}
                        key={'subscription_dropdown_standard'}
                    >
                        <div className="dropdown-standard">
                            <div style={{ float: 'left' }}>Standard</div>
                            {this.state.selectedTier === 'Standard' ? (
                                <i className="fas fa-check icon-check-mark">&nbsp;</i>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="dropdown-desc">Enhanced analytics feature set.</div>
                    </div>
                );
            } else {
                tierDropdownOptions.push(
                    <div
                        className="tier-info-basic"
                        onClick={() => this.toggleSelectedTier('Basic')}
                        key={'subscription_dropdown_basic'}
                    >
                        <div className="dropdown-basic">
                            <div style={{ float: 'left' }}>Basic</div>
                            {this.state.selectedTier === 'Basic' ? (
                                <i className="fas fa-check icon-check-mark">&nbsp;</i>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="dropdown-desc">Limited analytics feature set.</div>
                    </div>
                );
            }
        }

        return <React.Fragment>{tierDropdownOptions}</React.Fragment>;
    }

    getSubscriptionClass(tierName) {
        let subscriptionClass = 'subscription-dropdown';
        if (this.state.subscription === 'Standard') {
            if (this.state.selectedTier === 'Standard') {
                if (
                    this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY ||
                    this.state.allSubscriptionTiers.length > 1
                ) {
                    subscriptionClass = 'subscription-dropdown standard';
                } else {
                    subscriptionClass = 'subscription-dropdown standard non-pac';
                }
            } else {
                subscriptionClass = 'subscription-dropdown basic';
            }
        } else if (this.state.subscription === 'Basic') {
            if (tierName === 'Internal') {
                subscriptionClass = 'subscription-dropdown internal';
            } else {
                subscriptionClass = 'subscription-dropdown';
            }
        } else {
            subscriptionClass = 'subscription-dropdown no-subscription';
        }

        return subscriptionClass;
    }

    onReleaseSelected = (props) => {
        this.setState({
            showPopup: !this.state.showPopup,
            modal: !this.state.modal
        });
    };

    render() {
        let { isClosed } = this.state;
        let self = this;

        let activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true);

        const closeBtn = (
            <button className="close" onClick={this.togglePopup}>
                &times;
            </button>
        );

        const createMarkup = () => {
            return { __html: this.getReleaseNotesHtml() };
        };

        let tierName = '';
        let tierCursor = 'default';
        let multipleTiers = false;
        if (this.state.subscription) {
            tierName = this.state.subscription;
            if (
                (this.state.subscription === 'Standard' &&
                    this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY) ||
                this.state.allSubscriptionTiers.length > 1
            ) {
                tierName = this.state.selectedTier;
                tierCursor = 'pointer';
                multipleTiers = true;
            } else if (this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) {
                tierName = 'Internal';
            }
        }

        return (
            <div className={this.state.isClosed ? 'side-bar collapsed' : 'side-bar'}>
                <ToolMenu title={labels.NEXT_CLOUD_INSIGHTS} isClosed={isClosed} handleClosed={this.handleClosed}>
                    {activeMenuItems.map((menuItem, menuItemIndex) => {
                        return this.renderMenuItem(menuItem, menuItemIndex);
                    })}
                </ToolMenu>
            </div>
        );
    }

    fetchSesssion() {
        let currTenantIcaoCode = sessionStorage.getItem('pac_selected_tenant_icao_code');

        if (!currTenantIcaoCode) {
            setTimeout(
                function () {
                    this.fetchSesssion();
                }.bind(this),
                1000
            );
            return;
        }

        Auth.currentSession({})
            .then((session) => {
                let payload = session.idToken.payload;
                let tenantId = payload.pac_tenant_id;
                let tenantName = payload.pac_tenant_name;
                let currTenantId = sessionStorage.getItem('pac_selected_tenant_id');
                let currentTenantId = currTenantId ? currTenantId : tenantId;

                let pacIcaoCode = payload.pac_icao_code;

                let currentTenantIcaoCode = currTenantIcaoCode ? currTenantIcaoCode : pacIcaoCode;

                const groups = payload['cognito:groups'];
                let userInAdminGroup = false;
                let userInPacRackTesterGroup = false;
                let userInInsightsPLMGroup = false;
                if (typeof groups !== 'undefined') {
                    for (let i = 0; i < groups.length; i++) {
                        if (AppConstants.ADMIN_GROUPS.includes(groups[i])) {
                            userInAdminGroup = true;
                            //break;
                        }

                        if (groups[i] === AppConstants.GROUP_PAC_RACK_TESTER) {
                            userInPacRackTesterGroup = true;
                        }

                        if (groups[i] === AppConstants.GROUP_INSIGHTS_PLM) {
                            userInInsightsPLMGroup = true;
                        }
                    }
                }

                this.setState({
                    userInAdminGroup: userInAdminGroup,
                    userInPacRackTesterGroup: userInPacRackTesterGroup,
                    userInInsightsPLMGroup: userInInsightsPLMGroup,
                    tenantName: tenantName,
                    tenantIcaoCode: currentTenantIcaoCode,
                    pacTenantIcaCode: payload.pac_icao_code
                });

                this.fetchSubscription(currentTenantId);
            })
            .catch((err) => console.warn(err));
    }

    async fetchSubscription(tenantId) {
        let response = dashboardHelper.getStubbedSubscription(AppConstants.TENANT_ICAO_PANASKY);

        let defaultTier = response.tiers[0];
        for (let k in response.tiers) {
            if (response.tiers[k]['subscription-level'] === 'Standard') {
                defaultTier = response.tiers[k];
                break;
            }
        }

        window.sessionStorage.setItem('pac_subscription', defaultTier['subscription-level']);
        this.setState(
            {
                subscription: defaultTier['subscription-level'],
                allSubscriptionTiers: response.tiers
            },
            () => this.updateDisclaimer()
        );

        let activePagePath;

        if (defaultTier.apps && defaultTier.apps.length > 0) {
            defaultTier.apps.push('Overview');
        }

        let activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true);
        activePagePath = this.props.location.pathname;

        this.setState({
            activeMenuItems: activeMenuItems,
            menuItemActive: activePagePath,
            activeMenuItemsOriginal: activeMenuItems,
            allowedApps: defaultTier.apps,
            allowedAppsOriginal: defaultTier.apps
        });
    }
}

export default PageToolsSidebar;
