import React from 'react';
import Circular from 'assets/images/icons/circular.gif';
import UtilHelper from 'util/UtilHelper.js';
import 'assets/styles/plane-with-tooltip.css';

const utilHelper = new UtilHelper();

function trimText(text, threshold) {
    if (!text) return 'N/A';
    if (text.length <= threshold) return text;
    return text.substr(0, threshold).concat('...');
}

function formatComparisonData(data) {
    if (data) {
        return data > 999
            ? '999+%'
            : data < -999
            ? '999+%'
            : data > 100
            ? `${Math.abs(Math.round(data))}%`
            : `${Math.abs(data)}%`;
    } else {
        return 'N/A';
    }
}

function setClassData(classNames, classData, classChange, data, props, classDataTitle, top3Airlines) {
    classNames = data.label.split(' ');
    classData = data.value;
    if (classData) {
        if (props.unit === 'count') {
            classData = utilHelper.formatToUnitsForPillBox(data.value);
        } else if (props.unit === 'hr') {
            classData =
                props.text && props.text.includes('Total')
                    ? utilHelper.secondsToHmsForDynamicTabs(data.value)
                    : utilHelper.secondsToHoursMinutes(data.value);
        } else if (props.unit === 'string') {
            classDataTitle = classData;
            classData = trimText(classData, 9);
        }
    } else {
        classData = 'Data not available';
    }

    if (data.comparison_value) {
        classChange = data.comparison_value;
    }

    if (data.top_3_airlines) {
        top3Airlines = data.top_3_airlines;
    }

    return [classNames, classData, classChange, classDataTitle, top3Airlines];
}

function createOneClassSvgDefs() {
    return (
        <defs>
            <filter x="-1.6%" y="-5.9%" width="103.2%" height="111.9%" filterUnits="objectBoundingBox" id="filter-1">
                <feOffset dx="3" dy="3" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.301628059 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                ></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
            <linearGradient x1="-0.0282885431%" y1="49.971193%" x2="99.9717115%" y2="49.971193%" id="linearGradient-2">
                <stop stopColor="#FFEBEE" offset="0%"></stop>
                <stop stopColor="#EF9A9A" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="49.9265836%" x2="99.9496224%" y2="49.9265836%" id="linearGradient-3">
                <stop stopColor="#EF9A9A" offset="0%"></stop>
                <stop stopColor="#EF9A9A" offset="15.3381774%"></stop>
                <stop stopColor="#E57373" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="7.03179136%" y1="50.0056707%" x2="99.9748299%" y2="50.0056707%" id="linearGradient-4">
                <stop stopColor="#E57373" offset="0%"></stop>
                <stop stopColor="#F44336" offset="43.4904939%"></stop>
                <stop stopColor="#BB2217" offset="100%"></stop>
            </linearGradient>
            <linearGradient
                x1="-0.0251100413%"
                y1="50.0014561%"
                x2="100.000551%"
                y2="50.0014561%"
                id="linearGradient-5"
            >
                <stop stopColor="#BB2217" offset="0%"></stop>
                <stop stopColor="#B32217" offset="100%"></stop>
            </linearGradient>
        </defs>
    );
}

function createOneClassSvgGroup(classData, props, dataFontSize) {
    return (
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-352.000000, -2029.000000)">
                <g transform="translate(344.000000, 1885.000000)">
                    <g transform="translate(514.000000, 287.000000) scale(-1, 1) translate(-514.000000, -287.000000) translate(20.000000, 148.000000)">
                        <g filter="url(#filter-1)" transform="translate(0.000000, 8.000000)" fillRule="nonzero">
                            <path
                                d="M466.867893,140.4442 C437.143706,140.041781 407.023197,139.907642 378.620085,139.907642 C340.969448,139.907642 290.372277,139.102804 256.156436,139.505223 C256.420651,140.846619 189.97058,124.749863 184.818388,123.408466 C176.75983,121.262232 105.421782,22.6695987 93.7963225,10.5970313 C86.0019802,2.41451347 72.3949081,0 62.7510608,0 C57.202546,0 40.1606789,0.536558549 40.1606789,0.536558549 L90.0973126,148.492578 C84.8130127,148.894997 79.7929279,149.699835 74.772843,149.699835 C36.990099,138.968664 12.1538897,132.395822 9.64384724,132.395822 C2.64214993,132.395822 0,136.151732 0,136.151732 L68.5637907,162.040682 C70.2811881,172.503573 77.0186704,175.186366 95.1173975,182.295767 C113.216124,189.405168 255.363791,241.853766 303.847242,239.975811 C312.170014,239.707532 390.50976,242.256185 467,244 L467,140.4442 L466.867893,140.4442 Z"
                                id="Path"
                                fill="url(#linearGradient-2)"
                            ></path>
                            <path
                                d="M640.867825,144.080997 C601.215257,143.277259 534.598943,141.803738 466,141 L466,244.41433 C482.918429,244.816199 499.836858,245.084112 515.83006,245.352025 C516.226586,247.76324 517.019637,250.040498 518.473565,250.844237 C529.179758,256.470405 539.225076,261.426791 539.225076,261.426791 C539.225076,262.23053 539.753776,263.034268 540.679003,263.302181 C546.494713,264.775701 569.625378,270 592.623867,270 C617.208459,270 631.747734,268.52648 641,267.05296 L641,144.080997 L640.867825,144.080997 Z"
                                id="Path"
                                fill="url(#linearGradient-3)"
                            ></path>
                            <path
                                d="M666.777778,144.536532 C660.428571,144.402399 650.904762,144.268266 639,144 L639,267 C648.52381,265.524537 652.227513,263.91494 655.005291,263.91494 C665.322751,263.91494 666.777778,254.123228 666.380952,247.953108 C688.867725,247.953108 778.814815,249.428571 814,249.830971 L814,148.962923 C776.037037,146.280262 727.888889,144.536532 666.777778,144.536532 Z"
                                id="Path"
                                fill="url(#linearGradient-4)"
                            ></path>
                            <path
                                d="M955.252531,188.787695 C953.009217,180.419117 941.924604,159.165585 813,150 L813,249.891597 C813.395879,249.891597 813.791758,249.891597 814.055677,249.891597 C847.969314,248.961755 914.476991,254.673641 961.850515,235.279794 C1011.99519,214.690436 978.741354,199.41446 955.252531,188.787695 Z"
                                id="Path"
                                fill="url(#linearGradient-5)"
                            ></path>
                        </g>

                        <g id="seat-class-1">
                            <text
                                fill={classData['class1Data'] === 'Data not available' ? '#7A7A7A' : '#A41F1E'}
                                transform="translate(953.000000, 36.000000) scale(-1, 1) translate(-953.000000, -36.000000)"
                                fontFamily="Helvetica"
                                fontSize={classData['class1Data'] === 'Data not available' ? 18 : dataFontSize}
                                fontWeight="normal"
                            >
                                <tspan x={props.unit === 'string' ? '936' : '933'} y="48">
                                    {classData['class1Data']}
                                </tspan>
                                <title>{classData['class1DataTitle']}</title>
                            </text>
                            <text
                                fill="#A9201C"
                                transform="translate(945.500000, 77.500000) scale(-1, 1) translate(-945.500000, -77.500000)"
                                fontFamily="Helvetica-Bold, Helvetica"
                                fontSize="21"
                                fontWeight="bold"
                            >
                                <tspan x="921" y="78">
                                    {classData['class1Names'][0]}
                                </tspan>
                                <tspan x="921" y="103">
                                    {classData['class1Names'][1]}
                                </tspan>
                            </text>
                            {props.showWorkbenchLink && !isNaN(classData['class1Change']) ? (
                                <text
                                    fontFamily="Inter regular, sans-serif"
                                    transform="translate(945.500000, 77.500000) scale(-1, 1) translate(-945.500000, -77.500000)"
                                    fontSize="14"
                                    fontWeight="normal"
                                    line-spacing="20"
                                    letterSpacing="0.1"
                                    fill={
                                        classData['class1Change'] > 0
                                            ? '#225522'
                                            : classData['class1Change'] < 0
                                            ? '#FF0000'
                                            : '#9E9E9E'
                                    }
                                >
                                    <tspan x="921" y={classData['class1Names'].length === 2 ? 131 : 108}>
                                        {classData['class1Change'] > 0 ? (
                                            <tspan width="47" height="20" fontSize="13.4">
                                                {'\u25B2 '}
                                            </tspan>
                                        ) : classData['class1Change'] < 0 ? (
                                            <tspan width="47" height="20" fontSize="13.4">
                                                {'\u25BC '}
                                            </tspan>
                                        ) : classData['class1Change'] === 0 ? (
                                            <tspan width="47" height="20" fontSize="15">
                                                <i className="fas fa-sort" style={{ fontSize: '15px' }}></i>
                                            </tspan>
                                        ) : (
                                            ''
                                        )}
                                        {formatComparisonData(classData['class1Change'])}
                                    </tspan>
                                </text>
                            ) : (
                                ''
                            )}
                            {classData['class1Data'] !== 0 &&
                                top3AirlinesTooltip(classData['class1Top3Airlines'], 980, -70)}
                        </g>

                        <rect id="Rectangle" fill="#AF2119" x="986" y="7" width="2" height="215"></rect>
                    </g>
                </g>
            </g>
        </g>
    );
}

function oneClassSvg(classData, props, dataFontSize) {
    return (
        <svg
            width="1012px"
            height={props.tenantIcaoCode === 'all' ? '430px' : '294px'}
            viewBox="0 0 1012 294"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            {createOneClassSvgDefs()}

            {createOneClassSvgGroup(classData, props, dataFontSize)}
        </svg>
    );
}

function top3AirlinesTooltip(top3Airlines, translateX, translateY) {
    let transform = `translate(${translateX},${translateY}) scale(-1, 1)`;
    return (
        <g className="airlines-tooltip" transform={transform}>
            <rect rx="5" width="100" height="95" opacity="0.7"></rect>
            {top3Airlines && (
                <g>
                    <text x="15" y="20">
                        Top 3 airlines:
                    </text>
                    <text x="15" y="45">
                        {top3Airlines[0]}
                    </text>
                    <text x="15" y="65">
                        {top3Airlines[1]}
                    </text>
                    <text x="15" y="85">
                        {top3Airlines[2]}
                    </text>
                </g>
            )}
        </g>
    );
}

function twoClassSvg(classData, props, dataFontSize) {
    return (
        <svg
            width="1012px"
            height={props.tenantIcaoCode === 'all' ? '430px' : '294px'}
            viewBox="0 0 1012 294"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <filter
                    x="-1.6%"
                    y="-5.9%"
                    width="103.2%"
                    height="111.9%"
                    filterUnits="objectBoundingBox"
                    id="filter-1"
                >
                    <feOffset dx="3" dy="3" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur
                        stdDeviation="2.5"
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    ></feGaussianBlur>
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.301628059 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    ></feColorMatrix>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
                <linearGradient
                    x1="-0.0282885431%"
                    y1="49.971193%"
                    x2="99.9717115%"
                    y2="49.971193%"
                    id="linearGradient-2"
                >
                    <stop stopColor="#91BCF3" offset="0%"></stop>
                    <stop stopColor="#85B0E6" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="0%" y1="49.9265836%" x2="99.9496224%" y2="49.9265836%" id="linearGradient-3">
                    <stop stopColor="#85B0E6" offset="0%"></stop>
                    <stop stopColor="#82ACE2" offset="15.3381774%"></stop>
                    <stop stopColor="#749DD2" offset="100%"></stop>
                </linearGradient>
                <linearGradient
                    x1="7.03179136%"
                    y1="50.0056707%"
                    x2="99.9748299%"
                    y2="50.0056707%"
                    id="linearGradient-4"
                >
                    <stop stopColor="#749ED2" offset="0%"></stop>
                    <stop stopColor="#5563A2" offset="43.4904939%"></stop>
                    <stop stopColor="#250759" offset="100%"></stop>
                </linearGradient>
                <linearGradient
                    x1="-0.0251100413%"
                    y1="50.0014561%"
                    x2="100.000551%"
                    y2="50.0014561%"
                    id="linearGradient-5"
                >
                    <stop stopColor="#26085A" offset="0%"></stop>
                    <stop stopColor="#B32217" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-352.000000, -2029.000000)">
                    <g transform="translate(344.000000, 1885.000000)">
                        <g transform="translate(514.000000, 287.000000) scale(-1, 1) translate(-514.000000, -287.000000) translate(20.000000, 148.000000)">
                            <g filter="url(#filter-1)" transform="translate(0.000000, 8.000000)" fillRule="nonzero">
                                <path
                                    d="M466.867893,140.4442 C437.143706,140.041781 407.023197,139.907642 378.620085,139.907642 C340.969448,139.907642 290.372277,139.102804 256.156436,139.505223 C256.420651,140.846619 189.97058,124.749863 184.818388,123.408466 C176.75983,121.262232 105.421782,22.6695987 93.7963225,10.5970313 C86.0019802,2.41451347 72.3949081,0 62.7510608,0 C57.202546,0 40.1606789,0.536558549 40.1606789,0.536558549 L90.0973126,148.492578 C84.8130127,148.894997 79.7929279,149.699835 74.772843,149.699835 C36.990099,138.968664 12.1538897,132.395822 9.64384724,132.395822 C2.64214993,132.395822 0,136.151732 0,136.151732 L68.5637907,162.040682 C70.2811881,172.503573 77.0186704,175.186366 95.1173975,182.295767 C113.216124,189.405168 255.363791,241.853766 303.847242,239.975811 C312.170014,239.707532 390.50976,242.256185 467,244 L467,140.4442 L466.867893,140.4442 Z"
                                    id="Path"
                                    fill="url(#linearGradient-2)"
                                ></path>
                                <path
                                    d="M640.867825,144.080997 C601.215257,143.277259 534.598943,141.803738 466,141 L466,244.41433 C482.918429,244.816199 499.836858,245.084112 515.83006,245.352025 C516.226586,247.76324 517.019637,250.040498 518.473565,250.844237 C529.179758,256.470405 539.225076,261.426791 539.225076,261.426791 C539.225076,262.23053 539.753776,263.034268 540.679003,263.302181 C546.494713,264.775701 569.625378,270 592.623867,270 C617.208459,270 631.747734,268.52648 641,267.05296 L641,144.080997 L640.867825,144.080997 Z"
                                    id="Path"
                                    fill="url(#linearGradient-3)"
                                ></path>
                                <path
                                    d="M666.777778,144.536532 C660.428571,144.402399 650.904762,144.268266 639,144 L639,267 C648.52381,265.524537 652.227513,263.91494 655.005291,263.91494 C665.322751,263.91494 666.777778,254.123228 666.380952,247.953108 C688.867725,247.953108 778.814815,249.428571 814,249.830971 L814,148.962923 C776.037037,146.280262 727.888889,144.536532 666.777778,144.536532 Z"
                                    id="Path"
                                    fill="url(#linearGradient-4)"
                                ></path>
                                <path
                                    d="M955.252531,188.787695 C953.009217,180.419117 941.924604,159.165585 813,150 L813,249.891597 C813.395879,249.891597 813.791758,249.891597 814.055677,249.891597 C847.969314,248.961755 914.476991,254.673641 961.850515,235.279794 C1011.99519,214.690436 978.741354,199.41446 955.252531,188.787695 Z"
                                    id="Path"
                                    fill="url(#linearGradient-5)"
                                ></path>
                            </g>

                            <g id="seat-class-4">
                                <text
                                    fill={classData['class4Data'] === 'Data not available' ? '#7A7A7A' : '#26095A'}
                                    transform="translate(764.000000, 36.000000) scale(-1, 1) translate(-764.000000, -36.000000)"
                                    fontFamily="Helvetica"
                                    fontSize={classData['class4Data'] === 'Data not available' ? 18 : dataFontSize}
                                    fontWeight="normal"
                                >
                                    <tspan x={props.unit === 'string' ? '729' : '726'} y="47">
                                        {classData['class4Data']}
                                    </tspan>
                                    <title>{classData['class4DataTitle']}</title>
                                </text>
                                <text
                                    fill="#26095A"
                                    transform="translate(749.500000, 77.500000) scale(-1, 1) translate(-749.500000, -77.500000)"
                                    fontFamily="Helvetica-Bold, Helvetica"
                                    fontSize="21"
                                    fontWeight="bold"
                                >
                                    <tspan x="698" y="78">
                                        {classData['class4Names'][0]}
                                    </tspan>
                                    <tspan x="698" y="103">
                                        {classData['class4Names'][1]}
                                    </tspan>
                                </text>
                                {props.showWorkbenchLink && !isNaN(classData['class4Change']) ? (
                                    <text
                                        fontFamily="Inter regular, sans-serif"
                                        transform="translate(749.500000, 77.500000) scale(-1, 1) translate(-749.500000, -77.500000)"
                                        fontSize="14"
                                        fontWeight="normal"
                                        line-spacing="20"
                                        letterSpacing="0.1"
                                        fill={
                                            classData['class4Change'] > 0
                                                ? '#225522'
                                                : classData['class4Change'] < 0
                                                ? '#FF0000'
                                                : '#9E9E9E'
                                        }
                                    >
                                        <tspan x="698" y={classData['class4Names'].length === 2 ? 131 : 108}>
                                            {classData['class4Change'] > 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25B2 '}
                                                </tspan>
                                            ) : classData['class4Change'] < 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25BC '}
                                                </tspan>
                                            ) : classData['class4Change'] === 0 ? (
                                                <tspan width="47" height="20" fontSize="15">
                                                    <i className="fas fa-sort" style={{ fontSize: '15px' }}></i>
                                                </tspan>
                                            ) : (
                                                ''
                                            )}
                                            {formatComparisonData(classData['class4Change'])}
                                        </tspan>
                                    </text>
                                ) : (
                                    ''
                                )}
                                {classData['class4Data'] !== 0 &&
                                    top3AirlinesTooltip(classData['class4Top3Airlines'], 800, -70)}
                            </g>

                            <g id="seat-class-1">
                                <text
                                    fill={classData['class1Data'] === 'Data not available' ? '#7A7A7A' : '#A41F1E'}
                                    transform="translate(953.000000, 36.000000) scale(-1, 1) translate(-953.000000, -36.000000)"
                                    fontFamily="Helvetica"
                                    fontSize={classData['class1Data'] === 'Data not available' ? 18 : dataFontSize}
                                    fontWeight="normal"
                                >
                                    <tspan x={props.unit === 'string' ? '936' : '933'} y="47">
                                        {classData['class1Data']}
                                    </tspan>
                                    <title>{classData['class1DataTitle']}</title>
                                </text>
                                <text
                                    fill="#A9201C"
                                    transform="translate(945.500000, 77.500000) scale(-1, 1) translate(-945.500000, -77.500000)"
                                    fontFamily="Helvetica-Bold, Helvetica"
                                    fontSize="21"
                                    fontWeight="bold"
                                >
                                    <tspan x="918" y="78">
                                        {classData['class1Names'][0]}
                                    </tspan>
                                    <tspan x="918" y="103">
                                        {classData['class1Names'][1]}
                                    </tspan>
                                </text>
                                {props.showWorkbenchLink && !isNaN(classData['class1Change']) ? (
                                    <text
                                        fontFamily="Inter regular, sans-serif"
                                        transform="translate(945.500000, 77.500000) scale(-1, 1) translate(-945.500000, -77.500000)"
                                        fontSize="14"
                                        fontWeight="normal"
                                        line-spacing="20"
                                        letterSpacing="0.1"
                                        fill={
                                            classData['class1Change'] > 0
                                                ? '#225522'
                                                : classData['class1Change'] < 0
                                                ? '#FF0000'
                                                : '#9E9E9E'
                                        }
                                    >
                                        <tspan x="918" y={classData['class1Names'].length === 2 ? 131 : 108}>
                                            {classData['class1Change'] > 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25B2 '}
                                                </tspan>
                                            ) : classData['class1Change'] < 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25BC '}
                                                </tspan>
                                            ) : classData['class1Change'] === 0 ? (
                                                <tspan width="47" height="20" fontSize="15">
                                                    <i className="fas fa-sort" style={{ fontSize: '15px' }}></i>
                                                </tspan>
                                            ) : (
                                                ''
                                            )}
                                            {formatComparisonData(classData['class1Change'])}
                                        </tspan>
                                    </text>
                                ) : (
                                    ''
                                )}
                                {classData['class1Data'] !== 0 &&
                                    top3AirlinesTooltip(classData['class1Top3Airlines'], 980, -70)}
                            </g>

                            <rect id="Rectangle" fill="#AF2119" x="986" y="7" width="2" height="215"></rect>
                            <rect id="Rectangle" fill="#26085A" x="814" y="7" width="2" height="151"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

function threeClassSvg(classData, props, dataFontSize) {
    return (
        <svg
            width="1012px"
            height={props.tenantIcaoCode === 'all' ? '430px' : '294px'}
            viewBox="0 0 1012 294"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <filter
                    x="-1.6%"
                    y="-5.9%"
                    width="103.2%"
                    height="111.9%"
                    filterUnits="objectBoundingBox"
                    id="filter-1"
                >
                    <feOffset dx="3" dy="3" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur
                        stdDeviation="2.5"
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    ></feGaussianBlur>
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.301628059 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    ></feColorMatrix>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
                <linearGradient
                    x1="-0.0282885431%"
                    y1="49.971193%"
                    x2="99.9717115%"
                    y2="49.971193%"
                    id="linearGradient-2"
                >
                    <stop stopColor="#98C5FF" offset="0%"></stop>
                    <stop stopColor="#87B2EB" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="0%" y1="49.9265836%" x2="99.9496224%" y2="49.9265836%" id="linearGradient-3">
                    <stop stopColor="#87B2EB" offset="0%"></stop>
                    <stop stopColor="#80ABE3" offset="15.3381774%"></stop>
                    <stop stopColor="#749DD2" offset="100%"></stop>
                </linearGradient>
                <linearGradient
                    x1="7.03179136%"
                    y1="50.0056707%"
                    x2="99.9748299%"
                    y2="50.0056707%"
                    id="linearGradient-4"
                >
                    <stop stopColor="#749ED2" offset="0%"></stop>
                    <stop stopColor="#5563A2" offset="43.4904939%"></stop>
                    <stop stopColor="#250759" offset="100%"></stop>
                </linearGradient>
                <linearGradient
                    x1="-0.0251100413%"
                    y1="50.0014561%"
                    x2="100.000551%"
                    y2="50.0014561%"
                    id="linearGradient-5"
                >
                    <stop stopColor="#26085A" offset="0%"></stop>
                    <stop stopColor="#B32217" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-352.000000, -2029.000000)">
                    <g transform="translate(344.000000, 1885.000000)">
                        <g transform="translate(514.000000, 287.000000) scale(-1, 1) translate(-514.000000, -287.000000) translate(20.000000, 148.000000)">
                            <g filter="url(#filter-1)" transform="translate(0.000000, 8.000000)" fillRule="nonzero">
                                <path
                                    d="M466.867893,140.4442 C437.143706,140.041781 407.023197,139.907642 378.620085,139.907642 C340.969448,139.907642 290.372277,139.102804 256.156436,139.505223 C256.420651,140.846619 189.97058,124.749863 184.818388,123.408466 C176.75983,121.262232 105.421782,22.6695987 93.7963225,10.5970313 C86.0019802,2.41451347 72.3949081,0 62.7510608,0 C57.202546,0 40.1606789,0.536558549 40.1606789,0.536558549 L90.0973126,148.492578 C84.8130127,148.894997 79.7929279,149.699835 74.772843,149.699835 C36.990099,138.968664 12.1538897,132.395822 9.64384724,132.395822 C2.64214993,132.395822 0,136.151732 0,136.151732 L68.5637907,162.040682 C70.2811881,172.503573 77.0186704,175.186366 95.1173975,182.295767 C113.216124,189.405168 255.363791,241.853766 303.847242,239.975811 C312.170014,239.707532 390.50976,242.256185 467,244 L467,140.4442 L466.867893,140.4442 Z"
                                    id="Path"
                                    fill="url(#linearGradient-2)"
                                ></path>
                                <path
                                    d="M640.867825,144.080997 C601.215257,143.277259 534.598943,141.803738 466,141 L466,244.41433 C482.918429,244.816199 499.836858,245.084112 515.83006,245.352025 C516.226586,247.76324 517.019637,250.040498 518.473565,250.844237 C529.179758,256.470405 539.225076,261.426791 539.225076,261.426791 C539.225076,262.23053 539.753776,263.034268 540.679003,263.302181 C546.494713,264.775701 569.625378,270 592.623867,270 C617.208459,270 631.747734,268.52648 641,267.05296 L641,144.080997 L640.867825,144.080997 Z"
                                    id="Path"
                                    fill="url(#linearGradient-3)"
                                ></path>
                                <path
                                    d="M666.777778,144.536532 C660.428571,144.402399 650.904762,144.268266 639,144 L639,267 C648.52381,265.524537 652.227513,263.91494 655.005291,263.91494 C665.322751,263.91494 666.777778,254.123228 666.380952,247.953108 C688.867725,247.953108 778.814815,249.428571 814,249.830971 L814,148.962923 C776.037037,146.280262 727.888889,144.536532 666.777778,144.536532 Z"
                                    id="Path"
                                    fill="url(#linearGradient-4)"
                                ></path>
                                <path
                                    d="M955.252531,188.787695 C953.009217,180.419117 941.924604,159.165585 813,150 L813,249.891597 C813.395879,249.891597 813.791758,249.891597 814.055677,249.891597 C847.969314,248.961755 914.476991,254.673641 961.850515,235.279794 C1011.99519,214.690436 978.741354,199.41446 955.252531,188.787695 Z"
                                    id="Path"
                                    fill="url(#linearGradient-5)"
                                ></path>
                            </g>

                            <g id="seat-class-4">
                                <text
                                    fill={classData['class4Data'] === 'Data not available' ? '#7A7A7A' : '#75A0D3'}
                                    transform="translate(592.000000, 29.000000) scale(-1, 1) translate(-592.000000, -29.000000)"
                                    fontFamily="Helvetica"
                                    fontSize={classData['class4Data'] === 'Data not available' ? 18 : dataFontSize}
                                    fontWeight="normal"
                                >
                                    <tspan x={props.unit === 'string' ? '560' : '562'} y="47">
                                        {classData['class4Data']}
                                    </tspan>
                                    <title>{classData['class4DataTitle']}</title>
                                </text>
                                <text
                                    fill="#75A0D3"
                                    transform="translate(576.500000, 82.000000) scale(-1, 1) translate(-576.500000, -82.000000)"
                                    fontFamily="Helvetica-Bold, Helvetica"
                                    fontSize="21"
                                    fontWeight="bold"
                                >
                                    <tspan x="531" y="78">
                                        {classData['class4Names'][0]}
                                    </tspan>
                                    <tspan x="531" y="103">
                                        {classData['class4Names'][1]}
                                    </tspan>
                                </text>
                                {props.showWorkbenchLink && !isNaN(classData['class4Change']) ? (
                                    <text
                                        fontFamily="Inter regular, sans-serif"
                                        transform="translate(576.500000, 82.000000) scale(-1, 1) translate(-576.500000, -82.000000)"
                                        fontSize="14"
                                        fontWeight="normal"
                                        line-spacing="20"
                                        letterSpacing="0.1"
                                        fill={
                                            classData['class4Change'] > 0
                                                ? '#225522'
                                                : classData['class4Change'] < 0
                                                ? '#FF0000'
                                                : '#9E9E9E'
                                        }
                                    >
                                        <tspan x="531" y={classData['class4Names'].length === 2 ? 131 : 108}>
                                            {classData['class4Change'] > 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25B2 '}
                                                </tspan>
                                            ) : classData['class4Change'] < 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25BC '}
                                                </tspan>
                                            ) : classData['class4Change'] === 0 ? (
                                                <tspan width="47" height="20" fontSize="15">
                                                    <i className="fas fa-sort" style={{ fontSize: '15px' }}></i>
                                                </tspan>
                                            ) : (
                                                ''
                                            )}
                                            {formatComparisonData(classData['class4Change'])}
                                        </tspan>
                                    </text>
                                ) : (
                                    ''
                                )}
                                {classData['class4Data'] !== 0 &&
                                    top3AirlinesTooltip(classData['class4Top3Airlines'], 620, -70)}
                            </g>

                            <g id="seat-class-3">
                                <text
                                    fill={classData['class3Data'] === 'Data not available' ? '#7A7A7A' : '#26095A'}
                                    transform="translate(764.000000, 36.000000) scale(-1, 1) translate(-764.000000, -36.000000)"
                                    fontFamily="Helvetica"
                                    fontSize={classData['class3Data'] === 'Data not available' ? 18 : dataFontSize}
                                    fontWeight="normal"
                                >
                                    <tspan x={props.unit === 'string' ? '729' : '726'} y="47">
                                        {classData['class3Data']}
                                    </tspan>
                                    <title>{classData['class3DataTitle']}</title>
                                </text>
                                <text
                                    fill="#26095A"
                                    transform="translate(749.500000, 77.500000) scale(-1, 1) translate(-749.500000, -77.500000)"
                                    fontFamily="Helvetica-Bold, Helvetica"
                                    fontSize="21"
                                    fontWeight="bold"
                                >
                                    <tspan x="698" y="78">
                                        {classData['class3Names'][0]}
                                    </tspan>
                                    <tspan x="698" y="103">
                                        {classData['class3Names'][1]}
                                    </tspan>
                                </text>
                                {props.showWorkbenchLink && !isNaN(classData['class3Change']) ? (
                                    <text
                                        fontFamily="Inter regular, sans-serif"
                                        transform="translate(749.500000, 77.500000) scale(-1, 1) translate(-749.500000, -77.500000)"
                                        fontSize="14"
                                        fontWeight="normal"
                                        line-spacing="20"
                                        letterSpacing="0.1"
                                        fill={
                                            classData['class3Change'] > 0
                                                ? '#225522'
                                                : classData['class3Change'] < 0
                                                ? '#FF0000'
                                                : '#9E9E9E'
                                        }
                                    >
                                        <tspan x="698" y={classData['class3Names'].length === 2 ? 131 : 108}>
                                            {classData['class3Change'] > 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25B2 '}
                                                </tspan>
                                            ) : classData['class3Change'] < 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25BC '}
                                                </tspan>
                                            ) : classData['class3Change'] === 0 ? (
                                                <tspan width="47" height="20" fontSize="15">
                                                    <i className="fas fa-sort" style={{ fontSize: '15px' }}></i>
                                                </tspan>
                                            ) : (
                                                ''
                                            )}
                                            {formatComparisonData(classData['class3Change'])}
                                        </tspan>
                                    </text>
                                ) : (
                                    ''
                                )}
                                {classData['class3Data'] !== 0 &&
                                    top3AirlinesTooltip(classData['class3Top3Airlines'], 800, -70)}
                            </g>

                            <g id="seat-class-1">
                                <text
                                    fill={classData['class1Data'] === 'Data not available' ? '#7A7A7A' : '#A41F1E'}
                                    transform="translate(953.000000, 36.000000) scale(-1, 1) translate(-953.000000, -36.000000)"
                                    fontFamily="Helvetica"
                                    fontSize={classData['class1Data'] === 'Data not available' ? 18 : dataFontSize}
                                    fontWeight="normal"
                                >
                                    <tspan x={props.unit === 'string' ? '936' : '933'} y="47">
                                        {classData['class1Data']}
                                    </tspan>
                                    <title>{classData['class1DataTitle']}</title>
                                </text>
                                <text
                                    fill="#A9201C"
                                    transform="translate(945.500000, 77.500000) scale(-1, 1) translate(-945.500000, -77.500000)"
                                    fontFamily="Helvetica-Bold, Helvetica"
                                    fontSize="21"
                                    fontWeight="bold"
                                >
                                    <tspan x="918" y="78">
                                        {classData['class1Names'][0]}
                                    </tspan>
                                    <tspan x="918" y="103">
                                        {classData['class1Names'][1]}
                                    </tspan>
                                </text>
                                {props.showWorkbenchLink && !isNaN(classData['class1Change']) ? (
                                    <text
                                        fontFamily="Inter regular, sans-serif"
                                        transform="translate(945.500000, 77.500000) scale(-1, 1) translate(-945.500000, -77.500000)"
                                        fontSize="14"
                                        fontWeight="normal"
                                        line-spacing="20"
                                        letterSpacing="0.1"
                                        fill={
                                            classData['class1Change'] > 0
                                                ? '#225522'
                                                : classData['class1Change'] < 0
                                                ? '#FF0000'
                                                : '#9E9E9E'
                                        }
                                    >
                                        <tspan x="918" y={classData['class1Names'].length === 2 ? 131 : 108}>
                                            {classData['class1Change'] > 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25B2 '}
                                                </tspan>
                                            ) : classData['class1Change'] < 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25BC '}
                                                </tspan>
                                            ) : classData['class1Change'] === 0 ? (
                                                <tspan width="47" height="20" fontSize="15">
                                                    <i className="fas fa-sort" style={{ fontSize: '15px' }}></i>
                                                </tspan>
                                            ) : (
                                                ''
                                            )}
                                            {formatComparisonData(classData['class1Change'])}
                                        </tspan>
                                    </text>
                                ) : (
                                    ''
                                )}
                                {classData['class1Data'] !== 0 &&
                                    top3AirlinesTooltip(classData['class1Top3Airlines'], 980, -70)}
                            </g>

                            <rect id="Rectangle" fill="#AF2119" x="986" y="7" width="2" height="215"></rect>
                            <rect id="Rectangle" fill="#26085A" x="814" y="7" width="2" height="151"></rect>
                            <rect id="Rectangle" fill="#61A1D6" x="630" y="7" width="2" height="145"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

function fourClassSvg(classData, props, dataFontSize) {
    return (
        <svg
            width="1012px"
            height={props.tenantIcaoCode === 'all' ? '430px' : '294px'}
            viewBox="0 0 1012 294"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <filter
                    x="-1.6%"
                    y="-5.9%"
                    width="103.2%"
                    height="111.9%"
                    filterUnits="objectBoundingBox"
                    id="filter-1"
                >
                    <feOffset dx="3" dy="3" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur
                        stdDeviation="2.5"
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    ></feGaussianBlur>
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.301628059 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    ></feColorMatrix>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
                <linearGradient
                    x1="-0.0282885431%"
                    y1="49.971193%"
                    x2="99.9717115%"
                    y2="49.971193%"
                    id="linearGradient-2"
                >
                    <stop stopColor="#8AD4E0" offset="0%"></stop>
                    <stop stopColor="#8AD4E0" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="0%" y1="49.9265836%" x2="99.9496224%" y2="49.9265836%" id="linearGradient-3">
                    <stop stopColor="#8AD4E0" offset="0%"></stop>
                    <stop stopColor="#84C6DC" offset="15.3381774%"></stop>
                    <stop stopColor="#749DD2" offset="100%"></stop>
                </linearGradient>
                <linearGradient
                    x1="7.03179136%"
                    y1="50.0056707%"
                    x2="99.9748299%"
                    y2="50.0056707%"
                    id="linearGradient-4"
                >
                    <stop stopColor="#749ED2" offset="0%"></stop>
                    <stop stopColor="#5563A2" offset="43.4904939%"></stop>
                    <stop stopColor="#250759" offset="100%"></stop>
                </linearGradient>
                <linearGradient
                    x1="-0.0251100413%"
                    y1="50.0014561%"
                    x2="100.000551%"
                    y2="50.0014561%"
                    id="linearGradient-5"
                >
                    <stop stopColor="#26085A" offset="0%"></stop>
                    <stop stopColor="#B32217" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-352.000000, -2029.000000)">
                    <g transform="translate(344.000000, 1885.000000)">
                        <g transform="translate(514.000000, 287.000000) scale(-1, 1) translate(-514.000000, -287.000000) translate(20.000000, 148.000000)">
                            <g filter="url(#filter-1)" transform="translate(0.000000, 8.000000)" fillRule="nonzero">
                                <path
                                    d="M466.867893,140.4442 C437.143706,140.041781 407.023197,139.907642 378.620085,139.907642 C340.969448,139.907642 290.372277,139.102804 256.156436,139.505223 C256.420651,140.846619 189.97058,124.749863 184.818388,123.408466 C176.75983,121.262232 105.421782,22.6695987 93.7963225,10.5970313 C86.0019802,2.41451347 72.3949081,0 62.7510608,0 C57.202546,0 40.1606789,0.536558549 40.1606789,0.536558549 L90.0973126,148.492578 C84.8130127,148.894997 79.7929279,149.699835 74.772843,149.699835 C36.990099,138.968664 12.1538897,132.395822 9.64384724,132.395822 C2.64214993,132.395822 0,136.151732 0,136.151732 L68.5637907,162.040682 C70.2811881,172.503573 77.0186704,175.186366 95.1173975,182.295767 C113.216124,189.405168 255.363791,241.853766 303.847242,239.975811 C312.170014,239.707532 390.50976,242.256185 467,244 L467,140.4442 L466.867893,140.4442 Z"
                                    id="Path"
                                    fill="url(#linearGradient-2)"
                                ></path>
                                <path
                                    d="M640.867825,144.080997 C601.215257,143.277259 534.598943,141.803738 466,141 L466,244.41433 C482.918429,244.816199 499.836858,245.084112 515.83006,245.352025 C516.226586,247.76324 517.019637,250.040498 518.473565,250.844237 C529.179758,256.470405 539.225076,261.426791 539.225076,261.426791 C539.225076,262.23053 539.753776,263.034268 540.679003,263.302181 C546.494713,264.775701 569.625378,270 592.623867,270 C617.208459,270 631.747734,268.52648 641,267.05296 L641,144.080997 L640.867825,144.080997 Z"
                                    id="Path"
                                    fill="url(#linearGradient-3)"
                                ></path>
                                <path
                                    d="M666.777778,144.536532 C660.428571,144.402399 650.904762,144.268266 639,144 L639,267 C648.52381,265.524537 652.227513,263.91494 655.005291,263.91494 C665.322751,263.91494 666.777778,254.123228 666.380952,247.953108 C688.867725,247.953108 778.814815,249.428571 814,249.830971 L814,148.962923 C776.037037,146.280262 727.888889,144.536532 666.777778,144.536532 Z"
                                    id="Path"
                                    fill="url(#linearGradient-4)"
                                ></path>
                                <path
                                    d="M955.252531,188.787695 C953.009217,180.419117 941.924604,159.165585 813,150 L813,249.891597 C813.395879,249.891597 813.791758,249.891597 814.055677,249.891597 C847.969314,248.961755 914.476991,254.673641 961.850515,235.279794 C1011.99519,214.690436 978.741354,199.41446 955.252531,188.787695 Z"
                                    id="Path"
                                    fill="url(#linearGradient-5)"
                                ></path>
                            </g>

                            <g id="seat-class-4">
                                <text
                                    fill={classData['class4Data'] === 'Data not available' ? '#7A7A7A' : '#6EC4C2'}
                                    transform="translate(415.000000, 36.000000) scale(-1, 1) translate(-415.000000, -36.000000)"
                                    fontFamily="Helvetica"
                                    fontSize={classData['class4Data'] === 'Data not available' ? 18 : dataFontSize}
                                    fontWeight="normal"
                                >
                                    <tspan x="390" y="47">
                                        {classData['class4Data']}
                                    </tspan>
                                    <title>{classData['class4DataTitle']}</title>
                                </text>
                                <text
                                    fill="#6EC4C2"
                                    transform="translate(397.500000, 74.500000) scale(-1, 1) translate(-397.500000, -74.500000)"
                                    fontFamily="Helvetica-Bold, Helvetica"
                                    fontSize="21"
                                    fontWeight="bold"
                                >
                                    <tspan x="355" y="78">
                                        {classData['class4Names'][0]}
                                    </tspan>
                                    <tspan x="355" y="103">
                                        {classData['class4Names'][1]}
                                    </tspan>
                                </text>
                                {props.showWorkbenchLink && !isNaN(classData['class4Change']) ? (
                                    <text
                                        fontFamily="Inter regular, sans-serif"
                                        transform="translate(397.500000, 74.500000) scale(-1, 1) translate(-397.500000, -74.500000)"
                                        fontSize="14"
                                        fontWeight="normal"
                                        line-spacing="20"
                                        letterSpacing="0.1"
                                        fill={
                                            classData['class4Change'] > 0
                                                ? '#225522'
                                                : classData['class4Change'] < 0
                                                ? '#FF0000'
                                                : '#9E9E9E'
                                        }
                                    >
                                        <tspan x="351" y={classData['class4Names'].length === 2 ? 131 : 108}>
                                            {classData['class4Change'] > 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25B2 '}
                                                </tspan>
                                            ) : classData['class4Change'] < 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25BC '}
                                                </tspan>
                                            ) : classData['class4Change'] === 0 ? (
                                                <tspan width="47" height="20" fontSize="15">
                                                    <i className="fas fa-sort" style={{ fontSize: '15px' }}></i>
                                                </tspan>
                                            ) : (
                                                ''
                                            )}
                                            {formatComparisonData(classData['class4Change'])}
                                        </tspan>
                                    </text>
                                ) : (
                                    ''
                                )}
                                {classData['class4Data'] !== 0 &&
                                    top3AirlinesTooltip(classData['class4Top3Airlines'], 440, -70)}
                            </g>

                            <g id="seat-class-3">
                                <text
                                    fill={classData['class3Data'] === 'Data not available' ? '#7A7A7A' : '#75A0D3'}
                                    transform="translate(592.000000, 29.000000) scale(-1, 1) translate(-592.000000, -29.000000)"
                                    fontFamily="Helvetica"
                                    fontSize={classData['class3Data'] === 'Data not available' ? 18 : dataFontSize}
                                    fontWeight="normal"
                                >
                                    <tspan x={props.unit === 'string' ? '560' : '562'} y="47">
                                        {classData['class3Data']}
                                    </tspan>
                                    <title>{classData['class3DataTitle']}</title>
                                </text>
                                <text
                                    fill="#75A0D3"
                                    transform="translate(576.500000, 82.000000) scale(-1, 1) translate(-576.500000, -82.000000)"
                                    fontFamily="Helvetica-Bold, Helvetica"
                                    fontSize="21"
                                    fontWeight="bold"
                                >
                                    <tspan x="531" y="78">
                                        {classData['class3Names'][0]}
                                    </tspan>
                                    <tspan x="531" y="103">
                                        {classData['class3Names'][1]}
                                    </tspan>
                                </text>
                                {props.showWorkbenchLink && !isNaN(classData['class3Change']) ? (
                                    <text
                                        fontFamily="Inter regular, sans-serif"
                                        transform="translate(576.500000, 82.000000) scale(-1, 1) translate(-576.500000, -82.000000)"
                                        fontSize="14"
                                        fontWeight="normal"
                                        line-spacing="20"
                                        letterSpacing="0.1"
                                        fill={
                                            classData['class3Change'] > 0
                                                ? '#225522'
                                                : classData['class3Change'] < 0
                                                ? '#FF0000'
                                                : '#9E9E9E'
                                        }
                                    >
                                        <tspan x="531" y={classData['class3Names'].length === 2 ? 131 : 108}>
                                            {classData['class3Change'] > 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25B2 '}
                                                </tspan>
                                            ) : classData['class3Change'] < 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25BC '}
                                                </tspan>
                                            ) : classData['class3Change'] === 0 ? (
                                                <tspan width="47" height="20" fontSize="15">
                                                    <i className="fas fa-sort" style={{ fontSize: '15px' }}></i>
                                                </tspan>
                                            ) : (
                                                ''
                                            )}
                                            {formatComparisonData(classData['class3Change'])}
                                        </tspan>
                                    </text>
                                ) : (
                                    ''
                                )}
                                {classData['class3Data'] !== 0 &&
                                    top3AirlinesTooltip(classData['class3Top3Airlines'], 620, -70)}
                            </g>

                            <g id="seat-class-2">
                                <text
                                    fill={classData['class2Data'] === 'Data not available' ? '#7A7A7A' : '#26095A'}
                                    transform="translate(764.000000, 36.000000) scale(-1, 1) translate(-764.000000, -36.000000)"
                                    fontFamily="Helvetica"
                                    fontSize={classData['class2Data'] === 'Data not available' ? 18 : dataFontSize}
                                    fontWeight="normal"
                                >
                                    <tspan x={props.unit === 'string' ? '729' : '726'} y="47">
                                        {classData['class2Data']}
                                    </tspan>
                                    <title>{classData['class2DataTitle']}</title>
                                </text>
                                <text
                                    fill="#26095A"
                                    transform="translate(749.500000, 77.500000) scale(-1, 1) translate(-749.500000, -77.500000)"
                                    fontFamily="Helvetica-Bold, Helvetica"
                                    fontSize="21"
                                    fontWeight="bold"
                                >
                                    <tspan x="698" y="78">
                                        {classData['class2Names'][0]}
                                    </tspan>
                                    <tspan x="698" y="103">
                                        {classData['class2Names'][1]}
                                    </tspan>
                                </text>
                                {props.showWorkbenchLink && !isNaN(classData['class2Change']) ? (
                                    <text
                                        fontFamily="Inter regular, sans-serif"
                                        transform="translate(749.500000, 77.500000) scale(-1, 1) translate(-749.500000, -77.500000)"
                                        fontSize="14"
                                        fontWeight="normal"
                                        line-spacing="20"
                                        letterSpacing="0.1"
                                        fill={
                                            classData['class2Change'] > 0
                                                ? '#225522'
                                                : classData['class2Change'] < 0
                                                ? '#FF0000'
                                                : '#9E9E9E'
                                        }
                                    >
                                        <tspan x="698" y={classData['class2Names'].length === 2 ? 131 : 108}>
                                            {classData['class2Change'] > 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25B2 '}
                                                </tspan>
                                            ) : classData['class2Change'] < 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25BC '}
                                                </tspan>
                                            ) : classData['class2Change'] === 0 ? (
                                                <tspan width="47" height="20" fontSize="15">
                                                    <i className="fas fa-sort" style={{ fontSize: '15px' }}></i>
                                                </tspan>
                                            ) : (
                                                ''
                                            )}
                                            {formatComparisonData(classData['class2Change'])}
                                        </tspan>
                                    </text>
                                ) : (
                                    ''
                                )}
                                {classData['class2Data'] !== 0 &&
                                    top3AirlinesTooltip(classData['class2Top3Airlines'], 798, -70)}
                            </g>

                            <g id="seat-class-1">
                                <text
                                    fill={classData['class1Data'] === 'Data not available' ? '#7A7A7A' : '#A41F1E'}
                                    transform="translate(953.000000, 36.000000) scale(-1, 1) translate(-953.000000, -36.000000)"
                                    fontFamily="Helvetica"
                                    fontSize={classData['class1Data'] === 'Data not available' ? 18 : dataFontSize}
                                    fontWeight="normal"
                                >
                                    <tspan x={props.unit === 'string' ? '936' : '933'} y="47">
                                        {classData['class1Data']}
                                    </tspan>
                                    <title>{classData['class1DataTitle']}</title>
                                </text>

                                <text
                                    fill="#A9201C"
                                    transform="translate(945.500000, 77.500000) scale(-1, 1) translate(-945.500000, -77.500000)"
                                    fontFamily="Helvetica-Bold, Helvetica"
                                    fontSize="21"
                                    fontWeight="bold"
                                >
                                    <tspan x="918" y="78">
                                        {classData['class1Names'][0]}
                                    </tspan>
                                    <tspan x="918" y="103">
                                        {classData['class1Names'][1]}
                                    </tspan>
                                </text>
                                {props.showWorkbenchLink && !isNaN(classData['class1Change']) ? (
                                    <text
                                        fontFamily="Inter regular, sans-serif"
                                        transform="translate(945.500000, 77.500000) scale(-1, 1) translate(-945.500000, -77.500000)"
                                        fontSize="14"
                                        fontWeight="normal"
                                        line-spacing="20"
                                        letterSpacing="0.1"
                                        fill={
                                            classData['class1Change'] > 0
                                                ? '#225522'
                                                : classData['class1Change'] < 0
                                                ? '#FF0000'
                                                : '#9E9E9E'
                                        }
                                    >
                                        <tspan x="918" y={classData['class1Names'].length === 2 ? 131 : 108}>
                                            {classData['class1Change'] > 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25B2 '}
                                                </tspan>
                                            ) : classData['class1Change'] < 0 ? (
                                                <tspan width="47" height="20" fontSize="13.4">
                                                    {'\u25BC '}
                                                </tspan>
                                            ) : classData['class1Change'] === 0 ? (
                                                <tspan width="47" height="20" fontSize="15">
                                                    <i className="fas fa-sort" style={{ fontSize: '15px' }}></i>
                                                </tspan>
                                            ) : (
                                                ''
                                            )}
                                            {formatComparisonData(classData['class1Change'])}
                                        </tspan>
                                    </text>
                                ) : (
                                    ''
                                )}
                                {classData['class1Data'] !== 0 &&
                                    top3AirlinesTooltip(classData['class1Top3Airlines'], 970, -70)}
                            </g>

                            <rect id="Rectangle" fill="#6EC4C2" x="450" y="7" width="2" height="142"></rect>
                            <rect id="Rectangle" fill="#AF2119" x="986" y="7" width="2" height="215"></rect>
                            <rect id="Rectangle" fill="#26085A" x="814" y="7" width="2" height="151"></rect>
                            <rect id="Rectangle" fill="#61A1D6" x="630" y="7" width="2" height="145"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

function Plane(props) {
    let data;
    let classCount = 0;

    let class1Names;
    let class2Names;
    let class3Names;
    let class4Names;

    let class1Data;
    let class2Data;
    let class3Data;
    let class4Data;

    let class1Change;
    let class2Change;
    let class3Change;
    let class4Change;

    let class1DataTitle;
    let class2DataTitle;
    let class3DataTitle;
    let class4DataTitle;

    let class1Top3Airlines;
    let class2Top3Airlines;
    let class3Top3Airlines;
    let class4Top3Airlines;

    if (props && props.data && props.data.length > 0) {
        // Remove 'PED' class from the plane data.
        data = props.data.filter((item) => item.label !== 'PED');

        data = data.length > 4 ? data.slice(0, 4) : data;
        classCount = data.length;
    }

    if (data) {
        if (classCount === 1) {
            if (data[0]) {
                [class1Names, class1Data, class1Change, class1DataTitle, class1Top3Airlines] = setClassData(
                    class1Names,
                    class1Data,
                    class1Change,
                    data[0],
                    props,
                    class1DataTitle,
                    class1Top3Airlines
                );
            }
        } else if (classCount === 2) {
            for (let i = 0; i < data.length; i++) {
                if (i === 0) {
                    [class1Names, class1Data, class1Change, class1DataTitle, class1Top3Airlines] = setClassData(
                        class1Names,
                        class1Data,
                        class1Change,
                        data[i],
                        props,
                        class1DataTitle,
                        class1Top3Airlines
                    );
                } else if (i === 1) {
                    [class4Names, class4Data, class4Change, class4DataTitle, class4Top3Airlines] = setClassData(
                        class4Names,
                        class4Data,
                        class4Change,
                        data[i],
                        props,
                        class4DataTitle,
                        class4Top3Airlines
                    );
                }
            }
        } else if (classCount === 3) {
            for (let i = 0; i < data.length; i++) {
                if (i === 0) {
                    [class1Names, class1Data, class1Change, class1DataTitle, class1Top3Airlines] = setClassData(
                        class1Names,
                        class1Data,
                        class1Change,
                        data[i],
                        props,
                        class1DataTitle,
                        class1Top3Airlines
                    );
                } else if (i === 1) {
                    [class3Names, class3Data, class3Change, class3DataTitle, class3Top3Airlines] = setClassData(
                        class3Names,
                        class3Data,
                        class3Change,
                        data[i],
                        props,
                        class3DataTitle,
                        class3Top3Airlines
                    );
                } else if (i === 2) {
                    [class4Names, class4Data, class4Change, class4DataTitle, class4Top3Airlines] = setClassData(
                        class4Names,
                        class4Data,
                        class4Change,
                        data[i],
                        props,
                        class4DataTitle,
                        class4Top3Airlines
                    );
                }
            }
        } else if (classCount === 4) {
            for (let i = 0; i < data.length; i++) {
                if (i === 0) {
                    [class1Names, class1Data, class1Change, class1DataTitle, class1Top3Airlines] = setClassData(
                        class1Names,
                        class1Data,
                        class1Change,
                        data[i],
                        props,
                        class1DataTitle,
                        class1Top3Airlines
                    );
                } else if (i === 1) {
                    [class2Names, class2Data, class2Change, class2DataTitle, class2Top3Airlines] = setClassData(
                        class2Names,
                        class2Data,
                        class2Change,
                        data[i],
                        props,
                        class2DataTitle,
                        class2Top3Airlines
                    );
                } else if (i === 2) {
                    [class3Names, class3Data, class3Change, class3DataTitle, class3Top3Airlines] = setClassData(
                        class3Names,
                        class3Data,
                        class3Change,
                        data[i],
                        props,
                        class3DataTitle,
                        class3Top3Airlines
                    );
                } else if (i === 3) {
                    [class4Names, class4Data, class4Change, class4DataTitle, class4Top3Airlines] = setClassData(
                        class4Names,
                        class4Data,
                        class4Change,
                        data[i],
                        props,
                        class4DataTitle,
                        class4Top3Airlines
                    );
                }
            }
        }
    }

    let dataFontSize = props.unit === 'string' ? 22 : 27;

    //-----If consitions to determine which aircraft to draw-----

    if (classCount === 0 && props.hasPED) {
        return (
            <svg
                width="1012px"
                height="294px"
                viewBox="0 0 1012 294"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <defs>
                    <filter
                        x="-1.6%"
                        y="-5.9%"
                        width="103.2%"
                        height="111.9%"
                        filterUnits="objectBoundingBox"
                        id="filter-1"
                    >
                        <feOffset dx="3" dy="3" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                        <feGaussianBlur
                            stdDeviation="2.5"
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        ></feGaussianBlur>
                        <feColorMatrix
                            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.301628059 0"
                            type="matrix"
                            in="shadowBlurOuter1"
                            result="shadowMatrixOuter1"
                        ></feColorMatrix>
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                            <feMergeNode in="SourceGraphic"></feMergeNode>
                        </feMerge>
                    </filter>
                    <linearGradient
                        x1="-0.0282885431%"
                        y1="49.971193%"
                        x2="99.9717115%"
                        y2="49.971193%"
                        id="linearGradient-2"
                    >
                        <stop stopColor="#FFEBEE" offset="0%"></stop>
                        <stop stopColor="#EF9A9A" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="0%" y1="49.9265836%" x2="99.9496224%" y2="49.9265836%" id="linearGradient-3">
                        <stop stopColor="#EF9A9A" offset="0%"></stop>
                        <stop stopColor="#EF9A9A" offset="15.3381774%"></stop>
                        <stop stopColor="#E57373" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient
                        x1="7.03179136%"
                        y1="50.0056707%"
                        x2="99.9748299%"
                        y2="50.0056707%"
                        id="linearGradient-4"
                    >
                        <stop stopColor="#E57373" offset="0%"></stop>
                        <stop stopColor="#F44336" offset="43.4904939%"></stop>
                        <stop stopColor="#BB2217" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient
                        x1="-0.0251100413%"
                        y1="50.0014561%"
                        x2="100.000551%"
                        y2="50.0014561%"
                        id="linearGradient-5"
                    >
                        <stop stopColor="#BB2217" offset="0%"></stop>
                        <stop stopColor="#B32217" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-352.000000, -2029.000000)">
                        <g transform="translate(344.000000, 1885.000000)">
                            <g transform="translate(514.000000, 287.000000) scale(-1, 1) translate(-514.000000, -287.000000) translate(20.000000, 148.000000)">
                                <g filter="url(#filter-1)" transform="translate(0.000000, 8.000000)" fillRule="nonzero">
                                    <path
                                        d="M466.867893,140.4442 C437.143706,140.041781 407.023197,139.907642 378.620085,139.907642 C340.969448,139.907642 290.372277,139.102804 256.156436,139.505223 C256.420651,140.846619 189.97058,124.749863 184.818388,123.408466 C176.75983,121.262232 105.421782,22.6695987 93.7963225,10.5970313 C86.0019802,2.41451347 72.3949081,0 62.7510608,0 C57.202546,0 40.1606789,0.536558549 40.1606789,0.536558549 L90.0973126,148.492578 C84.8130127,148.894997 79.7929279,149.699835 74.772843,149.699835 C36.990099,138.968664 12.1538897,132.395822 9.64384724,132.395822 C2.64214993,132.395822 0,136.151732 0,136.151732 L68.5637907,162.040682 C70.2811881,172.503573 77.0186704,175.186366 95.1173975,182.295767 C113.216124,189.405168 255.363791,241.853766 303.847242,239.975811 C312.170014,239.707532 390.50976,242.256185 467,244 L467,140.4442 L466.867893,140.4442 Z"
                                        id="Path"
                                        fill="url(#linearGradient-2)"
                                    ></path>
                                    <path
                                        d="M640.867825,144.080997 C601.215257,143.277259 534.598943,141.803738 466,141 L466,244.41433 C482.918429,244.816199 499.836858,245.084112 515.83006,245.352025 C516.226586,247.76324 517.019637,250.040498 518.473565,250.844237 C529.179758,256.470405 539.225076,261.426791 539.225076,261.426791 C539.225076,262.23053 539.753776,263.034268 540.679003,263.302181 C546.494713,264.775701 569.625378,270 592.623867,270 C617.208459,270 631.747734,268.52648 641,267.05296 L641,144.080997 L640.867825,144.080997 Z"
                                        id="Path"
                                        fill="url(#linearGradient-3)"
                                    ></path>
                                    <path
                                        d="M666.777778,144.536532 C660.428571,144.402399 650.904762,144.268266 639,144 L639,267 C648.52381,265.524537 652.227513,263.91494 655.005291,263.91494 C665.322751,263.91494 666.777778,254.123228 666.380952,247.953108 C688.867725,247.953108 778.814815,249.428571 814,249.830971 L814,148.962923 C776.037037,146.280262 727.888889,144.536532 666.777778,144.536532 Z"
                                        id="Path"
                                        fill="url(#linearGradient-4)"
                                    ></path>
                                    <path
                                        d="M955.252531,188.787695 C953.009217,180.419117 941.924604,159.165585 813,150 L813,249.891597 C813.395879,249.891597 813.791758,249.891597 814.055677,249.891597 C847.969314,248.961755 914.476991,254.673641 961.850515,235.279794 C1011.99519,214.690436 978.741354,199.41446 955.252531,188.787695 Z"
                                        id="Path"
                                        fill="url(#linearGradient-5)"
                                    ></path>
                                </g>

                                <text
                                    fill="#A41F1E"
                                    transform="translate(953.000000, 36.000000) scale(-1, 1) translate(-953.000000, -36.000000)"
                                    fontFamily="Helvetica"
                                    fontSize={dataFontSize}
                                    fontWeight="normal"
                                >
                                    <tspan x={props.unit === 'string' ? '936' : '933'} y="47">
                                        {''}
                                    </tspan>
                                </text>
                                <text
                                    fill="#A9201C"
                                    transform="translate(945.500000, 77.500000) scale(-1, 1) translate(-945.500000, -77.500000)"
                                    fontFamily="Helvetica-Bold, Helvetica"
                                    fontSize="21"
                                    fontWeight="bold"
                                >
                                    <tspan x="921" y="78">
                                        {''}
                                    </tspan>
                                    <tspan x="921" y="103">
                                        {''}
                                    </tspan>
                                </text>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    } else if (classCount === 1) {
        return oneClassSvg(
            {
                class1Data: class1Data,
                class1Names: class1Names,
                class1DataTitle: class1DataTitle,
                class1Change: class1Change,
                class1Top3Airlines: class1Top3Airlines
            },
            props,
            dataFontSize
        );
    } else if (classCount === 2) {
        return twoClassSvg(
            {
                class1Data: class1Data,
                class1Names: class1Names,
                class1DataTitle: class1DataTitle,
                class1Change: class1Change,
                class4Data: class4Data,
                class4Names: class4Names,
                class4DataTitle: class4DataTitle,
                class4Change: class4Change,
                class1Top3Airlines: class1Top3Airlines,
                class4Top3Airlines: class4Top3Airlines
            },
            props,
            dataFontSize
        );
    } else if (classCount === 3) {
        return threeClassSvg(
            {
                class1Data: class1Data,
                class1Names: class1Names,
                class1DataTitle: class1DataTitle,
                class1Change: class1Change,
                class3Data: class3Data,
                class3Names: class3Names,
                class3DataTitle: class3DataTitle,
                class3Change: class3Change,
                class4Data: class4Data,
                class4Names: class4Names,
                class4DataTitle: class4DataTitle,
                class4Change: class4Change,
                class1Top3Airlines: class1Top3Airlines,
                class3Top3Airlines: class3Top3Airlines,
                class4Top3Airlines: class4Top3Airlines
            },
            props,
            dataFontSize
        );
    } else if (classCount === 4) {
        return fourClassSvg(
            {
                class1Data: class1Data,
                class1Names: class1Names,
                class1DataTitle: class1DataTitle,
                class1Change: class1Change,
                class2Data: class2Data,
                class2Names: class2Names,
                class2DataTitle: class2DataTitle,
                class2Change: class2Change,
                class3Data: class3Data,
                class3Names: class3Names,
                class3DataTitle: class3DataTitle,
                class3Change: class3Change,
                class4Data: class4Data,
                class4Names: class4Names,
                class4DataTitle: class4DataTitle,
                class4Change: class4Change,
                class1Top3Airlines: class1Top3Airlines,
                class2Top3Airlines: class2Top3Airlines,
                class3Top3Airlines: class3Top3Airlines,
                class4Top3Airlines: class4Top3Airlines
            },
            props,
            dataFontSize
        );
    } else {
        return (
            <div style={{ width: '100%', height: '300px' }}>
                {props.loading && (
                    <div>
                        <div className="data-wrapper custom-loading" style={{ marginTop: '10%' }}>
                            <img src={Circular} alt="" />
                        </div>
                    </div>
                )}
                {!props.loading && (
                    <div>
                        <div
                            className="no-data"
                            style={{
                                top: props.tenantIcaoCode === 'all' ? '45%' : '30%',
                                fontSize: '26px',
                                width: props.tenantIcaoCode === 'all' ? '1022px' : ''
                            }}
                        >
                            Data is not available
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Plane;
