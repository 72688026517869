/*------------SAMPLE CALL-----------------
dataObject is app-data-object.js
movieData is server response

<CarouselWidget
  defaultKey="top10_viewed_movies_by_title_by_total_viewing_time"
  data={movieData.items}
  dataObject={dataObject.movies.titles.dataKeys}
  title="Movie Title Viewing"
/>
----------------------------------------*/

import React from 'react';

import Carousel from 'components/updatedComponents/Carousel';
import WidgetFrame from 'components/updatedComponents/WidgetFrameNew';

class CarouselWidget extends React.Component {
    constructor(props) {
        super(props);

        const _unit = props.unit;

        let data = [];
        if (typeof props.data !== 'undefined' && Object.keys(props.data).length > 0) {
            if (typeof props.defaultKey1 !== 'undefined' && typeof props.defaultKey2 !== 'undefined') {
                data = props.data[props.defaultKey1][props.defaultKey2]['data'];
            }
        }

        this.state = {
            data: data,
            unit: _unit
        };
    }

    updateWidgetData = (newData) => {
        this.setState({
            data: newData.data,
            unit: newData.unit
        });
    };

    render() {
        let _length = 0;

        if (this.state.data && this.state.data.length) {
            _length = this.state.data.length;
        }
        return (
            <WidgetFrame
                type="carousel"
                titleImage={this.props.img}
                page={this.props.page}
                pageName={this.props.pageName}
                data={this.props.data}
                loading={this.props.loading}
                title={this.props.title}
                updateData={this.updateWidgetData}
                showTabs={false}
                showOverflow={true}
                showDropdown={true}
                showFooter={false}
                unit={this.props.unit}
                showViewMore={this.props.showViewMore}
                dataObject={this.props.dataObject}
                ddOptions={this.props.ddOptions}
                dd1Options={this.props.dd1Options}
                dd2Options={[]}
                component_type="carousel"
                tabFunction={this.props.tabFunction}
                workbench_type={this.props.workbench_type || ''}
                showWorkbenchLink={this.props.showWorkbenchLink || false}
                tenantIcaoCode={this.props.tenantIcaoCode || ''}
                appliedFilters={this.props.appliedFilters}
                subscription={this.props.subscription}
                app={this.props.app}
                subscriptionStage={this.props.subscriptionStage}
                dataAvailable={this.state.data && this.state.data.length !== 0 ? true : false}
                body={
                    <Carousel
                        page={this.props.page}
                        type={this.props.type}
                        data={this.state.data}
                        unit={this.state.unit || 'hr'}
                        loading={this.props.loading}
                        showWorkbenchLink={this.props.showWorkbenchLink || false}
                        appliedFilters={this.props.appliedFilters}
                        contentType={this.props.contentType}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        title={this.props.title}
                    />
                }
            />
        );
    }
}

export default CarouselWidget;
