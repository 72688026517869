import React from 'react';
import 'assets/sass/updatedComponents/pillBox.css';

const PillBox = (props) => {
    let data = '';
    let percentage = 0;

    if (props.data != null && props.data.length > 0) {
        data = props.data;
        if (props.percentage) {
            percentage = props.data;
            data = props.data + '%';
        }
    }

    if (data === '') {
        data = <span className="info">Not available</span>;
    }

    if ((percentage === 0 && !props.percentage) || percentage > 100) {
        percentage = 100;
    }

    let percentageText = percentage + '%';
    let radius = percentage > 95 ? (Math.abs(95 - percentage) / 5) * 15 + 'px' : '0px';
    return (
        <div className="pill-box">
            <div
                className="pill-box-background-fill"
                style={{
                    backgroundColor: props.color,
                    width: percentageText,
                    borderRadius: '15px ' + radius + ' ' + radius + ' 15px'
                }}
            />
            <div className="pill-box-data">
                <span className="vertical-middle">{data}</span>
            </div>
            <div className="pill-box-title" style={{ fontSize: props.textSize == 'small' && 14 }}>
                {props.title}
            </div>
        </div>
    );
};

export default PillBox;
