import React from 'react';
import { Card } from 'reactstrap';
import axios from 'axios';
import ErrorBoundary from 'util/ErrorBoundary.js';
import LopaWidget from '../../components/updatedWidgets/LopaWidget';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import moment from 'moment';

let service = new InsightsMonthlyService();

class LopaContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataset: [],
            error: false,
            requestedSections: [],
            flights: []
        };
        this.getFlights = this.getFlights.bind(this);
        this.getFlightData = this.getFlightData.bind(this);
        this.clearFlightData = this.clearFlightData.bind(this);

        this.cancelRequestSource = null;
    }

    async getFlights(date) {
        if (this.props.tenantIcaoCode === 'all') {
            return [];
        }

        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(this.props.appliedFilters.api));
        selectedFiltersDeepClone.flight_date = moment(date).format('YYYYMMDD');

        if (this.props.subscription) {
            selectedFiltersDeepClone['tier'] = this.props.subscription;
        }

        if (this.props.app) {
            selectedFiltersDeepClone['app'] = this.props.app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        let res = await service.fetchFlightsAxios(selectedFiltersDeepClone, this.cancelRequestSource.token);
        if (res && res.data && res.data.flight_info && !res.data.flight_info.error) {
            return res.data.flight_info;
        }
        return [];
    }

    async getFlightData(flight, date) {
        if (this.props.tenantIcaoCode === 'all') {
            return {};
        }

        const selectedFilters = {};
        const seatClassMap = { CrewRestArea: 'Crew Rest' };
        selectedFilters.flight_number = flight.flight_number;
        selectedFilters.flight_start_date = moment(date).format('YYYYMMDD');

        if (this.props.subscription) {
            selectedFilters['tier'] = this.props.subscription;
        }

        if (this.props.app) {
            selectedFilters['app'] = this.props.app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        let res = await service.fetchLopa(selectedFilters, this.cancelRequestSource.token);
        if (res && !res.error && res.data && res.data.usage && res.data.usage.class_usage) {
            let lopaData = {};
            for (let [seatClass, seats] of Object.entries(res.data.usage.class_usage)) {
                lopaData[parseInt(seats.seat_configs_per_usage.rows.min_row)] = {};
                for (
                    let seatNum = parseInt(seats.seat_configs_per_usage.rows.min_row);
                    seatNum <= parseInt(seats.seat_configs_per_usage.rows.max_row);
                    seatNum++
                ) {
                    lopaData[parseInt(seats.seat_configs_per_usage.rows.min_row)][parseInt(seatNum)] = {
                        row: parseInt(seatNum),
                        seats: [],
                        seatClass: seatClassMap.hasOwnProperty(seatClass) ? seatClassMap[seatClass] : seatClass,
                        classInfo: seats.seat_configs_per_usage
                    };
                }

                for (let [seatNumber, seatUsageInfo] of Object.entries(seats.seat_usage)) {
                    let num = seatNumber.slice(0, seatNumber.length - 1);
                    let letter = seatNumber.slice(-1);
                    lopaData[parseInt(seats.seat_configs_per_usage.rows.min_row)][parseInt(num)].seats.push({
                        letter: letter,
                        seatData: seatUsageInfo
                    });
                }
            }

            let seatLetters = [];
            for (
                let letter = res.data.usage.seat_configs_per_usage.columns.min_column.charCodeAt();
                letter <= res.data.usage.seat_configs_per_usage.columns.max_column.charCodeAt();
                letter++
            ) {
                seatLetters.push(String.fromCharCode(letter));
            }

            const sortedData = Object.values(lopaData).reduce((total, value, index, array) =>
                Object.values(total).concat(Object.values(value))
            );
            this.setState({ dataset: { seatData: Object.values(sortedData), seatLetters: seatLetters } });
            return { seatData: Object.values(sortedData), seatLetters: seatLetters };
        } else {
            return {};
        }
    }

    clearFlightData() {
        this.setState({ dataset: [] });
    }

    render() {
        let { dataset, loading } = this.state;

        return (
            <Card>
                <ErrorBoundary>
                    <LopaWidget
                        loading={loading}
                        key={'vertical_bar_chart'}
                        id={'vertical_bar_chart'}
                        data={dataset ? dataset : []}
                        title={this.props.title}
                        page={this.props.page}
                        getFlights={this.getFlights}
                        getFlightData={this.getFlightData}
                        clearFlightData={this.clearFlightData}
                        showWorkbenchLink={this.props.showWorkbenchLink}
                        appliedFilters={this.props.appliedFilters}
                        tenantIcaoCode={this.props.tenantIcaoCode || ''}
                        pageName={this.props.pageName}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </ErrorBoundary>
            </Card>
        );
    }
}

export default LopaContainer;
