function getBarChartRequestedSectionsHelper(reportType, reportSubType) {
    let requestedSections;
    switch (reportType) {
        case 'titles':
            requestedSections = ['titles'];
            break;
        case 'airlines':
            requestedSections = ['airlines'];
            break;
        case 'languages':
            switch (reportSubType) {
                case 'Movies':
                case 'TV Series':
                    requestedSections = ['soundtrack_languages', 'subtitle_languages'];
                    break;
                case 'Electronic Reader':
                case 'EReader':
                    requestedSections = ['ereader_languages'];
                    break;
                case 'Seatback':
                    requestedSections = ['lang_trans', 'selected_lang'];
                    break;
                default:
                    requestedSections = ['soundtrack_languages'];
            }
            break;
        case 'genres':
            requestedSections = ['genres'];
            break;
        case 'categories':
            requestedSections = ['categories'];
            break;
        case 'routes':
            requestedSections = ['routes'];
            break;
        case 'pages':
            requestedSections = ['pages'];
            break;
        case 'portal_browsing_pages':
            requestedSections = ['pages'];
            break;
        case 'events':
            switch (reportSubType) {
                case 'Companion App':
                    requestedSections = ['companion_app_events'];
                    break;
            }
            break;
        case 'program-titles':
            requestedSections = ['program_titles'];
            break;
        default:
            break;
    }

    return requestedSections;
}

export default getBarChartRequestedSectionsHelper;
