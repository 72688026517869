import React from 'react';
const querystring = require('querystring');
var parse = require('url-parse');

class LinkHelper extends React.Component {
    getTokenFragment(currentSession, expires_in = 3600, token_type = 'Bearer') {
        if (typeof currentSession !== 'undefined') {
            if (typeof currentSession.idToken !== 'undefined' && typeof currentSession.accessToken !== 'undefined') {
                const params = {
                    id_token: currentSession.idToken.jwtToken,
                    access_token: currentSession.accessToken.jwtToken,
                    expires_in: expires_in,
                    token_type: token_type
                };
                if (this.getCurAuthTenandid !== null) {
                    params.pac_selected_tenant_id = this.getCurAuthTenandid();
                }

                return '#' + querystring.stringify(params);
            }
        }
    }

    getCurAuthTenandid() {
        return window.sessionStorage.getItem('pac_selected_tenant_id');
    }

    getCurAuthTenantIcao() {
        return window.sessionStorage.getItem('pac_selected_tenant_icao_code');
    }

    extractPacTenantIdFromUrl(url) {
        let pacTenantId = null;

        const hasPacTenantId = !!(parse(url).hash || '#')
            .substr(1)
            .split('&')
            .map((entry) => entry.split('='))
            .find(([k]) => k === 'pac_selected_tenant_id');

        if (!hasPacTenantId) {
            return pacTenantId;
        }

        const queryParamPairs = (parse(url).hash || '#')
            .substr(1)
            .split('&')
            .map((entry) => entry.split('='));

        for (let i = 0; i < queryParamPairs.length; i++) {
            // select only the param pairs whose first element is pac_selected_tenant_id
            if (queryParamPairs[i][0] === 'pac_selected_tenant_id') {
                // select only param pairs that have exactly 2 elements - key and value
                if (queryParamPairs[i].length === 2) {
                    pacTenantId = queryParamPairs[i][1];
                }
            }
        }

        return pacTenantId;
    }

    addTenantIdUrlFragment(url = window.location.href) {
        let pacTenantId = this.extractPacTenantIdFromUrl(url);
        if (pacTenantId !== null) {
            window.sessionStorage.setItem('pac_selected_tenant_id', pacTenantId);
        }
    }
}

export default LinkHelper;
