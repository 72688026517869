import React from 'react';
import PropTypes from 'prop-types';
import { withOAuth } from 'aws-amplify-react';
import { NavLink } from 'reactstrap';

class SignInButton extends React.Component {
    render() {
        return (
            <NavLink href={process.env.REACT_APP_LOGINAPP_URL} className="btn btn-primary">
                Log In
            </NavLink>
        );
    }
}

SignInButton.propTypes = {
    OAuthSignIn: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default withOAuth(SignInButton);
