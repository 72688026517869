import React from 'react';
import { IconDiagram20 } from 'pac-ux-components';
import { ReactComponent as Overview } from 'assets/images/icons/sidebar/Overview.svg';
import { ReactComponent as Entertainment } from 'assets/images/icons/sidebar/Entertainment.svg';
import { ReactComponent as SeatbackPortal } from 'assets/images/icons/sidebar/SeatbackPortal.svg';
import { ReactComponent as Feedback } from 'assets/images/icons/sidebar/Feedback.svg';
import { ReactComponent as Admin } from 'assets/images/icons/sidebar/Admin.svg';
import { ReactComponent as AnalyticsData } from 'assets/images/icons/sidebar/Analytics.svg';
import { ReactComponent as AdvertisingData } from 'assets/images/icons/sidebar/Advertising.svg';
import { ReactComponent as OffloadsData } from 'assets/images/icons/airplanemode.svg';
import { ReactComponent as RackTestData } from 'assets/images/icons/sidebar/Rack.svg';

const menus = {
    sidebar: [
        {
            path: '/app',
            title: 'Overview',
            icon: <Overview />,
            isActive: true,
            verifyApp: true,
            children: []
        },
        {
            path: '/app/entertainment',
            title: 'Entertainment',
            icon: <Entertainment />,
            isActive: true,
            isExpanded: true,
            children: [
                { path: '/app/entertainment/movies', title: 'Movies', isActive: true, children: [], verifyApp: true },
                {
                    path: '/app/entertainment/tv-series',
                    title: 'TV Series',
                    isActive: true,
                    children: [],
                    verifyApp: true
                },
                { path: '/app/entertainment/audio', title: 'Audio', isActive: true, children: [], verifyApp: true },
                { path: '/app/entertainment/games', title: 'Games', isActive: true, children: [], verifyApp: true },
                { path: '/app/entertainment/live-tv', title: 'Live TV', isActive: true, children: [], verifyApp: true },
                {
                    path: '/app/entertainment/ereader',
                    title: 'Electronic Reader',
                    isActive: true,
                    children: [],
                    verifyApp: true
                }
            ]
        },
        {
            path: '/app/web-portal',
            title: 'Passenger Touchpoints',
            icon: <SeatbackPortal />,
            isActive: true,
            isExpanded: true,
            children: [
                { path: '/app/web-portal/seatback', title: 'Seatback', isActive: true, children: [], verifyApp: true },
                { path: '/app/web-portal/portal', title: 'Portal', isActive: true, children: [], verifyApp: true },
                {
                    path: '/app/web-portal/portal-navigation',
                    title: 'Portal Navigation',
                    isActive: true,
                    children: [],
                    verifyApp: true
                },
                {
                    path: '/app/web-portal/companion-app',
                    title: 'Companion App',
                    isActive: true,
                    children: [],
                    verifyApp: true
                },
                { path: '/app/web-portal/bluetooth', title: 'Bluetooth', isActive: true, children: [], verifyApp: true }
                //{ path: "/app/web-portal/arc", title: "Arc", isActive: false, children: [] },
                //{ path: "/app/web-portal/surveys", title: "Surveys", isActive: false, children: [] }
            ]
        },
        {
            path: '/app/web-portal',
            title: 'Premium Dashboards',
            icon: <IconDiagram20 />,
            premiumItem: false,
            isActive: false,
            children: [
                { path: '/app/premium-dashboard/one-media', title: 'OneMedia', isActive: true, children: [] },
                { path: '/app/premium-dashboard/app-usage', title: 'Application Usage', isActive: true, children: [] },
                {
                    path: '/app/premium-dashboard/screen-transition',
                    title: 'Screen Transition',
                    isActive: true,
                    children: []
                }
            ]
        },
        {
            path: '',
            title: 'Settings',
            isActive: false,
            children: []
        },
        {
            path: '/app/feedback',
            title: 'Feedback',
            icon: <Feedback />,
            isActive: false,
            children: []
        },
        {
            path: '/app/admin',
            title: 'Admin',
            icon: <Admin />,
            isAdmin: true,
            isActive: true,
            isExpanded: true,
            children: [
                {
                    path: '/app/admin/evaluations',
                    title: 'Evaluations',
                    isAdmin: true,
                    isActive: true,
                    children: [],
                    verifyApp: true
                },
                {
                    path: '/app/admin/deliveries',
                    title: 'Deliveries',
                    isAdmin: true,
                    isActive: true,
                    children: [],
                    verifyApp: true
                },
                {
                    path: '/app/admin/data-loading-dashboard',
                    title: 'Data Loading Dashboard',
                    isAdmin: true,
                    isActive: true,
                    children: [],
                    verifyApp: true
                }
            ],
            verifyApp: true
        },
        {
            path: '/app/rack-test-data',
            title: 'Rack Test Data',
            icon: <RackTestData />,
            isPacRackTester: true,
            isActive: true,
            isExpanded: true,
            children: [
                {
                    path: '/app/rack-test-data/rack-offload-dashboard',
                    title: 'Rack Offload Dashboard',
                    isPacRackTester: true,
                    isActive: true,
                    children: [],
                    verifyApp: true
                }
            ],
            verifyApp: true
        },
        {
            path: '/app/analytics',
            title: 'Ground Tools Usage',
            icon: <AnalyticsData />,
            isInsightsPLM: true,
            isActive: true,
            isExpanded: true,
            isAllTenantView: true,
            children: [
                {
                    path: '/app/analytics/analytics-dashboard',
                    title: 'NEXT Insights',
                    isInsightsPLM: true,
                    isActive: true,
                    children: [],
                    verifyApp: true,
                    isAllTenantView: true
                }
            ],
            verifyApp: true
        },
        {
            path: '/app/advertising',
            title: 'Advertising',
            icon: <AdvertisingData />,
            isActive: true,
            verifyApp: false,
            children: []
        },
        {
            path: '/app/offloads',
            title: 'Offloads',
            icon: <OffloadsData />,
            isActive: true,
            verifyApp: false,
            isExpanded: false,
            children: [
                {
                    path: '/app/offloads/paxus-offloads',
                    title: 'PAXUS Offloads',
                    isInsightsPLM: true,
                    isActive: true,
                    children: [],
                    verifyApp: true,
                    isAllTenantView: true
                }
            ]
        }
    ]
};

export function getTitleFromPath(path) {
    let activePage;
    for (let element of menus['sidebar']) {
        if (element.path === path) {
            activePage = element;
            break;
        } else {
            for (let subElement of element.children) {
                if (subElement.path === path) {
                    activePage = subElement;
                    break;
                }
            }
        }
    }
    if (activePage) {
        return activePage.title;
    }

    return '';
}

export default menus;
