import React from 'react';
import { Row } from 'reactstrap';
import DonutContainer from 'components/containers/DonutContainer';
import TrendContainer from 'components/containers/TrendContainer';
import AppDataRequestedSectionsHelper from 'data/app-data-requested-sections-helper.js';
import HorizontalBarChartContainer from 'components/containers/HorizontalBarChartContainer';
import TableContainer from 'components/containers/TableContainer';

const appDataRequestedSectionsHelper = new AppDataRequestedSectionsHelper();

const page = 'my-analytics';
let displayPageName = 'Overview';

class EntertainmentOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let requestedSections = [];
        if (this.props.allowedApps && this.props.allowedApps.length > 0) {
            requestedSections = appDataRequestedSectionsHelper.getTrendChartRequestedSections('Insights');
            requestedSections = requestedSections.filter((requestedSection) =>
                this.props.allowedApps.includes(requestedSection.title)
            );
        }

        let trendDashboard = '';
        if (requestedSections && requestedSections.length > 0) {
            trendDashboard = (
                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    allowedApps={this.props.allowedApps}
                    forceReloadTrend={this.props.forceReloadTrend}
                    forceReloadAll={this.props.forceReloadAll}
                    usageSource="Insights"
                    title="Trends"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="my_insights_overview_trend_1"
                    id="my_insights_overview_trend_1"
                    tabFunction={this.props.tabFunction}
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    subscription={this.props.subscription}
                    app={this.props.app}
                    subscriptionStage={this.props.subscriptionStage}
                />
            );
        }

        let dashboard = (
            <div>
                {trendDashboard}

                <Row>
                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        renderType="row"
                        page={page}
                        pageName={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        tabFunction={this.props.tabFunction}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        allowedApps={this.props.allowedApps}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>

                <Row>
                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        forceReloadAll={this.props.forceReloadAll}
                        title="Airlines"
                        workbenchTitle="airlines"
                        page={page}
                        pageName={displayPageName}
                        reportType="airlines"
                        color="#7FCECD"
                        key="entertainment_overview_airlines_1"
                        id="entertainment_overview_airlines_1"
                        tabFunction={this.props.tabFunction}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />

                    <TableContainer
                        pageName={displayPageName}
                        data={this.props.data}
                        dataset={this.props.dataset}
                        loading={this.props.loading}
                        appliedFilters={this.props.appliedFilters}
                        app={this.props.app}
                        title="Overview"
                        tenantIcaoCode={this.props.tenantIcaoCode}
                    />
                </Row>
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default EntertainmentOverview;
