import InsightsMonthlyService from 'services/InsightsMonthlyService';
import axios from 'axios';
import DynamicFilterHelper from 'data/dynamic-filter-helper.js';

/*
const sample_raw_data = [
  {
    "s": "eX2",
    "a": "B777-400ER",
    "o": "BKK",
    "d": "BKK",
    "n": "SQ713",
    "h": "Short Haul",
    "c": "Custom",
    "t": "9VSWU",
    "p": "Audio"
  },
  {
    "s": "eX3",
    "a": "B777-300ER",
    "o": "BKK",
    "d": "BKK",
    "n": "SQ712",
    "h": "Short Haul",
    "c": "Premium Economy",
    "t": "9VSWV",
    "p": "Movies"
  }
];
*/

const selected_filters_keys_mapping = {
    systemType: 's',
    aircraftType: 'a',
    seatClass: 'c',
    tail: 't',
    flightDuration: 'h',
    flightNumber: 'n',
    toCity: 'd',
    fromCity: 'o',
    product: 'p'
};

const raw_data_keys_mapping = {
    system_type: 's',
    aircraft_type_subtype: 'a',
    dep_airport_iata: 'o',
    arr_airport_iata: 'd',
    flight_number: 'n',
    flight_haul_type: 'h',
    seat_class: 'c',
    aircraft_tail_number: 't',
    product: 'p'
};

const non_dynamic_filters_mapping = {
    system_type: 'system_types',
    aircraft_type_subtype: 'aircraft_types',
    dep_airport_iata: 'flight_origins',
    arr_airport_iata: 'flight_destinations',
    flight_haul_type: 'flight_haul_types',
    seat_class: 'seat_classes'
};

class FiltersService {
    static dateRangeFiltersData = '';
    static nonDateRangeFiltersData = '';
    static nonDynamicFiltersData = '';
    static currentIcao = '';
    static currentApp = '';
    static regionsData = '';

    static async loadAllFilters(cancelRequestSource, subscription, app, selectedTenant = null) {
        let useDynamic = selectedTenant === null ? true : selectedTenant.icaoCode !== 'all';

        this.app = app;

        if (useDynamic) {
            let currTenantIcaoCode = sessionStorage.getItem('pac_selected_tenant_icao_code');

            if (selectedTenant) {
                currTenantIcaoCode = selectedTenant.icaoCode;
            }

            this.nonDynamicFiltersData = '';

            if (
                this.currentIcao === currTenantIcaoCode &&
                this.dateRangeFiltersData !== '' &&
                this.nonDateRangeFiltersData !== ''
            ) {
                return {
                    dateRangeFiltersDataIsSet: true,
                    nonDateRangeFiltersDataIsSet: true
                };
            }

            if (currTenantIcaoCode === 'all') {
                currTenantIcaoCode = 'pac';
            }

            this.currentIcao = currTenantIcaoCode;

            let service = new InsightsMonthlyService();

            let response = await service.fetchFilterValuesAxios(
                DynamicFilterHelper.getAllFilterQueryParams(false, useDynamic, subscription, app),
                cancelRequestSource.token
            );

            if (!response) {
                return {
                    dateRangeFiltersDataIsSet: false,
                    nonDateRangeFiltersDataIsSet: false
                };
            }

            this.dateRangeFiltersData = response.data.response.filter_values.date_comparison_ranges.data;

            if (response.data.response.filter_values.dynamic === undefined) {
                return {
                    dateRangeFiltersDataIsSet: true,
                    nonDateRangeFiltersDataIsSet: false
                };
            }

            let preSignedUrl = response.data.response.filter_values.dynamic.url;

            try {
                let rawDataResponse = await axios.get(preSignedUrl);
                this.nonDateRangeFiltersData = rawDataResponse.data;
            } catch (error) {
                console.log(error);
                return {
                    dateRangeFiltersDataIsSet: true,
                    nonDateRangeFiltersDataIsSet: false
                };
            }

            return {
                dateRangeFiltersDataIsSet: true,
                nonDateRangeFiltersDataIsSet: true
            };
        } else {
            return this.loadAllNonDynamicFilters(cancelRequestSource, subscription, app);
        }
    }

    static async loadAllNonDynamicFilters(cancelRequestSource, subscription, app) {
        let selectedIcaoCodes = 'all';
        if (
            this.currentIcao === selectedIcaoCodes &&
            this.dateRangeFiltersData !== '' &&
            this.nonDynamicFiltersData !== '' &&
            this.currentApp === app
        ) {
            return {
                dateRangeFiltersDataIsSet: true,
                nonDynamicFiltersDataIsSet: true
            };
        }

        this.currentIcao = selectedIcaoCodes;

        let service = new InsightsMonthlyService();

        if (app === 'Overview') {
            app = null;
        }

        let queryParams = DynamicFilterHelper.getAllFilterQueryParams(false, false, subscription, app);
        queryParams['requested_filters'] =
            'date_comparison_ranges,system_types,aircraft_types,flight_origins,flight_destinations,flight_haul_types,seat_classes,tails';
        let response = await service.fetchFilterValuesAxios(queryParams, cancelRequestSource.token);

        if (!response || !response.data) {
            return {
                dateRangeFiltersDataIsSet: false,
                nonDynamicFiltersDataIsSet: false
            };
        }

        this.dateRangeFiltersData = response.data.response.filter_values.date_comparison_ranges.data;
        this.nonDynamicFiltersData = response.data.response.filter_values;

        return {
            dateRangeFiltersDataIsSet: true,
            nonDynamicFiltersDataIsSet: true
        };
    }

    static getDateRangeFilters() {
        return this.dateRangeFiltersData;
    }

    static getCustomDateRange() {
        if (this.dateRangeFiltersData !== '') {
            let customRangeOption = this.dateRangeFiltersData.find((range) => {
                if (range.label === 'Custom') {
                    return true;
                } else {
                    return false;
                }
            });

            return {
                date_range_start: customRangeOption['from_date'], //'20220401'
                date_range_end: customRangeOption['to_date'] //'20220430'
            };
        } else {
            return null;
        }
    }

    static getNonDateRangeFilters() {
        return this.nonDateRangeFiltersData;
    }

    static getNonDynamicFilters() {
        return this.nonDynamicFiltersData;
    }

    static resetFilterValues() {
        this.dateRangeFiltersData = '';
        this.nonDateRangeFiltersData = '';
    }

    static getFilterValuesByTypeAndSelection(type, selectedFilters, page) {
        if ((page === 'Portal' || page === 'Portal Navigation') && type === 'seat_class') {
            return [{ label: 'PED', value: 'PED' }];
        }

        let response;

        if (this.nonDynamicFiltersData !== '') {
            let rawDataKey = non_dynamic_filters_mapping[type];
            response = this.nonDynamicFiltersData[rawDataKey] ? this.nonDynamicFiltersData[rawDataKey].data : [];
        } else {
            response = this.nonDateRangeFiltersData;

            //filter by current page
            if (page !== 'Overview') {
                response = response.filter((row) => {
                    let dataKey = raw_data_keys_mapping['product'];
                    if (page === row[dataKey]) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }

            //filter by selected filters
            Object.keys(selectedFilters).forEach((selection) => {
                /*skip current type, for example: if type = tail, filter dataset by all other selected filters except tail,
                to get list of all tails that match other selected filters*/
                if (selected_filters_keys_mapping[selection] !== raw_data_keys_mapping[type]) {
                    if (selectedFilters[selection].length > 0) {
                        response = response.filter((row) => {
                            let dataKey = selected_filters_keys_mapping[selection];
                            if (selectedFilters[selection].includes(row[dataKey])) {
                                return true;
                            }
                        });
                    }
                }
            });

            response = response.map((row) => {
                let rawDataKey = raw_data_keys_mapping[type];
                return row[rawDataKey];
            });

            //sort
            response.sort();

            //remove duplicates
            let set = new Set(response);

            response = [...set].map((filterOption) => {
                return { label: filterOption, value: filterOption };
            });
        }

        return response;
    }

    static async loadRegionsData(response) {
        let preSignedUrl = response.data.response.filter_values.regions.url;

        try {
            let rawDataResponse = await axios.get(preSignedUrl);
            this.regionsData = rawDataResponse.data;
        } catch (error) {
            console.log(error);
            return false;
        }

        return true;
    }
}

export default FiltersService;
