import React from 'react';
import Circular from 'assets/images/icons/circular.gif';
import { Route, Switch } from 'react-router-dom';
import PageSidebar from 'components/common/PageToolsSidebar';
import DashboardBuilder from 'components/common/dashboards/DashboardBuilder';
import { Row, Modal, Container, ModalBody } from 'reactstrap';
import { ButtonPrimary } from 'pac-ux-components';
import { Col } from 'react-bootstrap';
import 'assets/styles/disclaimer-popup.css';
import 'assets/styles/session-timeout-popup.css';
import PlatformCloudbar from 'components/containers/PlatformCloudbar.js';
import { PlatformFooter } from 'pac-platform-ui-components';
import { Auth } from 'aws-amplify';
import SubscriptionService from 'services/SubscriptionService';
import { CognitoRefreshToken } from 'amazon-cognito-identity-js';

let sessionIdleTime = 55 * 60 * 1000; // 55 minutes
let maxSessionTime = 55 * 60 * 1000; // 55 minutes

class AppLayout extends React.Component {
    constructor(props) {
        super(props);

        this.updatedTierHandler = this.updatedTierHandler.bind(this);
        this.showDisclaimerPopupAction = this.showDisclaimerPopupAction.bind(this);
        this.showTimeoutPopupAction = this.showTimeoutPopupAction.bind(this);

        this.state = {
            user: {},
            updatedTier: null,
            showDisclaimerPopup: false,
            showTimeoutPopup: false,
            modal: false,
            message: '',
            header: '',
            subscriptionData: ''
        };

        sessionStorage.setItem('session_idle_time', sessionIdleTime);
        sessionStorage.setItem('max_session_time', maxSessionTime);
    }

    extendSession() {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                const refreshToken = new CognitoRefreshToken({ RefreshToken: SubscriptionService.refreshToken });
                user.refreshSession(refreshToken, (err, session) => {
                    if (err) {
                        console.log('refresh session error ', err);
                    } else {
                        console.log(session);
                    }
                });
            })
            .catch(async (err) => {
                console.log(err);
                await Auth.signOut();
                //this.props.history.push('/');
            });
    }

    showTimeoutPopupAction(header, message) {
        this.extendSession();

        // close popup
        this.setState((prevState) => ({
            modal: !prevState.modal,
            showTimeoutPopup: !this.state.showTimeoutPopup
        }));
    }

    updatedTierHandler(value, landingPagePath) {
        if (value === 'Basic') {
            if (this.props.match.path === '/app' || this.props.match.path === '/app/') {
                this.props.history.push(landingPagePath);
            }
        }

        this.setState({
            updatedTier: value
        });
    }

    async componentDidMount() {
        // Subscription tier selection change
        sessionStorage.removeItem('pac_subscription');

        this.extendSession();

        // let subscriptionData = await SubscriptionService.fetchSubscription(true);
        // this.setState((prevState) => ({
        //     subscriptionData: subscriptionData
        // }));
    }

    componentDidUpdate(prevProps, prevState) {}

    showDisclaimerPopupAction(header, message) {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            showDisclaimerPopup: !this.state.showDisclaimerPopup,
            header: header,
            message: message
        }));
    }

    renderRoutes() {
        let { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.path}/entertainment/movies`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/audio`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/games`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/live-tv`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/tv-series`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/media-details`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/details/:dataName`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/ereader`} component={DashboardBuilder} />

                <Route path={`${match.path}/web-portal/seatback`} component={DashboardBuilder} />
                <Route path={`${match.path}/web-portal/portal`} component={DashboardBuilder} />
                <Route path={`${match.path}/web-portal/portal-navigation`} component={DashboardBuilder} />
                <Route path={`${match.path}/web-portal/companion-app`} component={DashboardBuilder} />
                <Route path={`${match.path}/web-portal/bluetooth`} component={DashboardBuilder} />

                <Route path={`${match.path}/admin/evaluations`} component={DashboardBuilder} />
                <Route path={`${match.path}/admin/deliveries`} component={DashboardBuilder} />
                <Route path={`${match.path}/admin/data-loading-dashboard`} component={DashboardBuilder} />

                <Route path={`${match.path}/rack-test-data/rack-offload-dashboard`} component={DashboardBuilder} />

                <Route path={`${match.path}/analytics/analytics-dashboard`} component={DashboardBuilder} />
                <Route path={`${match.path}/advertising`} component={DashboardBuilder} />
                <Route path={`${match.path}/offloads/paxus-offloads`} component={DashboardBuilder} />

                <Route path={`${match.path}/help`} component={DashboardBuilder} />

                <Route path={`${match.path}/release`} component={DashboardBuilder} />

                <Route path={`${match.path}/`} component={DashboardBuilder} {...this.props} />
            </Switch>
        );
    }

    render() {
        let { match } = this.props;

        const cloudHomeUrl = process.env.REACT_APP_CLOUD_HOME_URL ? process.env.REACT_APP_CLOUD_HOME_URL : '/';

        return (
            <React.Fragment>
                <span className="app-disclaimer" id="app-disclaimer" style={{ display: 'none' }}>
                    <p id="subscription_stage_msg">&nbsp;</p>
                </span>

                <div className="platform-cloudbar-container">
                    <PlatformCloudbar cloudHomeUrl={cloudHomeUrl} />
                </div>

                <div>
                    <Route
                        path={`${match.path}`}
                        render={(props) => {
                            return (
                                <PageSidebar
                                    {...this.props}
                                    onUpdatedTier={this.updatedTierHandler}
                                    showDisclaimerPopupAction={this.showDisclaimerPopupAction}
                                />
                            );
                        }}
                    />

                    <div className="main-container">
                        <main className="main-content">{this.renderRoutes()}</main>
                    </div>

                    <div className="pac-footer-wrapper">
                        <div className="pac-footer">
                            <PlatformFooter />
                        </div>
                    </div>
                </div>

                {this.state.showDisclaimerPopup ? (
                    <Container>
                        <Modal className="disclaimer-popup" isOpen={this.state.modal}>
                            <ModalBody className="disclaimer">
                                <div>
                                    <div className="wrapper">
                                        <div className="header">{this.state.header}</div>
                                        <div className="form-body">
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <div className="note">{this.state.message}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="buttons-section">
                                        <ButtonPrimary
                                            className="button-primary"
                                            onClick={this.showDisclaimerPopupAction}
                                        >
                                            Okay
                                        </ButtonPrimary>{' '}
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </Container>
                ) : null}

                {this.state.showTimeoutPopup ? (
                    <Container>
                        <Modal className="timeout-popup" isOpen={this.state.modal}>
                            <ModalBody className="timeout">
                                <div>
                                    <div className="wrapper">
                                        <div className="header">Session Timeout</div>
                                        <div className="form-body">
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <div className="note">
                                                            Your session has expired due to inactivity. Extend the
                                                            session time by clicking the button below.
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="buttons-section">
                                        <ButtonPrimary className="button-primary" onClick={this.showTimeoutPopupAction}>
                                            I'm still here
                                        </ButtonPrimary>{' '}
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </Container>
                ) : null}
            </React.Fragment>
        );
    }
}

export default AppLayout;
