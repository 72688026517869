function getRoutesDropdownListHelper(reportSubType) {
    let dropdownList;

    switch (reportSubType) {
        case 'Movies':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'routes.most_popular.total_views',
                    text: 'Movie Total Views',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'routes.most_popular.total_time',
                    text: 'Movie Total Viewing Time',
                    free_tier: 0,
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                },
                {
                    label: 'Total Views (Bottom 10)',
                    unit: 'count',
                    value: 'routes.least_popular.total_views',
                    text: 'Movie Total Views',
                    free_tier: 1,
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'routes.least_popular.total_time',
                    text: 'Movie Total Viewing Time',
                    free_tier: 0,
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Excelmovies':
            dropdownList = [
                {
                    label: 'Total Views',
                    unit: 'count',
                    value: 'routes.all_records.total_views',
                    text: 'Movie Total Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'routes.all_records.total_time',
                    text: 'Movie Total Viewing Time'
                }
            ];
            break;
        case 'TV Series':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'routes.most_popular.total_views',
                    text: 'TV Series Total Views',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'routes.most_popular.total_time',
                    text: 'TV Series Total Viewing Time',
                    free_tier: 0,
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                },
                {
                    label: 'Total Views (Bottom 10)',
                    unit: 'count',
                    value: 'routes.least_popular.total_views',
                    text: 'TV Series Total Views',
                    free_tier: 1,
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'routes.least_popular.total_time',
                    text: 'TV Series Total Viewing Time',
                    free_tier: 0,
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Exceltv-series':
            dropdownList = [
                {
                    label: 'Total Views',
                    unit: 'count',
                    value: 'routes.all_records.total_views',
                    text: 'Tv Series Total Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'routes.all_records.total_time',
                    text: 'Tv Series Total Viewing Time'
                }
            ];
            break;
        case 'Audio':
            dropdownList = [
                {
                    label: 'Total Listens (Top 10)',
                    unit: 'count',
                    value: 'routes.most_popular.total_views',
                    text: 'Audio Total Listens',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Listens'
                },
                {
                    label: 'Total Listening Time (Top 10)',
                    unit: 'hr',
                    value: 'routes.most_popular.total_time',
                    text: 'Audio Total Listening Time',
                    free_tier: 0,
                    section: 'top_10_total_time',
                    small_count_label: 'Total Listening Time'
                },
                {
                    label: 'Total Listens (Bottom 10)',
                    unit: 'count',
                    value: 'routes.least_popular.total_views',
                    text: 'Audio Total Listens',
                    free_tier: 1,
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Listens'
                },
                {
                    label: 'Total Listening Time (Bottom 10)',
                    unit: 'hr',
                    value: 'routes.least_popular.total_time',
                    text: 'Audio Total Listening Time',
                    free_tier: 0,
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Listening Time'
                }
            ];
            break;
        case 'Excelaudio':
            dropdownList = [
                {
                    label: 'Total Listens',
                    unit: 'count',
                    value: 'routes.all_records.total_views',
                    text: 'Audio Total Listens',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Listening Time',
                    unit: 'hr',
                    value: 'routes.all_records.total_time',
                    text: 'Audio Total Listening Time'
                }
            ];
            break;
        case 'Games':
            dropdownList = [
                {
                    label: 'Total Plays (Top 10)',
                    unit: 'count',
                    value: 'routes.most_popular.total_views',
                    text: 'Games Total Plays',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Plays'
                },
                {
                    label: 'Total Playing Time (Top 10)',
                    unit: 'hr',
                    value: 'routes.most_popular.total_time',
                    text: 'Games Total Playing Time',
                    free_tier: 0,
                    section: 'top_10_total_time',
                    small_count_label: 'Total Playing Time'
                },
                {
                    label: 'Total Plays (Bottom 10)',
                    unit: 'count',
                    value: 'routes.least_popular.total_views',
                    text: 'Games Total Plays',
                    free_tier: 1,
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Plays'
                },
                {
                    label: 'Total Playing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'routes.least_popular.total_time',
                    text: 'Games Total Playing Time',
                    free_tier: 0,
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Playing Time'
                }
            ];
            break;
        case 'Excelgames':
            dropdownList = [
                {
                    label: 'Total Plays',
                    unit: 'count',
                    value: 'routes.all_records.total_views',
                    text: 'Games Total Plays',
                    default: 1,
                    free_tier: 1,
                    small_count_label: 'Total Plays'
                },
                {
                    label: 'Total Playing Time',
                    unit: 'hr',
                    value: 'routes.all_records.total_time',
                    text: 'Games Total Playing Time',
                    small_count_label: 'Total Playing Time'
                }
            ];
            break;
        case 'Live TV':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'routes.most_popular.total_views',
                    text: 'Live TV Total Views',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'routes.most_popular.total_time',
                    text: 'Live TV Total Viewing Time',
                    free_tier: 0,
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                },
                {
                    label: 'Total Views (Bottom 10)',
                    unit: 'count',
                    value: 'routes.least_popular.total_views',
                    text: 'Live TV Total Views',
                    free_tier: 1,
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'routes.least_popular.total_time',
                    text: 'Live TV Total Viewing Time',
                    free_tier: 0,
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Excellive-tv':
            dropdownList = [
                {
                    label: 'Total Views',
                    unit: 'count',
                    value: 'routes.all_records.total_views',
                    text: 'Live TV Total Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'routes.all_records.total_time',
                    text: 'Live TV Total Viewing Time'
                }
            ];
            break;
        case 'Electronic Reader':
            dropdownList = [
                {
                    label: 'Total Title Views (Top 10)',
                    unit: 'count',
                    value: 'routes.most_popular.total_title_views',
                    text: 'eReader Total Title Views',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_total_title_views',
                    small_count_label: 'Total Title Views'
                },
                {
                    label: 'Total Readers (Top 10)',
                    unit: 'count',
                    value: 'routes.most_popular.total_readers',
                    text: 'eReader Total Readers',
                    free_tier: 0,
                    section: 'top_10_total_readers',
                    small_count_label: 'Total Readers'
                },
                {
                    label: 'Total Reading Time (Top 10)',
                    unit: 'hr',
                    value: 'routes.most_popular.total_time',
                    text: 'eReader Total Reading Time',
                    free_tier: 0,
                    section: 'top_10_total_time',
                    small_count_label: 'Total Reading Time'
                },
                {
                    label: 'Avg. Reading Time (Top 10)',
                    unit: 'hr',
                    value: 'routes.most_popular.avg_time',
                    text: 'eReader Avg. Reading Time',
                    free_tier: 0,
                    section: 'top_10_avg_time',
                    small_count_label: 'Avg. Reading Time'
                },
                {
                    label: 'Total Title Views (Bottom 10)',
                    unit: 'count',
                    value: 'routes.least_popular.total_title_views',
                    text: 'eReader Total Title Views',
                    free_tier: 1,
                    section: 'bottom_10_total_title_views',
                    small_count_label: 'Total Title Views'
                },
                {
                    label: 'Total Readers (Bottom 10)',
                    unit: 'count',
                    value: 'routes.least_popular.total_readers',
                    text: 'eReader Total Readers',
                    free_tier: 0,
                    section: 'bottom_10_total_readers',
                    small_count_label: 'Total Readers'
                },
                {
                    label: 'Total Reading Time (Bottom 10)',
                    unit: 'hr',
                    value: 'routes.least_popular.total_time',
                    text: 'eReader Total Reading Time',
                    free_tier: 0,
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Reading Time'
                },
                {
                    label: 'Avg. Reading Time (Bottom 10)',
                    unit: 'hr',
                    value: 'routes.least_popular.avg_time',
                    text: 'eReader Avg. Reading Time',
                    free_tier: 0,
                    section: 'bottom_10_avg_time',
                    small_count_label: 'Avg. Reading Time'
                }
            ];
            break;
        case 'Excelereader':
            dropdownList = [
                {
                    label: 'Total Title Views',
                    unit: 'count',
                    value: 'routes.all_records.total_title_views',
                    text: 'eReader Title Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Readers',
                    unit: 'count',
                    value: 'routes.all_records.total_readers',
                    text: 'eReader Total Readers'
                },
                {
                    label: 'Total Reading Time',
                    unit: 'hr',
                    value: 'routes.all_records.total_time',
                    text: 'eReader Total Reading Time'
                },
                {
                    label: 'Avg. Reading Time',
                    unit: 'hr',
                    value: 'routes.all_records.avg_time',
                    text: 'eReader Avg. Reading Time'
                }
            ];
            break;
        case 'Seatback':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'routes.most_popular.total_views',
                    text: 'Seatback Total Views',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'routes.most_popular.total_time',
                    text: 'Seatback Total Viewing Time',
                    free_tier: 0,
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                },
                {
                    label: 'Total Views (Bottom 10)',
                    unit: 'count',
                    value: 'routes.least_popular.total_views',
                    text: 'Seatback Total Views',
                    free_tier: 1,
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'routes.least_popular.total_time',
                    text: 'Seatback Total Viewing Time',
                    free_tier: 0,
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Excelseatback':
            dropdownList = [
                {
                    label: 'Total Views',
                    unit: 'count',
                    value: 'routes.all_records.total_views',
                    text: 'Seatback Total Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'routes.all_records.total_time',
                    text: 'Seatback Total Viewing Time'
                }
                // { label: "Total Browsing Time", unit: "hr", value: "routes.all_records.total_browsing_time", text: "Seatback Total Page Browsing Time",  },
                // { label: "Total Browsing Time", unit: "hr", value: "pages.all_records.total_browsing_timee", text: "Seatback Total Page Browsing Time",  },
            ];
            break;
        case 'Portal':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'routes.most_popular.total_views',
                    text: 'Portal Total Views',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'routes.most_popular.total_time',
                    text: 'Portal Total Viewing Time',
                    free_tier: 0,
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                },
                {
                    label: 'Total Views (Bottom 10)',
                    unit: 'count',
                    value: 'routes.least_popular.total_views',
                    text: 'Portal Total Views',
                    free_tier: 1,
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'routes.least_popular.total_time',
                    text: 'Portal Total Viewing Time',
                    free_tier: 0,
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Excelportal':
            dropdownList = [
                {
                    label: 'Total Views',
                    unit: 'count',
                    value: 'routes.all_records.total_views',
                    text: 'Portal Total Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'routes.all_records.total_time',
                    text: 'Portal Total Viewing Time'
                }
                // { label: "Total Browsing Time", unit: "hr", value: "pages.all_records.total_browsing_timee", text: "Seatback Total Page Browsing Time",  },
            ];
            break;
        case 'Companion App':
            dropdownList = [
                {
                    label: 'Media Launches (Top 10)',
                    unit: 'count',
                    value: 'routes.most_popular.total_views',
                    text: 'Companion App Total Media Launches',
                    default: 1,
                    free_tier: 1,
                    section: 'top_10_media_launches',
                    small_count_label: 'Media Launches'
                },
                {
                    label: 'Media Launches (Bottom 10)',
                    unit: 'count',
                    value: 'routes.least_popular.total_views',
                    text: 'Companion App Total Media Launches',
                    free_tier: 1,
                    section: 'bottom_10_media_launches',
                    small_count_label: 'Media Launches'
                }
            ];
            break;
        case 'Excelcompanion-app':
            dropdownList = [
                {
                    label: 'Media Launches',
                    unit: 'count',
                    value: 'routes.all_records.total_views',
                    text: 'Companion App Total Media Launches',
                    default: 1,
                    free_tier: 1
                },
                {
                    label: 'Media Launches',
                    unit: 'count',
                    value: 'routes.all_records.total_time',
                    text: 'Companion App Total Media Launches',
                    free_tier: 1
                }
                // { label: "Total Browsing Time", unit: "hr", value: "pages.all_records.total_browsing_timee", text: "Seatback Total Page Browsing Time",  },
            ];
            break;
        default:
    }

    return dropdownList;
}

export default getRoutesDropdownListHelper;
