import React from 'react';
import { Row } from 'reactstrap';
import TrendContainer from 'components/containers/TrendContainer';
import DonutContainer from 'components/containers/DonutContainer';
import HorizontalBarChartContainer from 'components/containers/HorizontalBarChartContainer';
import ScreenTransitionContainer from 'components/containers/ScreenTransitionContainer';
import VerticalBarChartContainer from 'components/containers/VerticalBarChartContainer';
import LopaContainer from 'components/containers/LopaContainer';
import TableContainer from 'components/containers/TableContainer';
import WorkbenchTableContainer from 'components/containers/WorkbenchTableContainer';

let page = 'seatback';
let displayPageName = 'Seatback';

class SeatBackOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ipsColumns: [
                { value: 'tail_no', header: 'Tail Number' },
                { value: 'airline_code', header: 'Airline' },
                { value: 'flight_number', header: 'Flight Number' },
                { value: 'flight_start_date', header: 'Flight Start Date', type: 'date' },
                { value: 'flight_end_date', header: 'Flight End Date', type: 'date' },
                { value: 'flight_duration_secs', header: 'Flight Duration', type: 'time' },
                { value: 'aircraft_type_subtype', header: 'Aircraft Type/Sub-type' },
                { value: 'system_type', header: 'System Type' },
                { value: 'dep_airport_iata', header: 'Flight Origin' },
                { value: 'arr_airport_iata', header: 'Flight Destination' },
                { value: 'seat_class', header: 'Seat Class' },
                { value: 'seatnum', header: 'Seat Number' },
                { value: 'payload_code', header: 'Payload Code' },
                { value: 'payload_event', header: 'Payload Event' }
            ]
        };
    }

    render() {
        let dashboard = (
            <div>
                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    usageSource="Seatback"
                    forceReloadTrend={this.props.forceReloadTrend}
                    forceReloadAll={this.props.forceReloadAll}
                    title="Trends"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="seatback_overview_trend_1"
                    id="seatback_overview_trend_1"
                    tabFunction={this.props.tabFunction}
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    subscription={this.props.subscription}
                    app={this.props.app}
                    subscriptionStage={this.props.subscriptionStage}
                />

                <Row>
                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        renderType=""
                        page={page}
                        pageName={displayPageName}
                        donut_value="passenger_engagement"
                        forceReloadAll={this.props.forceReloadAll}
                        tabFunction={this.props.tabFunction}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        allowedApps={this.props.allowedApps}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource="Seatback"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Languages"
                        workbenchTitle="languages"
                        page={page}
                        pageName={displayPageName}
                        reportType="languages"
                        color="#7FCECD"
                        key="seatback_overview_languages_1"
                        id="seatback_overview_languages_1"
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>

                <Row>
                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource="Seatback"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Seatback Browsing"
                        workbenchTitle="pages"
                        page={page}
                        pageName={displayPageName}
                        reportType="pages"
                        color="#2BCAB7"
                        key="seatback_overview_seatback_browsing_1"
                        id="seatback_overview_seatback_browsing_1"
                        tabFunction={this.props.tabFunction}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource="Seatback"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Routes"
                        workbenchTitle="routes"
                        page={page}
                        pageName={displayPageName}
                        reportType="routes"
                        color="#A1C9EB"
                        routes={true}
                        key="seatback_overview_routes_1"
                        id="seatback_overview_routes_1"
                        tabFunction={this.props.tabFunction}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>

                <Row>
                    <ScreenTransitionContainer
                        path={this.props.path}
                        usageSource={displayPageName}
                        pageName={displayPageName}
                        page={page}
                        forceReloadAll={this.props.forceReloadAll}
                        id={'portal_seat_back_overview_screen_transition'}
                        title={'Seatback Screen Transitions'}
                        appliedFilters={this.props.appliedFilters}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>

                <Row>
                    <VerticalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource={displayPageName}
                        pageName={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        title={'Passenger Interaction Timeline'}
                        page={page}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>

                <Row>
                    <LopaContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource={displayPageName}
                        pageName={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        title={'Seat Usage'}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        showWorkbenchLink={this.props.showWorkbench}
                        page={page}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>

                <Row>
                    <TableContainer
                        pageName={displayPageName}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        data={this.props.data}
                        dataset={this.props.dataset}
                        loading={this.props.loading}
                        appliedFilters={this.props.appliedFilters}
                        app={this.props.app}
                        title="Seatback"
                    />
                </Row>

                <Row>
                    <WorkbenchTableContainer
                        pageName={displayPageName}
                        appliedFilters={this.props.appliedFilters}
                        title="Login Details"
                        app={this.props.app}
                        columns={this.state.ipsColumns}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        page={page}
                    />
                </Row>
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default SeatBackOverview;
