export const screenTransitionData = {
  'response': {
    'airline_code': 'REU',
    'visits': [
      {
        'screen_name': 'Maps',
        'hits': '10000',
        'avg_dwell_time': '1000000.0',
        'visits': [
          {
            'screen_name': 'Connecting Gate',
            'hits': '1000',
            'avg_dwell_time': '10000.0',
            'visits': [
              {
                'screen_name': 'Airshow',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Maps',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Latest',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Games',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Hospitality',
            'hits': '900',
            'avg_dwell_time': '9000.0',
            'visits': [
              {
                'screen_name': 'SeatChat',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Maps',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Latest',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Games',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Surveys',
            'hits': '800',
            'avg_dwell_time': '8000.0',
            'visits': [
              {
                'screen_name': 'Live Text News',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Maps',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Latest',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Games',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Food & Drink',
            'hits': '700',
            'avg_dwell_time': '7000.0',
            'visits': [
              {
                'screen_name': 'Connecting Gate',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Maps',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Latest',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Games',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Maps',
            'hits': '600',
            'avg_dwell_time': '6000.0',
            'visits': [
              {
                'screen_name': 'Hospitality',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Maps',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Latest',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Games',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Latest',
            'hits': '500',
            'avg_dwell_time': '5000.0',
            'visits': [
              {
                'screen_name': 'Surveys',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Maps',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Latest',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Games',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Games',
            'hits': '400',
            'avg_dwell_time': '4000.0',
            'visits': [
              {
                'screen_name': 'Food & Drink',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Maps',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Latest',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Games',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Airshow',
            'hits': '300',
            'avg_dwell_time': '3000.0',
            'visits': [
              {
                'screen_name': 'Maps',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Latest',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Games',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'SeatChat',
            'hits': '200',
            'avg_dwell_time': '2000.0',
            'visits': [
              {
                'screen_name': 'Latest',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Games',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Maps',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Live Text News',
            'hits': '100',
            'avg_dwell_time': '1000.0',
            'visits': [
              {
                'screen_name': 'Games',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Maps',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Latest',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }]
      }, {
        'screen_name': 'Latest',
        'hits': '9000',
        'avg_dwell_time': '900000.0',
        'visits': [
          {
            'screen_name': 'Hospitality',
            'hits': '1000',
            'avg_dwell_time': '10000.0',
            'visits': [
              {
                'screen_name': 'SeatChat',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Maps',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Latest',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Games',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Surveys',
            'hits': '900',
            'avg_dwell_time': '9000.0',
            'visits': [
              {
                'screen_name': 'Live Text News',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Maps',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Latest',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Games',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Food & Drink',
            'hits': '800',
            'avg_dwell_time': '8000.0',
            'visits': [
              {
                'screen_name': 'Connecting Gate',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Maps',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Latest',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Games',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Maps',
            'hits': '700',
            'avg_dwell_time': '7000.0',
            'visits': [
              {
                'screen_name': 'Hospitality',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Maps',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Latest',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Games',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Latest',
            'hits': '600',
            'avg_dwell_time': '6000.0',
            'visits': [
              {
                'screen_name': 'Surveys',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Maps',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Latest',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Games',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Games',
            'hits': '500',
            'avg_dwell_time': '5000.0',
            'visits': [
              {
                'screen_name': 'Food & Drink',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Maps',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Latest',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Games',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Airshow',
            'hits': '400',
            'avg_dwell_time': '4000.0',
            'visits': [
              {
                'screen_name': 'Maps',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Latest',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Games',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'SeatChat',
            'hits': '300',
            'avg_dwell_time': '3000.0',
            'visits': [
              {
                'screen_name': 'Latest',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Games',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Maps',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Live Text News',
            'hits': '200',
            'avg_dwell_time': '2000.0',
            'visits': [
              {
                'screen_name': 'Games',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Maps',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Latest',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Connecting Gate',
            'hits': '100',
            'avg_dwell_time': '1000.0',
            'visits': [
              {
                'screen_name': 'Airshow',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Maps',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Latest',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Games',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }]
      }, {
        'screen_name': 'Games',
        'hits': '8000',
        'avg_dwell_time': '800000.0',
        'visits': [
          {
            'screen_name': 'Surveys',
            'hits': '1000',
            'avg_dwell_time': '10000.0',
            'visits': [
              {
                'screen_name': 'Live Text News',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Maps',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Latest',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Games',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Food & Drink',
            'hits': '900',
            'avg_dwell_time': '9000.0',
            'visits': [
              {
                'screen_name': 'Connecting Gate',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Maps',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Latest',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Games',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Maps',
            'hits': '800',
            'avg_dwell_time': '8000.0',
            'visits': [
              {
                'screen_name': 'Hospitality',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Maps',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Latest',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Games',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Latest',
            'hits': '700',
            'avg_dwell_time': '7000.0',
            'visits': [
              {
                'screen_name': 'Surveys',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Maps',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Latest',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Games',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Games',
            'hits': '600',
            'avg_dwell_time': '6000.0',
            'visits': [
              {
                'screen_name': 'Food & Drink',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Maps',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Latest',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Games',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Airshow',
            'hits': '500',
            'avg_dwell_time': '5000.0',
            'visits': [
              {
                'screen_name': 'Maps',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Latest',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Games',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'SeatChat',
            'hits': '400',
            'avg_dwell_time': '4000.0',
            'visits': [
              {
                'screen_name': 'Latest',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Games',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Maps',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Live Text News',
            'hits': '300',
            'avg_dwell_time': '3000.0',
            'visits': [
              {
                'screen_name': 'Games',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Maps',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Latest',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Connecting Gate',
            'hits': '200',
            'avg_dwell_time': '2000.0',
            'visits': [
              {
                'screen_name': 'Airshow',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Maps',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Latest',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Games',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Hospitality',
            'hits': '100',
            'avg_dwell_time': '1000.0',
            'visits': [
              {
                'screen_name': 'SeatChat',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Maps',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Latest',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Games',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }]
      }, {
        'screen_name': 'Airshow',
        'hits': '7000',
        'avg_dwell_time': '700000.0',
        'visits': [
          {
            'screen_name': 'Food & Drink',
            'hits': '1000',
            'avg_dwell_time': '10000.0',
            'visits': [
              {
                'screen_name': 'Connecting Gate',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Maps',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Latest',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Games',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Maps',
            'hits': '900',
            'avg_dwell_time': '9000.0',
            'visits': [
              {
                'screen_name': 'Hospitality',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Maps',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Latest',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Games',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Latest',
            'hits': '800',
            'avg_dwell_time': '8000.0',
            'visits': [
              {
                'screen_name': 'Surveys',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Maps',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Latest',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Games',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Games',
            'hits': '700',
            'avg_dwell_time': '7000.0',
            'visits': [
              {
                'screen_name': 'Food & Drink',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Maps',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Latest',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Games',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Airshow',
            'hits': '600',
            'avg_dwell_time': '6000.0',
            'visits': [
              {
                'screen_name': 'Maps',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Latest',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Games',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'SeatChat',
            'hits': '500',
            'avg_dwell_time': '5000.0',
            'visits': [
              {
                'screen_name': 'Latest',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Games',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Maps',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Live Text News',
            'hits': '400',
            'avg_dwell_time': '4000.0',
            'visits': [
              {
                'screen_name': 'Games',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Maps',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Latest',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Connecting Gate',
            'hits': '300',
            'avg_dwell_time': '3000.0',
            'visits': [
              {
                'screen_name': 'Airshow',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Maps',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Latest',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Games',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Hospitality',
            'hits': '200',
            'avg_dwell_time': '2000.0',
            'visits': [
              {
                'screen_name': 'SeatChat',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Maps',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Latest',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Games',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Surveys',
            'hits': '100',
            'avg_dwell_time': '1000.0',
            'visits': [
              {
                'screen_name': 'Live Text News',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Maps',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Latest',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Games',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }]
      }, {
        'screen_name': 'SeatChat',
        'hits': '6000',
        'avg_dwell_time': '600000.0',
        'visits': [
          {
            'screen_name': 'Maps',
            'hits': '1000',
            'avg_dwell_time': '10000.0',
            'visits': [
              {
                'screen_name': 'Hospitality',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Maps',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Latest',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Games',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Latest',
            'hits': '900',
            'avg_dwell_time': '9000.0',
            'visits': [
              {
                'screen_name': 'Surveys',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Maps',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Latest',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Games',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Games',
            'hits': '800',
            'avg_dwell_time': '8000.0',
            'visits': [
              {
                'screen_name': 'Food & Drink',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Maps',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Latest',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Games',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Airshow',
            'hits': '700',
            'avg_dwell_time': '7000.0',
            'visits': [
              {
                'screen_name': 'Maps',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Latest',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Games',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'SeatChat',
            'hits': '600',
            'avg_dwell_time': '6000.0',
            'visits': [
              {
                'screen_name': 'Latest',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Games',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Maps',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Live Text News',
            'hits': '500',
            'avg_dwell_time': '5000.0',
            'visits': [
              {
                'screen_name': 'Games',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Maps',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Latest',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Connecting Gate',
            'hits': '400',
            'avg_dwell_time': '4000.0',
            'visits': [
              {
                'screen_name': 'Airshow',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Maps',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Latest',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Games',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Hospitality',
            'hits': '300',
            'avg_dwell_time': '3000.0',
            'visits': [
              {
                'screen_name': 'SeatChat',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Maps',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Latest',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Games',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Surveys',
            'hits': '200',
            'avg_dwell_time': '2000.0',
            'visits': [
              {
                'screen_name': 'Live Text News',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Maps',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Latest',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Games',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Food & Drink',
            'hits': '100',
            'avg_dwell_time': '1000.0',
            'visits': [
              {
                'screen_name': 'Connecting Gate',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Maps',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Latest',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Games',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }]
      }, {
        'screen_name': 'Live Text News',
        'hits': '5000',
        'avg_dwell_time': '500000.0',
        'visits': [
          {
            'screen_name': 'Latest',
            'hits': '1000',
            'avg_dwell_time': '10000.0',
            'visits': [
              {
                'screen_name': 'Surveys',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Maps',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Latest',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Games',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Games',
            'hits': '900',
            'avg_dwell_time': '9000.0',
            'visits': [
              {
                'screen_name': 'Food & Drink',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Maps',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Latest',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Games',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Airshow',
            'hits': '800',
            'avg_dwell_time': '8000.0',
            'visits': [
              {
                'screen_name': 'Maps',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Latest',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Games',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'SeatChat',
            'hits': '700',
            'avg_dwell_time': '7000.0',
            'visits': [
              {
                'screen_name': 'Latest',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Games',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Maps',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Live Text News',
            'hits': '600',
            'avg_dwell_time': '6000.0',
            'visits': [
              {
                'screen_name': 'Games',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Maps',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Latest',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Connecting Gate',
            'hits': '500',
            'avg_dwell_time': '5000.0',
            'visits': [
              {
                'screen_name': 'Airshow',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Maps',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Latest',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Games',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Hospitality',
            'hits': '400',
            'avg_dwell_time': '4000.0',
            'visits': [
              {
                'screen_name': 'SeatChat',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Maps',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Latest',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Games',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Surveys',
            'hits': '300',
            'avg_dwell_time': '3000.0',
            'visits': [
              {
                'screen_name': 'Live Text News',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Maps',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Latest',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Games',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Food & Drink',
            'hits': '200',
            'avg_dwell_time': '2000.0',
            'visits': [
              {
                'screen_name': 'Connecting Gate',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Maps',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Latest',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Games',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Maps',
            'hits': '100',
            'avg_dwell_time': '1000.0',
            'visits': [
              {
                'screen_name': 'Hospitality',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Maps',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Latest',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Games',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }]
      }, {
        'screen_name': 'Connecting Gate',
        'hits': '4000',
        'avg_dwell_time': '400000.0',
        'visits': [
          {
            'screen_name': 'Games',
            'hits': '1000',
            'avg_dwell_time': '10000.0',
            'visits': [
              {
                'screen_name': 'Food & Drink',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Maps',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Latest',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Games',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Airshow',
            'hits': '900',
            'avg_dwell_time': '9000.0',
            'visits': [
              {
                'screen_name': 'Maps',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Latest',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Games',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'SeatChat',
            'hits': '800',
            'avg_dwell_time': '8000.0',
            'visits': [
              {
                'screen_name': 'Latest',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Games',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Maps',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Live Text News',
            'hits': '700',
            'avg_dwell_time': '7000.0',
            'visits': [
              {
                'screen_name': 'Games',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Maps',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Latest',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Connecting Gate',
            'hits': '600',
            'avg_dwell_time': '6000.0',
            'visits': [
              {
                'screen_name': 'Airshow',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Maps',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Latest',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Games',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Hospitality',
            'hits': '500',
            'avg_dwell_time': '5000.0',
            'visits': [
              {
                'screen_name': 'SeatChat',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Maps',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Latest',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Games',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Surveys',
            'hits': '400',
            'avg_dwell_time': '4000.0',
            'visits': [
              {
                'screen_name': 'Live Text News',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Maps',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Latest',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Games',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Food & Drink',
            'hits': '300',
            'avg_dwell_time': '3000.0',
            'visits': [
              {
                'screen_name': 'Connecting Gate',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Maps',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Latest',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Games',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Maps',
            'hits': '200',
            'avg_dwell_time': '2000.0',
            'visits': [
              {
                'screen_name': 'Hospitality',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Maps',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Latest',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Games',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Latest',
            'hits': '100',
            'avg_dwell_time': '1000.0',
            'visits': [
              {
                'screen_name': 'Surveys',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Maps',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Latest',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Games',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }]
      }, {
        'screen_name': 'Hospitality',
        'hits': '3000',
        'avg_dwell_time': '300000.0',
        'visits': [
          {
            'screen_name': 'Airshow',
            'hits': '1000',
            'avg_dwell_time': '10000.0',
            'visits': [
              {
                'screen_name': 'Maps',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Latest',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Games',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'SeatChat',
            'hits': '900',
            'avg_dwell_time': '9000.0',
            'visits': [
              {
                'screen_name': 'Latest',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Games',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Maps',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Live Text News',
            'hits': '800',
            'avg_dwell_time': '8000.0',
            'visits': [
              {
                'screen_name': 'Games',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Maps',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Latest',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Connecting Gate',
            'hits': '700',
            'avg_dwell_time': '7000.0',
            'visits': [
              {
                'screen_name': 'Airshow',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Maps',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Latest',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Games',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Hospitality',
            'hits': '600',
            'avg_dwell_time': '6000.0',
            'visits': [
              {
                'screen_name': 'SeatChat',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Maps',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Latest',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Games',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Surveys',
            'hits': '500',
            'avg_dwell_time': '5000.0',
            'visits': [
              {
                'screen_name': 'Live Text News',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Maps',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Latest',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Games',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Food & Drink',
            'hits': '400',
            'avg_dwell_time': '4000.0',
            'visits': [
              {
                'screen_name': 'Connecting Gate',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Maps',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Latest',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Games',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Maps',
            'hits': '300',
            'avg_dwell_time': '3000.0',
            'visits': [
              {
                'screen_name': 'Hospitality',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Maps',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Latest',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Games',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Latest',
            'hits': '200',
            'avg_dwell_time': '2000.0',
            'visits': [
              {
                'screen_name': 'Surveys',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Maps',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Latest',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Games',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Games',
            'hits': '100',
            'avg_dwell_time': '1000.0',
            'visits': [
              {
                'screen_name': 'Food & Drink',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Maps',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Latest',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Games',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }]
      }, {
        'screen_name': 'Surveys',
        'hits': '2000',
        'avg_dwell_time': '200000.0',
        'visits': [
          {
            'screen_name': 'SeatChat',
            'hits': '1000',
            'avg_dwell_time': '10000.0',
            'visits': [
              {
                'screen_name': 'Latest',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Games',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Maps',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Live Text News',
            'hits': '900',
            'avg_dwell_time': '9000.0',
            'visits': [
              {
                'screen_name': 'Games',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Maps',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Latest',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Connecting Gate',
            'hits': '800',
            'avg_dwell_time': '8000.0',
            'visits': [
              {
                'screen_name': 'Airshow',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Maps',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Latest',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Games',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Hospitality',
            'hits': '700',
            'avg_dwell_time': '7000.0',
            'visits': [
              {
                'screen_name': 'SeatChat',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Maps',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Latest',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Games',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Surveys',
            'hits': '600',
            'avg_dwell_time': '6000.0',
            'visits': [
              {
                'screen_name': 'Live Text News',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Maps',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Latest',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Games',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Food & Drink',
            'hits': '500',
            'avg_dwell_time': '5000.0',
            'visits': [
              {
                'screen_name': 'Connecting Gate',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Maps',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Latest',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Games',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Maps',
            'hits': '400',
            'avg_dwell_time': '4000.0',
            'visits': [
              {
                'screen_name': 'Hospitality',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Maps',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Latest',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Games',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Latest',
            'hits': '300',
            'avg_dwell_time': '3000.0',
            'visits': [
              {
                'screen_name': 'Surveys',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Maps',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Latest',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Games',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Games',
            'hits': '200',
            'avg_dwell_time': '2000.0',
            'visits': [
              {
                'screen_name': 'Food & Drink',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Maps',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Latest',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Games',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Airshow',
            'hits': '100',
            'avg_dwell_time': '1000.0',
            'visits': [
              {
                'screen_name': 'Maps',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Latest',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Games',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }]
      }, {
        'screen_name': 'Food & Drink',
        'hits': '1000',
        'avg_dwell_time': '100000.0',
        'visits': [
          {
            'screen_name': 'Live Text News',
            'hits': '1000',
            'avg_dwell_time': '10000.0',
            'visits': [
              {
                'screen_name': 'Games',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Maps',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Latest',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Connecting Gate',
            'hits': '900',
            'avg_dwell_time': '9000.0',
            'visits': [
              {
                'screen_name': 'Airshow',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Maps',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Latest',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Games',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Hospitality',
            'hits': '800',
            'avg_dwell_time': '8000.0',
            'visits': [
              {
                'screen_name': 'SeatChat',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Maps',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Latest',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Games',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Surveys',
            'hits': '700',
            'avg_dwell_time': '7000.0',
            'visits': [
              {
                'screen_name': 'Live Text News',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Maps',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Latest',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Games',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Food & Drink',
            'hits': '600',
            'avg_dwell_time': '6000.0',
            'visits': [
              {
                'screen_name': 'Connecting Gate',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Maps',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Latest',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Games',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Maps',
            'hits': '500',
            'avg_dwell_time': '5000.0',
            'visits': [
              {
                'screen_name': 'Hospitality',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Maps',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Latest',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Games',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Latest',
            'hits': '400',
            'avg_dwell_time': '4000.0',
            'visits': [
              {
                'screen_name': 'Surveys',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Maps',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Latest',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Games',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Games',
            'hits': '300',
            'avg_dwell_time': '3000.0',
            'visits': [
              {
                'screen_name': 'Food & Drink',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Maps',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Latest',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Games',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'Airshow',
            'hits': '200',
            'avg_dwell_time': '2000.0',
            'visits': [
              {
                'screen_name': 'Maps',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Latest',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Games',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }, {
            'screen_name': 'SeatChat',
            'hits': '100',
            'avg_dwell_time': '1000.0',
            'visits': [
              {
                'screen_name': 'Latest',
                'hits': '100',
                'avg_dwell_time': '100.0'
              }, {
                'screen_name': 'Games',
                'hits': '90',
                'avg_dwell_time': '90.0'
              }, {
                'screen_name': 'Airshow',
                'hits': '80',
                'avg_dwell_time': '80.0'
              }, {
                'screen_name': 'SeatChat',
                'hits': '70',
                'avg_dwell_time': '70.0'
              }, {
                'screen_name': 'Live Text News',
                'hits': '60',
                'avg_dwell_time': '60.0'
              }, {
                'screen_name': 'Connecting Gate',
                'hits': '50',
                'avg_dwell_time': '50.0'
              }, {
                'screen_name': 'Hospitality',
                'hits': '40',
                'avg_dwell_time': '40.0'
              }, {
                'screen_name': 'Surveys',
                'hits': '30',
                'avg_dwell_time': '30.0'
              }, {
                'screen_name': 'Food & Drink',
                'hits': '20',
                'avg_dwell_time': '20.0'
              }, {
                'screen_name': 'Maps',
                'hits': '10',
                'avg_dwell_time': '10.0'
              }]
          }]
      }],
    'request': {
      'uri': '/v2/metrics/screen_transition_data',
      'parameters': {
        'date_range': '20210401-20210430',
        'dateRangeText': 'Last Month'
      }
    }
  }
};