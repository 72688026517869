import React, { Component } from 'react';
import { Accordion, Card, Row, Col } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment';
import { ButtonPrimary, ButtonSecondary, DateRangePicker } from 'pac-ux-components';
import { DropdownMultiple, Checkbox, CheckboxSelectVariant, Chip } from 'pac-ux-components';
import Circular from 'assets/images/icons/circular.gif';
import _, { debounce } from 'lodash';
import display_names from 'data/dynamic-filter-display-data.js';
import DynamicFilterHelper from 'data/dynamic-filter-helper.js';
import FiltersService from '../../services/FiltersService';
import { getAllTenantOption, getAllRegionOption } from 'util/TenantHelper';
import InsightsMonthlyService from 'services/InsightsMonthlyService';

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faSearch : faCaretDown} />
            </components.DropdownIndicator>
        )
    );
};

const allTenantOption = getAllTenantOption();
const allRegionOption = getAllRegionOption();

class DynamicFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            applyFilterButtonDisabled: true,
            clearFilterButtonDisabled: true,
            dateRangeOptions: [],
            comparisonRangeOptions: [{ label: 'None', value: 'None' }],
            dateRangeValue: 'None',
            dateRangeText: '',
            defaultDateRangeOption: {},
            comparisonRangeValue: '',
            comparisonRangeText: '',
            filterValues: [],
            selectedSystemTypes: new Map(),
            fromCityOptions: [{ label: 'None', value: 'None' }],
            toCityOptions: [{ label: 'None', value: 'None' }],
            flightNumbersOptions: [{ label: 'None', value: 'None' }],
            selectedFromCity: null,
            selectedToCity: null,
            selectedFlightNumber: null,
            selectedTenant: {},
            selectedRegion: {},
            selectedLiveTVChannel: {},
            appliedFromCity: null,
            appliedToCity: null,
            appliedFlightNumber: null,
            appliedTenant: {},
            appliedLiveTVChannel: {},
            appliedRegion: {},
            routeFilters: ['appliedFromCity', 'appliedToCity', 'appliedFlightNumber'],
            tailOptions: [],
            allTailOptions: [],
            multiSelectFilterLabel: 'Select Tail',
            parentLoading: true,
            filterLoading: false,
            forceSendUpdatedData: false,
            pageSelectedFilters: {
                systemType: [],
                aircraftType: [],
                flightDuration: [],
                seatClass: [],
                guiLanguage: [],
                tail: [],
                toCity: [],
                fromCity: [],
                flightNumber: []
            },
            selectedFilters: {
                systemType: [],
                aircraftType: [],
                flightDuration: [],
                seatClass: [],
                guiLanguage: [],
                tail: [],
                toCity: [],
                fromCity: [],
                flightNumber: []
            },
            appliedFilters: {},
            prevAppliedFilters: {},
            displayFilters: false,
            systemTypes: [{ label: 'None', value: 'None' }],
            aircraftTypes: [{ label: 'None', value: 'None' }],
            flightHaulTypes: [{ label: 'None', value: 'None' }],
            seatClasses: [{ label: 'None', value: 'None' }],
            displayDateRangeSelector: false,
            customStartDate: moment().subtract(1, 'months').startOf('month'),
            customEndDate: moment().subtract(1, 'months').endOf('month'),
            tenants: [],
            regions: [],
            liveTVChannels: []
        };

        this.testData = {};

        this.cancelRequestSource = null;
        this.fetchFilterValues = debounce(this.fetchFilterValues.bind(this), 1000);

        this.accordionRef = React.createRef();

        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleFromCityChange = this.handleFromCityChange.bind(this);
        this.handleToCityChange = this.handleToCityChange.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleComparisonRangeChange = this.handleComparisonRangeChange.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.handleTenantChange = this.handleTenantChange.bind(this);
        this.handleLiveTVChannelChange = this.handleLiveTVChannelChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
    }

    inAllTenantView = true; //this.props.currentView === 'allTenants';

    async componentDidMount() {
        let selectedFiltersFromStore = this.getSelectedFiltersFromStore();
        this.setState(selectedFiltersFromStore);

        if (this.inAllTenantView) {
            this.setState({
                appliedTenant: allTenantOption,
                selectedTenant: allTenantOption,
                selectedRegion: allRegionOption,
                appliedFilters: this.inAllTenantView
                    ? { tenant: [allTenantOption.label], liveTVChannel: ['All Channels'], region: ['All Regions'] }
                    : {},
                appliedLiveTVChannel: { label: 'All Channels', value: 'ALL' },
                selectedLiveTVChannel: { label: 'All Channels', value: 'ALL' },
                appliedRegion: { label: 'All Regions', value: 'ALL' }
            });
        }

        await this.fetchFilterValues();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.forceLoad || !_.isEqual(this.props.title, prevProps.title)) {
            let filters = this.state.appliedFilters;
            filters['liveTVChannel'] = ['All Channels'];
            filters['region'] = ['All Regions'];
            sessionStorage.setItem('channel_title', 'ALL');
            sessionStorage.setItem('region', 'ALL');

            this.setState(
                {
                    appliedFilters: filters,
                    appliedLiveTVChannel: { label: 'All Channels', value: 'ALL' },
                    selectedLiveTVChannel: { label: 'All Channels', value: 'ALL' },
                    appliedRegion: { label: 'All Regions', value: 'ALL' },
                    selectedRegion: { label: 'All Regions', value: 'ALL' },
                    parentLoading: true,
                    forceSendUpdatedData: true
                },
                () => this.fetchFilterValues()
            );
        }
    }

    async fetchFilterValues() {
        return await this.fetchFilterValuesPromise(true, true);
    }

    async fetchFilterValuesPromise(fetchTenants = true, fetchLiveTVChannels = true) {
        if (fetchTenants && this.state.expanded) {
            this.accordionRef.current.click();
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        let tenant = null;
        const service = new InsightsMonthlyService();
        if (this.inAllTenantView && fetchTenants) {
            tenant = this.state.selectedTenant;
            let loadTenantsResponse = await service.fetchAdminTenantsAxios(
                this.props.app,
                this.props.subscription,
                this.cancelRequestSource.token
            );
            if (loadTenantsResponse) {
                let airlines = loadTenantsResponse.data.response.filter_values.paxus_offload_airlines.data;
                if (!airlines) {
                    airlines = [];
                }

                let regions = [];
                let loadedRegionsData = loadTenantsResponse.data.response.filter_values.regions.data;
                if (loadedRegionsData) {
                    regions = Object.keys(loadedRegionsData).map((icao) => {
                        return loadedRegionsData[icao]['region'];
                    });
                    let uniqueRegions = [];
                    regions.forEach((region) => {
                        if (region && !uniqueRegions.includes(region)) {
                            uniqueRegions.push(region);
                        }
                    });
                    regions = uniqueRegions;
                    regions.sort();
                    regions = regions.map((region) => {
                        let id = Math.floor(Math.random() * (99999 - 10000) + 10000) + region;
                        return { label: region, value: region, id: id };
                    });
                    regions.unshift(allRegionOption);
                }

                airlines = airlines
                    .map((airline) => {
                        let name = '';
                        if (loadedRegionsData[airline.value]) {
                            name = loadedRegionsData[airline.value]['name'];
                        }
                        if (name !== '') {
                            return { icao: airline.value, name: name };
                        } else {
                            return null;
                        }
                    })
                    .filter((airline) => {
                        if (airline === null) {
                            return false;
                        } else {
                            return true;
                        }
                    });

                airlines = airlines.map((airlineObj) => {
                    let id = Math.floor(Math.random() * (99999 - 10000) + 10000) + airlineObj['icao'];
                    return { label: airlineObj['name'], value: id, icaoCode: airlineObj['icao'] };
                });
                airlines.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }

                    return 0;
                });
                airlines.unshift(allTenantOption);

                this.setState({ tenants: airlines, regions: regions });

                if (tenant.label !== allTenantOption.label && !airlines.find((a) => a.label === tenant.label)) {
                    this.clearFilters();
                }
            }
        }

        if (this.inAllTenantView && this.props.title === 'Live TV' && fetchLiveTVChannels) {
            let data = {};
            tenant = this.state.selectedTenant;

            data['airlines'] = [tenant.icaoCode.toUpperCase()];
            if (!this.state.selectedLiveTVChannel.value) {
                data['channel_title'] = 'ALL';
            } else {
                data['channel_title'] = this.state.selectedLiveTVChannel.value;
            }

            let liveTVChannelsResponse = await service.fetchAdminLiveTVChannelsAxios(
                data,
                this.props.subscription,
                this.cancelRequestSource.token
            );

            if (liveTVChannelsResponse) {
                let channels = liveTVChannelsResponse.data.response.data['live_tv_channels'];
                if (!channels) {
                    channels = [];
                }
                let liveTVChannels = channels.map((channel) => ({ label: channel, value: channel }));
                liveTVChannels.unshift({ label: 'All Channels', value: 'ALL' });
                this.setState({ liveTVChannels: liveTVChannels });
            }
        }

        let loadFiltersResponse = await FiltersService.loadAllFilters(
            this.cancelRequestSource,
            this.props.subscription,
            this.props.app,
            fetchTenants ? tenant : this.state.selectedTenant
        );

        return new Promise((resolve) => {
            let dateRangeOptionsOnly = [],
                defaultDateRange = [],
                dateRangeValue = [],
                dateRangeText = [];
            let comparisonRanges = [],
                defaultComparisionRange = '',
                comparisonRangeText = '',
                aircraftTypes = [];
            let fromCities = [],
                toCities = [],
                flightNumbers = [],
                systemTypes = [];
            let tails = [],
                displayFilters = false;
            let flightHaulTypes = [],
                seatClasses = [];

            if (
                !loadFiltersResponse.dateRangeFiltersDataIsSet &&
                !loadFiltersResponse.nonDateRangeFiltersDataIsSet &&
                !this.inAllTenantView
            ) {
                this.setState({ displayFilters: false });
            } else {
                this.setState({ displayFilters: false });

                let dateRangeFiltersData = [];

                if (loadFiltersResponse.dateRangeFiltersDataIsSet) {
                    //populate date range filters

                    dateRangeFiltersData = FiltersService.getDateRangeFilters();

                    if (process.env.JEST_WORKER_ID) {
                        dateRangeFiltersData = this.testData.filterValues;
                    }

                    let dateRange = null;
                    if (dateRangeFiltersData) {
                        defaultDateRange = dateRangeFiltersData.filter(
                            (dateRange) => dateRange.default && dateRange.default === 1
                        );

                        if (this.state.dateRangeValue === 'None') {
                            dateRange = dateRangeFiltersData.filter(
                                (dateRange) => dateRange.default && dateRange.default === 1
                            );
                        } else {
                            dateRange = dateRangeFiltersData.filter(
                                (dateRange) => dateRange.label === this.state.dateRangeText
                            );

                            // TODO - Check if this needs to be removed after API is ready
                            if (dateRange && dateRange.length === 0) {
                                dateRange = dateRangeFiltersData.filter(
                                    (dateRange) => dateRange.default && dateRange.default === 1
                                );
                            }
                        }

                        if (this.state.dateRangeText === '' || this.state.dateRangeValue === 'None') {
                            dateRangeValue = dateRange.map((dateRange) => dateRange.value);
                            dateRangeText = dateRange.map((dateRange) => dateRange.label);
                        } else {
                            dateRangeValue = this.state.dateRangeValue;
                            dateRangeText = this.state.dateRangeText;
                        }

                        comparisonRanges = [{ label: 'None', value: 'None' }];
                        defaultComparisionRange = 'None';
                        comparisonRangeText = '';

                        if (
                            this.props.showWorkbenchLink &&
                            dateRange.length > 0 &&
                            dateRange[0].comparison_ranges &&
                            Object.keys(dateRange[0].comparison_ranges).length > 0
                        ) {
                            comparisonRanges = dateRange[0].comparison_ranges;

                            if (this.state.comparisonRangeValue === '' || this.state.comparisonRangeValue === 'None') {
                                defaultComparisionRange = dateRange[0].comparison_ranges[0].value;
                                comparisonRangeText = dateRange[0].comparison_ranges[0].label;
                            } else {
                                defaultComparisionRange = this.state.comparisonRangeValue;
                                comparisonRangeText = this.state.comparisonRangeText;
                            }
                        } else if (
                            this.props.showWorkbenchLink &&
                            dateRange.length > 0 &&
                            dateRange[0].label === 'Custom'
                        ) {
                            comparisonRanges = [{ label: 'Same period a year ago' }];
                            comparisonRangeText = comparisonRanges[0].label;
                            defaultComparisionRange = '';
                            if (this.state.comparisonRangeValue) {
                                defaultComparisionRange = this.state.comparisonRangeValue;
                            }
                            comparisonRanges[0].value = defaultComparisionRange;
                        }

                        let dateRangeData = JSON.parse(JSON.stringify(dateRangeFiltersData));
                        dateRangeOptionsOnly = dateRangeData.map(({ comparison_ranges, ...rest }) => rest);
                    }
                }

                // Disable options
                dateRangeOptionsOnly.forEach((option) => {
                    option.isDisabled = true;
                    if (typeof option.enabled === 'object' && option.enabled !== null) {
                        let app = this.props.app;
                        // filter_values API returns 'Portal' for 'Portal Navigation' app
                        if (app === 'Portal Navigation') {
                            app = 'Portal';
                        }
                        if (app === 'Overview') {
                            app = 'My Insights';
                        }
                        Object.entries(option.enabled).forEach(([key, value]) => {
                            if (key && key === app && value === true) {
                                option.isDisabled = false;
                            }
                        });
                        if (option.label === 'Custom') {
                            option.isDisabled = false;
                        }
                    }

                    if (
                        option.isDisabled &&
                        dateRangeValue &&
                        option.label === dateRangeText.toString() &&
                        dateRangeFiltersData
                    ) {
                        let dateRange = dateRangeFiltersData.filter(
                            (dateRange) => dateRange.default && dateRange.default === 1
                        );
                        dateRangeValue = dateRange.map((dateRange) => dateRange.value);
                        dateRangeText = dateRange.map((dateRange) => dateRange.label);

                        if (
                            this.props.showWorkbenchLink &&
                            dateRange.length > 0 &&
                            dateRange[0].comparison_ranges &&
                            Object.keys(dateRange[0].comparison_ranges).length > 0
                        ) {
                            comparisonRanges = dateRange[0].comparison_ranges;
                            defaultComparisionRange = dateRange[0].comparison_ranges[0].value;
                            comparisonRangeText = dateRange[0].comparison_ranges[0].label;
                        }
                    }
                });

                let nonDateRangeFiltersData = [];

                if (loadFiltersResponse.nonDateRangeFiltersDataIsSet) {
                    //populate non date range filters

                    nonDateRangeFiltersData = FiltersService.getNonDateRangeFilters();

                    fromCities = FiltersService.getFilterValuesByTypeAndSelection(
                        'dep_airport_iata',
                        [],
                        this.props.title
                    );
                    toCities = FiltersService.getFilterValuesByTypeAndSelection(
                        'arr_airport_iata',
                        [],
                        this.props.title
                    );
                    flightNumbers = FiltersService.getFilterValuesByTypeAndSelection(
                        'flight_number',
                        [],
                        this.props.title
                    );
                    tails = FiltersService.getFilterValuesByTypeAndSelection(
                        'aircraft_tail_number',
                        [],
                        this.props.title
                    );
                    systemTypes = FiltersService.getFilterValuesByTypeAndSelection('system_type', [], this.props.title);
                    aircraftTypes = FiltersService.getFilterValuesByTypeAndSelection(
                        'aircraft_type_subtype',
                        [],
                        this.props.title
                    );
                    flightHaulTypes = FiltersService.getFilterValuesByTypeAndSelection(
                        'flight_haul_type',
                        [],
                        this.props.title
                    );
                    seatClasses = FiltersService.getFilterValuesByTypeAndSelection('seat_class', [], this.props.title);

                    displayFilters = true;
                } else if (loadFiltersResponse.nonDynamicFiltersDataIsSet) {
                    let nonDynamicFiltersData = FiltersService.getNonDynamicFilters();

                    fromCities = nonDynamicFiltersData['flight_origins'].error
                        ? []
                        : nonDynamicFiltersData['flight_origins'].data;
                    toCities = nonDynamicFiltersData['flight_destinations'].error
                        ? []
                        : nonDynamicFiltersData['flight_destinations'].data;
                    flightNumbers = nonDynamicFiltersData['flight_numbers']
                        ? nonDynamicFiltersData['flight_numbers'].error
                            ? []
                            : nonDynamicFiltersData['flight_numbers'].data
                        : [];
                    tails = nonDynamicFiltersData['tails']
                        ? nonDynamicFiltersData['tails'].error
                            ? []
                            : nonDynamicFiltersData['tails'].data
                        : [];
                    systemTypes = nonDynamicFiltersData['system_types'].error
                        ? []
                        : nonDynamicFiltersData['system_types'].data;
                    aircraftTypes = nonDynamicFiltersData['aircraft_types'].error
                        ? []
                        : nonDynamicFiltersData['aircraft_types'].data;
                    flightHaulTypes = nonDynamicFiltersData['flight_haul_types'].error
                        ? []
                        : nonDynamicFiltersData['flight_haul_types'].data;
                    seatClasses = nonDynamicFiltersData['seat_classes'].error
                        ? []
                        : nonDynamicFiltersData['seat_classes'].data;
                }

                this.setState({
                    filterValues: nonDateRangeFiltersData,
                    dateRangeFiltersData: dateRangeFiltersData,
                    dateRangeOptions: dateRangeOptionsOnly,
                    dateRangeValue: dateRangeValue.toString(),
                    dateRangeText: dateRangeText.toString(),
                    defaultDateRangeOption: defaultDateRange[0],
                    comparisonRangeOptions: comparisonRanges,
                    comparisonRangeValue: defaultComparisionRange,
                    comparisonRangeText: comparisonRangeText,
                    fromCityOptions: fromCities,
                    toCityOptions: toCities,
                    flightNumbersOptions: flightNumbers,
                    tailOptions: tails,
                    allTailOptions: tails,
                    displayFilters: this.inAllTenantView ? true : displayFilters,
                    systemTypes: systemTypes,
                    aircraftTypes: aircraftTypes,
                    flightHaulTypes: flightHaulTypes,
                    seatClasses: seatClasses,
                    customDateRange: FiltersService.getCustomDateRange()
                });

                if (this.state.parentLoading) {
                    if (fetchTenants) {
                        this.toggleApplyFilterButton();
                    }
                    this.sendUpdatedData();
                    this.updateAllFilters();
                }
            }
            resolve();
        });
    }

    handleAccordionClick() {
        this.setState((prevState) => ({
            expanded: !prevState.expanded
        }));
    }

    handleCheckBoxChange(checkBoxType, e) {
        const item = e.target.name;

        let filters = this.state.selectedFilters;
        let checkboxChecked = false;
        for (let i = 0; i < filters[checkBoxType].length; i++) {
            if (filters[checkBoxType][i] === item) {
                checkboxChecked = true;
                break;
            }
        }

        if (!checkboxChecked) {
            filters[checkBoxType].push(item);
        } else {
            for (let i = 0; i < filters[checkBoxType].length; i++) {
                if (filters[checkBoxType][i] === item) {
                    filters[checkBoxType].splice(i, 1);
                }
            }
        }

        this.setState({ selectedFilters: filters }, () => this.updateAllFilters());
        this.toggleApplyFilterButton();
    }

    getPageSelectedFilters() {
        let selectedFiltersDeepClone = JSON.parse(JSON.stringify(this.state.selectedFilters));
        Object.keys(selectedFiltersDeepClone).forEach((key) => {
            if (selectedFiltersDeepClone[key].length > 0) {
                selectedFiltersDeepClone[key] = selectedFiltersDeepClone[key].filter(
                    (item) => !DynamicFilterHelper.isAppliedFilterNotExist(key, item, this.props.title)
                );
            }
        });
        return selectedFiltersDeepClone;
    }

    updateAllFilters() {
        if (this.state.selectedTenant.icaoCode === allTenantOption.icaoCode) {
            return;
        }

        let selectedFiltersDeepClone = this.getPageSelectedFilters();
        this.setState({ pageSelectedFilters: selectedFiltersDeepClone });

        let selectedFilterKeys = [];
        Object.keys(selectedFiltersDeepClone).forEach((key) => {
            if (selectedFiltersDeepClone[key].length > 0) {
                let shouldSkip = false;
                selectedFiltersDeepClone[key].forEach((filter) => {
                    if (shouldSkip) {
                        return;
                    }
                    if (!DynamicFilterHelper.isAppliedFilterNotExist(key, filter, this.props.title)) {
                        selectedFilterKeys.push(key);
                        shouldSkip = true;
                    }
                });
            }
        });

        let stateUpdateObj = {};
        let filterTypesAndStateProperties = [
            { dataProperty: 'flight_number', stateProperty: 'flightNumbersOptions', filterValue: 'flightNumber' },
            { dataProperty: 'aircraft_tail_number', stateProperty: 'tailOptions', filterValue: 'tail' },
            { dataProperty: 'aircraft_type_subtype', stateProperty: 'aircraftTypes', filterValue: 'aircraftType' },
            { dataProperty: 'system_type', stateProperty: 'systemTypes', filterValue: 'systemType' },
            { dataProperty: 'flight_haul_type', stateProperty: 'flightHaulTypes', filterValue: 'flightDuration' },
            { dataProperty: 'dep_airport_iata', stateProperty: 'fromCityOptions', filterValue: 'fromCity' },
            { dataProperty: 'arr_airport_iata', stateProperty: 'toCityOptions', filterValue: 'toCity' },
            { dataProperty: 'seat_class', stateProperty: 'seatClasses', filterValue: 'seatClass' }
        ];

        filterTypesAndStateProperties.forEach((filter) => {
            let updatedData = FiltersService.getFilterValuesByTypeAndSelection(
                filter.dataProperty,
                selectedFiltersDeepClone,
                this.props.title
            );
            stateUpdateObj[filter.stateProperty] = updatedData;
        });

        stateUpdateObj['allTailOptions'] = stateUpdateObj['tailOptions'];

        this.setState(stateUpdateObj);
    }

    handleChipButton(filterType, value, selectedRoute, filterNotExists) {
        let selectedFromCity = this.state.selectedFromCity;
        let selectedToCity = this.state.selectedToCity;
        let selectedFlightNumber = this.state.selectedFlightNumber;
        let appliedTenant = this.state.appliedTenant;
        let selectedTenant = this.state.selectedTenant;

        let filters = this.state.appliedFilters;
        for (let i = 0; i < filters[filterType].length; i++) {
            if (filters[filterType][i] === value) {
                if (
                    (filterType !== 'tenant' && filterType !== 'liveTVChannel' && filterType !== 'region') ||
                    filters[filterType].length > 1
                ) {
                    filters[filterType].splice(i, 1);
                } else {
                    return;
                }

                if ('fromCity' === filterType) {
                    selectedFromCity = null;
                }
                if ('toCity' === filterType) {
                    selectedToCity = null;
                }
                if ('flightNumber' === filterType) {
                    selectedFlightNumber = null;
                }
            }
        }

        let selectedFilters = this.state.selectedFilters;
        if (filterType !== 'tenant' || filterType !== 'liveTVChannel' || filterType !== 'region') {
            for (let i = 0; i < selectedFilters[filterType].length; i++) {
                if (selectedFilters[filterType][i] === value) {
                    selectedFilters[filterType].splice(i, 1);
                }
            }
        }

        this.setState(
            {
                selectedFilters: selectedFilters,
                appliedFilters: JSON.parse(JSON.stringify(filters)),
                selectedFlightNumber: selectedFlightNumber,
                selectedFromCity: selectedFromCity,
                selectedToCity: selectedToCity,
                selectedTenant: selectedTenant,
                appliedFromCity: selectedFromCity,
                appliedToCity: selectedToCity,
                appliedFlightNumber: selectedFlightNumber,
                appliedTenant: appliedTenant
            },
            () => {
                this.toggleApplyFilterButton();
                this.sendUpdatedData();
                this.updateAllFilters();
            }
        );
    }

    handleDateRangeChange(selectedOption) {
        if (selectedOption.label === 'Custom') {
            let rangeOptions = [{ label: 'None', value: 'None' }];
            if (this.props.subscription !== 'Basic') {
                rangeOptions = [{ label: 'Same period a year ago', value: '' }];
            }

            if (this.state.customStartDate && this.state.customEndDate) {
                let selectedRange =
                    this.state.customStartDate.format('YYYYMMDD') + '-' + this.state.customEndDate.format('YYYYMMDD');
                let startClone = this.state.customStartDate.clone();
                let endClone = this.state.customEndDate.clone();
                endClone.subtract(12, 'months');
                startClone.subtract(12, 'months');

                let comparisonRange = startClone.format('YYYYMMDD') + '-' + endClone.format('YYYYMMDD');
                let comparisonRangeOptions = [{ label: 'Same period a year ago', value: comparisonRange }];

                this.setState(
                    {
                        dateRangeValue: selectedRange,
                        comparisonRangeOptions: comparisonRangeOptions,
                        comparisonRangeValue: comparisonRange,
                        comparisonRangeText: comparisonRangeOptions[0]['label'],
                        customComparisonEndDate: endClone,
                        customComparisonStartDate: startClone,
                        forceSendUpdatedData: true,
                        dateRangeText: selectedOption.label,
                        displayDateRangeSelector: true
                    },
                    () => this.sendUpdatedData()
                );
            } else {
                this.setState({
                    comparisonRangeOptions: rangeOptions,
                    comparisonRangeValue: rangeOptions[0].value,
                    dateRangeValue: selectedOption.value,
                    dateRangeText: selectedOption.label,
                    displayDateRangeSelector: true
                });
            }
        } else {
            this.setState({
                dateRangeValue: selectedOption.value,
                dateRangeText: selectedOption.label,
                displayDateRangeSelector: false
            });

            let dateRange = this.state.dateRangeFiltersData.filter(
                (dateRange) => dateRange.value === selectedOption.value
            );

            let comparisonRanges = [{ label: 'None', value: 'None' }];
            let defaultComparisionRange = 'None';
            let comparisonRangeText = '';

            if (
                dateRange.length > 0 &&
                dateRange[0].comparison_ranges &&
                Object.keys(dateRange[0].comparison_ranges).length > 0
            ) {
                comparisonRanges = dateRange[0].comparison_ranges;
                defaultComparisionRange = dateRange[0].comparison_ranges[0].value;
                comparisonRangeText = dateRange[0].comparison_ranges[0].label;
            }

            this.setState(
                {
                    dateRangeValue: selectedOption.value,
                    dateRangeText: selectedOption.label,
                    comparisonRangeOptions: comparisonRanges,
                    comparisonRangeValue: defaultComparisionRange,
                    comparisonRangeText: comparisonRangeText
                },
                () => this.sendUpdatedData()
            );

            if (this.state.expanded) {
                setTimeout(
                    function () {
                        this.accordionRef.current.click();
                    }.bind(this),
                    500
                );
            }
        }
    }

    handleComparisonRangeChange(selectedOption) {
        this.setState({ comparisonRangeValue: selectedOption.value, comparisonRangeText: selectedOption.label }, () =>
            this.sendUpdatedData(true)
        );
        if (this.state.expanded) {
            setTimeout(
                function () {
                    this.accordionRef.current.click();
                }.bind(this),
                500
            );
        }
    }

    sendUpdatedData(onlyComparisonRangeUpdated) {
        if (this.state.parentLoading) {
            this.setState({ parentLoading: false });
        }

        let allAppliedFilters = DynamicFilterHelper.getAllAppliedFilters(this.state, this.props.title);
        let currentAppliedFilters = allAppliedFilters.all_applied_filters;
        let cleanedAppliedFilters = allAppliedFilters.cleaned_applied_filters; // keep this variable for future use.
        if (!_.isEqual(this.state.prevAppliedFilters, currentAppliedFilters) || this.state.forceSendUpdatedData) {
            DynamicFilterHelper.setSelectedFiltersToStore(this.state);
            this.setState({ prevAppliedFilters: currentAppliedFilters, forceSendUpdatedData: false }, () =>
                this.props.onSelectionUpdated(currentAppliedFilters, onlyComparisonRangeUpdated)
            );
        }
    }

    getSelectedFiltersFromStore() {
        let selectedFiltersFromStore = JSON.parse(window.sessionStorage.getItem('pac_next_insights_selected_filters'));

        if (selectedFiltersFromStore) {
            ['customStartDate', 'customEndDate', 'customComparisonStartDate', 'customComparisonEndDate'].forEach(
                (dateOption) => {
                    if (selectedFiltersFromStore[dateOption]) {
                        selectedFiltersFromStore[dateOption] = moment(selectedFiltersFromStore[dateOption]);
                    }
                }
            );
        }

        return selectedFiltersFromStore;
    }

    async handleFromCityChange(selectedOption) {
        if (selectedOption === null) {
            let filters = this.state.selectedFilters;
            if (filters.fromCity.length > 0) {
                filters.fromCity = [];
            }
            this.setState({
                selectedFilters: filters,
                selectedFromCity: null,
                applyFilterButtonDisabled: true,
                clearFilterButtonDisabled: true
            });
        } else if (selectedOption) {
            let filters = this.state.selectedFilters;
            let index = filters.fromCity.indexOf(selectedOption.value);
            if (index < 0) {
                filters.fromCity = [];
                filters.fromCity.push(selectedOption.value);
            }
            //this.setState({selectedFromCity: selectedOption.value}, () => this.toggleApplyFilterButton());
            this.setState({
                selectedFilters: filters,
                selectedFromCity: selectedOption.value,
                applyFilterButtonDisabled: true,
                clearFilterButtonDisabled: true
            });
            this.toggleApplyFilterButton();
        }

        this.updateAllFilters();
    }

    async handleToCityChange(selectedOption) {
        if (selectedOption === null) {
            let filters = this.state.selectedFilters;
            if (filters.toCity.length > 0) {
                filters.toCity = [];
            }
            this.setState({
                selectedFilters: filters,
                selectedToCity: null,
                applyFilterButtonDisabled: true,
                clearFilterButtonDisabled: true
            });
        } else if (selectedOption) {
            let filters = this.state.selectedFilters;
            let index = filters.toCity.indexOf(selectedOption.value);
            if (index < 0) {
                filters.toCity = [];
                filters.toCity.push(selectedOption.value);
            }
            this.setState({
                selectedFilters: filters,
                selectedToCity: selectedOption.value,
                applyFilterButtonDisabled: true,
                clearFilterButtonDisabled: true
            });
            this.toggleApplyFilterButton();
        }

        this.updateAllFilters();
    }

    handleFlightNumberChange = (selectedOption) => {
        if (selectedOption === null) {
            let filters = this.state.selectedFilters;
            if (filters.flightNumber.length > 0) {
                filters.flightNumber = [];
            }
            this.setState({ selectedFilters: filters, selectedFlightNumber: null }, () =>
                this.toggleApplyFilterButton()
            );
        } else if (selectedOption) {
            let filters = this.state.selectedFilters;
            let index = filters.flightNumber.indexOf(selectedOption.value);
            if (index < 0) {
                filters.flightNumber = [];
                filters.flightNumber.push(selectedOption.value);
            }

            this.setState({ selectedFilters: filters, selectedFlightNumber: selectedOption.value }, () =>
                this.toggleApplyFilterButton()
            );
        }

        this.updateAllFilters();
    };

    handleTailChange = (selectedOption) => {
        let filters = this.state.selectedFilters;
        let index = filters.tail.indexOf(selectedOption);
        if (index >= 0) {
            for (let i = 0; i < filters.tail.length; i++) {
                if (filters.tail[i] === selectedOption) {
                    filters.tail.splice(i, 1);
                }
            }
        } else {
            filters.tail.push(selectedOption);
        }
        this.setState({ selectedFilters: filters, tailOptions: this.state.allTailOptions });
        this.toggleApplyFilterButton();

        this.updateAllFilters();
    };

    clearTailFilter = (selectedOption) => {
        let filters = this.state.selectedFilters;
        filters.tail = [];
        this.setState({ selectedFilters: filters, multiSelectFilterLabel: 'Select Tail' });
        this.toggleApplyFilterButton();

        this.updateAllFilters();
    };

    handleTailFilter = (selectedOption) => {
        if (this.state.allTailOptions && this.state.allTailOptions.length > 0 && selectedOption) {
            var filteredTails = this.state.allTailOptions.filter(function (el) {
                return el.value.toLowerCase().includes(selectedOption.toLowerCase());
            });
            if (filteredTails.length > 0) {
                this.setState({ tailOptions: filteredTails });
            }
        } else {
            this.setState({ tailOptions: this.state.allTailOptions });
        }
    };

    async handleRegionChange(selectedOption) {
        console.log('handleRegionChange');
        this.setState({ selectedRegion: selectedOption }, () => {
            this.toggleApplyFilterButton();
        });
    }

    async handleTenantChange(selectedOption) {
        sessionStorage.setItem('all_tenant_view_selected_tenant', JSON.stringify(selectedOption));
        sessionStorage.removeItem('pac_next_insights_selected_filters');
        sessionStorage.removeItem('pac_next_insights_tail_selected_filters');

        this.setState(
            {
                selectedTenant: selectedOption,
                selectedLiveTVChannel: { label: 'All Channels', value: 'ALL' },
                filterLoading: true,
                selectedFilters: {
                    systemType: [],
                    aircraftType: [],
                    flightDuration: [],
                    seatClass: [],
                    guiLanguage: [],
                    tail: [],
                    toCity: [],
                    fromCity: [],
                    flightNumber: []
                }
            },
            async () => {
                await this.fetchFilterValuesPromise(false, true);
                let stateToUpdate = { filterLoading: false, forceSendUpdatedData: true };
                this.setState(stateToUpdate, () => {
                    this.toggleApplyFilterButton();
                });
            }
        );
    }

    async handleLiveTVChannelChange(selectedOption) {
        this.setState({ selectedLiveTVChannel: selectedOption }, () => {
            this.toggleApplyFilterButton();
        });
    }

    toggleApplyFilterButton() {
        let disableApplyButton = true;
        let disableClearButton = true;
        let hasFilterTenantChanged =
            this.inAllTenantView && this.state.selectedTenant.label !== this.state.appliedTenant.label;
        let hasLiveTVChannelChanged =
            this.inAllTenantView && this.state.selectedLiveTVChannel.label !== this.state.appliedLiveTVChannel.label;
        let hasRegionChanged =
            this.inAllTenantView && this.state.selectedRegion.label !== this.state.appliedRegion.label;

        for (let [key, value] of Object.entries(this.state.selectedFilters)) {
            if (value.length > 0) {
                disableApplyButton = false;
                disableClearButton = false;
                break;
            }
        }

        if (disableApplyButton) {
            if (DynamicFilterHelper.atleastOneRoutePropSelected(this.state)) {
                disableApplyButton = false;
            }
        }

        if (disableApplyButton) {
            if (hasFilterTenantChanged || hasLiveTVChannelChanged || hasRegionChanged) {
                disableApplyButton = false;
                disableClearButton = false;
            }
        }

        if (disableClearButton) {
            if (
                DynamicFilterHelper.atleastOneRoutePropSelected(this.state) ||
                DynamicFilterHelper.atleastOneRoutePropApplied(this.state)
            ) {
                disableClearButton = false;
            }
        }

        if (disableClearButton || this.inAllTenantView) {
            for (let [key, value] of Object.entries(this.state.appliedFilters)) {
                if (value.length > 1) {
                    disableClearButton = false;
                    break;
                }
            }
        }

        this.setState({ applyFilterButtonDisabled: disableApplyButton, clearFilterButtonDisabled: disableClearButton });
    }

    onDatesChange({ startDate, endDate }) {
        this.setState({
            customStartDate: startDate,
            customEndDate: endDate
        });

        // The 'Done' button in DateRangePicker component does not provide a callback.
        // Using the DOM events to check when the calendar view is closed by the Done button click.
        let elements = document.getElementsByClassName('DateRangePicker_picker');
        document.body.addEventListener('DOMNodeRemoved', (e) => {
            if (e.target === elements[0]) {
                if (!(startDate && endDate)) {
                    startDate = moment().subtract(1, 'months').startOf('month');
                    endDate = moment().subtract(1, 'months').endOf('month');
                }

                let selectedRange = startDate.format('YYYYMMDD') + '-' + endDate.format('YYYYMMDD');

                let startClone = startDate.clone();
                let endClone = endDate.clone();
                endClone.subtract(12, 'months');
                startClone.subtract(12, 'months');

                let comparisonRange = startClone.format('YYYYMMDD') + '-' + endClone.format('YYYYMMDD');
                let comparisonRangeOptions = [{ label: 'Same period a year ago', value: comparisonRange }];

                this.setState(
                    {
                        dateRangeValue: selectedRange,
                        comparisonRangeOptions: comparisonRangeOptions,
                        comparisonRangeValue: comparisonRange,
                        comparisonRangeText: comparisonRangeOptions[0]['label'],
                        customComparisonEndDate: endClone,
                        customComparisonStartDate: startClone,
                        customStartDate: startDate,
                        customEndDate: endDate
                    },
                    () => this.sendUpdatedData()
                );

                if (this.state.expanded) {
                    setTimeout(
                        function () {
                            this.accordionRef.current.click();
                        }.bind(this),
                        500
                    );
                }
            }
        });
    }

    outsideCustomDateRange = (day) => {
        if (this.state.customDateRange) {
            if (
                day.isAfter(moment(this.state.customDateRange.date_range_end), 'day') ||
                day.isBefore(moment(this.state.customDateRange.date_range_start), 'day')
            ) {
                return true;
            }
        }

        return false;
    };

    handleApplyFilterClick = (e) => {
        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(this.state.selectedFilters));
        let stateToUpdate = { appliedFilters: selectedFiltersDeepClone };

        if (this.state.selectedFromCity || this.state.selectedToCity || this.state.selectedFlightNumber) {
            stateToUpdate = {
                ...stateToUpdate,
                appliedFromCity: this.state.selectedFromCity,
                appliedToCity: this.state.selectedToCity,
                appliedFlightNumber: this.state.selectedFlightNumber
            };
        }

        if (this.inAllTenantView) {
            sessionStorage.setItem('pac_selected_tenant', JSON.stringify(this.state.selectedTenant));
            sessionStorage.setItem('pac_selected_tenant_icao_code', this.state.selectedTenant.icaoCode);
            sessionStorage.setItem('pac_selected_tenant_id', this.state.selectedTenant.value);
            sessionStorage.setItem('channel_title', this.state.selectedLiveTVChannel.value);
            sessionStorage.setItem('region', this.state.selectedRegion.value);
            stateToUpdate.appliedFilters['tenant'] = [this.state.selectedTenant.label];
            stateToUpdate.appliedFilters['liveTVChannel'] = [this.state.selectedLiveTVChannel.label];
            stateToUpdate.appliedFilters['region'] = [this.state.selectedRegion.label];
            stateToUpdate = {
                ...stateToUpdate,
                appliedTenant: this.state.selectedTenant,
                appliedLiveTVChannel: this.state.selectedLiveTVChannel,
                appliedRegion: this.state.selectedRegion
            };
        }

        this.setState(stateToUpdate, () => {
            this.toggleApplyFilterButton();

            this.sendUpdatedData();
        });

        setTimeout(
            function () {
                this.accordionRef.current.click();
            }.bind(this),
            500
        );

        this.updateAllFilters();
    };

    clearFilters() {
        if (this.state.defaultDateRangeOption) {
            this.handleDateRangeChange(this.state.defaultDateRangeOption);
        }

        let stateToUpdate = {
            appliedFilters: this.inAllTenantView
                ? { tenant: [allTenantOption.label], liveTVChannel: ['All Channels'], region: ['All Regions'] }
                : {},
            selectedFilters: {
                systemType: [],
                aircraftType: [],
                flightDuration: [],
                seatClass: [],
                guiLanguage: [],
                tail: [],
                toCity: [],
                fromCity: [],
                flightNumber: []
            },
            appliedFromCity: null,
            appliedToCity: null,
            appliedFlightNumber: null,
            appliedTenant: allTenantOption,
            appliedLiveTVChannel: { label: 'All Channels', value: 'ALL' },
            appliedRegion: { label: 'All Regions', value: 'ALL' },
            selectedFromCity: null,
            selectedToCity: null,
            selectedFlightNumber: null,
            selectedTenant: allTenantOption,
            selectedLiveTVChannel: { label: 'All Channels', value: 'ALL' },
            selectedRegion: { label: 'All Regions', value: 'ALL' },
            filterLoading: true
        };

        if (this.inAllTenantView) {
            sessionStorage.setItem('pac_selected_tenant', JSON.stringify(allTenantOption));
            sessionStorage.setItem('all_tenant_view_selected_tenant', JSON.stringify(allTenantOption));
            sessionStorage.setItem('pac_selected_tenant_icao_code', allTenantOption.icaoCode);
            sessionStorage.setItem('pac_selected_tenant_id', allTenantOption.value);
            sessionStorage.setItem('channel_title', 'ALL');
            sessionStorage.setItem('region', 'ALL');
        }

        this.setState(stateToUpdate, async () => {
            this.toggleApplyFilterButton();
            if (this.inAllTenantView) {
                await this.fetchFilterValuesPromise(false, true);
                this.sendUpdatedData();
                this.setState({ filterLoading: false });
            } else {
                this.sendUpdatedData();
            }
        });

        this.updateAllFilters();
    }

    handleClearFilterClick = (e) => {
        this.clearFilters();
    };

    render() {
        let toggleIcon = this.state.expanded ? (
            <div>
                <i className="fa fa-chevron-up custom-fa"></i>
                <br />
                <span className="hidden">Add Filters</span>
            </div>
        ) : (
            <div>
                <i className="fa fa-plus custom-fa"></i>
                <br />
                <span>Add Filters</span>
            </div>
        );

        let tenants = this.state.tenants;
        let liveTVChannels = this.state.liveTVChannels;

        return (
            <Accordion>
                <Row style={{ marginLeft: '0', marginRight: '0' }}>
                    <Card style={{ width: '100%' }}>
                        <Card.Header>
                            <Row>
                                <Col xs={10} md={10} lg={10}>
                                    <Row>
                                        <Col xs={12} md={5} lg={5}>
                                            <div
                                                style={{
                                                    fontFamily: 'Inter SemiBold',
                                                    fontSize: 16,
                                                    color: '#3E8ACD',
                                                    marginBottom: 4
                                                }}
                                            >
                                                Date Range
                                            </div>
                                            <Select
                                                options={this.state.dateRangeOptions}
                                                menuPortalTarget={document.body}
                                                value={this.state.dateRangeOptions.find((option) => {
                                                    return option.label === this.state.dateRangeText;
                                                })}
                                                getOptionValue={(option) => option.label}
                                                onChange={this.handleDateRangeChange}
                                                classNamePrefix="select"
                                                maxMenuHeight={370}
                                                components={{ DropdownIndicator }}
                                                styles={DynamicFilterHelper.getDropdownStyle()}
                                            />
                                            {this.state.dateRangeOptions.length > 0 ? (
                                                this.state.displayDateRangeSelector ? (
                                                    <DateRangePicker
                                                        disabled={false}
                                                        displayFormat="MM/DD/YYYY"
                                                        endDate={this.state.customEndDate}
                                                        endDatePlaceholderText="mm/dd/yyyy"
                                                        numberOfMonths={2}
                                                        onDatesChange={this.onDatesChange}
                                                        startDate={this.state.customStartDate}
                                                        startDatePlaceholderText="mm/dd/yyyy"
                                                        isOutsideRange={this.outsideCustomDateRange}
                                                        firstDayOfWeek={1}
                                                        minimumNights={0}
                                                    />
                                                ) : (
                                                    <div style={{ marginTop: 4 }}>
                                                        <span className="filter-date-desc">
                                                            {DynamicFilterHelper.getDateDescription(
                                                                this.state.dateRangeValue
                                                            )}
                                                        </span>
                                                    </div>
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </Col>
                                        {this.props.showWorkbenchLink &&
                                            this.state.comparisonRangeOptions &&
                                            this.state.comparisonRangeOptions.length > 0 && (
                                                <Col xs={12} md={6} lg={6}>
                                                    <div
                                                        style={{
                                                            fontFamily: 'Inter SemiBold',
                                                            fontSize: 16,
                                                            color: '#3E8ACD',
                                                            marginBottom: 4
                                                        }}
                                                    >
                                                        Comparison Range
                                                    </div>
                                                    <Select
                                                        options={this.state.comparisonRangeOptions}
                                                        menuPortalTarget={document.body}
                                                        value={this.state.comparisonRangeOptions.filter(
                                                            ({ value }) => value === this.state.comparisonRangeValue
                                                        )}
                                                        onChange={this.handleComparisonRangeChange}
                                                        isDisabled={this.state.comparisonRangeValue === 'None'}
                                                        classNamePrefix="select"
                                                        components={{ DropdownIndicator }}
                                                        styles={DynamicFilterHelper.getDropdownStyle()}
                                                    />
                                                    {this.state.comparisonRangeOptions[0].value !== 'None' &&
                                                    this.state.comparisonRangeValue !== '' ? (
                                                        this.state.dateRangeText === 'Custom' ? (
                                                            <DateRangePicker
                                                                disabled={true}
                                                                displayFormat="MM/DD/YYYY"
                                                                endDate={this.state.customComparisonEndDate}
                                                                startDate={this.state.customComparisonStartDate}
                                                                onDatesChange={(a, b) => {
                                                                    return;
                                                                }}
                                                            />
                                                        ) : (
                                                            <div style={{ marginTop: 4 }}>
                                                                <span className="filter-date-desc">
                                                                    {DynamicFilterHelper.getDateDescription(
                                                                        this.state.comparisonRangeValue
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </Col>
                                            )}
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={10} lg={10}>
                                            <span className="info">
                                                {DynamicFilterHelper.getDateInfoMessage(this.state.dateRangeText)}
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    xs={2}
                                    md={2}
                                    lg={2}
                                    style={{
                                        display: this.state.displayFilters ? 'block' : 'none'
                                    }}
                                >
                                    <Accordion.Toggle
                                        eventKey="1"
                                        className="AccordionButton"
                                        ref={this.accordionRef}
                                        onClick={() => this.handleAccordionClick()}
                                    >
                                        {toggleIcon}
                                    </Accordion.Toggle>
                                </Col>
                            </Row>

                            {this.state.displayFilters && (
                                <div className="applied-filter-container">
                                    {Object.entries(this.state.appliedFilters).map(([filterType, filterValues]) => {
                                        if (filterType === 'liveTVChannel' && !(this.props.app === 'Live TV')) {
                                            return null;
                                        } else {
                                            if (Array.isArray(filterValues)) {
                                                return filterValues.map((value, index) => {
                                                    let filterNotExists = DynamicFilterHelper.isAppliedFilterNotExist(
                                                        filterType,
                                                        value,
                                                        this.props.title
                                                    );
                                                    return (
                                                        <Chip
                                                            disabled={false}
                                                            className={
                                                                filterNotExists ? 'filter-chip disabled' : 'filter-chip'
                                                            }
                                                            key={index}
                                                            onClick={(e) =>
                                                                this.handleChipButton(
                                                                    filterType,
                                                                    value,
                                                                    null,
                                                                    filterNotExists
                                                                )
                                                            }
                                                        >
                                                            {display_names[filterType]}: {value}
                                                        </Chip>
                                                    );
                                                });
                                            }
                                        }
                                    })}
                                </div>
                            )}
                        </Card.Header>
                        {this.state.displayFilters && (
                            <Accordion.Collapse eventKey="1" className="accordion-collapse">
                                <Card.Body>
                                    <div>
                                        <Row>
                                            <Col>
                                                <ButtonPrimary
                                                    disabled={this.state.applyFilterButtonDisabled}
                                                    className="button-primary"
                                                    onClick={this.handleApplyFilterClick}
                                                >
                                                    Apply Filter
                                                </ButtonPrimary>{' '}
                                                <ButtonSecondary
                                                    disabled={this.state.clearFilterButtonDisabled}
                                                    className="button-secondary margin-left-10"
                                                    onClick={this.handleClearFilterClick}
                                                >
                                                    Clear All
                                                </ButtonSecondary>{' '}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <React.Fragment>
                                                <Col xs={12} md={4} lg={3}>
                                                    <div style={{ marginTop: '20px' }}>
                                                        <Card.Title>Airline</Card.Title>
                                                        <Card>
                                                            <Select
                                                                className="selectBox"
                                                                options={tenants}
                                                                isDisabled={this.state.filterLoading}
                                                                value={tenants.find(
                                                                    (tenant) =>
                                                                        this.state.selectedTenant &&
                                                                        tenant &&
                                                                        tenant.label === this.state.selectedTenant.label
                                                                )}
                                                                onChange={this.handleTenantChange}
                                                                hideSelectedOptions
                                                                defaultValue={tenants.find(
                                                                    (tenant) =>
                                                                        tenant.icaoCode === allTenantOption.icaoCode
                                                                )}
                                                                classNamePrefix="select"
                                                                maxMenuHeight={200}
                                                                components={{ DropdownIndicator }}
                                                                styles={{
                                                                    dropdownIndicator: (provided, state) => ({
                                                                        ...provided,
                                                                        fontSize: '14px'
                                                                    }),
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        fontSize: '15px',
                                                                        zIndex: '2'
                                                                    }),
                                                                    option: (
                                                                        styles,
                                                                        { data, isDisabled, isFocused, isSelected }
                                                                    ) => {
                                                                        return {
                                                                            ...styles,
                                                                            backgroundColor: isSelected
                                                                                ? '#ebebeb'
                                                                                : isFocused
                                                                                ? '#f6f6f6'
                                                                                : null,
                                                                            color: '#333333'
                                                                        };
                                                                    }
                                                                }}
                                                            />
                                                        </Card>
                                                    </div>

                                                    <div style={{ marginTop: '20px' }}>
                                                        <Card.Title>Region</Card.Title>
                                                        <Card>
                                                            <Select
                                                                className="selectBox"
                                                                options={this.state.regions}
                                                                isDisabled={this.state.filterLoading}
                                                                value={this.state.regions.find(
                                                                    (region) =>
                                                                        this.state.selectedRegion &&
                                                                        region &&
                                                                        region.label === this.state.selectedRegion.label
                                                                )}
                                                                onChange={this.handleRegionChange}
                                                                hideSelectedOptions
                                                                defaultValue={this.state.regions.find(
                                                                    (region) => region.id === allRegionOption.id
                                                                )}
                                                                classNamePrefix="select"
                                                                maxMenuHeight={200}
                                                                components={{ DropdownIndicator }}
                                                                styles={{
                                                                    dropdownIndicator: (provided, state) => ({
                                                                        ...provided,
                                                                        fontSize: '14px'
                                                                    }),
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        fontSize: '15px',
                                                                        zIndex: '2'
                                                                    }),
                                                                    option: (
                                                                        styles,
                                                                        { data, isDisabled, isFocused, isSelected }
                                                                    ) => {
                                                                        return {
                                                                            ...styles,
                                                                            backgroundColor: isSelected
                                                                                ? '#ebebeb'
                                                                                : isFocused
                                                                                ? '#f6f6f6'
                                                                                : null,
                                                                            color: '#333333'
                                                                        };
                                                                    }
                                                                }}
                                                            />
                                                        </Card>
                                                    </div>

                                                    {this.props.title === 'Live TV' && (
                                                        <div style={{ marginTop: '20px' }}>
                                                            <Card.Title>Live TV Channels</Card.Title>
                                                            <Card>
                                                                <Select
                                                                    className="selectBox"
                                                                    options={liveTVChannels}
                                                                    isDisabled={this.state.filterLoading}
                                                                    value={liveTVChannels.find(
                                                                        (channel) =>
                                                                            this.state.selectedLiveTVChannel &&
                                                                            channel &&
                                                                            channel.label ===
                                                                                this.state.selectedLiveTVChannel.label
                                                                    )}
                                                                    onChange={this.handleLiveTVChannelChange}
                                                                    hideSelectedOptions
                                                                    defaultValue={liveTVChannels.find(
                                                                        (channel) =>
                                                                            channel.value ===
                                                                                this.state.selectedLiveTVChannel
                                                                                    .value || channel.value === 'ALL'
                                                                    )}
                                                                    classNamePrefix="select"
                                                                    maxMenuHeight={200}
                                                                    components={{ DropdownIndicator }}
                                                                    styles={{
                                                                        dropdownIndicator: (provided, state) => ({
                                                                            ...provided,
                                                                            fontSize: '14px'
                                                                        }),
                                                                        menu: (base) => ({
                                                                            ...base,
                                                                            fontSize: '15px',
                                                                            zIndex: '2'
                                                                        }),
                                                                        option: (
                                                                            styles,
                                                                            { data, isDisabled, isFocused, isSelected }
                                                                        ) => {
                                                                            return {
                                                                                ...styles,
                                                                                backgroundColor: isSelected
                                                                                    ? '#ebebeb'
                                                                                    : isFocused
                                                                                    ? '#f6f6f6'
                                                                                    : null,
                                                                                color: '#333333'
                                                                            };
                                                                        }
                                                                    }}
                                                                />
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Col>
                                            </React.Fragment>

                                            {this.state.filterLoading ? (
                                                <React.Fragment>
                                                    <Col xs={12} md={8} lg={9}>
                                                        <div className="custom-loading">
                                                            <div style={{ textAlign: 'center', height: '100%' }}>
                                                                <img
                                                                    src={Circular}
                                                                    alt=""
                                                                    style={{
                                                                        position: 'relative',
                                                                        top: '50%',
                                                                        transform: 'translateY(-120%)'
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Col xs={12} md={4} lg={3}>
                                                        {this.state.systemTypes.length > 0 && (
                                                            <div style={{ marginTop: '20px' }}>
                                                                <Card.Title>System Type</Card.Title>
                                                                <Card>
                                                                    <ul className="filters-checkbox-list">
                                                                        {this.state.systemTypes.map((item, index) => (
                                                                            <li
                                                                                key={index + '_li_system_type'}
                                                                                data-item={item}
                                                                            >
                                                                                <Checkbox
                                                                                    name={item.value}
                                                                                    key={item.value}
                                                                                    style={{ float: 'left' }}
                                                                                    marked={
                                                                                        this.state.selectedFilters.systemType.includes(
                                                                                            item.value
                                                                                        )
                                                                                            ? CheckboxSelectVariant.selected
                                                                                            : CheckboxSelectVariant.unselected
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        this.handleCheckBoxChange(
                                                                                            'systemType',
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <div className="checkbox-title">
                                                                                    {item.value}
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </Card>
                                                            </div>
                                                        )}

                                                        <div style={{ marginTop: '20px' }}>
                                                            {this.props.showWorkbenchLink &&
                                                                this.state.aircraftTypes.length > 0 && (
                                                                    <React.Fragment>
                                                                        <Card.Title>Aircraft Type/Subtype</Card.Title>
                                                                        <Card>
                                                                            <ul className="filters-checkbox-list">
                                                                                {this.state.aircraftTypes.map(
                                                                                    (item, index) => (
                                                                                        <li
                                                                                            key={
                                                                                                index +
                                                                                                '_li_aircraft_type'
                                                                                            }
                                                                                        >
                                                                                            <Checkbox
                                                                                                name={item.value}
                                                                                                key={item.value}
                                                                                                style={{
                                                                                                    float: 'left'
                                                                                                }}
                                                                                                marked={
                                                                                                    this.state.selectedFilters.aircraftType.includes(
                                                                                                        item.value
                                                                                                    )
                                                                                                        ? CheckboxSelectVariant.selected
                                                                                                        : CheckboxSelectVariant.unselected
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    this.handleCheckBoxChange(
                                                                                                        'aircraftType',
                                                                                                        e
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            <div className="checkbox-title">
                                                                                                {item.value}
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </Card>
                                                                    </React.Fragment>
                                                                )}
                                                        </div>
                                                    </Col>

                                                    {!this.props.showWorkbenchLink &&
                                                        this.state.aircraftTypes.length > 0 && (
                                                            <Col xs={12} md={4} lg={3}>
                                                                <div style={{ marginTop: '20px' }}>
                                                                    <Card.Title>Aircraft Type/Subtype</Card.Title>
                                                                    <Card>
                                                                        <ul className="filters-checkbox-list">
                                                                            {this.state.aircraftTypes.map(
                                                                                (item, index) => (
                                                                                    <li
                                                                                        key={
                                                                                            index + '_li_aircraft_type'
                                                                                        }
                                                                                    >
                                                                                        <Checkbox
                                                                                            name={item.value}
                                                                                            key={item.value}
                                                                                            style={{ float: 'left' }}
                                                                                            marked={
                                                                                                this.state.selectedFilters.aircraftType.includes(
                                                                                                    item.value
                                                                                                )
                                                                                                    ? CheckboxSelectVariant.selected
                                                                                                    : CheckboxSelectVariant.unselected
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                this.handleCheckBoxChange(
                                                                                                    'aircraftType',
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        <div className="checkbox-title">
                                                                                            {item.value}
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    </Card>
                                                                </div>
                                                            </Col>
                                                        )}

                                                    <Col xs={12} md={4} lg={3}>
                                                        {this.state.tailOptions.length > 0 ? (
                                                            <div className="margin-top-20">
                                                                <Card.Title>Tail</Card.Title>
                                                                <div className="tail-select">
                                                                    <DropdownMultiple
                                                                        label={'Select Tail'}
                                                                        options={this.state.tailOptions}
                                                                        selected={this.state.selectedFilters.tail}
                                                                        onChange={this.handleTailChange}
                                                                        filterLabel={this.state.multiSelectFilterLabel}
                                                                        onClear={this.clearTailFilter}
                                                                        onFilter={this.handleTailFilter}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="margin-top-20">
                                                                <Card.Title>&nbsp;</Card.Title>
                                                            </div>
                                                        )}

                                                        <div
                                                            style={{
                                                                marginTop:
                                                                    this.state.tailOptions.length > 0 ? '20px' : '0px'
                                                            }}
                                                        >
                                                            {(this.state.fromCityOptions.length > 0 ||
                                                                this.state.toCityOptions.length > 0 ||
                                                                this.state.flightNumbersOptions.length > 0) && (
                                                                <Card style={{ padding: '10px' }}>
                                                                    {this.state.fromCityOptions.length > 0 && (
                                                                        <div>
                                                                            <Card.Title>Flight Origin</Card.Title>
                                                                            <Select
                                                                                options={this.state.fromCityOptions}
                                                                                menuPortalTarget={document.body}
                                                                                value={this.state.fromCityOptions.filter(
                                                                                    ({ value }) =>
                                                                                        value ===
                                                                                        this.state.selectedFromCity
                                                                                )}
                                                                                onChange={this.handleFromCityChange}
                                                                                hideSelectedOptions
                                                                                placeholder="Select Origin"
                                                                                classNamePrefix="select"
                                                                                maxMenuHeight={200}
                                                                                isClearable={true}
                                                                                components={{ DropdownIndicator }}
                                                                                styles={DynamicFilterHelper.getDropdownStyle()}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {this.state.toCityOptions.length > 0 && (
                                                                        <div className="margin-top-20">
                                                                            <Card.Title>Flight Destination</Card.Title>
                                                                            <Select
                                                                                options={this.state.toCityOptions}
                                                                                menuPortalTarget={document.body}
                                                                                value={this.state.toCityOptions.filter(
                                                                                    ({ value }) =>
                                                                                        value ===
                                                                                        this.state.selectedToCity
                                                                                )}
                                                                                onChange={this.handleToCityChange}
                                                                                hideSelectedOptions
                                                                                isDisabled={
                                                                                    this.state.selectedToCity ===
                                                                                        'None' ||
                                                                                    this.state.selectedToCity ===
                                                                                        'Loading...'
                                                                                }
                                                                                placeholder="Select Destination"
                                                                                classNamePrefix="select"
                                                                                maxMenuHeight={200}
                                                                                isClearable={true}
                                                                                components={{ DropdownIndicator }}
                                                                                styles={DynamicFilterHelper.getDropdownStyle()}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {this.state.flightNumbersOptions.length > 0 && (
                                                                        <div>
                                                                            <Card.Title className="margin-top-20">
                                                                                Flight Number
                                                                            </Card.Title>
                                                                            <Select
                                                                                options={
                                                                                    this.state.flightNumbersOptions
                                                                                }
                                                                                menuPortalTarget={document.body}
                                                                                value={this.state.flightNumbersOptions.filter(
                                                                                    ({ value }) =>
                                                                                        value ===
                                                                                        this.state.selectedFlightNumber
                                                                                )}
                                                                                onChange={this.handleFlightNumberChange}
                                                                                hideSelectedOptions
                                                                                isDisabled={
                                                                                    this.state.selectedFlightNumber ===
                                                                                        'None' ||
                                                                                    this.state.selectedFlightNumber ===
                                                                                        'Loading...' ||
                                                                                    this.state.selectedTenant
                                                                                        .icaoCode ===
                                                                                        allTenantOption.icaoCode
                                                                                }
                                                                                placeholder="Select Flight #"
                                                                                classNamePrefix="select"
                                                                                maxMenuHeight={200}
                                                                                isClearable={true}
                                                                                components={{ DropdownIndicator }}
                                                                                styles={DynamicFilterHelper.getDropdownStyle()}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Card>
                                                            )}
                                                        </div>
                                                    </Col>

                                                    <Col xs={12} md={4} lg={3}>
                                                        <div style={{ marginTop: '20px' }}>
                                                            {this.props.showWorkbenchLink &&
                                                                this.state.flightHaulTypes.length > 0 && (
                                                                    <React.Fragment>
                                                                        <Card.Title>Flight Haul</Card.Title>
                                                                        <Card>
                                                                            <ul className="filters-checkbox-list">
                                                                                {this.state.flightHaulTypes.map(
                                                                                    (item, index) => (
                                                                                        <li
                                                                                            key={
                                                                                                index +
                                                                                                '_li_flight_duration'
                                                                                            }
                                                                                        >
                                                                                            <Checkbox
                                                                                                name={item.value}
                                                                                                key={item.value}
                                                                                                style={{
                                                                                                    float: 'left'
                                                                                                }}
                                                                                                marked={
                                                                                                    this.state.selectedFilters.flightDuration.includes(
                                                                                                        item.value
                                                                                                    )
                                                                                                        ? CheckboxSelectVariant.selected
                                                                                                        : CheckboxSelectVariant.unselected
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    this.handleCheckBoxChange(
                                                                                                        'flightDuration',
                                                                                                        e
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            <div className="checkbox-title">
                                                                                                {item.value}
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </Card>
                                                                    </React.Fragment>
                                                                )}
                                                        </div>

                                                        <div style={{ marginTop: '20px' }}>
                                                            {this.props.showWorkbenchLink &&
                                                                this.state.seatClasses.length > 0 && (
                                                                    <React.Fragment>
                                                                        <Card.Title>Seat Class</Card.Title>
                                                                        <Card>
                                                                            <ul className="filters-checkbox-list">
                                                                                {this.state.seatClasses.map(
                                                                                    (item, index) => (
                                                                                        <li
                                                                                            key={
                                                                                                index +
                                                                                                '_li_seat_classes'
                                                                                            }
                                                                                        >
                                                                                            <Checkbox
                                                                                                name={item.value}
                                                                                                key={item.value}
                                                                                                style={{
                                                                                                    float: 'left'
                                                                                                }}
                                                                                                marked={
                                                                                                    this.state.selectedFilters.seatClass.includes(
                                                                                                        item.value
                                                                                                    )
                                                                                                        ? CheckboxSelectVariant.selected
                                                                                                        : CheckboxSelectVariant.unselected
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    this.handleCheckBoxChange(
                                                                                                        'seatClass',
                                                                                                        e
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            <div className="checkbox-title">
                                                                                                {item.value}
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </Card>
                                                                    </React.Fragment>
                                                                )}
                                                        </div>
                                                    </Col>
                                                </React.Fragment>
                                            )}
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        )}
                    </Card>
                </Row>
            </Accordion>
        );
    }
}

export default DynamicFilter;
