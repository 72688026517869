import React from 'react';
import Select, { components } from 'react-select';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import QuicksightDataHelper from 'data/quicksight-data-helper.js';
import QuickSightEmbedUrlService from 'services/QuickSightEmbedUrlService';
import Circular from 'assets/images/icons/circular.gif';
import DynamicFilterHelper from '../../data/dynamic-filter-helper';

const quicksightDataHelper = new QuicksightDataHelper();

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faSearch : faCaretDown} />
            </components.DropdownIndicator>
        )
    );
};

class QuicksightWorkbenchComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            embedUrl: '',
            loading: true,
            contentType: '',
            options: [],
            selected: null,
            iframeIndex: 0,
            filters: '',
            filtersUpdated: false,
            iframeAttributes: {
                'overflow-y': 'auto',
                frameBorder: '0',
                height: '100%',
                width: '100%',
                id: 'quickSightDashboard',
                title: 'dashboard',
                src: ''
            }
        };
    }

    async componentDidMount() {
        this.props.tabFunction(0);
        this.setState({ filters: this.props.filters, filtersUpdated: true }, () => this.setDropdownValues());
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.path !== this.props.path) {
            this.setDropdownValues();
        }
    }

    setDropdownValues() {
        let options = quicksightDataHelper.getDropdownValues(this.props.path);
        let defaultSelected = options.filter(function (option, index) {
            return option.default;
        });

        this.setState(
            { options: options, selected: defaultSelected[0], contentType: defaultSelected[0].content_type },
            () => this.fetchQuickSightWorkbenchEmbeddedUrl()
        );
    }

    async fetchQuickSightWorkbenchEmbeddedUrl() {
        let dashboardId = this.state.selected.dashboard_id[process.env.REACT_APP_STAGE];
        let service = new QuickSightEmbedUrlService();

        await service
            .fetchUrl(dashboardId)
            .then((response) => {
                if (!response.arn) {
                    this.setState({ error: 'Data Unavailable' });
                } else {
                    this.setState({ embedUrl: response['embed-url'] }, () => this.buildQuicksightWorkbenchDashboard());
                }
            })
            .catch((error) => {
                console.error('onRejected function called: ' + error.message);
            });
    }

    buildQuicksightWorkbenchDashboard() {
        this.setState({ loading: true });

        if (this.state.embedUrl === '') {
            this.fetchQuickSightWorkbenchEmbeddedUrl();
        }

        if (this.state.embedUrl && this.state.selected && (this.state.filters || this.props.forceLoad)) {
            let quickSightUrl = this.state.embedUrl;
            quickSightUrl = quickSightUrl.replace('resetDisabled=true', 'resetDisabled=false');

            // Set 'ContentType' parameter.
            let contentTypeParam = '';
            if (['passenger_engagement', 'passenger_touch_points'].includes(this.state.selected.value)) {
                if (this.state.contentType !== '') {
                    contentTypeParam = '&p.ContentType=' + this.state.contentType;
                } else {
                    let allowedContentTypes = this.props.allowedContentTypes;

                    // Unique merged content types
                    if (
                        this.state.selected.include_content_types &&
                        Object.keys(this.state.selected.include_content_types).length > 0
                    ) {
                        if (allowedContentTypes && Object.keys(allowedContentTypes).length > 0) {
                            allowedContentTypes = allowedContentTypes.concat(this.state.selected.include_content_types);
                            allowedContentTypes = allowedContentTypes.filter((v, i, a) => a.indexOf(v) === i);
                        }
                    }
                    if (allowedContentTypes && Object.keys(allowedContentTypes).length > 0) {
                        for (let i = 0; i < allowedContentTypes.length; i++) {
                            contentTypeParam += '&p.ContentType=' + allowedContentTypes[i];
                        }
                    }
                }
            }

            // Set 'Tail' parameter.
            let selectedFilters = '';
            if (this.state.filters) {
                selectedFilters = JSON.parse(JSON.stringify(this.state.filters));
                if (selectedFilters) {
                    let filterList = selectedFilters.split('&');

                    filterList = filterList.filter((element) => {
                        return element !== '';
                    });

                    filterList = filterList.filter((element) => {
                        if (element.indexOf('Tail') > 0) {
                            let tailKeyValue = element.split('=');
                            if (tailKeyValue.length === 2) {
                                return element;
                            }
                        } else {
                            return element;
                        }
                    });

                    selectedFilters = '&' + filterList.join('&');
                }
            }

            let icaoCode = this.props.tenantIcaoCode.toUpperCase();
            let icaoCodeParam = '&p.AirlineOperatorCode=' + icaoCode; // todo uncoment after testing.

            let appParam = '&p.AppName=' + this.props.app;

            quickSightUrl +=
                '&#p.requestedSec=' +
                this.state.selected.value +
                selectedFilters +
                contentTypeParam +
                icaoCodeParam +
                appParam;
            if (quickSightUrl !== undefined) {
                let iframeAttributes = {
                    'overflow-y': 'auto',
                    frameBorder: '0',
                    height: '100%',
                    width: '100%',
                    id: 'quickSightDashboard',
                    title: 'dashboard',
                    src: quickSightUrl
                };
                this.setState({
                    loading: false,
                    filtersUpdated: false,
                    iframeAttributes: iframeAttributes,
                    embedUrl: '',
                    iframeIndex: this.state.iframeIndex + 1
                });
            }
        }
    }

    changeSelected(workbenchType) {
        if (workbenchType) {
            let changed = this.state.options.filter(function (option, index) {
                return option.value === workbenchType;
            });

            if (
                changed[0] &&
                (changed[0].value !== this.state.selected.value || this.state.loading || this.state.filtersUpdated)
            ) {
                this.handleDropdownChange(changed[0]);
            }
        } else {
            if (this.state.filtersUpdated) {
                this.buildQuicksightWorkbenchDashboard();
            }
        }
    }

    updateFilters(filters) {
        this.setState({ filters: filters, filtersUpdated: true }, () => this.buildQuicksightWorkbenchDashboard());
    }

    handleDropdownChange = (selectedOption) => {
        if (this.state.selected) {
            if (selectedOption) {
                this.setState({ selected: selectedOption }, () => this.buildQuicksightWorkbenchDashboard());
            }
        }
    };

    render() {
        let { options, selected } = this.state;
        return (
            <React.Fragment>
                <Row className="mt-3">
                    <Col md="12" className="quick-sight" style={{ overflow: 'auto' }}>
                        <div
                            style={{
                                height: selected && selected.iframe_height ? selected.iframe_height : '1315px',
                                width: selected && selected.iframe_width ? selected.iframe_width : '1032px'
                            }}
                        >
                            {this.state.loading && (
                                <div className="custom-loading" style={{ marginTop: '10%' }}>
                                    <img src={Circular} alt="" />
                                </div>
                            )}
                            {!this.state.loading && (
                                <iframe
                                    id="quickSightDashboard"
                                    key={this.state.iframeIndex}
                                    {...this.state.iframeAttributes}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default QuicksightWorkbenchComponent;
