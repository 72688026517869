import React from 'react';
import Util from 'util/UtilHelper';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'assets/sass/updatedComponents/lopa.scss';
import chevronLeftImage from 'assets/images/icons/chevronLeft.svg';
import chevronRightImage from 'assets/images/icons/chevronRight.svg';
import 'assets/sass/updatedComponents/lopa.scss';
import Select from 'react-select';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import SearchIcon from 'assets/images/icons/searchIcon.svg';
import sampleData from 'data/lopa_sample_data.js';

const utilHelper = new Util();

class Lopa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: sampleData,
            activeSeat: '',
            clickedSeat: '',
            flightList: [],
            activeRow: 0,
            searchTerm: '',
            tooltipPos: { x: 20, y: 20 },
            wizardStep: 1,
            activeScroll: 0,
            seatColors: ['#A1C9EB', '#4D98D9', '#2264A0', '#013253'],
            loadingFlights: false,
            flightStartDate: null,
            selectedSeatInfo: { label: 'Total Seat Usage Time', value: 'usage' },
            loadingSeats: false,
            flight: '',
            date: '',
            dateForHeader: '',
            flightStartTime: '',
            route: '',
            flightDuration: '',
            aircraftType: '',
            maxScroll: true,
            activeCalendarStartDate: null
        };
        this.handleSeatInfoTypeChange = this.handleSeatInfoTypeChange.bind(this);
    }

    componentDidMount() {
        this.buildDefaultLopa(this.state.data);
    }

    componentWillUpdate(newProps, newState) {
        let icon = document.getElementById('disclaimer-icon');
        if (icon) {
            if (newState.wizardStep == 4) {
                icon.style.display = 'initial';
            } else {
                icon.style.display = 'none';
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.startDate !== nextProps.startDate || this.props.endDate !== nextProps.endDate) {
            this.setState({
                activeCalendarStartDate: ''
            });
        }
        if (this.state.date && this.props != nextProps) {
            this.setState({
                data: sampleData,
                wizardStep: 2
            });
        }
    }

    rendomNumberGenerator = () => {
        return Math.round(Math.random() * 100000);
    };

    openToolTip = (value, e) => {
        try {
            var target = e.target;
            var rect = target.getBoundingClientRect();
            var parent = target.parentNode.parentNode.parentElement;

            var xPosition = parent.getBoundingClientRect().x + parent.clientWidth - rect.x;
            var yPosition = parent.getBoundingClientRect().y + parent.clientHeight - rect.y;
            var xMargin = xPosition > 338 ? 10 : -260;
            var yMargin = yPosition > 310 ? -50 : yPosition > 180 ? -130 : -205;
            target.style.color = target.style.backgroundColor == 'rgb(243, 245, 247)' ? '#000000' : '#FFFFFF';
            if (target.parentNode.childNodes[1]) {
                //
            }
            var tooltip =
                target.parentNode.childNodes[1].data == '' ? <div>No Data</div> : target.parentNode.childNodes[1];
            tooltip.classList.remove('hidden');
            tooltip.style.marginLeft = xMargin + 'px';
            tooltip.style.marginTop = yMargin + 'px';

            if (yMargin == -140 || yMargin == -100) {
            } else {
                tooltip.style.top = -40 + 'px';
            }
            this.setState({
                activeSeat: value
            });
        } catch (error) {
            //console.log(error)
        }
    };

    closeTooltip(e) {
        let tooltip = e.target.parentNode.childNodes[1];
        e.target.style.color = 'transparent';
        if (tooltip) {
            if (tooltip.parentNode.childNodes[1].classList) {
                tooltip.parentNode.childNodes[1].classList.add('hidden');
                this.setState({
                    activeSeat: ''
                });
            }
        }
    }

    formatRoute(route) {
        if (route) {
            return route.split('-').join(' - ');
        }
        return route;
    }

    formatDate(date, type) {
        var weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
        return weekdays[date.getDay()];
    }

    async setDate(date) {
        var months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        this.setState({
            date: months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear(),
            loadingFlights: true,
            searchTerm: '',
            flightStartDate: date,
            flightStartDateHeader: moment(date).format('ddd, M/D/YY'),
            flightList: []
        });
        let flightList = await this.props.getFlights(date);
        this.setState({
            flightList: flightList,
            wizardStep: 3,
            loadingFlights: false,
            date: months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear(),
            flightStartDate: date,
            flightStartDateHeader: moment(date).format('ddd, M/D/YY')
        });
    }

    async setFlight(flight) {
        let months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        this.setState({
            flight: flight.flight_number,
            flightStartTime: flight.flight_start_time,
            route: flight.route,
            flightDuration: flight.flight_duration,
            aircraftType: flight.aircraft_type
        });

        this.setState({
            loadingSeats: true,
            selectedSeatInfo: { label: 'Total Seat Usage Time', value: 'usage' }
        });
        let lopaData = await this.props.getFlightData(flight, this.state.flightStartDate);

        let flightDate =
            months[this.state.flightStartDate.getMonth()] +
            ' ' +
            this.state.flightStartDate.getDate() +
            ', ' +
            this.state.flightStartDate.getFullYear();

        if (this.state.date) {
            if (Object.keys(lopaData).length > 0) {
                this.props.setFlightInfo({
                    startTime: flight.flight_start_time,
                    date: flightDate,
                    flightNumber: flight.flight_number,
                    route: this.formatRoute(flight.route),
                    flightDuration: utilHelper.secondsToHoursMinutes(flight.flight_duration),
                    aircraftType: flight.aircraft_type
                });
                this.buildDefaultLopa(lopaData);
            } else {
                lopaData = sampleData;
                this.buildDefaultLopa(sampleData);
            }
            this.setState({
                searchTerm: '',
                wizardStep: 4,
                loadingSeats: false,
                data: lopaData
            });
        } else {
            this.buildDefaultLopa(sampleData);
            this.setState({
                searchTerm: '',
                wizardStep: 1,
                loadingSeats: false,
                data: sampleData
            });
        }
    }

    buildDefaultLopa(data) {
        let seatColors = this.state.seatColors; //["#A1C9EB", "#4D98D9", "#2264A0", "#013253"];
        var seatLettersArray = [];
        var lopaSeats = [];
        data = data.seatData;
        if (!data) {
            return;
        }
        for (var i = 0; i < data.length; i++) {
            let rowInfo = data[i];
            for (var s = 0; s < rowInfo.seats.length; s++) {
                var seat = rowInfo.seats[s];
                if (!seatLettersArray.includes(seat.letter)) {
                    seatLettersArray.push(seat.letter);
                }
            }
        }

        seatLettersArray = seatLettersArray.sort().reverse();

        var seatLetters = [];
        for (var i = 0; i < seatLettersArray.length; i++) {
            seatLetters.push(
                <div className="seat-letter" key={`seat-letter-${seatLettersArray[i]}`}>
                    {seatLettersArray[i]}
                </div>
            );
        }

        for (var i = 0; i < data.length; i++) {
            let rowInfo = data[i];
            if (i !== 0 && rowInfo.row - data[i - 1].row > 1) {
                for (var d = 0; d < seatLetters.length + 1; d++) {
                    lopaSeats.push(<div className="lopa-buffer" key={`lopa-buffer-${this.rendomNumberGenerator()}`} />);
                }
            }

            lopaSeats.push(
                <div className="lopa-number" key={`lopa-row-${rowInfo.row}-${rowInfo.seatClass}`}>
                    {rowInfo.row}
                </div>
            );

            for (var sl = 0; sl < seatLettersArray.length; sl++) {
                var letterFound = false;
                var letterIndex = 0;
                for (var s = 0; s < rowInfo.seats.length; s++) {
                    if (rowInfo.seats[s].letter === seatLettersArray[sl]) {
                        letterFound = true;
                        letterIndex = s;
                        break;
                    }
                }
                if (letterFound) {
                    var percentage = parseFloat(rowInfo.seats[letterIndex].seatData.total_usage_time_percent);
                    var seatColor = seatColors[0];
                    switch (percentage) {
                        case percentage > 0 && percentage <= 25:
                            seatColor = this.state.seatColors[0];
                            break;
                        case percentage <= 50:
                            seatColor = this.state.seatColors[1];
                            break;
                        case percentage <= 75:
                            seatColor = this.state.seatColors[2];
                            break;
                        case percentage > 75:
                            seatColor = this.state.seatColors[3];
                            break;
                        default:
                            seatColor = '#F3F5F7';
                            break;
                    }
                    var seatNumber = rowInfo.row + rowInfo.seats[letterIndex].letter;

                    var noData = rowInfo.seats[letterIndex].seatData.total_usage_time_percent == 0;

                    var seatData = rowInfo.seats[letterIndex].seatData;
                    var seatContent = [];
                    var tooltip = '';
                    if (!noData) {
                        if (this.state.selectedSeatInfo.value == 'views') {
                            let totalViews = 0;
                            if (seatData.views_by_content_type) {
                                for (let [contentType, viewCount] of Object.entries(seatData.views_by_content_type)) {
                                    totalViews += viewCount;
                                    seatContent.push(
                                        <li key={seatNumber + contentType}>
                                            <span className="lopa-tool-tip-data-name">{contentType}</span>
                                            <span className="lopa-tool-tip-data-value">{viewCount}</span>
                                        </li>
                                    );
                                }
                            }
                            seatColor = totalViews ? this.state.seatColors[1] : '#F3F5F7';
                            if (seatContent.length > 0) {
                                tooltip = {
                                    tooltip: (
                                        <div className="lopa-tool-tip hidden">
                                            <div className="lopa-tool-tip-container">
                                                <div className="lopa-tool-tip-seatNumber">{seatNumber}</div>
                                                <div className="lopa-tool-tip-header">
                                                    {`Total Views`}:{' '}
                                                    <span className="lopa-tool-tip-header-hours value-small">
                                                        {' '}
                                                        {totalViews}
                                                    </span>
                                                </div>

                                                <ul className="lopa-tool-tip-data">{seatContent}</ul>
                                            </div>
                                        </div>
                                    ),
                                    seatColor: seatColor
                                };
                            }
                        } else {
                            let totalTimePercentage = seatData.total_usage_time_percent;
                            let totalTime = 0; // utilHelper.secondsToHoursMinutes( seatData.total_usage_time);
                            let totalContentPercentage =
                                this.state.selectedSeatInfo.value === 'usage' ? parseFloat(totalTimePercentage) : 0;
                            if (seatData.duration_by_content_type) {
                                for (let [contentType, contentInfo] of Object.entries(
                                    seatData.duration_by_content_type
                                )) {
                                    totalTime += contentInfo.duration;
                                    let seatDataString =
                                        contentInfo.duration == 0
                                            ? contentInfo.duration
                                            : utilHelper.secondsToHmsForDynamicTabs(contentInfo.duration);
                                    if (this.state.selectedSeatInfo.value !== 'usage') {
                                        totalContentPercentage = parseFloat(contentInfo.pct);
                                    }
                                    if (
                                        (this.state.selectedSeatInfo.value === 'usage' ||
                                            this.state.selectedSeatInfo.label === contentType) &&
                                        seatDataString !== 0
                                    ) {
                                        seatContent.push(
                                            <li key={seatNumber + contentType}>
                                                <span className="lopa-tool-tip-data-name">{contentType}</span>
                                                <span className="lopa-tool-tip-data-value">{seatDataString}</span>
                                            </li>
                                        );
                                    }
                                }
                            }
                            switch (true) {
                                case totalContentPercentage > 0 && totalContentPercentage <= 25:
                                    seatColor = this.state.seatColors[0];
                                    break;
                                case totalContentPercentage <= 50:
                                    seatColor = this.state.seatColors[1];
                                    break;
                                case totalContentPercentage <= 75:
                                    seatColor = this.state.seatColors[2];
                                    break;
                                case totalContentPercentage > 75:
                                    seatColor = this.state.seatColors[3];
                                    break;
                                default:
                                    seatColor = '#F3F5F7';
                                    break;
                            }
                            if (seatContent.length > 0) {
                                tooltip = {
                                    tooltip: (
                                        <div className="lopa-tool-tip hidden">
                                            <div className="lopa-tool-tip-container">
                                                <div className="lopa-tool-tip-seatNumber">{seatNumber}</div>
                                                <div className="lopa-tool-tip-header">{`Total Seat Usage Time`}</div>

                                                <div className="lopa-tool-tip-header-value">
                                                    {utilHelper.secondsToHmsForDynamicTabs(totalTime)}
                                                </div>
                                                <div className="lopa-tool-tip-header-light">
                                                    ({parseFloat(totalTimePercentage).toFixed(2)}% of flight time)
                                                </div>
                                                <ul className="lopa-tool-tip-data">{seatContent}</ul>
                                            </div>
                                        </div>
                                    ),
                                    seatColor: seatColor
                                };
                            }
                        } // Take this out if NOdata so here is no data then it does not show no Data.
                    }
                    if (seatColor == '#F3F5F7') {
                        tooltip = (
                            <div className="lopa-tool-tip hidden" style={{ marginTop: 24 }}>
                                <div className="lopa-tool-tip-noData-seatNumber">{seatNumber}</div>
                                <div className="lopa-tool-tip-noData-header">No data available</div>
                            </div>
                        );
                    } else {
                        tooltip = tooltip.tooltip;
                    }
                    lopaSeats.push(
                        <div className="lopa-seat-wrapper" key={'lopa-seat-wrapper-' + seatNumber}>
                            <div
                                id={'lopa-seat-' + seatNumber}
                                className="lopa-seat"
                                style={{ backgroundColor: seatColor, borderColor: noData ? '#DADCDD' : 'transparent' }}
                                onMouseOver={(e) => {
                                    this.openToolTip(seatNumber, e);
                                    e.target.classList.add('active');
                                }}
                                onMouseLeave={(e) => {
                                    this.closeTooltip(e);
                                    e.target.classList.remove('active');
                                }}
                            >
                                {seatNumber}
                            </div>
                            {tooltip}
                        </div>
                    );
                } else
                    lopaSeats.push(
                        <div
                            id={'lopa-seat-' + i + rowInfo.row + sl}
                            className="lopa-seat no-usage"
                            key={'lopaEmptySeat-' + rowInfo.row + sl + rowInfo.seatClass}
                        />
                    );
            }
        }
        lopaSeats.push(<div className="lopa-buffer" key={`lopa-buffer-${this.rendomNumberGenerator()}`} />);

        this.setState({
            seatLetterCount: seatLettersArray.length + 1,
            seatLetters: seatLetters,
            lopaSeats: lopaSeats
        });
    }

    setScroll = (e) => {
        var wrapper = e.target;
        var maxScroll = wrapper.scrollWidth - wrapper.clientWidth;
        var scrollPoint = Math.min(Math.round(wrapper.scrollLeft / (maxScroll / 3)), 3);

        if (scrollPoint !== this.state.activeScroll)
            this.setState({
                activeScroll: scrollPoint
            });
    };

    scrollTo(point) {
        var wrapper = document.getElementById('lopa-grid-wrapper');

        if (point === '++') point = this.state.activeScroll < 3 ? this.state.activeScroll + 1 : 3;
        else if (point === '--') point = this.state.activeScroll > 0 ? this.state.activeScroll - 1 : 0;

        var maxScroll = wrapper.scrollWidth - wrapper.clientWidth;

        wrapper.scrollTo({
            left: (maxScroll / 3) * point,
            behavior: 'smooth'
        });
    }

    getSeatDateTimeString(seatDataValue) {
        var seatDataHours = Math.floor(seatDataValue / 60);
        var seatDataMinutes = seatDataValue % 60;
        var seatDataTimeString =
            seatDataHours > 0 ? seatDataHours + 'h ' + seatDataMinutes + 'm' : seatDataMinutes + 'm';
        return seatDataTimeString;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Typical usage (don't forget to compare props):
        let wrapper = document.getElementById('lopa-grid-wrapper');
        let scrollSize = wrapper.scrollWidth - wrapper.clientWidth;
        if (
            !_.isEqual(sampleData, this.state.lopaSeats) &&
            !this.state.loadingSeats &&
            scrollSize !== this.state.maxScroll
        ) {
            this.setState({
                maxScroll: scrollSize
            });
        }
    }

    setSearchTerm = (event) => {
        this.setState({
            searchTerm: event.target.value
        });
    };

    handleSeatInfoTypeChange(selectedOption) {
        this.setState({ selectedSeatInfo: selectedOption }, () => {
            this.buildDefaultLopa(this.state.data);
        });
    }

    getLegend() {
        if (this.state.selectedSeatInfo.value === 'views') {
            return (
                <div className="lopa-legend-item text-center">
                    <div style={{ backgroundColor: this.state.seatColors[1] }} />
                    Data
                </div>
            );
        } else {
            return (
                <>
                    <div className="lopa-legend-item text-left">
                        <div style={{ backgroundColor: this.state.seatColors[0] }} />0
                    </div>
                    <div className="lopa-legend-item text-left">
                        <div style={{ backgroundColor: this.state.seatColors[1] }} />
                        25%
                    </div>
                    <div className="lopa-legend-item text-left">
                        <div style={{ backgroundColor: this.state.seatColors[2] }} />
                        50%
                    </div>
                    <div className="lopa-legend-item text-left">
                        <div style={{ backgroundColor: this.state.seatColors[3] }} />
                        75%+
                    </div>
                </>
            );
        }
    }

    render() {
        var seatLetterCount = this.state.seatLetterCount;
        var seatLetters = this.state.seatLetters;
        var lopaSeats = this.state.lopaSeats;
        var startDate = this.props.startDate; //new Date(2021, 4, 1)
        var endDate = this.props.endDate; //new Date(2021, 5, 0)

        const seatInfoDropdownOptions = [
            {
                label: 'Total Seat Usage Time',
                value: 'usage'
            },
            {
                label: 'Total Views',
                value: 'views'
            }
        ];
        return (
            <>
                <div
                    style={{
                        display:
                            this.state.wizardStep == 4 && _.isEqual(sampleData, this.state.data) ? 'initial' : 'none',
                        width: '100%',
                        position: 'absolute',
                        top: 30,
                        height: 'calc(100% - 30px)'
                    }}
                >
                    <div
                        style={{
                            pointerEvents: 'none',
                            height: '100%',
                            background: '#FFFFFF80',
                            backdropFilter: 'blur(8px)',
                            zIndex: 1,
                            position: 'absolute',
                            top: 0,
                            width: '100%'
                        }}
                    ></div>
                    <span className="no-data" style={{ position: 'absolute', zIndex: 2, marginTop: '21%', top: '0px' }}>
                        Data is not available
                    </span>
                </div>

                <div className="flight-details">
                    <div className="flight-detail">
                        <div className="text">{this.state.flight}</div>
                        <div className="category">Flight</div>
                    </div>
                    <div className="flight-detail">
                        <div className="text">{this.formatRoute(this.state.route)}</div>
                        <div className="category">Route</div>
                    </div>
                    <div className="flight-detail">
                        <div className="text">{utilHelper.secondsToHoursMinutes(this.state.flightDuration)}</div>
                        <div className="category">Duration</div>
                    </div>
                    <div className="flight-detail">
                        <div className="text">{this.state.flightStartDateHeader}</div>
                        <div className="category">Date</div>
                    </div>
                    <div className="flight-detail">
                        <div className="text">{this.state.flightStartTime}</div>
                        <div className="category">Departure</div>
                    </div>
                    <div className="flight-detail">
                        <div className="text">{this.state.aircraftType}</div>
                        <div className="category">Aircraft</div>
                    </div>
                    <div className="flight-detail">
                        <div
                            className="change-flight-link"
                            onClick={() => {
                                this.setState({ wizardStep: 3, data: sampleData });
                            }}
                        >
                            Change flight
                        </div>
                    </div>
                </div>

                <div className="flight-detail-separator">&nbsp;</div>

                <div
                    className={'px-4 pb-3'}
                    style={{
                        display: 'flex',
                        marginTop: 24,
                        pointerEvents: _.isEqual(sampleData, this.state.data) ? 'none' : 'all'
                    }}
                >
                    <div className="w-50 d-inline-block">
                        {this.props.showChangeSeatInfoType && (
                            <Select
                                options={seatInfoDropdownOptions}
                                value={this.state.selectedSeatInfo}
                                menuPortalTarget={document.body}
                                getOptionValue={(option) => option.label}
                                onChange={this.handleSeatInfoTypeChange}
                                classNamePrefix="select"
                                maxMenuHeight={370}
                            />
                        )}
                    </div>
                    <div className="lopa-legend w-50 d-inline-block text-right">
                        <div className="lopa-legend-item text-left">
                            <div
                                style={{
                                    //  'color': '#000000',
                                    backgroundColor: '#F3F5F7',
                                    border: `1px solid #DADCDD`
                                }}
                            ></div>
                            No Data
                        </div>
                        {this.getLegend()}
                    </div>
                </div>
                <div
                    className={clsx({
                        'lopa-wrapper': true,
                        'pb-5 mb-3': this.state.maxScroll || this.state.maxScroll > 0
                    })}
                    style={{ pointerEvents: _.isEqual(sampleData, this.state.data) ? 'none' : 'all' }}
                >
                    <div className="seat-letters">{seatLetters}</div>
                    <div
                        id="lopa-grid-wrapper"
                        aria-label={this.state.wizardStep == 4 ? '4' : ''}
                        className="lopa-grid-wrapper"
                        onScroll={this.setScroll}
                    >
                        <div
                            className="lopa-grid"
                            style={{
                                gridTemplateRows: 'repeat(' + seatLetterCount + ', 1fr)'
                            }}
                        >
                            {lopaSeats}
                        </div>
                    </div>
                    {this.state.maxScroll ? (
                        <div
                            className="lopa-grid-scroller"
                            style={{
                                display: this.state.wizardStep != 4 ? 'none' : 'initial'
                            }}
                        >
                            <div className="lopa-grid-scroll-arrow left" onClick={() => this.scrollTo('--')}>
                                &#x2039;
                            </div>
                            <div
                                className={
                                    this.state.activeScroll === 0
                                        ? 'lopa-grid-scroll-dot active'
                                        : 'lopa-grid-scroll-dot'
                                }
                                onClick={() => this.scrollTo(0)}
                            />
                            <div
                                className={
                                    this.state.activeScroll === 1
                                        ? 'lopa-grid-scroll-dot active'
                                        : 'lopa-grid-scroll-dot'
                                }
                                onClick={() => this.scrollTo(1)}
                            />
                            <div
                                className={
                                    this.state.activeScroll === 2
                                        ? 'lopa-grid-scroll-dot active'
                                        : 'lopa-grid-scroll-dot'
                                }
                                onClick={() => this.scrollTo(2)}
                            />
                            <div
                                className={
                                    this.state.activeScroll === 3
                                        ? 'lopa-grid-scroll-dot active'
                                        : 'lopa-grid-scroll-dot'
                                }
                                onClick={() => this.scrollTo(3)}
                            />
                            <div className="lopa-grid-scroll-arrow right" onClick={() => this.scrollTo('++')}>
                                &#x203A;
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                <div
                    className="lopa-wizard"
                    style={{
                        display: this.state.wizardStep != 4 ? 'initial' : 'none'
                    }}
                >
                    <div
                        className="lopa-wizard-popup"
                        style={{
                            display: this.state.wizardStep === 1 ? 'initial' : 'none'
                        }}
                    >
                        View seat usage for an individual flight
                        <div
                            className="lopa-wizard-button"
                            onClick={() => {
                                this.setState({ wizardStep: 2 });
                            }}
                        >
                            Select a Flight
                        </div>
                    </div>
                    <div
                        className="lopa-wizard-flight"
                        style={{
                            display: this.state.wizardStep === 2 || this.state.wizardStep === 3 ? 'initial' : 'none'
                        }}
                    >
                        <div className="lopa-wizard-label">Select a date and flight:</div>
                        <div
                            className="lopa-wizard-cancel-label"
                            onClick={() => {
                                this.setState(
                                    {
                                        wizardStep: _.isEqual(sampleData, this.state.data) ? 1 : 4,
                                        searchTerm: '',
                                        data: sampleData,
                                        selectedSeatInfo: {
                                            label: 'Total Seat Usage Time',
                                            value: 'usage'
                                        },
                                        flightList: [],
                                        loadingSeats: false,
                                        date: ''
                                    },
                                    () => {
                                        this.buildDefaultLopa(sampleData);
                                        this.props.clearFlightData();
                                    }
                                );
                            }}
                        >
                            Cancel
                        </div>

                        {this.state.wizardStep > 1 && (
                            <div style={{ pointerEvents: this.state.loadingSeats ? 'none' : 'all' }}>
                                <Calendar
                                    className="lopa-wizard-flight-calendar"
                                    tileClassName="lopa-wizard-flight-calendar-tile"
                                    onClickDay={(value, event) => {
                                        this.setDate(value);
                                    }}
                                    calendarType="US"
                                    minDetail="month"
                                    minDate={startDate}
                                    activeStartDate={
                                        this.state.activeCalendarStartDate
                                            ? this.state.activeCalendarStartDate
                                            : endDate
                                    }
                                    onActiveStartDateChange={({ activeStartDate }) =>
                                        this.setState({ activeCalendarStartDate: activeStartDate })
                                    }
                                    maxDate={endDate}
                                    formatShortWeekday={(locale, date) => this.formatDate(date, 'dd')}
                                    prevLabel={<img src={chevronLeftImage} />}
                                    nextLabel={<img src={chevronRightImage} />}
                                />
                            </div>
                        )}
                        <div className="lopa-flight-picker-right">
                            <div className="lopa-flight-list-wrapper">
                                {this.state.flightList.length > 0 && (
                                    <>
                                        <div className="lopa-flight-list-date">{this.state.date}</div>
                                        <div className="lopa-flight-list-search">
                                            <input
                                                className="lopa-flight-list-search-text"
                                                value={this.state.searchTerm}
                                                placeholder="Search by flight #"
                                                onChange={this.setSearchTerm}
                                            />
                                            <img src={SearchIcon} className="lopa-flight-list-search-icon" />
                                        </div>
                                        <div className="lopa-flight-list-header flight">Flight #</div>
                                        <div className="lopa-flight-list-header departure">Departure (UTC)</div>
                                    </>
                                )}
                                <div className="lopa-flight-list">
                                    {this.state.loadingFlights ? (
                                        <div className="lopa-loading-flights">Loading Flights ...</div>
                                    ) : this.state.flightList.length > 0 ? (
                                        <table className="lopa-flight-list-table">
                                            <tbody>
                                                {this.state.flightList.map((e, i) => {
                                                    if (
                                                        e.flight_number
                                                            .toLowerCase()
                                                            .includes(this.state.searchTerm.toLowerCase())
                                                    ) {
                                                        return (
                                                            <tr
                                                                key={e.flight_number + i}
                                                                className={
                                                                    !this.state.loadingSeats &&
                                                                    this.state.activeRow == i
                                                                        ? 'lopa-flight-list-table-row active'
                                                                        : 'lopa-flight-list-table-row'
                                                                }
                                                                onMouseOver={() => this.setState({ activeRow: i })}
                                                            >
                                                                <td
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        width: '111px',
                                                                        paddingLeft: '20px',
                                                                        color:
                                                                            this.state.loadingSeats &&
                                                                            this.state.flight != e.flight_number
                                                                                ? 'gray'
                                                                                : ''
                                                                    }}
                                                                >
                                                                    {e.flight_number}{' '}
                                                                    <div className="route">
                                                                        {this.formatRoute(e.route)}
                                                                    </div>{' '}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: '120px',
                                                                        color:
                                                                            this.state.loadingSeats &&
                                                                            this.state.flight != e.flight_number
                                                                                ? 'gray'
                                                                                : '',
                                                                        fontWeight:
                                                                            this.state.loadingSeats &&
                                                                            this.state.flight == e.flight_number
                                                                                ? 'bold'
                                                                                : ''
                                                                    }}
                                                                >
                                                                    {e.flight_start_time}
                                                                </td>
                                                                <td>
                                                                    {this.state.wizardStep &&
                                                                    !this.state.loadingSeats ? (
                                                                        <div
                                                                            className={
                                                                                this.state.activeRow == i
                                                                                    ? 'lopa-flight-select active'
                                                                                    : 'lopa-flight-select'
                                                                            }
                                                                            onClick={() => {
                                                                                this.setFlight(e);
                                                                            }}
                                                                        >
                                                                            View Seat Usage
                                                                        </div>
                                                                    ) : (
                                                                        this.state.loadingSeats &&
                                                                        this.state.flight == e.flight_number && (
                                                                            <div
                                                                                className={clsx({
                                                                                    'loading-flight': true
                                                                                })}
                                                                                style={{
                                                                                    marginLeft: '50px',
                                                                                    fontWeight:
                                                                                        this.state.loadingSeats &&
                                                                                        this.state.flight ==
                                                                                            e.flight_number
                                                                                            ? 'bold'
                                                                                            : ''
                                                                                }}
                                                                            >
                                                                                Loading...
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {!this.state.flightList.length > 0 && !this.state.loadingFlights && (
                                    <div className="lopa-no-flights-found">
                                        <span>No flights found for</span>
                                        <span className="no-flight-date">{this.state.date}</span>
                                        <span className="select-another"> &#8592; Please select another date</span>
                                    </div>
                                )}
                            </div>
                            <div
                                className="lopa-flight-list-placeholder"
                                style={{
                                    display: this.state.wizardStep === 2 ? 'initial' : 'none'
                                }}
                            >
                                {this.state.loadingFlights ? 'Loading Flights ...' : '← Select a date to view flights'}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Lopa;
