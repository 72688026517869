import React from 'react';
import DonutChart from 'components/updatedComponents/DonutChart';
import WidgetFrame from 'components/updatedComponents/WidgetFrameNew';

class DonutWidgetDropdown extends React.Component {
    constructor(props) {
        super(props);

        const _unit = props.unit;
        const text = '';

        let data = [];
        if (typeof props.data !== 'undefined' && Object.keys(props.data).length > 0) {
            data = props.data;
        }

        this.state = {
            data: data,
            unit: _unit,
            text: text
        };
    }

    updateWidgetData = (newData) => {
        let allZeroValues = true;

        if (newData.data) {
            for (let i = 0; i < newData.data.length; i++) {
                if (newData.data[i].value !== '0') {
                    allZeroValues = false;
                    break;
                }
            }
        }

        if (allZeroValues) {
            newData.data = null;
        }

        this.setState({
            data: newData.data,
            unit: newData.unit,
            text: newData.text ? newData.text : this.state.text
        });
    };

    render() {
        return (
            <WidgetFrame
                type="donut"
                titleImage={this.props.titleImage}
                page={this.props.page}
                pageName={this.props.pageName}
                data={this.props.data}
                loading={this.props.loading}
                title={this.props.title}
                updateData={this.updateWidgetData}
                showTabs={false}
                showOverflow={true}
                showDropdown={true}
                showFooter={false}
                unit={this.props.unit}
                showViewMore={this.props.showViewMore}
                dataObject={this.props.dataObject}
                ddOptions={this.props.ddOptions}
                dd1Options={this.props.dd1Options}
                dd2Options={[]}
                component_type="donut"
                tabFunction={this.props.tabFunction}
                workbench_type={this.props.workbench_type || ''}
                showWorkbenchLink={this.props.showWorkbenchLink || false}
                tenantIcaoCode={this.props.tenantIcaoCode || ''}
                appliedFilters={this.props.appliedFilters}
                subscription={this.props.subscription}
                app={this.props.app}
                subscriptionStage={this.props.subscriptionStage}
                dataAvailable={!!(this.state.data && this.state.data.length !== 0)}
                body={
                    <DonutChart
                        donut_name={this.props.donut_name}
                        unit={this.state.unit || 'count'}
                        text={this.state.text || ''}
                        data={this.state.data}
                        loading={this.props.loading}
                        defaultKey={this.state.defaultKey || this.props.defaultKey}
                        defaultShow={this.props.defaultShow || ''}
                        showLegend={this.props.showLegend || false}
                        showMetrics={this.props.showMetrics || false}
                        donut_type={this.props.donut_type || ''}
                        small={this.props.type !== 'half'}
                        graphobject={this.props.graphobject}
                        showWorkbenchLink={this.props.showWorkbenchLink || false}
                        page={this.props.page}
                        title={this.props.title}
                    />
                }
            />
        );
    }
}

export default DonutWidgetDropdown;
