export function getAllTenantOption() {
    return {
        label: 'All Airlines',
        value: '903a42ll-985e-40d1-2222-32b8c2e33397',
        icaoCode: 'all'
    };
}

export function getPanaSkyOption() {
    return {
        label: 'PanaSky',
        value: '903a42bc-985e-40d1-2222-32b8c2e33397',
        icaoCode: 'pac'
    };
}

export function getAllRegionOption() {
    return {
        label: 'All Regions',
        value: 'ALL',
        id: '10000all'
    };
}
