import React from 'react';
import ScreenTransitionWidget from 'components/updatedWidgets/ScreenTransitionWidget';
import { Card } from 'reactstrap';
import ErrorBoundary from 'util/ErrorBoundary.js';
import _, { debounce } from 'lodash';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import * as AppConstants from 'constants/app/constants';
import AppDataHelper from 'data/app-data-helper.js';
const appDataHelper = new AppDataHelper();

class ScreenTransitionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataset: [],
            loading: true,
            error: false,
            requestedSections: []
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) ||
            prevProps.path !== this.props.path ||
            this.props.forceReloadAll
        ) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        if (this.props.tenantIcaoCode === 'all') {
            this.setState({ error: false, dataset: [], loading: false });
            return;
        }

        this.setState({ loading: true });

        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));
        selectedFiltersDeepClone.usage_source = this.props.usageSource;
        selectedFiltersDeepClone.screens_per_level = 10;

        if (this.props.subscription) {
            selectedFiltersDeepClone['tier'] = this.props.subscription;
        }

        if (this.props.app) {
            selectedFiltersDeepClone['app'] = this.props.app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        this.throttleFetch(selectedFiltersDeepClone);
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchNextInsightsMetricsAxios(
                selectedFiltersDeepClone,
                appDataHelper.getAPIPath('screen-transition'),
                this.cancelRequestSource.token
            )
            .then((response) => {
                if (!response && !response.visits) {
                    this.setState({ error: true, dataset: [], loading: false });
                } else {
                    this.setState({
                        error: false,
                        dataset: response.visits,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, dataset: [], loading: true });
                } else {
                    this.setState({ error: true, dataset: [], loading: false });
                }
            });
    }

    render() {
        let { dataset, loading } = this.state;
        return (
            <Card>
                <ErrorBoundary>
                    <ScreenTransitionWidget
                        loading={loading}
                        page={this.props.page}
                        key={'screen_transition'}
                        id={'screen_transition'}
                        data={dataset ? dataset : []}
                        title={this.props.title}
                        pageName={this.props.pageName}
                        showWorkbenchLink={this.props.showWorkbenchLink}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        appliedFilters={this.props.appliedFilters.api}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </ErrorBoundary>
            </Card>
        );
    }
}

export default ScreenTransitionContainer;
