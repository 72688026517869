import React from 'react';

class PageFooter extends React.Component {
    render() {
        const companyName = 'Panasonic Avionics Corporation';
        const thisYear = new Date().getFullYear();

        return (
            <footer className="footer">
                <center>
                    {companyName}. &copy; {thisYear} All Rights Reserved
                </center>
            </footer>
        );
    }
}

export default PageFooter;
