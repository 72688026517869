import React from 'react';
import { Row } from 'reactstrap';
import TrendContainer from 'components/containers/TrendContainer';
import DonutContainer from 'components/containers/DonutContainer';
import HorizontalBarChartContainer from 'components/containers/HorizontalBarChartContainer';
import VerticalBarChartContainer from 'components/containers/VerticalBarChartContainer';
import TableContainer from 'components/containers/TableContainer';
import WorkbenchTableContainer from 'components/containers/WorkbenchTableContainer';

let page = 'companion-app';
let displayPageName = 'Companion App';

class CompanionAppOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ipsColumns: [
                { value: 'tail_no', header: 'Tail Number' },
                { value: 'airline_code', header: 'Airline' },
                { value: 'flight_number', header: 'Flight Number' },
                { value: 'flight_start_date', header: 'Flight Start Date', type: 'date' },
                { value: 'flight_end_date', header: 'Flight End Date', type: 'date' },
                { value: 'flight_duration_secs', header: 'Flight Duration', type: 'time' },
                { value: 'aircraft_type_subtype', header: 'Aircraft Type/Sub-type' },
                { value: 'system_type', header: 'System Type' },
                { value: 'dep_airport_iata', header: 'Flight Origin' },
                { value: 'arr_airport_iata', header: 'Flight Destination' },
                { value: 'seat_class', header: 'Seat Class' },
                { value: 'seatnum', header: 'Seat Number' },
                { value: 'payload_code', header: 'Payload Code' },
                { value: 'payload_event', header: 'Payload Event' }
            ]
        };
    }

    render() {
        let dashboard = (
            <div>
                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    usageSource={displayPageName}
                    forceReloadTrend={this.props.forceReloadTrend}
                    forceReloadAll={this.props.forceReloadAll}
                    title="Trends - Media Launches"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="Companion_App_overview_trend_1"
                    id="Companion_App_overview_trend_1"
                    tabFunction={this.props.tabFunction}
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    subscription={this.props.subscription}
                    app={this.props.app}
                    subscriptionStage={this.props.subscriptionStage}
                />

                <Row>
                    {this.props.showWorkbench && (
                        <DonutContainer
                            path={this.props.path}
                            appliedFilters={this.props.appliedFilters}
                            renderType=""
                            donut_value="passenger_engagement"
                            page={page}
                            pageName={displayPageName}
                            forceReloadAll={this.props.forceReloadAll}
                            tabFunction={this.props.tabFunction}
                            showWorkbenchLink={this.props.showWorkbench}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            allowedApps={this.props.allowedApps}
                            subscription={this.props.subscription}
                            app={this.props.app}
                            subscriptionStage={this.props.subscriptionStage}
                        />
                    )}

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        title="Routes"
                        workbenchTitle="routes"
                        page={page}
                        pageName={displayPageName}
                        reportType="routes"
                        color="#A1C9EB"
                        routes={true}
                        key="companion_app_overview_routes_1"
                        id="companion_app_overview_routes_1"
                        tabFunction={this.props.tabFunction}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>
                <Row></Row>

                <Row>
                    <VerticalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource={displayPageName}
                        pageName={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        title={'Passenger Interaction Timeline'}
                        page={page}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </Row>

                <Row>
                    <TableContainer
                        pageName={displayPageName}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        data={this.props.data}
                        dataset={this.props.dataset}
                        loading={this.props.loading}
                        appliedFilters={this.props.appliedFilters}
                        app={this.props.app}
                        title="Companion App"
                    />
                </Row>

                <Row>
                    <WorkbenchTableContainer
                        pageName={displayPageName}
                        appliedFilters={this.props.appliedFilters}
                        title="Login Details"
                        app={this.props.app}
                        columns={this.state.ipsColumns}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        page={page}
                    />
                </Row>
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default CompanionAppOverview;
