import React from 'react';
import ScreenTransition from '../updatedComponents/ScreenTransition';
import WidgetFrameNew from '../updatedComponents/WidgetFrameNew';

class ScreenTransitionWidget extends React.Component {
    render() {
        let body =
            this.props.tenantIcaoCode === 'all' ? (
                <div style={{ paddingLeft: '14px', paddingRight: '5px' }}>
                    <div class="no-data" style={{ marginTop: '14%', fontSize: '32px' }}>
                        Disabled for 'All Airlines' selection
                    </div>
                </div>
            ) : (
                <ScreenTransition
                    data={this.props.data}
                    loading={this.props.loading}
                    showWorkbench={this.props.showWorkbenchLink}
                    title={this.props.title}
                />
            );
        return (
            <WidgetFrameNew
                type="screen-transition"
                titleImage={this.props.img}
                title={this.props.title}
                data={this.props.data}
                showOverflow={true}
                loading={this.props.loading}
                tenantIcaoCode={this.props.tenantIcaoCode || ''}
                showWorkbenchLink={this.props.showWorkbenchLink || false}
                appliedFilters={this.props.appliedFilters}
                subscription={this.props.subscription}
                app={this.props.app}
                subscriptionStage={this.props.subscriptionStage}
                showDropdown={false}
                showWorkbench={false}
                page={this.props.page}
                pageName={this.props.pageName}
                dataAvailable={!this.props.data.length ? false : true}
                body={body}
            />
        );
    }
}

export default ScreenTransitionWidget;
