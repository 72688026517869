import React, { Component } from 'react';
import HorizontalBarChart from 'components/updatedComponents/HorizontalBarChart';
import WidgetFrame from 'components/updatedComponents/WidgetFrameNew';

class HorizontalBarChartWidget extends Component {
    constructor(props) {
        super(props);

        const _unit = props.unit;
        const text = 'Total';

        this.state = {
            data: [],
            unit: _unit,
            text: text
        };
    }

    updateWidgetData = (newData) => {
        this.setState({
            data: newData,
            unit: newData.unit ? newData.unit : this.props.unit,
            text: newData.text ? newData.text : this.state.text
        });
    };

    checkDataAvailable = () => {
        let data;
        let dataAvailable = false;
        let blurred = false;

        if (this.props && this.props.data && this.props.reportType == 'events') {
            data = JSON.parse(JSON.stringify(this.props.data));
            dataAvailable = true;
        } else if (this.state && this.state.data && this.state.data.data && this.state.data.data.length > 0) {
            data = JSON.parse(JSON.stringify(this.state.data.data)); // @todo @important - updating container "data" props on "splice"
            dataAvailable = true;
        }
        if (dataAvailable) {
            let allZeroValues = true;

            for (let i = 0; i < data.length; i++) {
                if (data[i].value !== '0' && data[i].value !== null) {
                    allZeroValues = false;
                    break;
                }
            }

            if (allZeroValues) {
                dataAvailable = true;
                blurred = true;
            }
        } else {
            dataAvailable = true;
            blurred = true;
        }
        return !blurred;
    };

    render() {
        let body =
            this.props.tenantIcaoCode === 'all' && this.props.reportType === 'pages' ? (
                <div style={{ paddingLeft: '14px', paddingRight: '5px' }}>
                    <div class="no-data" style={{ marginTop: '14%', fontSize: '32px' }}>
                        Disabled for 'All Airlines' selection
                    </div>
                </div>
            ) : this.props.tenantIcaoCode !== 'all' && this.props.reportType === 'airlines' ? (
                <div style={{ paddingLeft: '14px', paddingRight: '5px' }}>
                    <div class="no-data" style={{ marginTop: '14%', fontSize: '32px' }}>
                        Disabled for single airline selection
                    </div>
                </div>
            ) : (
                <HorizontalBarChart
                    maxRows={10}
                    page={this.props.page}
                    type={this.props.type}
                    loading={this.props.loading}
                    data={this.props.reportType == 'events' ? this.props.data : this.state.data}
                    unit={this.state.unit || 'count'}
                    text={this.state.text || 'total'}
                    routes={this.props.routes}
                    reportType={this.props.reportType}
                    color={this.props.color}
                    showWorkbenchLink={this.props.showWorkbenchLink || false}
                    appliedFilters={this.props.appliedFilters}
                />
            );

        let dd1Options =
            this.props.tenantIcaoCode === 'all' && this.props.reportType === 'pages' ? [] : this.props.dd1Options;
        return (
            <WidgetFrame
                id={Math.random()}
                type="bar"
                page={this.props.page}
                pageName={this.props.pageName}
                titleImage={this.props.img}
                data={this.props.data}
                dataset={this.props.dataset}
                loading={this.props.loading}
                title={this.props.title}
                updateData={this.updateWidgetData}
                showTabs={false}
                showOverflow={true}
                showDropdown={true}
                showFooter={false}
                unit={this.props.unit}
                showViewMore={this.props.showViewMore}
                dataObject={this.props.dataObject}
                ddOptions={this.props.dd1Options}
                dd1Options={dd1Options}
                dd2Options={this.props.dd2Options}
                component_type="bar"
                tabFunction={this.props.tabFunction}
                workbench_type={this.props.workbench_type || ''}
                showWorkbenchLink={this.props.showWorkbenchLink || false}
                tenantIcaoCode={this.props.tenantIcaoCode || ''}
                appliedFilters={this.props.appliedFilters}
                subscription={this.props.subscription}
                app={this.props.app}
                subscriptionStage={this.props.subscriptionStage}
                dataAvailable={this.checkDataAvailable()}
                reportSubType={this.props.reportType}
                body={body}
            />
        );
    }
}

export default HorizontalBarChartWidget;
