import React, { Component } from 'react';
import _ from 'lodash';
import VerticalBarChart from '../updatedComponents/VerticalBarChart';
import WidgetFrameNew from '../updatedComponents/WidgetFrameNew';
import UtilHelper from 'util/UtilHelper.js';

const utilHelper = new UtilHelper();

class VerticalBarChartWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            options: null,
            dataAvailable: false,
            yAxisLabel: null,
            unit: 'count',
            dataWithoutChange: []
        };

        this.createChart = this.createChart.bind(this);
    }

    updateWidgetData = (newData) => {
        this.createChart(newData);
    };

    createChart(newData) {
        let chartData = newData.data;
        let chartText = newData.text;
        let chartUnit = newData.unit;
        let dataSet = [];
        let dataSetWithoutChange = {};
        let labelSet = [];
        let colorSet = [];
        let options = {};
        let allData = [];
        let chartPropUnit = chartUnit;
        let yAxisLabel = chartText;
        let maxValue = 0;
        let minValue = 0;

        let colorDict = {
            Movies: { color: '#71137180', hoverColor: '#711371' },
            'Live TV': { color: '#002C6180', hoverColor: '#002C61' },
            'TV Series': { color: '#E4801C80', hoverColor: '#E4801C' },
            Audio: { color: '#00461280', hoverColor: '#004612' },
            Games: { color: '#79C2D080', hoverColor: '#79C2D0' },
            'Info Videos': { color: '#C6970880', hoverColor: '#C69708' },
            Trailer: { color: '#9C2D1380', hoverColor: '#9C2D13' },
            Others: { color: '#624F8380', hoverColor: '#624F83' },
            Other: { color: '#624F8380', hoverColor: '#624F83' },
            Advertisement: { color: '#FD01E580', hoverColor: '#FD01E5' },
            Video: { color: '#E52F6180', hoverColor: '#E52F61' }
        };

        if (chartData && chartData.length > 0) {
            let data = {};
            chartData.forEach((phaseData) => {
                if (
                    'current_date_range' in phaseData &&
                    phaseData['start'] !== 'unmapped' &&
                    phaseData['end'] !== 'unmapped'
                ) {
                    for (let [key, value] of Object.entries(phaseData.current_date_range)) {
                        if (value >= 0) {
                            allData.push(value);
                        }
                    }
                }
            });
            if (allData.length > 0) {
                maxValue = Math.max(...allData);
                minValue = (maxValue * 5) / 100;
            }
            chartData.forEach((phaseData) => {
                if (
                    'current_date_range' in phaseData &&
                    phaseData['start'] !== 'unmapped' &&
                    phaseData['end'] !== 'unmapped'
                ) {
                    for (let [key, value] of Object.entries(phaseData.current_date_range)) {
                        if (!(key in data)) {
                            data[key] = [];
                            dataSetWithoutChange[key] = [];
                            colorSet.push(colorDict[key]);
                        }
                        if (value >= 0) {
                            dataSetWithoutChange[key].push(value);
                        }
                        if (value >= 0 && value > minValue) {
                            data[key].push(value);
                        }
                        if (value <= minValue) {
                            data[key].push(minValue);
                        }
                    }
                }
            });
            labelSet = Object.keys(data);
            dataSet = Object.values(data);
            dataSetWithoutChange = Object.values(dataSetWithoutChange);

            if (dataSet && !_.isEmpty(dataSet)) {
                if (chartUnit && chartUnit.toLowerCase() === 'time') {
                    if (allData.length > 0) {
                        let maxValue = Math.max(...allData);
                        var minNonZeroValue = Math.min.apply(null, allData.filter(Boolean));
                        if (
                            maxValue > 0 &&
                            maxValue !== minNonZeroValue &&
                            utilHelper.isMixedUnit(minNonZeroValue, maxValue)
                        ) {
                            chartPropUnit = 'mixed';
                        } else {
                            yAxisLabel = utilHelper.getUnit(utilHelper.secondsToHms(maxValue));
                        }
                    }
                }
            }
        }

        let data = {
            labels: labelSet,
            data: dataSet,
            colors: colorSet
        };

        this.setState({
            dataWithoutChange: dataSetWithoutChange,
            data: data,
            options: options,
            dataAvailable: !_.isEmpty(options),
            yAxisLabel: yAxisLabel,
            unit: chartUnit,
            chartPropUnit: chartPropUnit
        });
    }

    render() {
        return (
            <WidgetFrameNew
                type="vertical-bar"
                data={this.props.data}
                tabData={this.props.tabData}
                graphObject={'flight_phases'}
                reportSubType={this.props.pageName}
                updateData={this.updateWidgetData}
                title={this.props.title}
                page={this.props.page}
                pageName={this.props.pageName}
                showTabs={true}
                showOverflow={true}
                loading={this.props.loading}
                showWorkbenchLink={this.props.showWorkbenchLink || false}
                comparisonRangeText={
                    this.props.appliedFilters && this.props.appliedFilters.comparison_range_text
                        ? this.props.appliedFilters.comparison_range_text
                        : ''
                }
                tenantIcaoCode={this.props.tenantIcaoCode || ''}
                appliedFilters={this.props.appliedFilters}
                subscription={this.props.subscription}
                app={this.props.app}
                subscriptionStage={this.props.subscriptionStage}
                dataAvailable={
                    this.state.data && this.state.data.data && this.state.data.data.length != 0 ? true : false
                }
                body={
                    <VerticalBarChart
                        dataWithoutChange={this.state.dataWithoutChange}
                        data={this.state.data}
                        options={this.state.options}
                        loading={this.props.loading}
                        yAxisLabel={this.state.yAxisLabel}
                        unit={this.state.unit}
                        dataAvailable={this.state.dataAvailable}
                        chartPropUnit={this.state.chartPropUnit}
                        title={this.props.title}
                    />
                }
            />
        );
    }
}

export default VerticalBarChartWidget;
