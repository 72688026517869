function getTrendChartRequestedSectionsHelper(reportSubType) {
    let requestedSections;

    switch (reportSubType) {
        case 'Movies':
        case 'TV Series':
        case 'Live TV':
        case 'Seatback':
        case 'Portal':
            requestedSections = [
                {
                    key: 'total_views',
                    title: 'Total Views',
                    y_axis_label: 'Views',
                    unit: 'count',
                    label: 'Total Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    key: 'total_users',
                    title: 'Total Viewers',
                    y_axis_label: 'Viewers',
                    unit: 'count',
                    label: 'Total Viewers',
                    free_tier: 0
                },
                {
                    key: 'total_usage_time',
                    title: 'Total Viewing Time',
                    y_axis_label: 'Time',
                    unit: 'time',
                    label: 'Total Viewing Time',
                    free_tier: 0
                },
                {
                    key: 'avg_users',
                    title: 'Avg. Viewers',
                    y_axis_label: 'Viewers',
                    unit: 'count',
                    label: 'Average Viewers',
                    free_tier: 0
                },
                {
                    key: 'avg_usage_time',
                    title: 'Avg. Viewing Time',
                    y_axis_label: 'Time',
                    unit: 'time',
                    label: 'Average Viewing Time',
                    free_tier: 0
                }
            ];
            break;
        case 'Games':
            requestedSections = [
                {
                    key: 'total_views',
                    title: 'Total Plays',
                    y_axis_label: 'Plays',
                    unit: 'count',
                    label: 'Total Plays',
                    default: 1,
                    free_tier: 1
                },
                {
                    key: 'total_users',
                    title: 'Total Players',
                    y_axis_label: 'Players',
                    unit: 'count',
                    label: 'Total Players',
                    free_tier: 0
                },
                {
                    key: 'total_usage_time',
                    title: 'Total Playing Time',
                    y_axis_label: 'Time',
                    unit: 'time',
                    label: 'Total Playing Time',
                    free_tier: 0
                },
                {
                    key: 'avg_users',
                    title: 'Avg. Players',
                    y_axis_label: 'Players',
                    unit: 'count',
                    label: 'Average Players',
                    free_tier: 0
                },
                {
                    key: 'avg_usage_time',
                    title: 'Avg. Playing Time',
                    y_axis_label: 'Time',
                    unit: 'time',
                    label: 'Average Playing Time',
                    free_tier: 0
                }
            ];
            break;
        case 'Audio':
            requestedSections = [
                {
                    key: 'total_views',
                    title: 'Total Listens',
                    y_axis_label: 'Listens',
                    unit: 'count',
                    label: 'Total Listens',
                    default: 1,
                    free_tier: 1
                },
                {
                    key: 'total_users',
                    title: 'Total Listeners',
                    y_axis_label: 'Listeners',
                    unit: 'count',
                    label: 'Total Listeners',
                    free_tier: 0
                },
                {
                    key: 'total_usage_time',
                    title: 'Total Listening Time',
                    y_axis_label: 'Time',
                    unit: 'time',
                    label: 'Total Listening Time',
                    free_tier: 0
                },
                {
                    key: 'avg_users',
                    title: 'Avg. Listeners',
                    y_axis_label: 'Listeners',
                    unit: 'count',
                    label: 'Avg. Listeners',
                    free_tier: 0
                },
                {
                    key: 'avg_usage_time',
                    title: 'Avg. Listening Time',
                    y_axis_label: 'Time',
                    unit: 'time',
                    label: 'Avg. Listening Time',
                    free_tier: 0
                }
            ];
            break;
        case 'Electronic Reader':
            requestedSections = [
                {
                    key: 'total_title_views',
                    title: 'Total Title Views',
                    y_axis_label: 'Views',
                    unit: 'count',
                    label: 'Total Title Views',
                    default: 1,
                    free_tier: 1
                },
                {
                    key: 'total_readers',
                    title: 'Total Readers',
                    y_axis_label: 'Readers',
                    unit: 'count',
                    label: 'Total Readers',
                    free_tier: 0
                },
                {
                    key: 'total_reading_time',
                    title: 'Total Reading Time',
                    y_axis_label: 'Time',
                    unit: 'time',
                    label: 'Total Reading Time',
                    free_tier: 0
                },
                {
                    key: 'avg_reading_time',
                    title: 'Avg. Reading Time',
                    y_axis_label: 'Time',
                    unit: 'time',
                    label: 'Avg. Reading Time',
                    free_tier: 0
                },
                {
                    key: 'avg_readers',
                    title: 'Avg. Readers',
                    y_axis_label: 'Readers',
                    unit: 'count',
                    label: 'Avg. Readers',
                    free_tier: 0
                },
                {
                    key: 'avg_page_reads',
                    title: 'Avg. Page Reads',
                    y_axis_label: 'Reads',
                    unit: 'count',
                    label: 'Avg. Page Reads',
                    free_tier: 0
                }
                //{ key: "total_views", title: "Total Title Views", y_axis_label: "Views", unit: "count", label: "Total Title Views", "free_tier": 0 },
            ];
            break;
        case 'Companion App':
            requestedSections = [
                {
                    key: 'total_paired_users',
                    title: 'Total Paired Users',
                    y_axis_label: 'Users',
                    unit: 'count',
                    label: 'Avg. Users',
                    default: 1
                },
                {
                    key: 'total_users',
                    title: 'Total Paired Users launched Media',
                    y_axis_label: 'Users',
                    unit: 'count',
                    label: 'Total Users'
                },
                {
                    key: 'total_views',
                    title: 'Total Media Launches',
                    y_axis_label: 'Launches',
                    unit: 'count',
                    label: 'Total Media Launches',
                    free_tier: 1
                },
                {
                    key: 'avg_media_launches',
                    title: 'Avg. Media Launches per User',
                    y_axis_label: 'Launches',
                    unit: 'count',
                    label: 'Avg. Media Launches per User'
                }
            ];
            break;
        case 'Bluetooth':
            requestedSections = [
                {
                    key: 'paired_devices',
                    title: 'Paired Devices',
                    y_axis_label: 'Devices',
                    unit: 'count',
                    label: 'Paired Devices',
                    default: 1,
                    free_tier: 1
                },
                {
                    key: 'total_connections',
                    title: 'Total Connections',
                    y_axis_label: 'Connections',
                    unit: 'count',
                    label: 'Total Connections',
                    free_tier: 1
                },
                {
                    key: 'total_disconnections',
                    title: 'Total Disconnections',
                    y_axis_label: 'Disconnections',
                    unit: 'count',
                    label: 'Total Disconnections',
                    free_tier: 1
                }
            ];
            break;
        case 'Insights':
        case 'Overview':
            requestedSections = [
                {
                    key: 'movies',
                    title: 'Movies',
                    y_axis_label: 'Views',
                    unit: 'count',
                    label: 'Movies - Views',
                    free_tier: 1
                },
                {
                    key: 'tv_series',
                    title: 'TV Series',
                    y_axis_label: 'Views',
                    unit: 'count',
                    label: 'TV Series - Views',
                    free_tier: 1
                },
                {
                    key: 'audio',
                    title: 'Audio',
                    y_axis_label: 'Listens',
                    unit: 'count',
                    label: 'Audio - Listens',
                    free_tier: 1
                },
                {
                    key: 'games',
                    title: 'Games',
                    y_axis_label: 'Plays',
                    unit: 'count',
                    label: 'Games - Plays',
                    free_tier: 1
                },
                {
                    key: 'live_tv',
                    title: 'Live TV',
                    y_axis_label: 'Views',
                    unit: 'count',
                    label: 'Live TV - Views',
                    free_tier: 1
                }
            ];
            break;
        default:
            break;
    }

    return requestedSections;
}

export default getTrendChartRequestedSectionsHelper;
