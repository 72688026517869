function getTitlesDropdownListHelper(reportSubType) {
    let dropdownList;

    switch (reportSubType) {
        case 'Movies':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'titles.most_popular.total_views',
                    text: 'Movies Total Views',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'titles.most_popular.total_time',
                    text: 'Movies Total Viewing Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                },
                {
                    label: 'Total Views (Bottom 10)',
                    unit: 'count',
                    value: 'titles.least_popular.total_views',
                    text: 'Movies Total Views',
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'titles.least_popular.total_time',
                    text: 'Movies Total Viewing Time',
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Excelmovies':
            dropdownList = [
                {
                    tab_title: 'Ordered by Total Views',
                    label: 'Total Views',
                    unit: 'count',
                    value: 'titles.all_records.total_views',
                    text: 'Movies Total Views',
                    default: 1,
                    free_tier: 1,
                    data: 'total_views',
                    additional_metrics: [
                        {
                            title: 'Total Viewing Time',
                            unit: 'hr',
                            data: 'total_engagement_time',
                            free_tier: 1
                        },
                        { title: 'Unique Viewers', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Runtime', unit: 'hr,min', data: 'media_duration' },
                        { title: 'Avg. Completion Rate', unit: 'count', data: 'avg_completion_rate' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                },
                {
                    tab_title: 'Ordered by Total Viewing Time',
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'titles.all_records.total_time',
                    text: 'Movies Total Viewing Time',
                    data: 'total_engagement_time',
                    additional_metrics: [
                        { title: 'Total Views', unit: 'count', data: 'total_views', free_tier: 1 },
                        { title: 'Unique Viewers', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Runtime', unit: 'hr,min', data: 'media_duration' },
                        { title: 'Avg. Completion Rate', unit: 'count', data: 'avg_completion_rate' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                }
            ];
            break;
        case 'TV Series':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'titles.most_popular.total_views',
                    text: 'TV Series Total Views',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'titles.most_popular.total_time',
                    text: 'TV Series Total Viewing Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                },
                {
                    label: 'Total Views (Bottom 10)',
                    unit: 'count',
                    value: 'titles.least_popular.total_views',
                    text: 'TV Series Total Views',
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'titles.least_popular.total_time',
                    text: 'TV Series Total Viewing Time',
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Exceltv-series':
            dropdownList = [
                {
                    tab_title: 'Ordered by Total Views(Series)',
                    title: 'TV Series Rankings',
                    label: 'Total Views',
                    unit: 'count',
                    value: 'titles.all_records.total_views',
                    text: 'TV Total Views',
                    default: 1,
                    free_tier: 1,
                    data: 'total_views',
                    additional_metrics: [
                        {
                            title: 'Total Viewing Time',
                            unit: 'hr',
                            data: 'total_engagement_time',
                            free_tier: 1
                        },
                        { title: 'Unique Viewers', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Runtime', unit: 'hr,min', data: 'media_duration' },
                        { title: 'Avg. Completion Rate', unit: 'count', data: 'avg_completion_rate' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                },
                {
                    tab_title: 'Ordered by Total Time(Series)',
                    title: 'TV Series Rankings',
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'titles.all_records.total_time',
                    text: 'TV Total Viewing Time',
                    data: 'total_engagement_time',
                    additional_metrics: [
                        { title: 'Total Views', unit: 'count', data: 'total_views', free_tier: 1 },
                        { title: 'Unique Viewers', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Runtime', unit: 'hr,min', data: 'media_duration' },
                        { title: 'Avg. Completion Rate', unit: 'count', data: 'avg_completion_rate' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                },
                {
                    tab_title: 'Ordered by Total Views(Episode)',
                    title: 'TV Episode Rankings',
                    label: 'Total Views',
                    unit: 'count',
                    value: 'titles.all_records.total_views_child',
                    text: 'TV Total Views',
                    free_tier: 1,
                    data: 'total_views',
                    additional_metrics: [
                        {
                            title: 'Total Viewing Time',
                            unit: 'hr',
                            data: 'total_engagement_time',
                            free_tier: 1
                        },
                        { title: 'Unique Viewers', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Runtime', unit: 'hr,min', data: 'media_duration' },
                        { title: 'Avg. Completion Rate', unit: 'count', data: 'avg_completion_rate' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value',
                    parent_title: { title: 'TV Series', unit: 'text', data: 'parent_title', free_tier: 1 }
                },
                {
                    tab_title: 'Ordered by Total Time(Episode)',
                    title: 'TV Episode Rankings',
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'titles.all_records.total_time_child',
                    text: 'TV Total Viewing Time',
                    data: 'total_engagement_time',
                    additional_metrics: [
                        { title: 'Total Views', unit: 'count', data: 'total_views', free_tier: 1 },
                        { title: 'Unique Viewers', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Runtime', unit: 'hr,min', data: 'media_duration' },
                        { title: 'Avg. Completion Rate', unit: 'count', data: 'avg_completion_rate' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value',
                    parent_title: { title: 'TV Series', unit: 'text', data: 'parent_title', free_tier: 1 }
                }
            ];
            break;
        case 'Audio':
            dropdownList = [
                {
                    label: 'Total Listens (Top 10)',
                    unit: 'count',
                    value: 'titles.most_popular.total_views',
                    text: 'Audio Total Listens',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Listens'
                },
                {
                    label: 'Total Listening Time (Top 10)',
                    unit: 'hr',
                    value: 'titles.most_popular.total_time',
                    text: 'Audio Total Listening Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Listening Time'
                },
                {
                    label: 'Total Listens (Bottom 10)',
                    unit: 'count',
                    value: 'titles.least_popular.total_views',
                    text: 'Audio Total Listens',
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Listens'
                },
                {
                    label: 'Total Listening Time (Bottom 10)',
                    unit: 'hr',
                    value: 'titles.least_popular.total_time',
                    text: 'Audio Total Listening Time',
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Listening Time'
                }
            ];
            break;
        case 'Excelaudio':
            dropdownList = [
                {
                    tab_title: 'Ordered by Total Listens(Album)',
                    title: 'Album Rankings',
                    label: 'Total Listens',
                    unit: 'count',
                    value: 'titles.all_records.total_views',
                    text: 'Audio Total Listens',
                    default: 1,
                    free_tier: 1,
                    data: 'total_views',
                    additional_metrics: [
                        {
                            title: 'Total Listening Time',
                            unit: 'hr',
                            data: 'total_engagement_time',
                            free_tier: 1
                        },
                        { title: 'Unique Listeners', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Runtime', unit: 'hr,min', data: 'media_duration' },
                        { title: 'Avg. Completion Rate', unit: 'count', data: 'avg_completion_rate' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                },
                {
                    tab_title: 'Ordered by Total Time(Album)',
                    title: 'Album Rankings',
                    label: 'Total Listening Time',
                    unit: 'hr',
                    value: 'titles.all_records.total_time',
                    text: 'Audio Total Listening Time',
                    data: 'total_engagement_time',
                    additional_metrics: [
                        { title: 'Total Listens', unit: 'count', data: 'total_views', free_tier: 1 },
                        { title: 'Unique Listeners', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Runtime', unit: 'hr', data: 'media_duration' },
                        { title: 'Avg. Completion Rate', unit: 'count', data: 'avg_completion_rate' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                },
                {
                    tab_title: 'Ordered by Total Listens(Track)',
                    title: 'Track Rankings',
                    label: 'Total Listens',
                    unit: 'count',
                    value: 'titles.all_records.total_views_child',
                    text: 'Audio Total Listens',
                    free_tier: 1,
                    data: 'total_views',
                    additional_metrics: [
                        {
                            title: 'Total Listening Time',
                            unit: 'hr',
                            data: 'total_engagement_time',
                            free_tier: 1
                        },
                        { title: 'Unique Listeners', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Runtime', unit: 'hr,min', data: 'media_duration' },
                        { title: 'Avg. Completion Rate', unit: 'count', data: 'avg_completion_rate' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value',
                    parent_title: { title: 'Album', unit: 'text', data: 'parent_title', free_tier: 1 }
                },
                {
                    tab_title: 'Ordered by Total Time(Track)',
                    title: 'Track Rankings',
                    label: 'Total Listening Time',
                    unit: 'hr',
                    value: 'titles.all_records.total_time_child',
                    text: 'Audio Total Listening Time',
                    data: 'total_engagement_time',
                    additional_metrics: [
                        { title: 'Total Listens', unit: 'count', data: 'total_views', free_tier: 1 },
                        { title: 'Unique Listeners', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Runtime', unit: 'hr', data: 'media_duration' },
                        { title: 'Avg. Completion Rate', unit: 'count', data: 'avg_completion_rate' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value',
                    parent_title: { title: 'Album', unit: 'text', data: 'parent_title', free_tier: 1 }
                }
            ];
            break;
        case 'Games':
            dropdownList = [
                {
                    label: 'Total Plays (Top 10)',
                    unit: 'count',
                    value: 'titles.most_popular.total_views',
                    text: 'Game Total Plays',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Plays'
                },
                {
                    label: 'Total Playing Time (Top 10)',
                    unit: 'hr',
                    value: 'titles.most_popular.total_time',
                    text: 'Game Total Playing Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Playing Time'
                },
                {
                    label: 'Total Plays (Bottom 10)',
                    unit: 'count',
                    value: 'titles.least_popular.total_views',
                    text: 'Game Total Plays',
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Plays'
                },
                {
                    label: 'Total Playing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'titles.least_popular.total_time',
                    text: 'Game Total Playing Time',
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Playing Time'
                }
            ];
            break;
        case 'Excelgames':
            dropdownList = [
                {
                    tab_title: 'Ordered by Total Plays',
                    label: 'Total Plays',
                    unit: 'count',
                    value: 'titles.all_records.total_views',
                    text: 'Game Total Plays',
                    default: 1,
                    free_tier: 1,
                    data: 'total_views',
                    additional_metrics: [
                        {
                            title: 'Total Playing Time',
                            unit: 'hr',
                            data: 'total_engagement_time',
                            free_tier: 1
                        },
                        { title: 'Unique Players', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Avg. Playing Time', unit: 'hr', data: 'avg_viewing_time' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                },
                {
                    tab_title: 'Ordered by Total Playing Time',
                    label: 'Total Playing Time',
                    unit: 'hr',
                    value: 'titles.all_records.total_time',
                    text: 'Game Total Playing Time',
                    data: 'total_engagement_time',
                    additional_metrics: [
                        { title: 'Total Plays', unit: 'count', data: 'total_views', free_tier: 1 },
                        { title: 'Unique Players', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Avg. Playing Time', unit: 'hr', data: 'avg_viewing_time' }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                }
            ];
            break;
        case 'Live TV':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'titles.most_popular.total_views',
                    text: 'Live TV Total Views',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'titles.most_popular.total_time',
                    text: 'Live TV Total Viewing Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                },
                {
                    label: 'Total Views (Bottom 10)',
                    unit: 'count',
                    value: 'titles.least_popular.total_views',
                    text: 'Live TV Total Views',
                    section: 'bottom_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'titles.least_popular.total_time',
                    text: 'Live TV Total Viewing Time',
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Excellive-tv':
            dropdownList = [
                {
                    tab_title: 'Ordered by Total Views(Channel)',
                    label: 'Total Views',
                    unit: 'count',
                    value: 'titles.all_records.total_views',
                    text: 'Live TV Total Views',
                    default: 1,
                    free_tier: 1,
                    data: 'total_views',
                    additional_metrics: [
                        {
                            title: 'Total Viewing Time',
                            unit: 'hr',
                            data: 'total_engagement_time',
                            free_tier: 1
                        },
                        { title: 'Unique Viewers', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Avg. Viewing Time', unit: 'hr', data: 'avg_viewing_time', free_tier: 0 }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                },
                {
                    tab_title: 'Ordered by Total Time(Channel)',
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'titles.all_records.total_time',
                    text: 'Live TV Total Viewing Time',
                    data: 'total_engagement_time',
                    additional_metrics: [
                        { title: 'Total Views', unit: 'count', data: 'total_views', free_tier: 1 },
                        { title: 'Unique Viewers', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Avg. Viewing Time', unit: 'hr', data: 'avg_viewing_time', free_tier: 0 }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                },
                {
                    tab_title: 'Ordered by Total Views(Program)',
                    label: 'Total Views',
                    unit: 'count',
                    value: 'titles.all_records.total_views_child',
                    text: 'Live TV Total Views',
                    default: 1,
                    free_tier: 1,
                    data: 'total_views',
                    additional_metrics: [
                        {
                            title: 'Total Viewing Time',
                            unit: 'hr',
                            data: 'total_engagement_time',
                            free_tier: 1
                        },
                        { title: 'Unique Viewers', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Avg. Viewing Time', unit: 'hr', data: 'avg_viewing_time', free_tier: 0 }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                },
                {
                    tab_title: 'Ordered by Total Time(Program)',
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'titles.all_records.total_time_child',
                    text: 'Live TV Total Viewing Time',
                    data: 'total_engagement_time',
                    additional_metrics: [
                        { title: 'Total Views', unit: 'count', data: 'total_views', free_tier: 1 },
                        { title: 'Unique Viewers', unit: 'count', data: 'unique_views', free_tier: 1 },
                        { title: 'Avg. Viewing Time', unit: 'hr', data: 'avg_viewing_time', free_tier: 0 }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                }
            ];
            break;
        case 'Electronic Reader':
            dropdownList = [
                {
                    label: 'Total Title Views (Top 10)',
                    unit: 'count',
                    value: 'titles.most_popular.total_title_views',
                    text: 'eReader Total Title Views',
                    default: 1,
                    section: 'top_10_total_title_views',
                    small_count_label: 'Total Title Views'
                },
                {
                    label: 'Total Readers (Top 10)',
                    unit: 'count',
                    value: 'titles.most_popular.total_readers',
                    text: 'eReader Total Readers',
                    section: 'top_10_total_readers',
                    small_count_label: 'Total Readers'
                },
                {
                    label: 'Total Reading Time (Top 10)',
                    unit: 'hr',
                    value: 'titles.most_popular.total_time',
                    text: 'eReader Total Reading Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Reading Time'
                },
                {
                    label: 'Avg. Reading Time (Top 10)',
                    unit: 'hr',
                    value: 'titles.most_popular.avg_time',
                    text: 'eReader Avg. Reading Time',
                    section: 'top_10_avg_time',
                    small_count_label: 'Avg. Reading Time'
                },
                {
                    label: 'Total Title Views (Bottom 10)',
                    unit: 'count',
                    value: 'titles.least_popular.total_title_views',
                    text: 'eReader Total Title Views',
                    section: 'bottom_10_total_title_views',
                    small_count_label: 'Total Title Views'
                },
                {
                    label: 'Total Readers (Bottom 10)',
                    unit: 'count',
                    value: 'titles.least_popular.total_readers',
                    text: 'eReader Total Readers',
                    section: 'bottom_10_total_readers',
                    small_count_label: 'Total Readers'
                },
                {
                    label: 'Total Reading Time (Bottom 10)',
                    unit: 'hr',
                    value: 'titles.least_popular.total_time',
                    text: 'eReader Total Reading Time',
                    section: 'bottom_10_total_time',
                    small_count_label: 'Total Reading Time'
                },
                {
                    label: 'Avg. Reading Time (Bottom 10)',
                    unit: 'hr',
                    value: 'titles.least_popular.avg_time',
                    text: 'eReader Avg. Reading Time',
                    section: 'bottom_10_avg_time',
                    small_count_label: 'Avg. Reading Time'
                }
            ];
            break;
        case 'Excelereader':
            dropdownList = [
                {
                    label: 'Total Title Views',
                    unit: 'count',
                    value: 'titles.all_records.total_title_views',
                    text: 'eReader Title Views',
                    default: 1,
                    free_tier: 1,
                    data: 'value',
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value',
                    additional_metrics: [
                        {
                            title: 'Total Reading Time',
                            unit: 'hr,min,sec',
                            data: 'time_to_read',
                            free_tier: 1
                        },
                        { title: 'Total Readers', unit: 'count', data: 'total_readers', free_tier: 1 },
                        {
                            title: 'Total Page Reads',
                            unit: 'count',
                            data: 'total_page_reads',
                            free_tier: 1
                        },
                        { title: 'Avg. Reading Time', unit: 'hr,min,sec', data: 'avg_reading_time' },
                        { title: 'Avg. Page Reads', unit: 'count', data: 'avg_page_reads' }
                    ]
                },
                {
                    label: 'Total Readers',
                    unit: 'count',
                    value: 'titles.all_records.total_readers',
                    text: 'eReader Total Readers',
                    data: 'total_readers',
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value',
                    additional_metrics: [
                        {
                            title: 'Total Reading Time',
                            unit: 'hr,min,sec',
                            data: 'time_to_read',
                            free_tier: 1
                        },
                        {
                            title: 'Total Page Reads',
                            unit: 'count',
                            data: 'total_page_reads',
                            free_tier: 1
                        },
                        { title: 'Avg. Reading Time', unit: 'hr,min,sec', data: 'avg_reading_time' },
                        { title: 'Avg. Page Reads', unit: 'count', data: 'avg_page_reads' }
                    ]
                },
                {
                    label: 'Total Reading Time',
                    unit: 'hr,min,sec',
                    value: 'titles.all_records.total_time',
                    text: 'eReader Total Reading Time',
                    data: 'time_to_read',
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value',
                    additional_metrics: [
                        { title: 'Total Readers', unit: 'count', data: 'total_readers', free_tier: 1 },
                        {
                            title: 'Total Page Reads',
                            unit: 'count',
                            data: 'total_page_reads',
                            free_tier: 1
                        },
                        { title: 'Avg. Reading Time', unit: 'hr,min,sec', data: 'avg_reading_time' },
                        { title: 'Avg. Page Reads', unit: 'count', data: 'avg_page_reads' }
                    ]
                },
                {
                    label: 'Avg. Reading Time',
                    unit: 'hr,min,sec',
                    value: 'titles.all_records.avg_time',
                    text: 'eReader Avg. Reading Time',
                    data: 'avg_reading_time',
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value',
                    additional_metrics: [
                        {
                            title: 'Total Reading Time',
                            unit: 'hr,min,sec',
                            data: 'time_to_read',
                            free_tier: 1
                        },
                        { title: 'Total Readers', unit: 'count', data: 'total_readers', free_tier: 1 },
                        {
                            title: 'Total Page Reads',
                            unit: 'count',
                            data: 'total_page_reads',
                            free_tier: 1
                        },
                        { title: 'Avg. Page Reads', unit: 'count', data: 'avg_page_reads' }
                    ]
                }
            ];
            break;
        default:
    }

    return dropdownList;
}

export default getTitlesDropdownListHelper;
