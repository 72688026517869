import React from 'react';
import WidgetFrame from 'components/updatedComponents/WidgetFrameNew';
import AppDataDropdownHelper from 'data/app-data-dropdown-helper.js';
import LineChart from 'components/common/charts/TrendLineChart';
import ChartProperties from 'components/common/charts/ChartProperties';
import _ from 'lodash';
import UtilHelper from 'util/UtilHelper.js';
import AppDataHelper from 'data/app-data-helper.js';

const utilHelper = new UtilHelper();
const appDataDropdownHelper = new AppDataDropdownHelper();
const chartHelper = new ChartProperties();
const appDataHelper = new AppDataHelper();

class ScatterPlotDropdownWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            selectedDropdownOption: ''
        };
    }

    updateWidgetData = (newData) => {
        if (newData.text) {
            let chartKey = newData.text.toLowerCase();
            let perHourKey = chartKey + '_per_hour_flown';
            let perHourPercentileKey = chartKey + '_per_hour_flown_percentile';

            if (newData.data) {
                let plotData = newData.data.map((obj) => {
                    let retObj = {
                        tail_number: obj.label,
                        percentile: obj.percentile,
                        icao: obj.icao,
                        flights: obj.flights,
                        flight_duration: obj.flight_duration,
                        value: obj.value
                    };
                    retObj[perHourKey] = obj[perHourKey];
                    retObj[perHourPercentileKey] = obj[perHourPercentileKey];

                    return retObj;
                });

                //utilHelper.shuffleArray(plotData);
                plotData.sort((a, b) => {
                    return a.flight_duration - b.flight_duration;
                });

                let chartData = {
                    current_date_range: {
                        data: plotData
                    }
                };

                let chartUnit = 'count';

                let labelset = [];
                let datasets = [];
                let dataSet1 = [];
                let options = {};
                let i = 0;

                let hoverList = appDataHelper.getScatterPlotHoverList(this.props.pageName, chartKey);
                let hoverData = null;

                if (chartData) {
                    if (
                        chartData.current_date_range &&
                        chartData.current_date_range.data &&
                        Object.keys(chartData.current_date_range.data).length > 0
                    ) {
                        chartData.current_date_range.data.forEach((item) => {
                            labelset[i] = item.flight_duration;

                            if (item.percentile !== undefined && item.percentile !== null) {
                                dataSet1[i] = item.percentile;
                            } else {
                                dataSet1[i] = null;
                            }

                            i++;
                        });

                        let chartPropUnit = chartUnit;

                        let chartObject = chartHelper.getChartProperties('scatter', chartPropUnit);

                        options = chartObject.options;

                        datasets[0] = chartObject.graphStyle;
                        datasets[0]['data'] = dataSet1;
                        datasets[0]['labels'] = labelset;
                        datasets[0]['hoverData'] = hoverData;
                        datasets[0]['fill'] = false;
                        datasets[0]['showLine'] = false;
                        datasets[0]['pointBackgroundColor'] = function (context) {
                            var value = context.dataset.data[context.dataIndex];
                            return value < 15 ? '#f39494' : value > 90 ? '#abf1ab' : '#66c3e8';
                        };
                        datasets[0]['pointHoverBackgroundColor'] = function (context) {
                            var value = context.dataset.data[context.dataIndex];
                            return value < 15 ? '#f39494' : value > 90 ? '#abf1ab' : '#66c3e8';
                        };
                    }
                }

                let data = {
                    labels: labelset,
                    datasets: datasets,
                    unit: chartUnit
                };

                if (!_.isEmpty(options)) {
                    options.tooltips['callbacks'] = {
                        title: function (tooltipItem, data) {
                            // Remove title
                            return null;
                        },
                        label: function (tooltipItem, data) {
                            let index = tooltipItem.index;

                            if (hoverList && hoverList.length > 0) {
                                let labels = [];

                                hoverList.forEach((item) => {
                                    let hoverItemLabelData = '';
                                    if (item.unit && item.unit === 'count') {
                                        hoverItemLabelData = utilHelper.formatToUnitsForPillBox(
                                            plotData[index][item.key]
                                        );
                                    } else if (item.unit && item.unit === 'time') {
                                        hoverItemLabelData = utilHelper.secondsToHmsForDynamicTabs(
                                            plotData[index][item.key]
                                        );
                                    } else {
                                        hoverItemLabelData = plotData[index][item.key];
                                    }

                                    labels.push(item.label + ': ' + hoverItemLabelData);
                                });

                                return labels;
                            } else {
                                return [];
                            }
                        }
                    };
                }

                this.setState({
                    data: data,
                    options: options,
                    selectedDropdownOption: newData.text ? newData.text : this.state.selectedDropdownOption
                });
            }
        }
    };

    render() {
        let scatterPlot;

        let dropdownList = appDataDropdownHelper.getDropdownList('tails', this.props.pageName);
        let dropdownOption = this.state.selectedDropdownOption;

        let legend = (
            <div style={{ height: '15px' }}>
                <div
                    className={`scatter-plot-legend ${
                        dropdownOption === 'Connections' ||
                        dropdownOption === 'Devices' ||
                        dropdownOption === 'Listens' ||
                        dropdownOption === 'Launches'
                            ? 'bluetooth'
                            : ''
                    }`}
                >
                    <div style={{ height: '24px' }}>
                        <div
                            className="scatter-plot-legend-color"
                            style={{
                                backgroundColor: '#abf1ab'
                            }}
                        ></div>
                        <div className="scatter-plot-legend-label">
                            <span>{'Percentile (' + dropdownOption + ') > 90'}</span>
                        </div>
                    </div>
                    <div style={{ height: '24px' }}>
                        <div
                            className="scatter-plot-legend-color"
                            style={{
                                backgroundColor: '#f39494'
                            }}
                        ></div>
                        <div className="scatter-plot-legend-label">
                            <span>{'Percentile (' + dropdownOption + ') < 15'}</span>
                        </div>
                    </div>
                    <div style={{ height: '24px' }}>
                        <div
                            className="scatter-plot-legend-color"
                            style={{
                                backgroundColor: '#66c3e8'
                            }}
                        ></div>
                        <div className="scatter-plot-legend-label">
                            <span>{'Percentile (' + dropdownOption + ') 90-15'}</span>
                        </div>
                    </div>
                </div>
            </div>
        );

        if (this.state.data && this.state.data.datasets.length > 0) {
            scatterPlot = (
                <div>
                    {legend}
                    <LineChart
                        data={this.state.data}
                        options={this.state.options}
                        cardClass="line-chart"
                        lineHeight={370}
                        dateRangeText={''}
                        dataAvailable={true}
                        loading={this.props.loading}
                        showWorkbenchLink={false}
                        comparisonDataFlag={false}
                        scatterYAxisLabel="Percentile"
                        scatterXAxisLabel="Flights Duration"
                    />
                </div>
            );
        } else {
            scatterPlot = (
                <div style={{ paddingLeft: '14px', paddingRight: '5px' }}>
                    <div className="no-data" style={{ marginTop: '12%', fontSize: '22px' }}>
                        Data is not available
                    </div>
                </div>
            );
        }

        return (
            <WidgetFrame
                id={Math.random()}
                type={this.props.type}
                pageName={this.props.pageName}
                loading={this.props.loading}
                title={'Tails'}
                showTabs={false}
                showOverflow={false}
                showDropdown={false}
                showFooter={false}
                body={scatterPlot}
                dd1Options={dropdownList}
                updateData={this.updateWidgetData}
                data={this.props.data}
                switchView={this.props.switchView}
            />
        );
    }
}

export default ScatterPlotDropdownWidget;
