export default class AppDataHelper {
    getAPIPath = function (type) {
        let apiPath;

        switch (type) {
            case 'donut':
                apiPath = '/v2/metrics/engagement_data';
                break;
            case 'carousel':
            case 'horizontal-bar':
                apiPath = '/v2/metrics/popularity_metrics_data';
                break;
            case 'title-detail':
                apiPath = '/v2/metrics/media_usage_data';
                break;
            case 'trend':
                apiPath = '/v2/metrics/trend_usage_data';
                break;
            case 'screen-transition':
                apiPath = '/v2/metrics/screen_transition_data';
                break;
            case 'vertical-bar':
                apiPath = '/v2/metrics/media_usage_by_flight_phases';
                break;
            case 'cabin-class-viewing':
                apiPath = '/v2/metrics/cabin_class_data';
                break;
            default:
                break;
        }

        return apiPath;
    };

    getPillBoxSections = function (usageSource) {
        let sections = ['flights', 'tails', 'destination_countries', 'destination_airports', 'routes'];
        switch (usageSource) {
            case 'Companion App':
                sections = sections.concat(['devices', 'login_attempts']);
                break;
            case 'Bluetooth':
                sections = sections.concat([
                    'paired_devices',
                    'total_connections',
                    'total_disconnections',
                    'pairing_attempts',
                    'bt_users',
                    'avg_connections',
                    'avg_disconnections',
                    'avg_connection_time'
                ]);
                break;
            case 'Portal':
                sections = sections.concat([
                    'total_sessions',
                    'total_page_views',
                    'avg_session_duration',
                    'avg_page_view_duration',
                    'devices'
                ]);
                break;
            case 'Portal Navigation':
                sections = sections.concat([
                    'total_sessions',
                    'total_page_views',
                    'avg_session_duration',
                    'avg_page_view_duration',
                    'devices'
                ]);
                break;
            case 'Seatback':
                sections = sections.concat([
                    'total_sessions',
                    'total_page_views',
                    'avg_session_duration',
                    'avg_page_view_duration',
                    'login_attempts'
                ]);
                break;
            case 'Electronic Reader':
                sections = sections.concat([
                    'total_readers',
                    'total_reading_time',
                    'total_page_reads',
                    'total_title_views',
                    'unique_title_views',
                    'avg_reading_time',
                    'avg_page_reads',
                    'avg_title_views'
                ]);
                break;
            case 'Live TV':
                sections = sections.concat([
                    'total_channel_hits',
                    'total_viewing_time',
                    'total_flight_duration',
                    'total_unique_passengers',
                    'avg_passengers_per_flight',
                    'avg_time_per_flight',
                    'avg_time_per_passenger',
                    'avg_content_viewing_time'
                ]);
                break;
            default:
                console.error('Unknown usage source');
        }
        return sections;
    };

    getContentType = function (page) {
        let sections = ['Movies', 'Audio', 'TV Series', 'Games', 'Live TV', 'Electronic Reader'];
        let searched = sections.filter((item) => item === page);
        return searched.length > 0 ? searched : null;
    };

    getTrendChartHoverList = function (page, requestedSection) {
        let hoverList;

        switch (page) {
            case 'Bluetooth':
            case 'bluetooth':
                switch (requestedSection) {
                    case 'paired_devices':
                        hoverList = [
                            {
                                key: 'paired_devices',
                                title: 'Paired Devices',
                                unit: 'count',
                                label: 'Paired Devices',
                                excel: 0
                            },
                            {
                                key: 'bt_users',
                                title: 'Unique Passengers Used Bluetooth',
                                unit: 'count',
                                label: 'Unique Passengers Used Bluetooth',
                                short_label: 'Unique Passengers Used BT',
                                excel: 1
                            },
                            {
                                key: 'pairing_attempts',
                                title: 'Pairing Attempts',
                                unit: 'count',
                                label: 'Pairing Attempts',
                                excel: 1
                            },
                            {
                                key: 'successful_attempts',
                                title: 'Successful Attempts',
                                unit: 'count',
                                label: 'Successful Attempts',
                                excel: 1
                            },
                            {
                                key: 'failed_attempts',
                                title: 'Failed Attempts',
                                unit: 'count',
                                label: 'Failed Attempts',
                                excel: 1
                            },
                            {
                                key: 'reconnects',
                                title: 'Reconnects',
                                unit: 'count',
                                label: 'Reconnects',
                                excel: 1
                            }
                        ];
                        break;
                    case 'total_connections':
                        hoverList = [
                            {
                                key: 'avg_connection_time',
                                title: 'Avg. Connection Duration by Passenger Device',
                                unit: 'hr',
                                label: 'Avg. Connection Duration by Passenger Device',
                                short_label: 'Avg. Connection Duration',
                                excel: 1
                            },
                            {
                                key: 'avg_connections',
                                title: 'Avg. Connections per Flight',
                                unit: 'count',
                                label: 'Avg. Connections per Flight',
                                excel: 1
                            }
                        ];
                        break;
                    case 'total_disconnections':
                        hoverList = [
                            {
                                key: 'avg_disconnections',
                                title: 'Avg. Disconnections per Flight',
                                unit: 'count',
                                label: 'Avg. Disconnections per Flight',
                                excel: 1
                            }
                        ];
                        break;
                    case 'excel':
                        hoverList = [
                            {
                                key: 'paired_devices',
                                title: 'Paired Devices',
                                unit: 'count',
                                label: 'Paired Devices',
                                excel: 0
                            },
                            {
                                key: 'bt_users',
                                title: 'Unique Passengers Used Bluetooth',
                                unit: 'count',
                                label: 'Unique Passengers Used Bluetooth',
                                short_label: 'Unique Passengers Used BT',
                                excel: 1
                            },
                            {
                                key: 'pairing_attempts',
                                title: 'Pairing Attempts',
                                unit: 'count',
                                label: 'Pairing Attempts',
                                excel: 1
                            },
                            {
                                key: 'successful_attempts',
                                title: 'Successful Attempts',
                                unit: 'count',
                                label: 'Successful Attempts',
                                excel: 1
                            },
                            {
                                key: 'failed_attempts',
                                title: 'Failed Attempts',
                                unit: 'count',
                                label: 'Failed Attempts',
                                excel: 1
                            },
                            {
                                key: 'reconnects',
                                title: 'Reconnects',
                                unit: 'count',
                                label: 'Reconnects',
                                excel: 1
                            },
                            {
                                key: 'total_connections',
                                title: 'Total Connections',
                                y_axis_label: 'Connections',
                                unit: 'count',
                                label: 'Total Connections',
                                free_tier: 1,
                                excel: 1
                            },
                            {
                                key: 'avg_connection_time',
                                title: 'Avg. Connection Duration by Passenger Device',
                                unit: 'time',
                                label: 'Avg. Connection Duration by Passenger Device',
                                short_label: 'Avg. Connection Duration',
                                excel: 1
                            },
                            {
                                key: 'avg_connections',
                                title: 'Avg. Connections per Flight',
                                unit: 'count',
                                label: 'Avg. Connections per Flight',
                                excel: 1
                            },
                            {
                                key: 'total_disconnections',
                                title: 'Total Disconnections',
                                y_axis_label: 'Disconnections',
                                unit: 'count',
                                label: 'Total Disconnections',
                                free_tier: 1,
                                excel: 1
                            },
                            {
                                key: 'avg_disconnections',
                                title: 'Avg. Disconnections per Flight',
                                unit: 'count',
                                label: 'Avg. Disconnections per Flight',
                                excel: 1
                            }
                        ];
                        break;
                    default:
                }
                break;
            default:
                //  throw new Error("Please check the label you are passing")
                break;
        }

        return hoverList;
    };

    getScatterPlotHoverList = function (page, requestedSection) {
        let hoverList;

        switch (page) {
            case 'Movies':
            case 'TV Series':
            case 'Live TV':
            case 'EReader':
            case 'Overview':
            case 'Seatback':
            case 'Portal':
                switch (requestedSection) {
                    case 'views':
                        hoverList = [
                            {
                                key: 'tail_number',
                                unit: 'string',
                                label: 'Tail Number'
                            },
                            {
                                key: 'icao',
                                unit: 'string',
                                label: 'Airline'
                            },
                            {
                                key: 'flights',
                                unit: 'count',
                                label: 'Flights Count'
                            },
                            {
                                key: 'flight_duration',
                                unit: 'time',
                                label: 'Flights Duration'
                            },
                            {
                                key: 'value',
                                unit: 'count',
                                label: 'Views'
                            },
                            {
                                key: 'percentile',
                                unit: 'count',
                                label: 'Percentile (Views)'
                            },
                            {
                                key: 'views_per_hour_flown',
                                unit: 'count',
                                label: 'Views/Flight/Hour'
                            },
                            {
                                key: 'views_per_hour_flown_percentile',
                                unit: 'count',
                                label: 'Percentile (Views/Flight/Hour)'
                            }
                        ];
                        break;
                    case 'time':
                        hoverList = [
                            {
                                key: 'tail_number',
                                unit: 'string',
                                label: 'Tail Number'
                            },
                            {
                                key: 'icao',
                                unit: 'string',
                                label: 'Airline'
                            },
                            {
                                key: 'flights',
                                unit: 'count',
                                label: 'Flights Count'
                            },
                            {
                                key: 'flight_duration',
                                unit: 'time',
                                label: 'Flights Duration'
                            },
                            {
                                key: 'value',
                                unit: 'time',
                                label: 'Time'
                            },
                            {
                                key: 'percentile',
                                unit: 'count',
                                label: 'Percentile (Time)'
                            },
                            {
                                key: 'time_per_hour_flown',
                                unit: 'time',
                                label: 'Time/Flight/Hour'
                            },
                            {
                                key: 'time_per_hour_flown_percentile',
                                unit: 'count',
                                label: 'Percentile (Time/Flight/Hour)'
                            }
                        ];
                        break;

                    default:
                }
                break;
            case 'Audio':
                switch (requestedSection) {
                    case 'listens':
                        hoverList = [
                            {
                                key: 'tail_number',
                                unit: 'string',
                                label: 'Tail Number'
                            },
                            {
                                key: 'icao',
                                unit: 'string',
                                label: 'Airline'
                            },
                            {
                                key: 'flights',
                                unit: 'count',
                                label: 'Flights Count'
                            },
                            {
                                key: 'flight_duration',
                                unit: 'time',
                                label: 'Flights Duration'
                            },
                            {
                                key: 'value',
                                unit: 'count',
                                label: 'Listens'
                            },
                            {
                                key: 'percentile',
                                unit: 'count',
                                label: 'Percentile (Listens)'
                            },
                            {
                                key: 'views_per_hour_flown',
                                unit: 'count',
                                label: 'Listens/Flight/Hour'
                            },
                            {
                                key: 'views_per_hour_flown_percentile',
                                unit: 'count',
                                label: 'Percentile (Listens/Flight/Hour)'
                            }
                        ];
                        break;
                    case 'time':
                        hoverList = [
                            {
                                key: 'tail_number',
                                unit: 'string',
                                label: 'Tail Number'
                            },
                            {
                                key: 'icao',
                                unit: 'string',
                                label: 'Airline'
                            },
                            {
                                key: 'flights',
                                unit: 'count',
                                label: 'Flights Count'
                            },
                            {
                                key: 'flight_duration',
                                unit: 'time',
                                label: 'Flights Duration'
                            },
                            {
                                key: 'value',
                                unit: 'time',
                                label: 'Time'
                            },
                            {
                                key: 'percentile',
                                unit: 'count',
                                label: 'Percentile (Time)'
                            },
                            {
                                key: 'time_per_hour_flown',
                                unit: 'time',
                                label: 'Time/Flight/Hour'
                            },
                            {
                                key: 'time_per_hour_flown_percentile',
                                unit: 'count',
                                label: 'Percentile (Time/Flight/Hour)'
                            }
                        ];
                        break;

                    default:
                }
                break;
            case 'Companion App':
                switch (requestedSection) {
                    case 'devices':
                        hoverList = [
                            {
                                key: 'tail_number',
                                unit: 'string',
                                label: 'Tail Number'
                            },
                            {
                                key: 'icao',
                                unit: 'string',
                                label: 'Airline'
                            },
                            {
                                key: 'flights',
                                unit: 'count',
                                label: 'Flights Count'
                            },
                            {
                                key: 'flight_duration',
                                unit: 'time',
                                label: 'Flights Duration'
                            },
                            {
                                key: 'value',
                                unit: 'count',
                                label: 'Devices'
                            },
                            {
                                key: 'percentile',
                                unit: 'count',
                                label: 'Percentile (Devices)'
                            },
                            {
                                key: 'devices_per_hour_flown',
                                unit: 'count',
                                label: 'Devices/Flight/Hour'
                            },
                            {
                                key: 'devices_per_hour_flown_percentile',
                                unit: 'count',
                                label: 'Percentile (Devices/Flight/Hour)'
                            }
                        ];
                        break;
                    case 'launches':
                        hoverList = [
                            {
                                key: 'tail_number',
                                unit: 'string',
                                label: 'Tail Number'
                            },
                            {
                                key: 'icao',
                                unit: 'string',
                                label: 'Airline'
                            },
                            {
                                key: 'flights',
                                unit: 'count',
                                label: 'Flights Count'
                            },
                            {
                                key: 'flight_duration',
                                unit: 'time',
                                label: 'Flights Duration'
                            },
                            {
                                key: 'value',
                                unit: 'count',
                                label: 'Launches'
                            },
                            {
                                key: 'percentile',
                                unit: 'count',
                                label: 'Percentile (Launches)'
                            },
                            {
                                key: 'launches_per_hour_flown',
                                unit: 'count',
                                label: 'Launches/Flight/Hour'
                            },
                            {
                                key: 'launches_per_hour_flown_percentile',
                                unit: 'count',
                                label: 'Percentile (Launches/Flight/Hour)'
                            }
                        ];
                        break;

                    default:
                }
                break;
            case 'Games':
                switch (requestedSection) {
                    case 'plays':
                        hoverList = [
                            {
                                key: 'tail_number',
                                unit: 'string',
                                label: 'Tail Number'
                            },
                            {
                                key: 'icao',
                                unit: 'string',
                                label: 'Airline'
                            },
                            {
                                key: 'flights',
                                unit: 'count',
                                label: 'Flights Count'
                            },
                            {
                                key: 'flight_duration',
                                unit: 'time',
                                label: 'Flights Duration'
                            },
                            {
                                key: 'value',
                                unit: 'count',
                                label: 'Plays'
                            },
                            {
                                key: 'percentile',
                                unit: 'count',
                                label: 'Percentile (Plays)'
                            },
                            {
                                key: 'views_per_hour_flown',
                                unit: 'count',
                                label: 'Plays/Flight/Hour'
                            },
                            {
                                key: 'views_per_hour_flown_percentile',
                                unit: 'count',
                                label: 'Percentile (Plays/Flight/Hour)'
                            }
                        ];
                        break;
                    case 'time':
                        hoverList = [
                            {
                                key: 'tail_number',
                                unit: 'string',
                                label: 'Tail Number'
                            },
                            {
                                key: 'icao',
                                unit: 'string',
                                label: 'Airline'
                            },
                            {
                                key: 'flights',
                                unit: 'count',
                                label: 'Flights Count'
                            },
                            {
                                key: 'flight_duration',
                                unit: 'time',
                                label: 'Flights Duration'
                            },
                            {
                                key: 'value',
                                unit: 'time',
                                label: 'Time'
                            },
                            {
                                key: 'percentile',
                                unit: 'count',
                                label: 'Percentile (Time)'
                            },
                            {
                                key: 'time_per_hour_flown',
                                unit: 'time',
                                label: 'Time/Flight/Hour'
                            },
                            {
                                key: 'time_per_hour_flown_percentile',
                                unit: 'count',
                                label: 'Percentile (Time/Flight/Hour)'
                            }
                        ];
                        break;

                    default:
                }
                break;
            case 'Bluetooth':
                switch (requestedSection) {
                    case 'devices':
                        hoverList = [
                            {
                                key: 'tail_number',
                                unit: 'string',
                                label: 'Tail Number'
                            },
                            {
                                key: 'icao',
                                unit: 'string',
                                label: 'Airline'
                            },
                            {
                                key: 'flights',
                                unit: 'count',
                                label: 'Flights Count'
                            },
                            {
                                key: 'flight_duration',
                                unit: 'time',
                                label: 'Flights Duration'
                            },
                            {
                                key: 'value',
                                unit: 'count',
                                label: 'Devices'
                            },
                            {
                                key: 'percentile',
                                unit: 'count',
                                label: 'Percentile (Devices)'
                            },
                            {
                                key: 'devices_per_hour_flown',
                                unit: 'count',
                                label: 'Devices/Flight/Hour'
                            },
                            {
                                key: 'devices_per_hour_flown_percentile',
                                unit: 'count',
                                label: 'Percentile (Devices/Flight/Hour)'
                            }
                        ];
                        break;
                    case 'connections':
                        hoverList = [
                            {
                                key: 'tail_number',
                                unit: 'string',
                                label: 'Tail Number'
                            },
                            {
                                key: 'icao',
                                unit: 'string',
                                label: 'Airline'
                            },
                            {
                                key: 'flights',
                                unit: 'count',
                                label: 'Flights Count'
                            },
                            {
                                key: 'flight_duration',
                                unit: 'time',
                                label: 'Flights Duration'
                            },
                            {
                                key: 'value',
                                unit: 'count',
                                label: 'Connections'
                            },
                            {
                                key: 'percentile',
                                unit: 'count',
                                label: 'Percentile (Connections)'
                            },
                            {
                                key: 'connections_per_hour_flown',
                                unit: 'count',
                                label: 'Connections/Flight/Hour'
                            },
                            {
                                key: 'connections_per_hour_flown_percentile',
                                unit: 'count',
                                label: 'Percentile (Connections/Flight/Hour)'
                            }
                        ];
                        break;

                    default:
                }
                break;
            default:
                //  throw new Error("Please check the label you are passing")
                break;
        }

        return hoverList;
    };
}
