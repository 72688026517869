import InsightsMonthlyService from 'services/InsightsMonthlyService';

class SubscriptionService {
    static data = '';

    static refreshToken = '';

    static async fetchSubscription(refetch = false) {
        if (this.data === '' || refetch) {
            let service = new InsightsMonthlyService();
            this.data = await service.fetchSubscriptionData();
            return this.data;
        } else {
            return this.data;
        }
    }
}

export default SubscriptionService;
