import React from 'react';
import WidgetFrame from 'components/updatedComponents/WidgetFrameNew';
import SortTableHeaderButton from 'components/common/SortTableHeaderButton.js';
import { TablePagination } from 'pac-ux-components';
import * as AppConstants from 'constants/app/constants';
import moment from 'moment';
import UtilHelper from 'util/UtilHelper.js';

const utilHelper = new UtilHelper();

const DEFAULT_SORT_COLUMN = 'tail_no';
const DEFAULT_SORT_DIRECTION = 'ascending';

class WorkbenchTableWidget extends React.Component {
    constructor(props) {
        super(props);

        this.updateTableStateAction = this.updateTableStateAction.bind(this);

        this.state = {
            data: [],
            table: {
                currentTableSortColumn: DEFAULT_SORT_COLUMN,
                currentTableSortDirection: DEFAULT_SORT_DIRECTION,
                currentPageData: [],
                pageCount: AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE,
                paging: {
                    count: AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE,
                    page: 0
                },
                updateTableStateAction: this.updateTableStateAction
            }
        };

        this.paginate = this.paginate.bind(this);
    }

    async componentDidMount() {
        this.updateTableStateAction(0, this.props.tailsList, DEFAULT_SORT_DIRECTION, DEFAULT_SORT_COLUMN);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.tailsList.length !== this.props.tailsList.length) {
            this.updateTableStateAction(0, this.props.tailsList, DEFAULT_SORT_DIRECTION, DEFAULT_SORT_COLUMN);
        }
    }

    updateTableStateAction(newPage, dataset, direction, column) {
        let itemsPerPage = this.state.table.pageCount;

        let newTableStartIndex = itemsPerPage * newPage;
        let newTableEndIndex = newTableStartIndex + itemsPerPage;
        let newData = dataset.slice(newTableStartIndex, newTableEndIndex);

        let tableState = {};
        Object.assign(tableState, this.state.table);
        tableState.currentPageData = newData;
        tableState.paging = {
            count: itemsPerPage,
            page: newPage
        };
        tableState.currentTableSortDirection = direction;
        tableState.currentTableSortColumn = column;

        this.setState({
            table: tableState
        });
    }

    paginate(newPage) {
        this.updateTableStateAction(
            newPage,
            this.props.tailsList,
            this.state.table.currentTableSortDirection,
            this.state.table.currentTableSortColumn
        );
    }

    checkDataAvailable = () => {
        if (this.props.tailsList && this.props.tailsList.length > 0) {
            return true;
        }

        return false;
    };

    render() {
        let tailsTable;

        if (this.state.table.currentPageData.length > 0) {
            tailsTable = (
                <div className="table-widget">
                    <div className="table-outer-container">
                        <div className="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        {this.props.columns.map((column, idx) => (
                                            <th key={'th_' + idx}>
                                                <SortTableHeaderButton
                                                    column={column['value']}
                                                    label={column['header']}
                                                    dataType="string"
                                                    tableData={this.props.tailsList}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.table.currentPageData.map((tail, idx) => (
                                        <tr key={'tr_' + idx}>
                                            {this.props.columns.map((column, idx) =>
                                                column.type && column.type === 'date' ? (
                                                    <td key={'td_' + idx}>
                                                        {moment(tail[column.value]).format('YYYY-MM-DD')}
                                                    </td>
                                                ) : column.type && column.type === 'time' ? (
                                                    <td key={'td_' + idx}>
                                                        {utilHelper.secondsToHmsForDynamicTabs(tail[column.value])}
                                                    </td>
                                                ) : (
                                                    <td key={'td_' + idx}>{tail[column.value]}</td>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination-container">
                            <TablePagination
                                itemCount={this.props.tailsList.length}
                                onChangePage={this.paginate}
                                paging={this.state.table.paging}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            tailsTable = (
                <div style={{ paddingLeft: '14px', paddingRight: '5px' }}>
                    <div className="no-data" style={{ marginTop: '16%', fontSize: '22px' }}>
                        Data is not available
                    </div>
                </div>
            );
        }

        return (
            <WidgetFrame
                id={Math.random()}
                type="table"
                pageName={this.props.pageName}
                loading={this.props.loading}
                title={this.props.title}
                showTabs={false}
                body={tailsTable}
                showOverflow={true}
                dataAvailable={this.checkDataAvailable()}
                tenantIcaoCode={this.props.tenantIcaoCode || ''}
                page={this.props.page}
                appliedFilters={this.props.appliedFilters}
                app={this.props.app}
                data={this.props.tailsList}
            />
        );
    }
}

export default WorkbenchTableWidget;
