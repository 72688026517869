function getProgramTitlesDropdownList(reportSubType) {
    let dropdownList;

    switch (reportSubType) {
        case 'Live TV':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'program_titles.most_popular.total_views',
                    text: 'Live TV Program Total Views',
                    default: 1,
                    section: 'top_10_program_titles_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'program_titles.most_popular.total_time',
                    text: 'Live TV Program Total Viewing Time',
                    section: 'top_10_program_titles_total_time',
                    small_count_label: 'Total Viewing Time'
                },
                {
                    label: 'Avg. Time Per Flight (Top 10)',
                    unit: 'hr',
                    value: 'program_titles.most_popular.avg_time_per_flight',
                    text: 'Live TV Program Avg. Viewing Time Per Flight',
                    section: 'top_10_avg_time_per_flight',
                    small_count_label: 'Avg. Time Per Flight'
                },
                {
                    label: 'Avg. Time Per Passenger (Top 10)',
                    unit: 'hr',
                    value: 'program_titles.most_popular.avg_time_per_passenger',
                    text: 'Live TV Program Avg. Viewing Time Per Passenger',
                    section: 'top_10_avg_time_per_passenger',
                    small_count_label: 'Avg. Time Per Passenger'
                },
                {
                    label: 'Avg. Time Per View (Top 10)',
                    unit: 'hr',
                    value: 'program_titles.most_popular.avg_time_per_view',
                    text: 'Live TV Program Avg. Viewing Time Per View',
                    section: 'top_10_avg_time_per_view',
                    small_count_label: 'Avg. Time Per View'
                },
                {
                    label: 'Total Flights (Top 10)',
                    unit: 'count',
                    value: 'program_titles.most_popular.total_flights',
                    text: 'Live TV Program Total Flights',
                    section: 'top_10_total_flights',
                    small_count_label: 'Total Flights'
                },
                {
                    label: 'Unique Passengers (Top 10)',
                    unit: 'count',
                    value: 'program_titles.most_popular.unique_passengers',
                    text: 'Live TV Program Unique Passengers',
                    section: 'top_10_unique_passengers',
                    small_count_label: 'Unique Passengers'
                },
                {
                    label: 'Total Views (Bottom 10)',
                    unit: 'count',
                    value: 'program_titles.least_popular.total_views',
                    text: 'Live TV Program Total Views',
                    section: 'bottom_10_program_titles_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Bottom 10)',
                    unit: 'hr',
                    value: 'program_titles.least_popular.total_time',
                    text: 'Live TV Program Total Viewing Time',
                    section: 'bottom_10_program_titles_total_time',
                    small_count_label: 'Total Viewing Time'
                },
                {
                    label: 'Avg. Time Per Flight (Bottom 10)',
                    unit: 'hr',
                    value: 'program_titles.least_popular.avg_time_per_flight',
                    text: 'Live TV Program Avg. Viewing Time Per Flight',
                    section: 'bottom_10_avg_time_per_flight',
                    small_count_label: 'Avg. Time Per Flight'
                },
                {
                    label: 'Avg. Time Per Passenger (Bottom 10)',
                    unit: 'hr',
                    value: 'program_titles.least_popular.avg_time_per_passenger',
                    text: 'Live TV Program Avg. Viewing Time Per Passenger',
                    section: 'bottom_10_avg_time_per_passenger',
                    small_count_label: 'Avg. Time Per Passenger'
                },
                {
                    label: 'Avg. Time Per View (Bottom 10)',
                    unit: 'hr',
                    value: 'program_titles.least_popular.avg_time_per_view',
                    text: 'Live TV Program Avg. Viewing Time Per View',
                    section: 'bottom_10_avg_time_per_view',
                    small_count_label: 'Avg. Time Per View'
                },
                {
                    label: 'Total Flights (Bottom 10)',
                    unit: 'count',
                    value: 'program_titles.least_popular.total_flights',
                    text: 'Live TV Program Total Flights',
                    section: 'bottom_10_total_flights',
                    small_count_label: 'Total Flights'
                },
                {
                    label: 'Unique Passengers (Bottom 10)',
                    unit: 'count',
                    value: 'program_titles.least_popular.unique_passengers',
                    text: 'Live TV Program Unique Passengers',
                    section: 'bottom_10_unique_passengers',
                    small_count_label: 'Unique Passengers'
                }
            ];
            break;
        case 'Excellive-tv':
            dropdownList = [
                {
                    tab_title: 'Total Views',
                    label: 'Total Views',
                    unit: 'count',
                    value: 'program_titles.all_records',
                    text: 'Live TV Program Total Views',
                    default: 1,
                    free_tier: 1,
                    data: 'total_views',
                    additional_metrics: [
                        {
                            title: 'Total Viewing Time',
                            unit: 'hr',
                            data: 'total_time',
                            free_tier: 1
                        },
                        { title: 'Unique Passenger Count', unit: 'count', data: 'unique_passengers', free_tier: 1 },
                        { title: 'Flight Count', unit: 'count', data: 'total_flights', free_tier: 1 },
                        { title: 'Avg. Viewing Time per View', unit: 'hr', data: 'avg_time_per_view', free_tier: 0 },
                        {
                            title: 'Avg. Viewing Time per Passenger',
                            unit: 'hr',
                            data: 'avg_time_per_passenger',
                            free_tier: 0
                        },
                        {
                            title: 'Avg. Viewing Time per Flight',
                            unit: 'hr',
                            data: 'avg_time_per_flight',
                            free_tier: 0
                        }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                },
                {
                    tab_title: 'Total Viewing Time',
                    label: 'Total Viewing Time',
                    unit: 'hr',
                    value: 'program_titles.all_records',
                    text: 'Live TV Program Total Viewing Time',
                    data: 'total_time',
                    additional_metrics: [
                        { title: 'Total Views', unit: 'count', data: 'total_views', free_tier: 1 },
                        { title: 'Unique Passenger Count', unit: 'count', data: 'unique_passengers', free_tier: 1 },
                        { title: 'Flight Count', unit: 'count', data: 'total_flights', free_tier: 1 },
                        { title: 'Avg. Viewing Time per View', unit: 'hr', data: 'avg_time_per_view', free_tier: 0 },
                        {
                            title: 'Avg. Viewing Time per Passenger',
                            unit: 'hr',
                            data: 'avg_time_per_passenger',
                            free_tier: 0
                        },
                        {
                            title: 'Avg. Viewing Time per Flight',
                            unit: 'hr',
                            data: 'avg_time_per_flight',
                            free_tier: 0
                        }
                    ],
                    comparison_data_1: 'comparison_raw_value',
                    comparison_data_2: 'comparison_value'
                }
            ];
            break;
        default:
            break;
    }

    return dropdownList;
}

export default getProgramTitlesDropdownList;
