import * as AppConstants from 'constants/app/constants';

class AuthHelper {
    
    currentUserBelongsToOnlyPacRackTesters(currentSession) {
        const groups = currentSession.idToken.payload["cognito:groups"];

        if (typeof groups !== 'undefined' && groups.length > 0 && groups.length <= 2) {
            if (groups.length === 1) {
                if (groups[0] === AppConstants.GROUP_PAC_RACK_TESTER) {
                    return true;
                }
            }
            if (groups.length === 2) {
                let rackTesterGroup = false;
                let qualityManagmentGroup = false;
                if (groups[0] === AppConstants.GROUP_PAC_RACK_TESTER || groups[1] === AppConstants.GROUP_PAC_RACK_TESTER) {
                    rackTesterGroup =  true;
                }
                if (groups[0] === AppConstants.GROUP_QUALITY_MANAGEMENT || groups[1] === AppConstants.GROUP_QUALITY_MANAGEMENT) {
                    qualityManagmentGroup =  true;
                }

                if (rackTesterGroup && qualityManagmentGroup) {
                    return true;
                }
            }
        }
        
        return false;
    }
}

export default AuthHelper;
