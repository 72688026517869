import getTitlesDropdownListHelper from 'data/DropdownListHelper/getTitlesDropdownListHelper.js';
import getLanguagesDropdownListHelper from 'data/DropdownListHelper/getLanguagesDropdownListHelper.js';
import getGenresDropdownListHelper from 'data/DropdownListHelper/getGenresDropdownListHelper.js';
import getCategoriesDropdownListHelper from 'data/DropdownListHelper/getCategoriesDropdownListHelper.js';
import getRoutesDropdownListHelper from 'data/DropdownListHelper/getRoutesDropdownListHelper.js';
import getPagesDropdownListHelper from 'data/DropdownListHelper/getPagesDropdownListHelper.js';
import getDonutDropdownListHelper from 'data/DropdownListHelper/getDonutDropdownListHelper.js';
import getProgramTitlesDropdownListHelper from 'data/DropdownListHelper/getProgramTitlesDropdownListHelper.js';
import getAirlinesDropdownListHelper from 'data/DropdownListHelper/getAirlinesDropdownListHelper.js';
import getTailsDropdownListHelper from 'data/DropdownListHelper/getTailsDropdownListHelper.js';

export default class AppDataDropdownHelper {
    getDonutDropdownList = function (requestedSection) {
        return getDonutDropdownListHelper(requestedSection);
    };

    getDropdownList = function (requestedSection, reportSubType) {
        let dropdownList;

        switch (requestedSection) {
            case 'titles':
                dropdownList = getTitlesDropdownListHelper(reportSubType);
                break;
            case 'languages':
                dropdownList = getLanguagesDropdownListHelper(reportSubType);
                break;
            case 'genres':
                dropdownList = getGenresDropdownListHelper(reportSubType);
                break;
            case 'categories':
                dropdownList = getCategoriesDropdownListHelper(reportSubType);
                break;
            case 'routes':
                dropdownList = getRoutesDropdownListHelper(reportSubType);
                break;
            case 'pages':
                dropdownList = getPagesDropdownListHelper(reportSubType);
                break;
            case 'program-titles':
                dropdownList = getProgramTitlesDropdownListHelper(reportSubType);
                break;
            case 'airlines':
                dropdownList = getAirlinesDropdownListHelper(reportSubType);
                break;
            case 'tails':
                dropdownList = getTailsDropdownListHelper(reportSubType);
                break;
            default:
                break;
        }

        return dropdownList;
    };
}
