import moment from 'moment';
import FiltersService from '../services/FiltersService';

const DynamicFilterHelper = {
    getAllAppliedFilters: function (state, page) {
        let allAppliedFilters = { api: {}, quicksight: '' };
        let cleanedAppliedFilters = { api: {}, quicksight: '' };

        if (state) {
            if (state.dateRangeValue) {
                allAppliedFilters['api']['date_range'] = state.dateRangeValue;
                cleanedAppliedFilters['api']['date_range'] = state.dateRangeValue;
                if (state.dateRangeText && state.dateRangeText !== '') {
                    allAppliedFilters['api']['dateRangeText'] = state.dateRangeText;
                    cleanedAppliedFilters['api']['dateRangeText'] = state.dateRangeText;
                }
                let dateRanges = state.dateRangeValue.split('-');
                if (dateRanges.length === 2) {
                    allAppliedFilters['quicksight'] +=
                        '&p.StartDate=' +
                        this.formatDateString(dateRanges[0]) +
                        '&p.EndDate=' +
                        this.formatDateString(dateRanges[1]);
                    cleanedAppliedFilters['quicksight'] +=
                        '&p.StartDate=' +
                        this.formatDateString(dateRanges[0]) +
                        '&p.EndDate=' +
                        this.formatDateString(dateRanges[1]);
                }
            }
            if (state.comparisonRangeValue && state.comparisonRangeValue !== 'None') {
                allAppliedFilters['api']['comparison_range'] = state.comparisonRangeValue;
                cleanedAppliedFilters['api']['comparison_range'] = state.comparisonRangeValue;
                if (state.comparisonRangeText && state.comparisonRangeText !== '') {
                    allAppliedFilters['api']['comparison_range_text'] = state.comparisonRangeText;
                    cleanedAppliedFilters['api']['comparison_range_text'] = state.comparisonRangeText;
                }
                let dateRanges = state.comparisonRangeValue.split('-');
                if (dateRanges.length === 2) {
                    allAppliedFilters['quicksight'] +=
                        '&p.ComparisonStartDate=' +
                        this.formatDateString(dateRanges[0]) +
                        '&p.ComparisonEndDate=' +
                        this.formatDateString(dateRanges[1]);
                    cleanedAppliedFilters['quicksight'] +=
                        '&p.ComparisonStartDate=' +
                        this.formatDateString(dateRanges[0]) +
                        '&p.ComparisonEndDate=' +
                        this.formatDateString(dateRanges[1]);
                }
            }
            if (state.appliedFromCity) {
                allAppliedFilters['api']['flight_origin'] = state.appliedFromCity;
                allAppliedFilters['quicksight'] += '&p.FlightOrigin=' + state.appliedFromCity;

                cleanedAppliedFilters['api']['flight_origin'] = state.appliedFromCity;
                cleanedAppliedFilters['quicksight'] += '&p.FlightOrigin=' + state.appliedFromCity;
            }
            if (state.appliedToCity) {
                allAppliedFilters['api']['flight_destination'] = state.appliedToCity;
                allAppliedFilters['quicksight'] += '&p.FlightDestination=' + state.appliedToCity;

                cleanedAppliedFilters['api']['flight_destination'] = state.appliedToCity;
                cleanedAppliedFilters['quicksight'] += '&p.FlightDestination=' + state.appliedToCity;
            }
            if (state.appliedFlightNumber) {
                allAppliedFilters['api']['flight_number'] = state.appliedFlightNumber;
                allAppliedFilters['quicksight'] += '&p.FlightNumber=' + state.appliedFlightNumber;

                cleanedAppliedFilters['api']['flight_number'] = state.appliedFlightNumber;
                cleanedAppliedFilters['quicksight'] += '&p.FlightNumber=' + state.appliedFlightNumber;
            }
            if (state.appliedTenant) {
                allAppliedFilters['tenant'] = state.appliedTenant;
                cleanedAppliedFilters['tenant'] = state.appliedTenant;
            }

            if (state.appliedLiveTVChannel) {
                allAppliedFilters['liveTVChannel'] = state.appliedLiveTVChannel;
                cleanedAppliedFilters['liveTVChannel'] = state.appliedLiveTVChannel;
            }

            if (state.appliedRegion) {
                allAppliedFilters['region'] = state.appliedRegion;
                cleanedAppliedFilters['region'] = state.appliedRegion;
            }

            if (state.appliedFilters) {
                if (state.appliedFilters.systemType && Object.keys(state.appliedFilters.systemType).length > 0) {
                    allAppliedFilters['api']['system_types'] = state.appliedFilters.systemType;

                    let cleanedSystemTypes = this.getCleanAppliedFilters(
                        'system_type',
                        state.appliedFilters.systemType,
                        page
                    );
                    if (cleanedSystemTypes && cleanedSystemTypes.length > 0) {
                        cleanedAppliedFilters['api']['system_types'] = cleanedSystemTypes;
                    }

                    for (let i = 0; i < state.appliedFilters.systemType.length; i++) {
                        let encodedSystemType =
                            state.appliedFilters.systemType[i] === '==='
                                ? encodeURIComponent(state.appliedFilters.systemType[i])
                                : state.appliedFilters.systemType[i];
                        allAppliedFilters['quicksight'] += '&p.SystemType=' + encodedSystemType;
                        if (this.isAppliedFilterExist('system_type', state.appliedFilters.systemType[i], page)) {
                            cleanedAppliedFilters['quicksight'] += '&p.SystemType=' + encodedSystemType;
                        }
                    }
                }
                if (state.appliedFilters.aircraftType && Object.keys(state.appliedFilters.aircraftType).length > 0) {
                    allAppliedFilters['api']['aircraft_types'] = state.appliedFilters.aircraftType;

                    let cleanedAircraftTypes = this.getCleanAppliedFilters(
                        'aircraft_type_subtype',
                        state.appliedFilters.aircraftType,
                        page
                    );
                    if (cleanedAircraftTypes && cleanedAircraftTypes.length > 0) {
                        cleanedAppliedFilters['api']['aircraft_types'] = cleanedAircraftTypes;
                    }

                    for (let i = 0; i < state.appliedFilters.aircraftType.length; i++) {
                        allAppliedFilters['quicksight'] += '&p.AirFrame=' + state.appliedFilters.aircraftType[i];
                        if (
                            this.isAppliedFilterExist(
                                'aircraft_type_subtype',
                                state.appliedFilters.aircraftType[i],
                                page
                            )
                        ) {
                            cleanedAppliedFilters['quicksight'] +=
                                '&p.AirFrame=' + state.appliedFilters.aircraftType[i];
                        }
                    }
                }
                if (
                    state.appliedFilters.flightDuration &&
                    Object.keys(state.appliedFilters.flightDuration).length > 0
                ) {
                    allAppliedFilters['api']['flight_haul_types'] = state.appliedFilters.flightDuration;

                    let cleanedHaulTypes = this.getCleanAppliedFilters(
                        'flight_haul_type',
                        state.appliedFilters.flightDuration,
                        page
                    );
                    if (cleanedHaulTypes && cleanedHaulTypes.length > 0) {
                        cleanedAppliedFilters['api']['flight_haul_types'] = cleanedHaulTypes;
                    }

                    for (let i = 0; i < state.appliedFilters.flightDuration.length; i++) {
                        allAppliedFilters['quicksight'] += '&p.FlightHaul=' + state.appliedFilters.flightDuration[i];
                        if (
                            this.isAppliedFilterExist('flight_haul_type', state.appliedFilters.flightDuration[i], page)
                        ) {
                            cleanedAppliedFilters['quicksight'] +=
                                '&p.FlightHaul=' + state.appliedFilters.flightDuration[i];
                        }
                    }
                }
                if (state.appliedFilters.seatClass && Object.keys(state.appliedFilters.seatClass).length > 0) {
                    allAppliedFilters['api']['seat_classes'] = state.appliedFilters.seatClass;

                    let cleanedSeatClasses = this.getCleanAppliedFilters(
                        'seat_class',
                        state.appliedFilters.seatClass,
                        page
                    );
                    if (cleanedSeatClasses && cleanedSeatClasses.length > 0) {
                        cleanedAppliedFilters['api']['seat_classes'] = cleanedSeatClasses;
                    }

                    for (let i = 0; i < state.appliedFilters.seatClass.length; i++) {
                        allAppliedFilters['quicksight'] += '&p.SeatClass=' + state.appliedFilters.seatClass[i];
                        if (this.isAppliedFilterExist('seat_class', state.appliedFilters.seatClass[i], page)) {
                            cleanedAppliedFilters['quicksight'] += '&p.SeatClass=' + state.appliedFilters.seatClass[i];
                        }
                    }
                }
                // if (state.appliedFilters.guiLanguage && Object.keys(state.appliedFilters.guiLanguage).length > 0) {
                //     allAppliedFilters["api"]["gui_languages"] = state.appliedFilters.guiLanguage;

                //     let cleanedGuiLanguages = this.getCleanAppliedFilters("gui_languages", state.appliedFilters.guiLanguage, state.filterValues);
                //     if (cleanedGuiLanguages && cleanedGuiLanguages.length > 0) {
                //         cleanedAppliedFilters["api"]["gui_languages"] = cleanedGuiLanguages;
                //     }

                //     for (let i = 0; i < state.appliedFilters.guiLanguage.length; i++) {
                //         allAppliedFilters["quicksight"] += "&p.guiLanguage=" + state.appliedFilters.guiLanguage[i];
                //         if (this.isAppliedFilterExist("gui_languages", state.appliedFilters.guiLanguage[i], state.filterValues)) {
                //             cleanedAppliedFilters["quicksight"] += "&p.guiLanguage=" + state.appliedFilters.guiLanguage[i];
                //         }
                //     }
                // }
                if (state.appliedFilters.tail && Object.keys(state.appliedFilters.tail).length > 0) {
                    allAppliedFilters['api']['tails'] = state.appliedFilters.tail;

                    let cleanedTails = this.getCleanAppliedFilters(
                        'aircraft_tail_number',
                        state.appliedFilters.tail,
                        page
                    );
                    if (cleanedTails && cleanedTails.length > 0) {
                        cleanedAppliedFilters['api']['tails'] = cleanedTails;
                    }

                    for (let i = 0; i < state.appliedFilters.tail.length; i++) {
                        allAppliedFilters['quicksight'] += '&p.Tail=' + state.appliedFilters.tail[i];
                        if (this.isAppliedFilterExist('aircraft_tail_number', state.appliedFilters.tail[i], page)) {
                            cleanedAppliedFilters['quicksight'] += '&p.Tail=' + state.appliedFilters.tail[i];
                        }
                    }
                }
            }
        }

        //return allAppliedFilters;

        return { all_applied_filters: allAppliedFilters, cleaned_applied_filters: cleanedAppliedFilters };
    },

    setSelectedFiltersToStore: function (state) {
        let selectedFiltersToStore = {
            //expanded: false,
            applyFilterButtonDisabled: true,
            clearFilterButtonDisabled: true,
            dateRangeValue: 'None',
            dateRangeText: '',
            comparisonRangeValue: '',
            comparisonRangeText: '',
            selectedFromCity: null,
            selectedToCity: null,
            selectedFlightNumber: null,
            appliedFromCity: null,
            appliedToCity: null,
            appliedFlightNumber: null,
            appliedTenant: null,
            appliedLiveTVChannel: null,
            appliedRegion: null,
            selectedFilters: {
                systemType: [],
                aircraftType: [],
                flightDuration: [],
                seatClass: [],
                guiLanguage: [],
                tail: []
            },
            appliedFilters: {},
            displayDateRangeSelector: false,
            customStartDate: null,
            customEndDate: null,
            customComparisonEndDate: null,
            customComparisonStartDate: null
        };

        if (state) {
            //selectedFiltersToStore.expanded = state.expanded;
            selectedFiltersToStore.applyFilterButtonDisabled = state.applyFilterButtonDisabled;
            selectedFiltersToStore.clearFilterButtonDisabled = state.clearFilterButtonDisabled;
            selectedFiltersToStore.dateRangeValue = state.dateRangeValue;
            selectedFiltersToStore.dateRangeText = state.dateRangeText;
            selectedFiltersToStore.comparisonRangeValue = state.comparisonRangeValue;
            selectedFiltersToStore.comparisonRangeText = state.comparisonRangeText;
            selectedFiltersToStore.selectedFromCity = state.selectedFromCity;
            selectedFiltersToStore.selectedToCity = state.selectedToCity;
            selectedFiltersToStore.selectedFlightNumber = state.selectedFlightNumber;
            selectedFiltersToStore.appliedFromCity = state.appliedFromCity;
            selectedFiltersToStore.appliedToCity = state.appliedToCity;
            selectedFiltersToStore.appliedFlightNumber = state.appliedFlightNumber;
            selectedFiltersToStore.appliedTenant = state.appliedTenant;
            selectedFiltersToStore.appliedLiveTVChannel = state.appliedLiveTVChannel;
            selectedFiltersToStore.appliedRegion = state.appliedRegion;
            selectedFiltersToStore.selectedFilters = state.selectedFilters;
            selectedFiltersToStore.appliedFilters = state.appliedFilters;
            selectedFiltersToStore.displayDateRangeSelector = state.displayDateRangeSelector;
            selectedFiltersToStore.customStartDate = state.customStartDate;
            selectedFiltersToStore.customEndDate = state.customEndDate;
            selectedFiltersToStore.customComparisonEndDate = state.customComparisonEndDate;
            selectedFiltersToStore.customComparisonStartDate = state.customComparisonStartDate;
        }

        window.sessionStorage.setItem('pac_next_insights_selected_filters', JSON.stringify(selectedFiltersToStore));
    },

    getCleanAppliedFilters: function (filterType, appliedFilterTypes, page) {
        let cleanedFilters = [];

        if (filterType && appliedFilterTypes && appliedFilterTypes.length > 0) {
            let data = FiltersService.getFilterValuesByTypeAndSelection(filterType, [], page);
            if (data.length > 0) {
                for (let i = 0; i < appliedFilterTypes.length; i++) {
                    let itemExists = data.some((item) => {
                        if (item.value === appliedFilterTypes[i]) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    if (itemExists) {
                        cleanedFilters.push(appliedFilterTypes[i]);
                    }
                }
            }
        }

        return cleanedFilters;
    },

    isAppliedFilterExist: function (filterType, value, page) {
        let filterExists = false;

        if (filterType && value) {
            let data = FiltersService.getFilterValuesByTypeAndSelection(filterType, [], page);
            if (data.length > 0) {
                filterExists = data.some((item) => {
                    if (item.value === value) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }
        }

        return filterExists;
    },

    isAppliedFilterNotExist: function (filterType, value, page) {
        let filterNotExists = false;

        if (filterType && value) {
            const filterKeys = {
                systemType: 'system_type',
                aircraftType: 'aircraft_type_subtype',
                flightDuration: 'flight_haul_type',
                seatClass: 'seat_class',
                tail: 'aircraft_tail_number',
                fromCity: 'dep_airport_iata',
                toCity: 'arr_airport_iata',
                flightNumber: 'flight_number'
            };

            if (filterKeys[filterType]) {
                let data = FiltersService.getFilterValuesByTypeAndSelection(filterKeys[filterType], [], page);
                if (data.length > 0) {
                    let itemExists = data.some((item) => {
                        if (item.value === value) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    filterNotExists = !itemExists;
                }
            }
        }

        return filterNotExists;
    },

    getDateDescription: function (dateRange) {
        let formattedDate = this.parseDateRange(dateRange);
        return formattedDate !== '' ? formattedDate + ' UTC' : '';
    },

    parseDateRange: function (dateRange) {
        try {
            let rangeArr = dateRange.split('-');
            let startDate = this.parseDateString(rangeArr[0]);
            let endDate = this.parseDateString(rangeArr[1]);

            const options = {
                month: 'short',
                day: '2-digit',
                year: 'numeric'
            };

            let parsedRange = `${startDate.toLocaleString('default', options)}`;

            if (startDate.getFullYear() === endDate.getFullYear()) {
                let { year, ...tmpOptions } = options;
                parsedRange = `${startDate.toLocaleString('default', tmpOptions)}`;
            }

            if (startDate.getMonth() === endDate.getMonth()) {
                parsedRange += ` - ${endDate.getDate()}, ${endDate.getFullYear()}`;
            } else {
                parsedRange += ` - ${endDate.toLocaleString('default', options)}`;
            }
            return parsedRange;
        } catch (e) {
            return '';
        }
    },

    parseDateString: function (dateString) {
        if (dateString && dateString.length === 8) {
            let year = dateString.substring(0, 4);
            let month = dateString.substring(4, 6);
            let day = dateString.substring(6, 8);

            let date = new Date(year, month - 1, day);
            if (date instanceof Date && !isNaN(date)) {
                return date;
            }
        }
        return '';
    },

    formatDateString: function (dateString) {
        if (dateString && dateString.length === 8) {
            let year = dateString.substring(0, 4);
            let month = dateString.substring(4, 6);
            let day = dateString.substring(6, 8);

            let date = new Date(year, month - 1, day);
            if (date instanceof Date && !isNaN(date)) {
                return moment(date).format('YYYY-MM-DD');
            }
        }
        return '';
    },

    hasProperty: function (json, key) {
        let tmpJson = json;
        let isPresent = true;
        let keyArray = key.split('.');
        for (let [index, value] of keyArray.entries()) {
            if (tmpJson.hasOwnProperty(value)) {
                tmpJson = tmpJson[value];
            } else {
                isPresent = false;
                break;
            }
        }
        return isPresent;
    },

    atleastOneRoutePropSelected: function (state) {
        let selected = false;

        if (
            (state.selectedFromCity !== null && state.selectedFromCity !== 'None') ||
            (state.selectedToCity !== null && state.selectedToCity !== 'None') ||
            (state.selectedFlightNumber !== null && state.selectedFlightNumber !== 'None')
        ) {
            selected = true;
        }

        return selected;
    },

    atleastOneRoutePropApplied: function (state) {
        let applied = false;

        if (
            (state.appliedFromCity !== null && state.appliedFromCity !== 'None') ||
            (state.appliedToCity !== null && state.appliedToCity !== 'None') ||
            (state.appliedFlightNumber !== null && state.appliedFlightNumber !== 'None') ||
            (state.appliedTenant !== null && state.appliedTenant.icaoCode !== 'all') ||
            (state.appliedLiveTVChannel !== null && state.appliedLiveTVChannel.value !== 'ALL') ||
            (state.appliedRegion !== null && state.appliedRegion.value !== 'ALL')
        ) {
            applied = true;
        }

        return applied;
    },

    getDropdownStyle: function () {
        return {
            dropdownIndicator: (provided, state) => ({
                ...provided,
                fontSize: '14px'
                //transform: state.selectProps.menuIsOpen && 'rotate(180deg)' // to rotate the arrow icon on drop down
            }),
            menu: (base) => ({
                ...base,
                fontSize: '15px'
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                if (data.label === 'Custom' || data.label === 'Last Year') {
                    return {
                        ...styles,
                        backgroundColor: isDisabled ? '#f9f9f9' : isSelected ? '#ebebeb' : isFocused ? '#f6f6f6' : null,
                        color: isDisabled ? '#7A7A7A' : '#333333',
                        cursor: isDisabled ? 'not-allowed' : 'default',
                        borderTop: 'solid 1px #EEEEEE',
                        paddingTop: '10px'
                    };
                } else {
                    return {
                        ...styles,
                        backgroundColor: isDisabled ? '#f9f9f9' : isSelected ? '#ebebeb' : isFocused ? '#f6f6f6' : null,
                        color: isDisabled ? '#7A7A7A' : '#333333',
                        cursor: isDisabled ? 'not-allowed' : 'default'
                    };
                }
            }
        };
    },

    getDateInfoMessage: function (dateText) {
        let info = '';
        if (dateText === 'Current Week' || dateText === 'Current Month' || dateText === 'Current Year') {
            info = 'No comparison range selection available for the partial date range';
        } else if (dateText === '2 Years' || dateText === 'Last 2 Years') {
            info = 'No comparison range selection available for the selected date range due to data unavailability';
        }

        return info;
    },

    getAllFilterQueryParams: function (title, includeFormat, subscription, app) {
        // In future, modify to make the translation language dynamic

        let queryParams = {
            translation_language: 'en-us',
            requested_filters:
                'date_comparison_ranges,system_types,aircraft_types,flight_origins,flight_destinations,flight_numbers,flight_haul_types,seat_classes,gui_languages,tails'
        };

        if (title) {
            if (title === 'Seatback' || title === 'Portal') {
                queryParams['usage_source'] = title;
            }
            if (title === 'Portal Navigation') {
                queryParams['usage_source'] = 'Portal';
            }
        }

        if (includeFormat) {
            queryParams['format'] = 'dynamic';
            queryParams['requested_filters'] = 'date_comparison_ranges';
        }

        if (subscription) {
            queryParams['tier'] = subscription;
        }

        if (app) {
            queryParams['app'] = app;
        }

        return queryParams;
    },

    getFilterQueryParamsByOrigin: function (origin, subscription, app) {
        // In future, modify to make the translation language dynamic
        let queryParams = {
            translation_language: 'en-us',
            requested_filters: 'flight_destinations,flight_numbers',
            flight_origins: origin
        };

        if (subscription) {
            queryParams['tier'] = subscription;
        }

        if (app) {
            queryParams['app'] = app;
        }

        return queryParams;
    },

    getFilterQueryParamsByOriginAndDestination: function (origin, destination, subscription, app) {
        // In future, modify to make the translation language dynamic
        let params = {
            translation_language: 'en-us',
            requested_filters: 'flight_numbers'
        };

        if (origin !== null) {
            params['flight_origins'] = origin;
        }

        if (destination !== null) {
            params['flight_destinations'] = destination;
        }

        if (subscription) {
            params['tier'] = subscription;
        }

        if (app) {
            params['app'] = app;
        }

        return params;
    }
};

export default DynamicFilterHelper;
