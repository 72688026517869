const sampleData = {
    seatData:
        [
            {
                row: 1,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 2,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 3,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 4,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 7,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 8,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 10,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 11,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 12,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 13,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 14,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 15,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 16,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 17,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 18,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 19,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 25,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 26,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 27,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 28,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 29,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 30,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 31,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 32,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 33,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 34,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 35,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 36,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 37,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 38,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 39,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 40,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 41,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 42,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 43,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 44,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 45,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 46,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 47,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 48,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 49,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 50,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 51,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 52,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 53,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            },
            {
                row: 54,
                seats: [
                    {
                        letter: 'A',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'B',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'C',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'D',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'E',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'F',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'G',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'J',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'K',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    },
                    {
                        letter: 'L',
                        seatData: {
                            'total_usage_time': Math.floor(Math.random() * 120),
                            'total_views': Math.floor(Math.random()),
                            'total_usage_time_percent': Math.random() * 100,
                            'duration_by_content_type': {
                                Movies: {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'TV Series': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                },
                                'Audio': {
                                    'duration': Math.floor(Math.random() * 300),
                                    'pct': Math.floor(Math.random() * 300)
                                }
                            },
                            'views_by_content_type': {
                                'TV Series': Math.floor(Math.random() * 5),
                                'Movies': Math.floor(Math.random() * 50),
                                'Audio': Math.floor(Math.random() * 10)
                            }
                        }
                    }
                ]
            }
        ],
    seatLetters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L']
};

export default sampleData;