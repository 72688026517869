import React from 'react';
import 'assets/sass/updatedComponents/coverage.css';
import PillboxTabs from 'components/updatedComponents/PillboxTabs.js';

function CoverageWidget(props) {
    const { data, loading, title, showWorkbenchLink, tails_list } = props;

    let Data = Object.entries(data).map((item) => ({
        key: item[0],
        data: item[1].data
    }));

    return (
        <div className="coverage-widget">
            <PillboxTabs
                loading={loading}
                data={Data}
                tails_list={tails_list}
                title={title}
                showWorkbenchLink={showWorkbenchLink}
            />
        </div>
    );
}

export default CoverageWidget;
