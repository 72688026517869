import getDonutRequestedSectionsHelper from 'data/RequestedSectionsHelper/getDonutRequestedSectionsHelper.js';
import getBarChartRequestedSectionsHelper from 'data/RequestedSectionsHelper/getBarChartRequestedSectionsHelper.js';
import getCabinClassRequestedSectionsHelper from 'data/RequestedSectionsHelper/getCabinClassRequestedSectionsHelper.js';
import getTrendChartRequestedSectionsHelper from 'data/RequestedSectionsHelper/getTrendChartRequestedSectionsHelper.js';
import getVerticalChartRequestedSectionsHelper from 'data/RequestedSectionsHelper/getVerticalChartRequestedSectionsHelper.js';

export default class AppDataRequestedSectionsHelper {
    getDonutRequestedSections = function (path) {
        return getDonutRequestedSectionsHelper(path);
    };

    getBarChartRequestedSections = function (reportType, reportSubType) {
        return getBarChartRequestedSectionsHelper(reportType, reportSubType);
    };

    getCabinClassRequestedSections = function (reportType) {
        return getCabinClassRequestedSectionsHelper(reportType);
    };

    getTrendChartRequestedSections = function (reportSubType) {
        return getTrendChartRequestedSectionsHelper(reportSubType);
    };

    getVerticalChartRequestedSections = function (requestedSection) {
        return getVerticalChartRequestedSectionsHelper(requestedSection);
    };
}
