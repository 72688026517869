import ReactDOM from 'react-dom';

import App from './App';
import PublicHomePage from './pages/PublicHomePage';
import { Auth, Hub } from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';

//import JwtHelper from 'util/JwtHelper';
import { JwtHelper } from 'pac-platform-ui-auth';
import config from './config.js';
import { getAllTenantOption } from './util/TenantHelper';
import SubscriptionService from 'services/SubscriptionService';

var refreshTokenKey = '';
var refreshTokenValue = '';

const allTenantOption = getAllTenantOption();

window.sessionStorage.setItem('all_tenants_view', 'true');
window.sessionStorage.setItem('pac_selected_tenant', JSON.stringify(allTenantOption));
window.sessionStorage.setItem('pac_selected_tenant_id', allTenantOption.value);
window.sessionStorage.setItem('pac_selected_tenant_icao_code', allTenantOption.icaoCode);
window.sessionStorage.setItem('channel_title', 'ALL');
window.sessionStorage.setItem('all_tenant_view_selected_tenant', JSON.stringify(allTenantOption));
window.sessionStorage.setItem('hub_auth_event_received', 'false');

configureAmplify().then(async (configured) => {
    if (!configured) {
        startApp(true);
    } else {
        let user = await Auth.currentUserInfo({});
        if (!user) {
            // listen to auth event dispatched by amplify configure
            Hub.listen('auth', (obj) => {
                window.sessionStorage.setItem('hub_auth_event_received', 'true');
                if (obj.payload.event === 'signIn') {
                    window.localStorage.setItem(refreshTokenKey, refreshTokenValue);
                    SubscriptionService.refreshToken = refreshTokenValue;
                    startApp(false);
                } else {
                    startApp(true);
                }
            });

            // redirect to login page if no auth event in 10 seconds
            await new Promise((r) => setTimeout(r, 10000));
            if (window.sessionStorage.getItem('hub_auth_event_received') === 'false') {
                startApp(true);
            }
        } else {
            window.localStorage.setItem(refreshTokenKey, refreshTokenValue);
            SubscriptionService.refreshToken = refreshTokenValue;
            startApp(false);
        }
    }
});

async function configureAmplify() {
    const helper = new JwtHelper();
    /*
        helper.run does the following:
        
        1. parse url
        2. if url hash (string after #) is empty:
            i. get 'idToken' from localStorage, decode the value (jwt-decode) and return.
        3. if url hash is not empty:
            // sample hash value: '#expires_in=3600&token_type=Bearer&id_token=ey..&access_token=ey..&refresh_token=ey..'
            i. remove 'idToken', 'accessToken', 'refreshToken' from localStorage
            ii. extract idToken from hash and decode the value (jwt-decode) 
            iii. extract 'pac_app_client_id' and 'cognito:username' from decoded value and store them in localStorage
                with 'idToken' and 'clockDrift' suffix value
            iv. extract access_token from hash and store in localStorage with accessToken suffix
            v. extract refresh_token from hash and store in localStorage with refreshToken suffix
            vi. return decoded idToken value
    */
    let decodedToken = helper.run();

    if (decodedToken) {
        config.Auth.userPoolId = decodedToken.pac_user_pool_id;
        config.Auth.userPoolWebClientId = decodedToken.pac_app_client_id;
        config.Auth.oauth.domain = decodedToken.pac_user_pool_domain + '.auth.us-west-2.amazoncognito.com';

        // store refresh_token in temporary variable, since Amplify.configure tends to remove it from localStorage
        for (var i = 0; i < window.localStorage.length; i++) {
            let key = window.localStorage.key(i);
            if (key.indexOf('refreshToken') !== -1) {
                refreshTokenKey = key;
                refreshTokenValue = window.localStorage.getItem(key);
                break;
            }
        }

        // configure() dispatches Hub auth event
        return Amplify.configure(config);
    } else {
        return false;
    }
}

function startApp(publicPage) {
    if (publicPage) {
        ReactDOM.render(
            <Router>
                <PublicHomePage />
            </Router>,
            document.getElementById('root')
        );
    } else {
        ReactDOM.render(
            <Router>
                <App />
            </Router>,
            document.getElementById('root')
        );
    }
}
