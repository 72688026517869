import React from 'react';
import WidgetFrame from 'components/updatedComponents/WidgetFrameNew';
import SortTableHeaderButton, { sortColumn } from 'components/common/SortTableHeaderButton.js';
import { TablePagination } from 'pac-ux-components';
import AppDataDropdownHelper from 'data/app-data-dropdown-helper.js';
import UtilHelper from 'util/UtilHelper.js';

const DEFAULT_SORT_COLUMN = 'value';
const DEFAULT_SORT_DIRECTION = 'ascending';

const appDataDropdownHelper = new AppDataDropdownHelper();
const utilHelper = new UtilHelper();

class TableWithDropdownsWidget extends React.Component {
    constructor(props) {
        super(props);

        this.updateTableStateAction = this.updateTableStateAction.bind(this);

        this.state = {
            data: [],
            table: {
                currentTableSortColumn: DEFAULT_SORT_COLUMN,
                currentTableSortDirection: DEFAULT_SORT_DIRECTION,
                currentPageData: [],
                pageCount: 8,
                paging: {
                    count: 8,
                    page: 0
                },
                updateTableStateAction: this.updateTableStateAction
            },
            selectedDropdownOption: '',
            selectedDropdownOptionUnit: ''
        };

        this.paginate = this.paginate.bind(this);
    }

    updateWidgetData = (newData) => {
        if (newData.data) {
            let sortedData = sortColumn(
                DEFAULT_SORT_COLUMN,
                DEFAULT_SORT_COLUMN,
                DEFAULT_SORT_DIRECTION,
                newData.data,
                true,
                null,
                'string'
            );
            this.setState({
                selectedDropdownOption: newData.text ? newData.text : this.state.selectedDropdownOption,
                selectedDropdownOptionUnit: newData.unit ? newData.unit : this.state.selectedDropdownOptionUnit
            });

            this.updateTableStateAction(0, sortedData, DEFAULT_SORT_DIRECTION, DEFAULT_SORT_COLUMN);
        }
    };

    updateTableStateAction(newPage, dataset, direction, column) {
        let itemsPerPage = this.state.table.pageCount;

        let newTableStartIndex = itemsPerPage * newPage;
        let newTableEndIndex = newTableStartIndex + itemsPerPage;
        let newData = dataset.slice(newTableStartIndex, newTableEndIndex);

        let tableState = {};
        Object.assign(tableState, this.state.table);
        tableState.currentPageData = newData;
        tableState.paging = {
            count: itemsPerPage,
            page: newPage
        };
        tableState.currentTableSortDirection = direction;
        tableState.currentTableSortColumn = column;

        this.setState({
            table: tableState,
            data: dataset
        });
    }

    paginate(newPage) {
        this.updateTableStateAction(
            newPage,
            this.state.data,
            this.state.table.currentTableSortDirection,
            this.state.table.currentTableSortColumn
        );
    }

    render() {
        let tailsTable;

        let dropdownList = appDataDropdownHelper.getDropdownList('tails', this.props.pageName);
        let dropdownOption = this.state.selectedDropdownOption;
        if (this.state.table.currentPageData.length > 0) {
            tailsTable = (
                <div>
                    <div style={{ height: '1px' }}>
                        <div
                            className={`scatter-plot-legend ${
                                dropdownOption === 'Connections' ||
                                dropdownOption === 'Devices' ||
                                dropdownOption === 'Listens' ||
                                dropdownOption === 'Launches'
                                    ? 'bluetooth'
                                    : ''
                            }`}
                        >
                            <div style={{ height: '24px' }}>
                                <div
                                    className="scatter-plot-legend-color"
                                    style={{
                                        backgroundColor: '#abf1ab'
                                    }}
                                ></div>
                                <div className="scatter-plot-legend-label">
                                    <span>{'Percentile (' + dropdownOption + ') > 90'}</span>
                                </div>
                            </div>
                            <div style={{ height: '24px' }}>
                                <div
                                    className="scatter-plot-legend-color"
                                    style={{
                                        backgroundColor: '#f39494'
                                    }}
                                ></div>
                                <div className="scatter-plot-legend-label">
                                    <span>{'Percentile (' + dropdownOption + ') < 15'}</span>
                                </div>
                            </div>
                            <div style={{ height: '24px' }}>
                                <div
                                    className="scatter-plot-legend-color"
                                    style={{
                                        backgroundColor: '#66c3e8'
                                    }}
                                ></div>
                                <div className="scatter-plot-legend-label">
                                    <span>{'Percentile (' + dropdownOption + ') 90-15'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-widget">
                        <div className="table-outer-container">
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className={'tail'}>
                                                <SortTableHeaderButton
                                                    column="label"
                                                    label="Tail Number"
                                                    dataType="string"
                                                    tableData={this.state.data}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            {this.props.tenantIcaoCode === 'all' && (
                                                <th>
                                                    <SortTableHeaderButton
                                                        column="icao"
                                                        label="Airline"
                                                        dataType="string"
                                                        tableData={this.state.data}
                                                        tableState={this.state.table}
                                                    />
                                                </th>
                                            )}

                                            <th>
                                                <SortTableHeaderButton
                                                    column="flights"
                                                    label="Flights Count"
                                                    dataType="number"
                                                    tableData={this.state.data}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th>
                                                <SortTableHeaderButton
                                                    column="flight_duration"
                                                    label="Flights Duration"
                                                    dataType="number"
                                                    tableData={this.state.data}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th>
                                                <SortTableHeaderButton
                                                    column="value"
                                                    label={this.state.selectedDropdownOption}
                                                    dataType="number"
                                                    tableData={this.state.data}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th>
                                                <SortTableHeaderButton
                                                    column="percentile"
                                                    label={'Percentile (' + this.state.selectedDropdownOption + ')'}
                                                    dataType="number"
                                                    tableData={this.state.data}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th>
                                                <SortTableHeaderButton
                                                    column={
                                                        this.state.selectedDropdownOption.toLowerCase() +
                                                        '_per_hour_flown'
                                                    }
                                                    label={this.state.selectedDropdownOption + '/Flight/Hour'}
                                                    dataType="number"
                                                    tableData={this.state.data}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th>
                                                <SortTableHeaderButton
                                                    column={
                                                        this.state.selectedDropdownOption.toLowerCase() +
                                                        '_per_hour_flown_percentile'
                                                    }
                                                    label={
                                                        'Percentile (' +
                                                        this.state.selectedDropdownOption +
                                                        '/Flight/Hour)'
                                                    }
                                                    dataType="number"
                                                    tableData={this.state.data}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.table.currentPageData.map((tail, idx) => (
                                            <tr key={'tr_' + idx}>
                                                <td
                                                    className={
                                                        tail.percentile > 90
                                                            ? 'green-percentile'
                                                            : tail.percentile < 15
                                                            ? 'red-percentile'
                                                            : ''
                                                    }
                                                >
                                                    {tail.label}
                                                </td>
                                                {this.props.tenantIcaoCode === 'all' && (
                                                    <td
                                                        className={
                                                            tail.percentile > 90
                                                                ? 'green-percentile'
                                                                : tail.percentile < 15
                                                                ? 'red-percentile'
                                                                : ''
                                                        }
                                                    >
                                                        {tail.icao}
                                                    </td>
                                                )}
                                                <td
                                                    className={
                                                        tail.percentile > 90
                                                            ? 'green-percentile'
                                                            : tail.percentile < 15
                                                            ? 'red-percentile'
                                                            : ''
                                                    }
                                                >
                                                    {tail.flights}
                                                </td>
                                                <td
                                                    className={
                                                        tail.percentile > 90
                                                            ? 'green-percentile'
                                                            : tail.percentile < 15
                                                            ? 'red-percentile'
                                                            : ''
                                                    }
                                                >
                                                    {utilHelper.secondsToHmsForDynamicTabs(tail.flight_duration)}
                                                </td>
                                                <td
                                                    className={
                                                        tail.percentile > 90
                                                            ? 'green-percentile'
                                                            : tail.percentile < 15
                                                            ? 'red-percentile'
                                                            : ''
                                                    }
                                                >
                                                    {this.state.selectedDropdownOptionUnit === 'hr'
                                                        ? utilHelper.secondsToHmsForDynamicTabs(tail.value)
                                                        : tail.value}
                                                </td>
                                                <td
                                                    className={
                                                        tail.percentile > 90
                                                            ? 'green-percentile'
                                                            : tail.percentile < 15
                                                            ? 'red-percentile'
                                                            : ''
                                                    }
                                                >
                                                    {tail.percentile}
                                                </td>
                                                <td
                                                    className={
                                                        tail.percentile > 90
                                                            ? 'green-percentile'
                                                            : tail.percentile < 15
                                                            ? 'red-percentile'
                                                            : ''
                                                    }
                                                >
                                                    {this.state.selectedDropdownOptionUnit === 'hr'
                                                        ? utilHelper.secondsToHmsForDynamicTabs(
                                                              tail[
                                                                  this.state.selectedDropdownOption.toLowerCase() +
                                                                      '_per_hour_flown'
                                                              ]
                                                          )
                                                        : utilHelper.formatToUnitsForPillBox(
                                                              tail[
                                                                  this.state.selectedDropdownOption.toLowerCase() +
                                                                      '_per_hour_flown'
                                                              ]
                                                          )}
                                                </td>
                                                <td
                                                    className={
                                                        tail.percentile > 90
                                                            ? 'green-percentile'
                                                            : tail.percentile < 15
                                                            ? 'red-percentile'
                                                            : ''
                                                    }
                                                >
                                                    {
                                                        tail[
                                                            this.state.selectedDropdownOption.toLowerCase() +
                                                                '_per_hour_flown_percentile'
                                                        ]
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination-container">
                                <TablePagination
                                    itemCount={this.state.data.length}
                                    onChangePage={this.paginate}
                                    paging={this.state.table.paging}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            tailsTable = (
                <div style={{ paddingLeft: '14px', paddingRight: '5px' }}>
                    <div className="no-data" style={{ marginTop: '12%', fontSize: '22px' }}>
                        Data is not available
                    </div>
                </div>
            );
        }

        return (
            <WidgetFrame
                id={Math.random()}
                type={this.props.type}
                pageName={this.props.pageName}
                loading={this.props.loading}
                title={'Tails'}
                showTabs={false}
                showOverflow={false}
                showDropdown={false}
                showFooter={false}
                body={tailsTable}
                dd1Options={dropdownList}
                updateData={this.updateWidgetData}
                switchView={this.props.switchView}
                data={this.props.tailsList}
            />
        );
    }
}

export default TableWithDropdownsWidget;
