import React, { useRef, useEffect } from 'react';
import '../../assets/sass/tooltipModal.scss';

export default function TooltipModal(props) {
    // const ref = useRef(null);
    // useEffect(()=>{
    //       function handleClickOutside(event) {
    //         if (ref.current && !ref.current.contains(event.target)) {
    //           props.close();
    //         }
    //     }

    //     // Bind the event listener
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         // Unbind the event listener on clean up
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [ref]);

    // const createMarkup =()=>{
    //   return {__html:props.data.content}
    // }

    // Work around to turn off webpack loader error and read HTML files.
    // Don't use props.directory variable as it gives loader parsing error.
    const createMarkup = () => {
        /* eslint import/no-webpack-loader-syntax: off */
        let htmlModule;
        htmlModule = require('!!raw-loader!../../content/help/standard/html/' + props.data.content);
        //var htmlModule = require('raw-loader!../../content/help/' + props.directory + '/html/' + props.data.content);
        return { __html: htmlModule.default };
    };

    return (
        <div className={'tooltipContainer ' + props.type + ' ' + props.page}>
            <div className="closeBtn" onClick={props.close}>
                <div className="close-arrow">
                    <div className="arrow-left"></div>
                    <div className="arrow-right"></div>
                </div>
            </div>
            <div className="tooltipBody">
                <p dangerouslySetInnerHTML={createMarkup()} className="paragraph"></p>
            </div>
        </div>
    );
}
