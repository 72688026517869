function getAirlinesDropdownListHelper(reportSubType) {
    let dropdownList;

    switch (reportSubType) {
        case 'Overview':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'airlines.most_popular.total_views',
                    text: 'Total Views',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'airlines.most_popular.total_time',
                    text: 'Total Viewing Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Movies':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'airlines.most_popular.total_views',
                    text: 'Movies Total Views',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'airlines.most_popular.total_time',
                    text: 'Movies Total Viewing Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'TV Series':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'airlines.most_popular.total_views',
                    text: 'TV Series Total Views',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'airlines.most_popular.total_time',
                    text: 'TV Series Total Viewing Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Audio':
            dropdownList = [
                {
                    label: 'Total Listens (Top 10)',
                    unit: 'count',
                    value: 'airlines.most_popular.total_views',
                    text: 'Audio Total Listens',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Listens'
                },
                {
                    label: 'Total Listening Time (Top 10)',
                    unit: 'hr',
                    value: 'airlines.most_popular.total_time',
                    text: 'Audio Total Listening Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Listening Time'
                }
            ];
            break;
        case 'Games':
            dropdownList = [
                {
                    label: 'Total Plays (Top 10)',
                    unit: 'count',
                    value: 'airlines.most_popular.total_views',
                    text: 'Game Total Plays',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Plays'
                },
                {
                    label: 'Total Playing Time (Top 10)',
                    unit: 'hr',
                    value: 'airlines.most_popular.total_time',
                    text: 'Game Total Playing Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Playing Time'
                }
            ];
            break;
        case 'Live TV':
            dropdownList = [
                {
                    label: 'Total Views (Top 10)',
                    unit: 'count',
                    value: 'airlines.most_popular.total_views',
                    text: 'Live TV Total Views',
                    default: 1,
                    section: 'top_10_total_views',
                    small_count_label: 'Total Views'
                },
                {
                    label: 'Total Viewing Time (Top 10)',
                    unit: 'hr',
                    value: 'airlines.most_popular.total_time',
                    text: 'Live TV Total Viewing Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Viewing Time'
                }
            ];
            break;
        case 'Electronic Reader':
            dropdownList = [
                {
                    label: 'Total Title Views (Top 10)',
                    unit: 'count',
                    value: 'airlines.most_popular.total_views',
                    text: 'eReader Total Title Views',
                    default: 1,
                    section: 'top_10_total_title_views',
                    small_count_label: 'Total Title Views'
                },
                {
                    label: 'Total Reading Time (Top 10)',
                    unit: 'hr',
                    value: 'airlines.most_popular.total_time',
                    text: 'eReader Total Reading Time',
                    section: 'top_10_total_time',
                    small_count_label: 'Total Reading Time'
                }
            ];
            break;
        case 'Bluetooth':
            dropdownList = [
                {
                    label: 'Paired Devices (Top 10)',
                    unit: 'count',
                    value: 'airlines.most_popular.total_paired_devices',
                    text: 'Bluetooth Total Paired Devices',
                    default: 1,
                    section: 'top_10_total_paired_devices',
                    small_count_label: 'Paired Devices'
                },
                {
                    label: 'Total Connections (Top 10)',
                    unit: 'count',
                    value: 'airlines.most_popular.total_connections',
                    text: 'Bluetooth Total Connections',
                    section: 'top_10_total_connections',
                    small_count_label: 'Total Connections'
                }
            ];
            break;
        default:
    }

    return dropdownList;
}

export default getAirlinesDropdownListHelper;
