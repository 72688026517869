import React, { useState } from 'react';
import Lopa from '../updatedComponents/Lopa';
import WidgetFrameNew from '../updatedComponents/WidgetFrameNew';

function LopaWidget(props) {
    const [flightInfo, setFlightInfo] = useState({});
    let startDate = '';
    let endDate = '';

    if (props.appliedFilters.api) {
        let dr = props.appliedFilters.api.date_range;
        dr = dr.split('-');
        var year = parseInt(dr[0].substring(0, 4));
        var month = parseInt(dr[0].substring(4, 6));
        var day = parseInt(dr[0].substring(6, 8));
        startDate = new Date(year, month - 1, day);
        year = parseInt(dr[1].substring(0, 4));
        month = parseInt(dr[1].substring(4, 6));
        day = parseInt(dr[1].substring(6, 8));
        endDate = new Date(year, month - 1, day);
    }

    let body =
        props.tenantIcaoCode === 'all' ? (
            <div style={{ paddingLeft: '14px', paddingRight: '5px' }}>
                <div className="no-data" style={{ marginTop: '14%', fontSize: '32px' }}>
                    Disabled for 'All Airlines' selection
                </div>
            </div>
        ) : (
            <Lopa
                startDate={startDate}
                endDate={endDate}
                data={props.data}
                getFlights={props.getFlights}
                getFlightData={props.getFlightData}
                clearFlightData={props.clearFlightData}
                showChangeSeatInfoType={props.showWorkbenchLink}
                setFlightInfo={setFlightInfo}
                tenantIcaoCode={props.tenantIcaoCode || ''}
            />
        );

    return (
        <WidgetFrameNew
            type="lopa"
            titleImage={props.img}
            title={props.title}
            showTabs={false}
            showWorkbenchLink={props.showWorkbenchLink || false}
            showWorkbench={false}
            showOverflow={true}
            page={props.page}
            startDate={startDate}
            endDate={endDate}
            loading={!props.appliedFilters.api}
            appliedFilters={props.appliedFilters.api}
            subscription={props.subscription}
            app={props.app}
            subscriptionStage={props.subscriptionStage}
            tenantIcaoCode={props.tenantIcaoCode || ''}
            pageName={props.pageName}
            showDownload={false}
            data={props.data}
            flightInfo={flightInfo}
            dataAvailable={!(Object.keys(props.data).length > 0) ? false : true}
            body={body}
        />
    );
}

export default LopaWidget;
