import React from 'react';
import CaretDownIcon from 'assets/images/icons/table-sort-caret-down.svg';
import CaretUpIcon from 'assets/images/icons/table-sort-caret-up.svg';

class SortTableHeaderButton extends React.Component {
    caretIcon(sortDirection) {
        if (sortDirection === 'ascending') {
            return CaretUpIcon;
        } else {
            return CaretDownIcon;
        }
    }

    buttonStyle(currentTableSortColumn, column) {
        if (currentTableSortColumn === column) {
            return 'table-sort-button-selected';
        } else {
            return 'table-sort-button-not-selected';
        }
    }

    sortColumn(
        column,
        currentTableSortColumn,
        currentTableSortDirection,
        tableData,
        changeDirection,
        updateTableStateAction,
        dataType
    ) {
        let direction = 'ascending';

        if (changeDirection && currentTableSortColumn === column) {
            if (currentTableSortDirection === 'ascending') {
                direction = 'descending';
            }
        }

        let newData = tableData.sort((a, b) => {
            if (dataType === 'number') {
                if (direction === 'descending') {
                    return b[column] - a[column];
                } else {
                    return a[column] - b[column];
                }
            } else if (dataType === 'string') {
                if (direction === 'descending') {
                    if (a[column] < b[column]) {
                        return 1;
                    } else if (a[column] > b[column]) {
                        return -1;
                    } else {
                        return 0;
                    }
                } else {
                    if (a[column] < b[column]) {
                        return -1;
                    } else if (a[column] > b[column]) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }
        });

        if (updateTableStateAction) {
            updateTableStateAction(0, newData, direction, column);
        } else {
            return newData;
        }
    }

    render() {
        return (
            <button
                type="button"
                onClick={() =>
                    this.sortColumn(
                        this.props.column,
                        this.props.tableState.currentTableSortColumn,
                        this.props.tableState.currentTableSortDirection,
                        this.props.tableData,
                        true,
                        this.props.tableState.updateTableStateAction,
                        this.props.dataType
                    )
                }
                className={this.buttonStyle(this.props.tableState.currentTableSortColumn, this.props.column)}
            >
                {this.props.label}
                {this.props.tableState.currentTableSortColumn === this.props.column && (
                    <img src={this.caretIcon(this.props.tableState.currentTableSortDirection)} />
                )}
            </button>
        );
    }
}

export const sortColumn = SortTableHeaderButton.prototype.sortColumn;
export default SortTableHeaderButton;
