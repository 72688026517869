import React from 'react';
import { CardBody, Row, Col, Card } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import ChartsDashBoard from 'components/common/dashboards/ChartsDashBoard.js';
import SubscriptionService from 'services/SubscriptionService';
import { ApiDataMap } from 'components/workbench/ApiDataMap';
import QuicksightDataHelper from 'data/quicksight-data-helper.js';
import { Auth } from 'aws-amplify';
import SubscriptionBanner from 'components/common/SubscriptionBanner';
import moment from 'moment';
import QuicksightWorkbenchComponent from 'components/workbench/QuicksightWorkbenchComponent';
import ErrorBoundary from 'util/ErrorBoundary';
import AirlineSummary from 'pages/entertainment/AirlineSummary';
import DynamicFilter from 'components/common/DynamicFilter';
import { getTitleFromPath } from 'data/menus.js';
import * as AppConstants from 'constants/app/constants';
import Circular from 'assets/images/icons/circular.gif';
import DashboardHelper from 'components/common/dashboards/DashboardHelper';
import AuthHelper from 'util/AuthHelper.js';

const authHelper = new AuthHelper();
const dashboardHelper = new DashboardHelper();

const paidTiers = ['Standard'];
const basicTier = 'Basic';

let quicksightDataHelper = new QuicksightDataHelper();

class DashboardBuilder extends React.Component {
    constructor(props) {
        super(props);

        this.handleSelectionUpdated = this.handleSelectionUpdated.bind(this);
        this.handleTenantSelection = this.handleTenantSelection.bind(this);

        this.state = {
            tenantId: null,
            originalTenantId: null,
            tenantName: '',
            dataset: null,
            dashboard: null,
            show: true,
            selectedValue: null,
            allSubscriptionTiers: [],
            subscription: '',
            subscriptionStage: '',
            allowedApps: [],
            allowedContentTypes: [],
            licenseStartDate: '',
            licenseEndDate: '',
            subscriptionSet: false,
            originalTenantIcaoCode: '',
            tenantIcaoCode: '',
            inputEnabled: true,
            embedUrlMapping: {},
            showSummary: true,
            appliedFilters: {},
            onlyComparisonRangeUpdated: false,
            reloadAirlineSummary: false,
            reloadDynamicFilters: false,
            reloadTrendInQueue: false,
            reloadTrend: false,
            reloadAll: false,
            timestamp: '',
            isTier0: false,
            userInAdminGroup: false,
            userInPacRackTesterGroup: false,
            userInInsightsPLMGroup: false
        };

        this.selectRef = React.createRef();
        this.pageTabsRef = React.createRef();
        this.quicksightWorkbenchRef = React.createRef();
        this.dynamicFilterRef = React.createRef();
    }

    handleTenantSelection(selectedIcao) {
        this.setState({
            tenantIcaoCode: selectedIcao
        });
    }

    fetchSesssion() {
        Auth.currentSession({})
            .then((session) => {
                sessionStorage.setItem('channel_title', 'ALL');
                let payload = session.idToken.payload;
                let tenantId = payload.pac_tenant_id;
                let tenantName = payload.pac_tenant_name;
                let pacIcaoCode = payload.pac_icao_code;

                this.setState({
                    originalTenantId: payload.pac_tenant_id,
                    originalTenantIcaoCode: payload.pac_icao_code
                });

                let cognitoGroups = payload['cognito:groups'];
                let cognitoAssignedSubject = payload.sub;
                let currTenantId = sessionStorage.getItem('pac_selected_tenant_id');
                if (currTenantId && currTenantId !== tenantId) {
                    let currTenantIcaoCode = sessionStorage.getItem('pac_selected_tenant_icao_code');
                    if (currTenantIcaoCode) {
                        pacIcaoCode = currTenantIcaoCode;
                    }
                }
                let currentTenantId = currTenantId ? currTenantId : tenantId;

                const groups = payload['cognito:groups'];
                let userInAdminGroup = false;
                let userInPacRackTesterGroup = false;
                let userInInsightsPLMGroup = false;
                if (typeof groups !== 'undefined') {
                    for (let i = 0; i < groups.length; i++) {
                        if (AppConstants.ADMIN_GROUPS.includes(groups[i])) {
                            userInAdminGroup = true;
                        }

                        if (groups[i] === AppConstants.GROUP_PAC_RACK_TESTER) {
                            userInPacRackTesterGroup = true;
                        }

                        if (groups[i] === AppConstants.GROUP_INSIGHTS_PLM) {
                            userInInsightsPLMGroup = true;
                        }
                    }
                }

                if (pacIcaoCode && pacIcaoCode.toLowerCase() === AppConstants.TENANT_ICAO_PANASKY) {
                    if (authHelper.currentUserBelongsToOnlyPacRackTesters(session)) {
                        pacIcaoCode = AppConstants.TENANT_ICAO_PANASONIC_AVIONICS;
                    }
                }

                this.fetchSubscription(currentTenantId, pacIcaoCode, cognitoAssignedSubject, cognitoGroups);

                this.setState({
                    tenantId: currentTenantId,
                    tenantName: tenantName,
                    tenantIcaoCode: pacIcaoCode,
                    userInAdminGroup: userInAdminGroup,
                    userInPacRackTesterGroup: userInPacRackTesterGroup,
                    userInInsightsPLMGroup: userInInsightsPLMGroup
                });
                if (this.dynamicFilterRef.current) {
                    this.dynamicFilterRef.current.getSelectedFiltersFromStore();
                }
            })
            .catch(async (err) => {
                console.warn(err);
                await Auth.signOut();
                this.props.history.push('/');
            });
    }

    async fetchSubscription(tenantId, pacIcaoCode, cognitoAssignedSubject, cognitoGroups) {
        let response = dashboardHelper.getStubbedSubscription(AppConstants.TENANT_ICAO_PANASKY);

        let tempSubscription = '';
        let tempStage = '';
        let tempAllowedApps = [];
        let tempAllowedContentTypes = [];
        let licenseStartDate = '';
        let licenseEndDate = '';
        let isTier0 = false;

        let defaultTier = response.tiers[0];
        for (let k in response.tiers) {
            if (response.tiers[k]['subscription-level'] === 'Standard') {
                defaultTier = response.tiers[k];
                break;
            }
        }
        tempSubscription = defaultTier['subscription-level'];
        tempStage = defaultTier['stage'];
        tempAllowedApps = defaultTier.apps;
        tempAllowedContentTypes = defaultTier.content_types;
        licenseStartDate = defaultTier['license-start-date'];
        licenseEndDate = defaultTier['license-end-date'];

        this.setState({
            subscription: tempSubscription,
            subscriptionStage: tempStage,
            allowedApps: tempAllowedApps,
            allowedContentTypes: tempAllowedContentTypes,
            licenseStartDate: licenseStartDate,
            licenseEndDate: licenseEndDate,
            isTier0: isTier0,
            allSubscriptionTiers: response.tiers,
            subscriptionSet: true
        });
    }

    componentDidMount() {
        this.fetchSesssion();
    }

    componentDidUpdate(prevProps, prevState) {
        // Subscription tier selection change
        let updatedSubscription = sessionStorage.getItem('pac_subscription');
        if (['Basic', 'Standard'].includes(updatedSubscription)) {
            if (this.state.subscription !== updatedSubscription) {
                let subscriptionStage = '';
                let allowedApps = [];
                let allowedContentTypes = [];
                let licenseStartDate = '';
                let licenseEndDate = '';

                for (let i = 0; i < this.state.allSubscriptionTiers.length; i++) {
                    if (this.state.allSubscriptionTiers[i]['subscription-level'] === updatedSubscription) {
                        allowedApps = JSON.parse(JSON.stringify(this.state.allSubscriptionTiers[i]['apps']));
                        allowedContentTypes = JSON.parse(
                            JSON.stringify(this.state.allSubscriptionTiers[i]['content_types'])
                        );
                        licenseStartDate = JSON.parse(
                            JSON.stringify(this.state.allSubscriptionTiers[i]['license-start-date'])
                        );
                        licenseEndDate = JSON.parse(
                            JSON.stringify(this.state.allSubscriptionTiers[i]['license-end-date'])
                        );
                        subscriptionStage = JSON.parse(JSON.stringify(this.state.allSubscriptionTiers[i]['stage']));
                    }
                }

                // Workaround for PANASKY Basic tier. If there is no Basic subscription for PANASKY then, assign the
                // allowed apps from the Standard subscription.
                if (
                    allowedApps.length === 0 &&
                    this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY &&
                    updatedSubscription === 'Basic'
                ) {
                    for (let i = 0; i < this.state.allSubscriptionTiers.length; i++) {
                        if (this.state.allSubscriptionTiers[i]['subscription-level'] === 'Standard') {
                            allowedApps = JSON.parse(JSON.stringify(this.state.allSubscriptionTiers[i]['apps']));
                            allowedContentTypes = JSON.parse(
                                JSON.stringify(this.state.allSubscriptionTiers[i]['content_types'])
                            );
                            licenseStartDate = JSON.parse(
                                JSON.stringify(this.state.allSubscriptionTiers[i]['license-start-date'])
                            );
                            licenseEndDate = JSON.parse(
                                JSON.stringify(this.state.allSubscriptionTiers[i]['license-end-date'])
                            );
                            subscriptionStage = JSON.parse(JSON.stringify(this.state.allSubscriptionTiers[i]['stage']));
                        }
                    }
                }

                this.setState({
                    subscription: updatedSubscription,
                    subscriptionStage: subscriptionStage,
                    allowedApps: allowedApps,
                    allowedContentTypes: allowedContentTypes,
                    licenseStartDate: licenseStartDate,
                    licenseEndDate: licenseEndDate,
                    reloadDynamicFilters: true,
                    reloadAirlineSummary: true,
                    reloadAll: true
                });
            }
        }

        if (this.props.match.path !== prevProps.match.path) {
            this.fetchSesssion();

            this.setState({ reloadAirlineSummary: true, showSummary: true });
        }
    }

    getMainTitle() {
        console.debug(this.props.match);

        if (this.props) {
            if (this.props.match.path === '/app/details/:dataName') {
                const pageTitle = this.props.match.params.dataName;
                let dataTitle = ApiDataMap[pageTitle].headerLabel || pageTitle;
                return <div style={{ textTransform: 'capitalize' }}>{'All ' + dataTitle}</div>;
            } else {
                let path = this.props.match.path;
                if (this.props.match.path === '/app/') {
                    path = '/app';
                }
                return getTitleFromPath(path);
            }
        }

        return '';
    }

    handleDisclaimer() {
        //
    }

    isEreaderAndPACInternal() {
        if (
            this.props.match.path === '/app/entertainment/ereader' &&
            this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS
        ) {
            return true;
        }
        return false;
    }

    displayWorkbenchByPath() {
        if (this.props) {
            if (dashboardHelper.getExcludedPaths().includes(this.props.match.path)) {
                return false;
            }
        }

        if (
            this.props.match.path === '/app/entertainment/ereader' &&
            this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASONIC_AVIONICS
        ) {
            return false;
        }

        // TODO: remove after bluetooth quicksight dashboard is developed.
        if (
            this.props.match.path === '/app/web-portal/bluetooth' &&
            this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASONIC_AVIONICS
        ) {
            return false;
        }

        return true;
    }

    displayFilterAndSummaryByPath() {
        if (this.props) {
            if (dashboardHelper.getExcludedPaths().includes(this.props.match.path)) {
                return false;
            }
        }

        return true;
    }

    userHasAccess() {
        return AppConstants.USER_HAS_ACCESS_YES;

        /*let { tenantIcaoCode, allowedApps, userInPacRackTesterGroup, userInInsightsPLMGroup } = this.state;
        let userHasAccess = AppConstants.USER_HAS_ACCESS_CHECKING;

        if (
            this.props.match.path === '/app' ||
            this.props.match.path === '/app/' ||
            this.props.match.path === '/app/help' ||
            this.props.match.path === '/app/help/'
        ) {
            return AppConstants.USER_HAS_ACCESS_YES;
        }

        if (tenantIcaoCode && allowedApps && allowedApps.length > 0) {
            let activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true);

            loop1: for (let i = 0; i < activeMenuItems.length; i++) {
                let menuItem = activeMenuItems[i];
                if (menuItem.path === this.props.match.path || menuItem.path + '/' === this.props.match.path) {
                    if (
                        !menuItem.verifyApp ||
                        (menuItem.verifyApp && menuItem.isAdmin) ||
                        (menuItem.verifyApp && menuItem.isPacRackTester && userInPacRackTesterGroup) ||
                        (menuItem.verifyApp && menuItem.isInsightsPLM && userInInsightsPLMGroup) ||
                        (menuItem.verifyApp && allowedApps.includes(menuItem.title))
                    ) {
                        userHasAccess = AppConstants.USER_HAS_ACCESS_YES;
                    } else {
                        userHasAccess = AppConstants.USER_HAS_ACCESS_NO;
                    }
                    break;
                } else {
                    if (menuItem.children.length > 0) {
                        for (let i = 0; i < menuItem.children.length; i++) {
                            let childItem = menuItem.children[i];

                            if (
                                childItem.path === this.props.match.path ||
                                childItem.path + '/' === this.props.match.path
                            ) {
                                if (
                                    !childItem.verifyApp ||
                                    (childItem.verifyApp && childItem.isAdmin) ||
                                    (childItem.verifyApp && childItem.isPacRackTester && userInPacRackTesterGroup) ||
                                    (childItem.verifyApp && childItem.isInsightsPLM && userInInsightsPLMGroup) ||
                                    (childItem.verifyApp && allowedApps.includes(childItem.title)) ||
                                    (childItem.verifyApp &&
                                        childItem.isInsightsPLM &&
                                        userInInsightsPLMGroup &&
                                        this.context.view === 'allTenants')
                                ) {
                                    userHasAccess = AppConstants.USER_HAS_ACCESS_YES;
                                } else {
                                    userHasAccess = AppConstants.USER_HAS_ACCESS_NO;
                                }
                                break loop1;
                            }
                        }
                    }
                }
            }
        } else {
            if (this.state.isAdminTier) {
                if (dashboardHelper.getAdminTierAllowedPaths().includes(this.props.match.path)) {
                    if (dashboardHelper.getPacRackTesterAllowedPaths().includes(this.props.match.path)) {
                        if (this.state.userInPacRackTesterGroup) {
                            return AppConstants.USER_HAS_ACCESS_YES;
                        } else {
                            return AppConstants.USER_HAS_ACCESS_NO;
                        }
                    } else if (dashboardHelper.getInsightsPLMAllowedPaths().includes(this.props.match.path)) {
                        if (this.state.userInInsightsPLMGroup) {
                            return AppConstants.USER_HAS_ACCESS_YES;
                        } else {
                            return AppConstants.USER_HAS_ACCESS_NO;
                        }
                    } else {
                        if (this.state.userInAdminGroup) {
                            return AppConstants.USER_HAS_ACCESS_YES;
                        } else {
                            return AppConstants.USER_HAS_ACCESS_NO;
                        }
                    }
                } else {
                    return AppConstants.USER_HAS_ACCESS_NO;
                }
            } else {
                if (this.subscriptionNotFound()) {
                    return AppConstants.USER_HAS_NO_SUBSCRIPTION;
                } else {
                    return AppConstants.USER_HAS_ACCESS_CHECKING;
                }
            }
        }

        return userHasAccess;*/
    }

    isPaidTier() {
        let paidTier = false;
        if (typeof this.state.subscription !== 'undefined') {
            if (paidTiers.indexOf(this.state.subscription) !== -1) {
                paidTier = true;
            }
        }

        // Only for Dev environment
        // if (process.env.REACT_APP_STAGE === 'cloud9' || process.env.REACT_APP_STAGE === 'dev') {
        //     if (this.state.tenantIcaoCode) {
        //         const freeTierTenants = ["dlh", "etd", "aca", "tap"];
        //         paidTier = !freeTierTenants.includes(this.state.tenantIcaoCode.toLowerCase());
        //     }
        // }

        return paidTier;
    }

    isBasicTier() {
        let isBasicTier = false;
        if (typeof this.state.subscription !== 'undefined') {
            if (this.state.subscription === basicTier) {
                isBasicTier = true;
            }
        }
        return isBasicTier;
    }

    isStage1() {
        let isStage1 = true;
        if (typeof this.state.subscriptionStage !== 'undefined' && typeof this.state.tenantIcaoCode !== 'undefined') {
            if (
                (this.state.subscriptionStage > 1 && this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASKY) ||
                this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY
            ) {
                isStage1 = false;
            }
        }

        return isStage1;
    }

    subscriptionNotFound() {
        let subscriptionNotFound = false;
        if (typeof this.state.subscription !== 'undefined') {
            if (this.state.subscription === 'No Subscription') {
                subscriptionNotFound = true;
            }
        }
        return subscriptionNotFound;
    }

    isForceReloadDynamicFilters() {
        if (this.state.reloadDynamicFilters) {
            this.setState({ reloadDynamicFilters: false });
            return true;
        }

        return false;
    }

    isForceReloadAirlineSummary() {
        if (this.state.reloadAirlineSummary) {
            this.setState({ reloadAirlineSummary: false });
            return true;
        }

        return false;
    }

    isForceReloadTrend() {
        if (this.state.reloadTrend) {
            this.setState({ reloadTrend: false });
            return true;
        }

        return false;
    }

    isForceReloadAll() {
        if (this.state.reloadAll) {
            this.setState({ reloadAll: false });
            return true;
        }

        return false;
    }

    updateTimestamp = (timestamp) => {
        this.setState({ timestamp: timestamp });
    };

    getDashboardComponent() {
        let { dataset, tenantId, subscription, subscriptionStage, allowedApps } = this.state;
        let tenantIcaoCode = window.sessionStorage.getItem('pac_selected_tenant_icao_code');

        if (this.state.error) {
            return (
                <div>
                    <Card>
                        <CardBody>
                            <h3>Data Unavailable</h3>
                        </CardBody>
                    </Card>
                </div>
            );
        } else {
            let showWorkbench = this.isPaidTier();
            let forceReloadTrend = this.isForceReloadTrend();
            let forceReloadAll = this.isForceReloadAll();
            let refreshAirlineSummary = this.isForceReloadAirlineSummary();
            const maintitle = this.getMainTitle();
            const appName = dashboardHelper.getAppName(this.props.match.path);
            return (
                <div className="components-wrapper" style={{ display: this.state.showSummary ? 'block' : 'none' }}>
                    <ChartsDashBoard
                        tenantId={tenantId}
                        tenantIcaoCode={tenantIcaoCode}
                        subscription={subscription}
                        app={appName}
                        subscriptionStage={subscriptionStage}
                        dataset1={dataset}
                        showWorkbench={showWorkbench}
                        tabFunction={this.changeTab}
                        updateQuicksightFiltersFunction={this.updateQuicksightFilters}
                        appliedFilters={this.state.appliedFilters}
                        forceReloadTrend={forceReloadTrend}
                        forceReloadAll={forceReloadAll}
                        allowedApps={allowedApps}
                        timestampFunction={this.updateTimestamp}
                        refreshAirlineSummary={refreshAirlineSummary}
                        onlyComparisonRangeUpdated={this.state.onlyComparisonRangeUpdated}
                        title={maintitle}
                        {...this.props}
                    />
                </div>
            );
        }
    }

    getQuicksightWorkbenchDashboardComponent() {
        let { tenantId, tenantIcaoCode, appliedFilters, allowedContentTypes } = this.state;

        if (this.state.error) {
            return (
                <div className="workbench-wrapper" style={{ display: this.state.showSummary ? 'none' : 'block' }}>
                    Data Unavailable
                </div>
            );
        } else {
            let componentKey = quicksightDataHelper.getWorkbenchComponentKey(this.props.match.path);
            const appName = dashboardHelper.getAppName(this.props.match.path);
            return (
                <div
                    className="workbench-wrapper"
                    style={{ display: this.state.showSummary && !this.isEreaderAndPACInternal() ? 'none' : 'block' }}
                >
                    <QuicksightWorkbenchComponent
                        ref={this.quicksightWorkbenchRef}
                        key={componentKey}
                        forceLoad={true}
                        tenantId={tenantId}
                        tenantIcaoCode={tenantIcaoCode}
                        app={appName}
                        path={this.props.match.path}
                        tabFunction={this.changeTab}
                        filters={appliedFilters && appliedFilters.quicksight ? appliedFilters.quicksight : ''}
                        allowedContentTypes={allowedContentTypes}
                        {...this.props}
                    />
                </div>
            );
        }
    }

    updateQuicksightFilters = (filters) => {
        // todo : add another parameter and set it from dashboarcbuilder.componentdidupate-IF
        if (filters) {
            if (this.quicksightWorkbenchRef.current) {
                this.quicksightWorkbenchRef.current.updateFilters(filters);
            } else {
                setTimeout(
                    function () {
                        if (this.quicksightWorkbenchRef.current) {
                            this.quicksightWorkbenchRef.current.updateFilters(filters);
                        }
                    }.bind(this),
                    3000
                );
            }
        }
    };

    changeTab = (tabIndex, workbenchType, scrollToTop) => {
        if (tabIndex === 1) {
            this.setState({ showSummary: false });
            this.quicksightWorkbenchRef.current.changeSelected(workbenchType);
        } else {
            this.setState({ showSummary: true });
            if (this.state.reloadTrendInQueue) {
                this.setState({ reloadTrend: true });
            }
        }

        if (this.pageTabsRef.current) {
            this.pageTabsRef.current.setActive(tabIndex);
        }
    };

    handleSelectionUpdated(appliedFilters, onlyComparisonRangeUpdated) {
        if (typeof this.state.subscription !== 'undefined') {
            if (!this.isPaidTier()) {
                if (appliedFilters.api && appliedFilters.api.comparison_range) {
                    delete appliedFilters.api.comparison_range;
                    delete appliedFilters.api.comparison_range_text;
                }
            }
        }

        if (!this.state.showSummary) {
            this.setState({ reloadTrendInQueue: true });
        }

        this.setState({ appliedFilters: appliedFilters, onlyComparisonRangeUpdated: onlyComparisonRangeUpdated }, () =>
            this.refreshReports(appliedFilters)
        );
    }

    refreshReports(appliedFilters) {
        if (appliedFilters && appliedFilters.quicksight) {
            this.updateQuicksightFilters(appliedFilters.quicksight);
        }
    }

    render() {
        const maintitle = this.getMainTitle();
        const appName = dashboardHelper.getAppName(this.props.match.path);

        let userHasAccess = AppConstants.USER_HAS_ACCESS_CHECKING;
        let pacSubscription = sessionStorage.getItem('pac_subscription');
        if (
            this.state.tenantIcaoCode &&
            this.state.subscription &&
            (!pacSubscription || (pacSubscription && pacSubscription === this.state.subscription)) &&
            this.state.subscriptionSet &&
            this.state.subscription.length > 0
        ) {
            userHasAccess = this.userHasAccess();
        }

        if (userHasAccess === AppConstants.USER_HAS_ACCESS_YES) {
            if (
                this.subscriptionNotFound() ||
                (this.state.allowedApps.length === 0 &&
                    this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASONIC_AVIONICS)
            ) {
                if (this.props.match.path !== '/app/help' && this.props.match.path !== '/app/help/') {
                    let landingPagePath = dashboardHelper.getPagePath(this.state.allowedApps);
                    return <Redirect to={{ pathname: landingPagePath }} />;
                }
            }

            let subscriptionStatus = dashboardHelper.getSubscriptionStatus(
                this.state.licenseStartDate,
                this.state.licenseEndDate
            );
            if (subscriptionStatus !== AppConstants.SUBSCRIPTION_STATUS_ACTIVE) {
                if (this.props.match.path !== '/app/help' && this.props.match.path !== '/app/help/') {
                    let landingPagePath = dashboardHelper.getPagePath([]);
                    return <Redirect to={{ pathname: landingPagePath }} />;
                }
            }

            let ereaderAndPACInternal = this.isEreaderAndPACInternal();

            let dashboard = !ereaderAndPACInternal ? this.getDashboardComponent() : '';
            let quicksightWorkbenchDashboard = '';

            let showWorkbench = false;
            let paidTier = this.isPaidTier();
            let isStage1 = this.isStage1();
            if (paidTier) {
                if (this.displayWorkbenchByPath() && !isStage1) {
                    showWorkbench = true;
                }
            }

            // if (this.state.isAdminTier) {
            //     if (this.props.match.path === '/app/entertainment/ereader') {
            //         showWorkbench = true;
            //     }
            // }

            if (this.state.isTier0) {
                if (this.props.match.path === '/app/help') {
                } else {
                    if (this.props.match.path !== '/app/entertainment/ereader') {
                        return <Redirect to={{ pathname: '/app/entertainment/ereader' }} />;
                    }
                }
            }

            let currTenantIcaoCode = sessionStorage.getItem('pac_selected_tenant_icao_code');

            let showFilterAndSummary = this.displayFilterAndSummaryByPath();
            let refreshAirlineSummary = this.isForceReloadAirlineSummary();
            let refreshDynamicFilters = this.isForceReloadDynamicFilters();

            return (
                <div>
                    <div id="content-container" style={{ display: currTenantIcaoCode ? 'block' : 'none' }}>
                        <Row className="header">
                            {this.props.match.path === '/app/help' &&
                                subscriptionStatus !== AppConstants.SUBSCRIPTION_STATUS_ACTIVE && (
                                    <SubscriptionBanner
                                        subscriptionStatus={subscriptionStatus}
                                        licenseStartDate={this.state.licenseStartDate}
                                        licenseEndDate={this.state.licenseEndDate}
                                    />
                                )}

                            <Col md="12" sm="12" className="page-title">
                                {maintitle}
                                {maintitle === 'Overview' && (
                                    <Col md="12" className="page-date">
                                        {moment().format('MMMM DD, YYYY')}
                                    </Col>
                                )}
                            </Col>
                        </Row>

                        {showFilterAndSummary &&
                            !ereaderAndPACInternal &&
                            !(this.props.match.path === '/app/analytics/analytics-dashboard') && (
                                <div>
                                    <Row>
                                        <DynamicFilter
                                            ref={this.dynamicFilterRef}
                                            forceLoad={refreshDynamicFilters}
                                            showWorkbenchLink={paidTier}
                                            onSelectionUpdated={this.handleSelectionUpdated}
                                            title={maintitle}
                                            subscription={this.state.subscription}
                                            app={appName}
                                        />
                                    </Row>
                                    <Row>
                                        <ErrorBoundary>
                                            <AirlineSummary
                                                appliedFilters={this.state.appliedFilters}
                                                forceLoad={refreshAirlineSummary}
                                                onlyComparisonRangeUpdated={this.state.onlyComparisonRangeUpdated}
                                                title={maintitle}
                                                subscription={this.state.subscription}
                                                app={appName}
                                            />
                                        </ErrorBoundary>
                                    </Row>
                                </div>
                            )}

                        {dashboard}

                        {showWorkbench && quicksightWorkbenchDashboard}
                    </div>

                    <div
                        className={'data-wrapper custom-loading'}
                        style={{ display: currTenantIcaoCode ? 'none' : 'block' }}
                    >
                        <img src={Circular} alt="" />
                    </div>
                    {this.handleDisclaimer()}
                </div>
            );
        } else if (userHasAccess === AppConstants.USER_HAS_ACCESS_CHECKING) {
            return (
                <div className={'data-wrapper custom-loading'}>
                    <img src={Circular} alt="" />
                </div>
            );
        } else {
            return <Redirect to={{ pathname: '/' }} />;
        }
    }
}

export default DashboardBuilder;
