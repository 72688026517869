import BaseService from 'services/BaseService';

class QuickSightEmbedUrlService extends BaseService {
    constructor() {
        let apiName = 'QuickSightEmbedUrlServiceAPI';
        let collectionPath = '/dashboards';
        super(apiName, collectionPath);
    }

    async fetchUrl(dashboardId) {
        let path = ['dashboards', dashboardId].join('/');
        path = path + '?embed-url=true';

        let options = {};
        return this.makeGetApiCall(this.apiName, path, options);
    }
}

export default QuickSightEmbedUrlService;
