import UtilHelper from 'util/UtilHelper.js';

const utilHelper = new UtilHelper();

export default class ChartProperties {
    getChartProperties = function (type, unit) {
        let lineProperties = {
            options: {
                legend: {
                    display: false,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {
                                display: true
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090'
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#2a7fc9',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#2a7fc9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            },
            graphStyle2: {
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#5B00A9',
                pointBackgroundColor: '#7500c5',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#5B00A9',
                pointHoverBorderColor: '#5B00A9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                fill: false,
                borderDash: [3, 3],
                borderColor: '#5B00A9'
            }
        };

        let linePropertiesTrend = {
            options: {
                unit: 'count',
                legend: {
                    display: false,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    displayColors: false,
                    titleFontSize: 14,
                    titleMarginBottom: 10,
                    bodyFontSize: 14,
                    bodyFontFamily: "'Inter', sans-serif",
                    bodySpacing: 8,
                    xPadding: 16,
                    yPadding: 12,
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {},
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090',
                                crossAlign: 'far',
                                callback: (dataSet) => {
                                    return utilHelper.formatToUnitsForPillBox(dataSet) + '   ';
                                }
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#2a7fc9',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#2a7fc9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            },
            graphStyle2: {
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#5B00A9',
                pointBackgroundColor: '#7500c5',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#5B00A9',
                pointHoverBorderColor: '#5B00A9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                fill: false,
                borderDash: [3, 3],
                borderColor: '#5B00A9'
            }
        };

        let linePropertiesTrendTime = {
            options: {
                unit: 'count',
                legend: {
                    display: false,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {},
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090',
                                crossAlign: 'far',
                                callback: (dataSet) => {
                                    return utilHelper.secondsToHmsWithoutUnit(dataSet, 1) + '   ';
                                }
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#2a7fc9',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#2a7fc9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            },
            graphStyle2: {
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#5B00A9',
                pointBackgroundColor: '#7500c5',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#5B00A9',
                pointHoverBorderColor: '#5B00A9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                fill: false,
                borderDash: [3, 3],
                borderColor: '#5B00A9'
            }
        };

        let linePropertiesTrendRoundingMixed = {
            options: {
                unit: 'count',
                legend: {
                    display: false,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {},
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090',
                                crossAlign: 'far',
                                callback: (dataSet) => {
                                    return utilHelper.secondsToHours(dataSet, 1) + '   ';
                                }
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#2a7fc9',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#2a7fc9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            },
            graphStyle2: {
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#5B00A9',
                pointBackgroundColor: '#7500c5',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#5B00A9',
                pointHoverBorderColor: '#5B00A9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                fill: false,
                borderDash: [3, 3],
                borderColor: '#5B00A9'
            }
        };

        let linePropertiesTrendTimeMixed = {
            options: {
                unit: 'count',
                legend: {
                    display: false,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {},
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090',
                                crossAlign: 'far',
                                callback: (dataSet) => {
                                    return utilHelper.secondsToHoursMinutes(dataSet) + '   ';
                                }
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#2a7fc9',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#2a7fc9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            },
            graphStyle2: {
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#5B00A9',
                pointBackgroundColor: '#7500c5',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#5B00A9',
                pointHoverBorderColor: '#5B00A9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                fill: false,
                borderDash: [3, 3],
                borderColor: '#5B00A9'
            }
        };

        let linePropertiespop = {
            options: {
                legend: {
                    display: true,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            display: true,
                            position: 'left',
                            id: 'y-axis-1',
                            ticks: {
                                maxTicksLimit: 3,
                                fontColor: '#909090'
                            }
                        },
                        {
                            type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            display: true,
                            position: 'right',
                            id: 'y-axis-2',
                            ticks: {
                                maxTicksLimit: 3,
                                fontColor: '#909090'
                            }
                        }
                    ],
                    xAxes: [
                        {
                            maxTicksLimit: 3,
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 3,
                                fontColor: '#909090',
                                maxRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: true
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0.2,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'blue',
                pointBackgroundColor: 'blue',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'blue',
                pointHoverBorderColor: 'blue',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            }
        };

        let linePropertiesScatter = {
            options: {
                unit: 'count',
                legend: {
                    display: false
                },
                plugins: {
                    datalabels: {
                        display: true
                    }
                },
                tooltips: {
                    displayColors: false,
                    titleFontSize: 14,
                    titleMarginBottom: 10,
                    bodyFontSize: 14,
                    bodyFontFamily: "'Inter', sans-serif",
                    bodySpacing: 8,
                    xPadding: 16,
                    yPadding: 12,
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {},
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090',
                                crossAlign: 'far',
                                callback: (dataSet) => {
                                    return utilHelper.formatToUnitsForPillBox(dataSet) + '   ';
                                }
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            scaleLabel: {},
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0,
                                min: 0,
                                callback: (dataSet) => {
                                    return utilHelper.secondsToHmsForDynamicTabs(dataSet) + '   ';
                                }
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: true
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#ffffff',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 9,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#ffffff',
                pointHoverBorderWidth: 2,
                pointRadius: 6,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            }
        };

        switch (type) {
            case 'scatter':
                return linePropertiesScatter;
            case 'line':
                return lineProperties;
            case 'linetrend':
                if (unit === 'time') {
                    return linePropertiesTrendTime;
                } else if (unit === 'mixed') {
                    return linePropertiesTrendTimeMixed;
                } else if (unit === 'roundingMixed') {
                    return linePropertiesTrendRoundingMixed;
                }
                return linePropertiesTrend;
            case 'linesetpop':
                return linePropertiespop;
        }
    };
}
