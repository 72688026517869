function getDonutRequestedSectionsHelper(path) {
    let sectionList;

    switch (path) {
        case '/':
        case '/app/':
        case '/app':
            sectionList = [
                {
                    label: 'Passenger Engagement',
                    value: 'passenger_engagement',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_views',
                    dropdown: 1,
                    type: 'half',
                    default_show: '',
                    free_tier: 0
                },
                {
                    label: 'Passenger Touchpoints',
                    value: 'passenger_touchpoints',
                    requested_section: 'ped_vs_seatback',
                    default_value: 'ped_vs_seatback.total_views',
                    dropdown: 1,
                    type: 'half',
                    default_show: '',
                    free_tier: 1
                }
            ];
            break;
        case '/app/entertainment/movies':
            sectionList = [
                {
                    label: 'Passenger Engagement',
                    value: 'passenger_engagement',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_views',
                    dropdown: 1,
                    exclude_content_types: ['Others'],
                    exclude: ['Others'],
                    type: 'half',
                    default_show: 'Movies',
                    free_tier: 0
                },
                {
                    label: 'Passenger Touchpoints',
                    value: 'passenger_touchpoints',
                    requested_section: 'ped_vs_seatback',
                    default_value: 'ped_vs_seatback.total_views',
                    dropdown: 1,
                    type: 'half',
                    content_types: ['Movies'],
                    default_show: '',
                    free_tier: 1
                }
            ];
            break;
        case '/app/entertainment/tv-series':
            sectionList = [
                {
                    label: 'Passenger Engagement',
                    value: 'passenger_engagement',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_views',
                    dropdown: 1,
                    exclude_content_types: ['Others'],
                    exclude: ['Others'],
                    type: 'half',
                    default_show: 'TV Series',
                    free_tier: 0
                },
                {
                    label: 'Passenger Touchpoints',
                    value: 'passenger_touchpoints',
                    requested_section: 'ped_vs_seatback',
                    default_value: 'ped_vs_seatback.total_views',
                    dropdown: 1,
                    type: 'half',
                    content_types: ['TV Series'],
                    default_show: '',
                    free_tier: 1
                }
            ];
            break;
        case '/app/entertainment/audio':
            sectionList = [
                {
                    label: 'Passenger Engagement',
                    value: 'passenger_engagement',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_views',
                    dropdown: 1,
                    exclude_content_types: ['Others'],
                    exclude: ['Others'],
                    type: 'half',
                    default_show: 'Audio',
                    free_tier: 0
                },
                {
                    label: 'Passenger Touchpoints',
                    value: 'passenger_touchpoints',
                    requested_section: 'ped_vs_seatback',
                    default_value: 'ped_vs_seatback.total_views',
                    dropdown: 1,
                    type: 'half',
                    content_types: ['Audio'],
                    default_show: '',
                    free_tier: 1
                }
            ];
            break;
        case '/app/entertainment/games':
            sectionList = [
                {
                    label: 'Passenger Engagement',
                    value: 'passenger_engagement',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_views',
                    dropdown: 1,
                    exclude_content_types: ['Others'],
                    exclude: ['Others'],
                    type: 'half',
                    default_show: 'Games',
                    free_tier: 0
                },
                {
                    label: 'Passenger Touchpoints',
                    value: 'passenger_touchpoints',
                    requested_section: 'ped_vs_seatback',
                    default_value: 'ped_vs_seatback.total_views',
                    dropdown: 1,
                    type: 'half',
                    content_types: ['Games'],
                    default_show: '',
                    free_tier: 1
                }
            ];
            break;
        case '/app/entertainment/live-tv':
            sectionList = [
                {
                    label: 'Passenger Engagement',
                    value: 'passenger_engagement',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_views',
                    dropdown: 1,
                    exclude_content_types: ['Others'],
                    exclude: ['Others'],
                    type: 'half',
                    default_show: 'Live TV',
                    free_tier: 0
                },
                {
                    label: 'Passenger Touchpoints',
                    value: 'passenger_touchpoints',
                    requested_section: 'ped_vs_seatback',
                    default_value: 'ped_vs_seatback.total_views',
                    dropdown: 1,
                    type: 'half',
                    content_types: ['Live TV'],
                    default_show: '',
                    free_tier: 1
                }
            ];
            break;
        case '/app/entertainment/ereader':
            sectionList = [
                {
                    label: 'Passenger Engagement',
                    value: 'passenger_engagement',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_views',
                    dropdown: 1,
                    exclude_content_types: ['Others'],
                    exclude: ['Others'],
                    type: 'half',
                    default_show: 'Electronic Reader',
                    free_tier: 0
                },
                {
                    label: 'Passenger Touchpoints',
                    value: 'passenger_touchpoints',
                    requested_section: 'ped_vs_seatback',
                    default_value: 'ped_vs_seatback.total_views',
                    dropdown: 1,
                    type: 'half',
                    content_types: ['Electronic Reader'],
                    default_show: '',
                    free_tier: 1
                }
            ];
            break;
        case '/app/web-portal/seatback':
            sectionList = [
                {
                    label: 'Passenger Engagement',
                    value: 'passenger_engagement',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_views',
                    dropdown: 1,
                    type: 'half',
                    usage_source: 'Seatback',
                    default_show: ''
                }
            ];
            break;
        case '/app/web-portal/portal':
            sectionList = [
                {
                    label: 'Passenger Engagement',
                    value: 'passenger_engagement',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_views',
                    dropdown: 1,
                    type: 'half',
                    usage_source: 'Portal',
                    default_show: '',
                    free_tier: 0
                },
                {
                    label: 'Device Browsers',
                    value: 'portal_device_browser',
                    requested_section: 'portal_device_browser',
                    default_value: 'portal_device_browser.total_counts',
                    dropdown: 0,
                    type: 'half',
                    usage_source: 'Portal',
                    default_show: '',
                    free_tier: 1
                },
                {
                    label: 'Device OS',
                    value: 'portal_device_os',
                    requested_section: 'portal_device_os',
                    default_value: 'portal_device_os.total_counts',
                    dropdown: 0,
                    type: 'half',
                    usage_source: 'Portal',
                    default_show: '',
                    free_tier: 1
                },
                {
                    label: 'Device Type',
                    value: 'portal_device_type',
                    requested_section: 'portal_device_type',
                    default_value: 'portal_device_type.total_counts',
                    dropdown: 0,
                    type: 'half',
                    usage_source: 'Portal',
                    default_show: '',
                    free_tier: 1
                }
            ];
            break;
        case '/app/web-portal/portal-navigation':
            sectionList = [
                {
                    label: 'Device Browsers',
                    value: 'portal_device_browser',
                    requested_section: 'portal_device_browser',
                    default_value: 'portal_device_browser.total_counts',
                    dropdown: 0,
                    type: 'half',
                    usage_source: 'Portal',
                    default_show: '',
                    free_tier: 1
                },
                {
                    label: 'Device OS',
                    value: 'portal_device_os',
                    requested_section: 'portal_device_os',
                    default_value: 'portal_device_os.total_counts',
                    dropdown: 0,
                    type: 'half',
                    usage_source: 'Portal',
                    default_show: '',
                    free_tier: 1
                },
                {
                    label: 'Device Type',
                    value: 'portal_device_type',
                    requested_section: 'portal_device_type',
                    default_value: 'portal_device_type.total_counts',
                    dropdown: 0,
                    type: 'half',
                    usage_source: 'Portal',
                    default_show: '',
                    free_tier: 1
                }
            ];
            break;
        case '/app/web-portal/companion-app':
            sectionList = [
                {
                    label: 'Media launches by Content Type',
                    value: 'media_launches_by_content_type',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_counts',
                    dropdown: 0,
                    type: 'half',
                    usage_source: 'Companion App',
                    default_show: '',
                    free_tier: 1
                }
            ];
            break;
        default:
            sectionList = [
                {
                    label: 'Passenger Engagement',
                    value: 'passenger_engagement',
                    requested_section: 'passenger_engagement',
                    default_value: 'passenger_engagement.total_views',
                    dropdown: 0,
                    type: 'half',
                    default_show: '',
                    free_tier: 0
                }
            ];
            break;
    }

    return sectionList;
}

export default getDonutRequestedSectionsHelper;
