/*
  "datalabels": the key - the property name to match in the JSON response, value - the column header label to display on the grid.
                     Note: Headers are capitalized via the Grid CSS.
  
  "dataProps": additional data to add to a row but not rendered, key - matches that in JSON resonse, value is the datalabel key to attach the prop
  
  "defaultSort": array with the column name and sort direction which is either (DESC, ASC)
  
  "formats": object defines the data that needs to be formatted and which util.js function to use to format
  
  "colWidths": array for each column width
               Note: is the columns are resizable and have been resized, these values will only be retained for the first render  
*/

export const ApiDataMap = {
    movies: {
      dataLabels: {
        row_count: '#',
        name: "title",
        year: "year",
        genre: "genre",
        duration: "length",
        total_usage_time: "total viewing time",
        total_users: "total viewers",
        avg_usage_time: "avg. viewing time",
        avg_users: "avg. viewers",
        take_rate: "take rate",
        avg_completion_rate: "avg. completion rate"
      },
      defaultSort: ['total_usage_time', 'DESC'],
      formats: {  
        HMTimeFromStrFormat: ['duration'],
        HMTimeAltFormat:['total_usage_time', 'avg_usage_time'],
        formatToPercentage: ['take_rate', 'avg_completion_rate'],
        numberWithCommas: ['total_users', 'avg_users']
      },
      colWidths: [48, 342, 94, 181, 126, 200, 157, 192, 165, 123, 198]
    },

    "tv-series": {
      dataLabels: {
        row_count: '#',
        name: "title",
        year: "year",
        genre: "genre",
        duration: "length",
        total_usage_time: "total viewing time",
        total_users: "total viewers",
        avg_usage_time: "avg. viewing time",
        avg_users: "avg. viewers",
        take_rate: "take rate",
        avg_completion_rate: "avg. completion rate"
      },
      apiKey: 'tv-series',
      headerLabel: 'TV Series',
      defaultSort: ['total_usage_time', 'DESC'],
      formats: {  
        HmTimeFormat:['duration', 'total_usage_time', 'avg_usage_time'],
        formatToPercentage: ['take_rate', 'avg_completion_rate'],
        numberWithCommas: ['total_users', 'avg_users']
      },
      colWidths: [48, 342, 94, 181, 126, 200, 157, 192, 165, 123, 198]
    },

    audio: {
      dataLabels: {
        row_count: '#',
        name: "title",
        artist: "artist",
        year: "year",
        genre: "genre",
        total_usage_time: "total listening time",
        total_users: "total listeners",
        avg_usage_time: "avg. listening time",
        avg_users: "avg. listeners",
        take_rate: "take rate"
      },
      defaultSort: ['total_usage_time', 'DESC'],
      formats: { 
        HMTimeAltFormat:['total_usage_time', 'avg_usage_time'],
        formatToPercentage: ['take_rate', 'avg_completion_rate'],
        numberWithCommas: ['total_users', 'avg_users']
      },
      colWidths: [48, 310, 169, 89, 202, 207, 165, 202, 165, 125]
    },

    games: {
      dataLabels: {
        row_count: '#',
        name: "title",
        year: "year",
        genre: "genre",
        duration: "length",
        total_usage_time: "total playing time",
        total_users: "total players",
        avg_usage_time: "avg. playing time",
        avg_users: "avg. players",
        take_rate: "take rate",
        avg_completion_rate: "avg. completion rate"
      },
      defaultSort: ['total_usage_time', 'DESC'],
      formats: {  
        HmTimeFormat:['duration', 'total_usage_time', 'avg_usage_time'],
        formatToPercentage: ['take_rate', 'avg_completion_rate'],
        numberWithCommas: ['total_users', 'avg_users']
      },
      colWidths: [48, 342, 94, 181, 126, 200, 157, 192, 165, 123, 178]
    },

    livetv: {
      dataLabels: {
        row_count: '#',
        name: "title",
        year: "year",
        genre: "genre",
        duration: "length",
        total_usage_time: "total viewing time",
        total_users: "total viewers",
        avg_usage_time: "avg. viewing time",
        avg_users: "avg. viewers",
        take_rate: "take rate",
        avg_completion_rate: "avg. completion rate"
      },
      apiKey: 'live-tv',
      headerLabel: 'Live TV',
      defaultSort: ['total_usage_time', 'DESC'],
      formats: {  
        HmTimeFormat:['duration', 'total_usage_time', 'avg_usage_time'],
        formatToPercentage: ['take_rate', 'avg_completion_rate'],
        numberWithCommas: ['total_users', 'avg_users']
      },
      colWidths: [48, 342, 94, 181, 126, 200, 157, 192, 165, 123, 198]
    },
    
    connectivity: {
      dataLabels: {
        row_count: '#',
        name: 'Tail',
        flight_id: "Flight ID",
        route: "Route",
        xid: "XID",
        overall_avg: "Overall Avg",
        wap: "WAP",
        network: "Network",
        wisp: "WISP"
      },
      apiKey: 'connectivity',
      defaultSort: ['overall_avg', 'DESC'],
      formats: {  
        numberWithCommas: ['xid', 'overall_avg', 'wap', 'network', 'wisp']
      },
      colWidths: [48, 120, 100, 100, 100, 150, 150, 150, 150]
    }
};