import React from 'react';
import { Card } from 'reactstrap';
import _, { debounce } from 'lodash';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import ErrorBoundary from 'util/ErrorBoundary.js';
import DonutWidgetDropdown from 'components/updatedWidgets/DonutWidgetDropdown';
import * as AppConstants from 'constants/app/constants';
import AppDataDropdownHelper from 'data/app-data-dropdown-helper.js';
import AppDataRequestedSectionsHelper from 'data/app-data-requested-sections-helper.js';

const appDataDropdownHelper = new AppDataDropdownHelper();
const appDataRequestedSectionsHelper = new AppDataRequestedSectionsHelper();

class DonutContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: '',
            loading: true,
            error: false,
            requestedSections: []
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) ||
            prevProps.path !== this.props.path ||
            this.props.forceReloadAll
        ) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        this.setState({ loading: true });

        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));
        let sectionList = appDataRequestedSectionsHelper.getDonutRequestedSections(this.props.path);
        let requestedSections = [];
        let excludeContentTypes = [];
        let contentTypes = [];
        let usageSource = null;
        for (let i = 0; i < sectionList.length; i++) {
            if (this.props.renderType === 'row') {
                if (this.props.showWorkbenchLink) {
                    requestedSections.push(sectionList[i].requested_section);
                } else {
                    if (sectionList[i].free_tier === 1) {
                        requestedSections.push(sectionList[i].requested_section);
                    }
                }
            } else {
                if (this.props.donut_value && sectionList[i].requested_section === this.props.donut_value) {
                    requestedSections.push(sectionList[i].requested_section);
                    if (sectionList[i].usage_source) {
                        usageSource = sectionList[i].usage_source;
                    }
                }
            }

            if (
                excludeContentTypes.length === 0 &&
                sectionList[i].exclude_content_types &&
                sectionList[i].exclude_content_types.length > 0
            ) {
                excludeContentTypes = sectionList[i].exclude_content_types;
            }
            if (contentTypes.length === 0 && sectionList[i].content_types && sectionList[i].content_types.length > 0) {
                contentTypes = sectionList[i].content_types;
            }
        }

        selectedFiltersDeepClone.requested_sections = requestedSections;
        if (excludeContentTypes.length > 0) {
            selectedFiltersDeepClone.exclude_content_types = excludeContentTypes;
        }

        if (contentTypes.length > 0) {
            selectedFiltersDeepClone.content_types = contentTypes;
        }

        if (usageSource) {
            selectedFiltersDeepClone.usage_source = usageSource;
        }
        if (usageSource === 'Companion App') {
            delete selectedFiltersDeepClone.usage_source;
            selectedFiltersDeepClone.launched_by = ['Companion App'];
        }

        if (this.props.subscription) {
            selectedFiltersDeepClone['tier'] = this.props.subscription;
        }

        if (this.props.app) {
            selectedFiltersDeepClone['app'] = this.props.app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        this.throttleFetch(selectedFiltersDeepClone);
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchEngagementMetricsAxios(selectedFiltersDeepClone, this.cancelRequestSource.token)
            .then((response) => {
                if (!response && !response.requested_sections) {
                    this.setState({ error: true, dataset: [], loading: false });
                } else {
                    this.setState({ error: false, dataset: response.requested_sections, loading: false });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, dataset: [], loading: true });
                } else {
                    this.setState({ error: true, dataset: [], loading: false });
                }
            });
    }

    getDonutWidget(item) {
        let { dataset, loading } = this.state;

        let dropdownList;
        if (this.props.pageName === 'EReader') {
            dropdownList = appDataDropdownHelper.getDonutDropdownList('ereader_' + item.requested_section);
        } else if (this.props.pageName === 'Companion App') {
            dropdownList = appDataDropdownHelper.getDonutDropdownList('media_launches_by_content_type');
        } else if (this.props.pageName === 'Audio') {
            dropdownList = appDataDropdownHelper.getDonutDropdownList('audio_' + item.requested_section);
        } else if (this.props.pageName === 'Games') {
            dropdownList = appDataDropdownHelper.getDonutDropdownList('games_' + item.requested_section);
        } else {
            dropdownList = appDataDropdownHelper.getDonutDropdownList(item.requested_section);
        }
        if (!this.props.showWorkbenchLink && dropdownList) {
            dropdownList = dropdownList.filter((item) => item.free_tier === 1);
        }

        let showLegend = true;
        let showMetrics = false;
        if (
            item.value === 'passenger_engagement' ||
            item.value === 'passenger_touchpoints' ||
            item.value.includes('portal_device') ||
            item.value === 'media_launches_by_content_type'
        ) {
            showMetrics = true;
        }

        return (
            <Card className="bar-chart bar-chart-gap" key={'donut_' + item.value + this.props.path}>
                <ErrorBoundary>
                    <DonutWidgetDropdown
                        key={item.value}
                        donut_name={item.value}
                        type={item.type}
                        defaultKey="data"
                        defaultShow={item.default_show}
                        showLegend={showLegend}
                        showMetrics={showMetrics}
                        data={dataset ? dataset : []}
                        loading={loading}
                        ddOptions={dropdownList}
                        dd1Options={dropdownList}
                        dd2Options={[]}
                        donut_type={item.value}
                        title={item.label}
                        unit="count"
                        showFooter={false}
                        page={this.props.page}
                        pageName={this.props.pageName}
                        tabFunction={this.props.tabFunction}
                        workbench_type={item.value}
                        showWorkbenchLink={this.props.showWorkbenchLink}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        appliedFilters={this.props.appliedFilters.api}
                        subscription={this.props.subscription}
                        app={this.props.app}
                        subscriptionStage={this.props.subscriptionStage}
                    />
                </ErrorBoundary>
            </Card>
        );
    }

    renderDonutWidgets() {
        let sectionList = appDataRequestedSectionsHelper.getDonutRequestedSections(this.props.path);
        let componentList = [];
        for (let i = 0; i < sectionList.length; i++) {
            //@https://jira.panasonic.aero/browse/INSI-2702
            if (
                sectionList[i].value === 'passenger_engagement' &&
                this.props.allowedApps &&
                this.props.allowedApps.length === 1
            ) {
                continue;
            }

            if (this.props.renderType === 'row') {
                if (this.props.showWorkbenchLink) {
                    componentList.push(this.getDonutWidget(sectionList[i]));
                } else {
                    if (sectionList[i].free_tier === 1) {
                        componentList.push(this.getDonutWidget(sectionList[i]));
                    }
                }
            } else {
                if (this.props.donut_value && sectionList[i].requested_section === this.props.donut_value) {
                    if (this.props.showWorkbenchLink) {
                        componentList.push(this.getDonutWidget(sectionList[i]));
                    } else {
                        if (sectionList[i].free_tier === 1) {
                            componentList.push(this.getDonutWidget(sectionList[i]));
                        }
                    }
                }
            }
        }

        return <React.Fragment>{componentList}</React.Fragment>;
    }

    render() {
        return <React.Fragment>{this.renderDonutWidgets()}</React.Fragment>;
    }
}

export default DonutContainer;
