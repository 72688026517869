import EntertainmentOverview from 'pages/entertainment/EntertainmentOverview';
import MoviesOverview from 'pages/entertainment/MoviesOverview';
import AudioOverview from 'pages/entertainment/AudioOverview';
import GamesOverview from 'pages/entertainment/GamesOverview';
import LiveTVOverview from 'pages/entertainment/LiveTVOverview';
import TVSeriesOverview from 'pages/entertainment/TVSeriesOverview';
import MediaDetails from 'pages/entertainment/MediaDetails';
import EReaderOverview from 'pages/entertainment/EReaderOverview';
import AnalyticsDashboardOverview from 'pages/analytics/AnalyticsDashboardOverview';
import AdvertisingDashboardOverview from 'pages/advertising/AdvertisingDashboardOverview';
import OffloadsDashboardOverview from 'pages/offloads/OffloadsDashboardOverview';
import RackOffloadDashboardOverview from 'pages/rack-test-data/RackOffloadDashboardOverview';
import SeatbackOverview from 'pages/portal/SeatBackOverview';
import PortalOverview from 'pages/portal/PortalOverview';
import PortalNavigationOverview from 'pages/portal/PortalNavigationOverview';
import CompanionAppOverview from 'pages/portal/CompanionAppOverview';
import BluetoothOverview from 'pages/portal/BluetoothOverview';

const dashboards = [
    {
        key: '/app/',
        title: 'IFE Summary',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<EntertainmentOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/entertainment/movies',
        title: 'Media',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<MoviesOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/entertainment/audio',
        title: 'Audio',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<AudioOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/entertainment/live-tv',
        title: 'Live TV',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<LiveTVOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/entertainment/games',
        title: 'Games',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<GamesOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/entertainment/tv-series',
        title: 'TV Series',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<TVSeriesOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/entertainment/media-details',
        title: 'Media Detials',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<MediaDetails />] }]
                }
            ]
        }
    },
    {
        key: '/app/entertainment/ereader',
        title: 'e-Reader',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<EReaderOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/web-portal/seatback',
        title: 'Seatback',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<SeatbackOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/web-portal/portal',
        title: 'Portal',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<PortalOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/web-portal/portal-navigation',
        title: 'Portal Navigation',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<PortalNavigationOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/web-portal/companion-app',
        title: 'Companion App',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<CompanionAppOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/web-portal/bluetooth',
        title: 'Bluetooth',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<BluetoothOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/rack-test-data/rack-offload-dashboard',
        title: 'Rack Test Data',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<RackOffloadDashboardOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/analytics/analytics-dashboard',
        title: 'NEXT Insights Analytics',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<AnalyticsDashboardOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/advertising',
        title: 'Advertising',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<AdvertisingDashboardOverview />] }]
                }
            ]
        }
    },
    {
        key: '/app/offloads/paxus-offloads',
        title: 'Offloads',
        layout: {
            rows: [
                {
                    columns: [{ cards: [<OffloadsDashboardOverview />] }]
                }
            ]
        }
    }
];

export default dashboards;
